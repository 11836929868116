import { ColorScheme } from '../types';
import { Language } from '../api/languages.types';
import { Role } from './enums';
import packageJson from '../../package.json';

export const isServiceWorkerSupported = 'serviceWorker' in window.navigator;
export const browserLanguage = window.navigator?.language || window.navigator?.languages[0];

export const fallbackLanguage: Language = {
  language: 'eng',
  languageCode: 'en',
  name: 'English'
};

export const AppVersion = packageJson.version;
export const VERSION_DIFFERS_TIME_RELOAD = 3;
export const TEMPLATE_TITLE_LIMIT = 50;
export const CHAT_MESSAGE_LIMIT = 10_000;
export const COMPONENT_COLOR_DEFAULT: ColorScheme = 'green';

export const NO_NOTIFICATIONS_RE_FETCH_INTERVAL = 15_000;

export const isDev = !process.env.NODE_ENV
  || process.env.NODE_ENV === 'development' || process.env.REACT_APP_BASE_SERVER === 'dev';

export const REQUEST_CATEGORY_LIMIT_MAX = 100;
export const TEMPLATE_ATTACH_MAX = 4;

export const QUERY_KEY_INTEREST_CHANNELS = 'interestChannels';

export const AWRUserId = '999999999';
export const BroadcastAllId = '0';

export const SERVER_URL = {
  prod: 'https://awrchatbot.org/orion',
  dev: 'https://awrinfo.org/orion',
  hope: 'https://awrhope.org/orion',
  hope_staging: 'https://staging.awrhope.org/orion',
};

export const SEARCH_TIMEOUT = 1000;

export const BaseUrl = SERVER_URL.prod;

console.log(`%c==================== IS DEV: ${isDev}`, 'background: black; color: white;');

export type AuthAccountsTypes = typeof registerAccountTypeDM | typeof registerAccountTypeCM;
export type RegistrationSteps = typeof registerStepVerification | typeof registerStepSetup;

export const registerAccountTypeDM = 'dm';
export const registerAccountTypeCM = 'manager';

export const registerStepVerification = 'verify';
export const registerStepSetup = 'setup';

export const totalTabId = 'total';

const urlRegister = '/register';
const urlLogin = '/login';
const urlConnect = '/connect';
const urlTeams = '/teams';
const urlManageTeams = urlTeams + '/manage-teams';
const urlAdmin = '/admin';

export const URLS = {
  main: '/',

  dashboard: '/dashboard',

  admin: urlAdmin,
  accounts: urlAdmin + '/accounts',
  interests: urlAdmin + '/interests',
  adminAwrHelp: urlAdmin + '/awr-help',
  vetters: urlAdmin + '/vetters',
  api: '/api',

  teams: urlTeams,
  awrHelp: urlTeams + '/awr-help',
  dmChats: urlTeams + '/dm-chats',
  leaderChats: urlTeams + '/leader-chats',
  campaignChats: urlTeams + '/team-chats',

  manageTeams: urlManageTeams,
  teamLeaders: urlManageTeams + '/team-leaders',
  digitalMissionaries: urlManageTeams + '/digital-missionaries',

  campaigns: '/campaigns',
  knowledge: '/knowledge',
  profile: '/profile',
  userProfile: '/user-profile',
  settings: '/settings',
  FAQ: '/faq',

  connect: urlConnect,
  requests: urlConnect + '/requests',
  reminders: urlConnect + '/reminders',
  seekersInactive: urlConnect + '/inactive-interests',
  broadcasts: urlConnect + '/broadcasts',
  seekers: urlConnect + '/interests',
  evangelistChats: urlConnect + '/missionaries',
  prayerList: urlConnect + '/prayer-list',

  students: '/students',

  myInformation: '/settings/my-information',

  checkProfiles: '/check-profiles',

  register: urlRegister,
  registerStepVerification: urlRegister + '/' + registerStepVerification,
  registerStepSetup: urlRegister + '/' + registerStepSetup,

  login: urlLogin,
  loginRestore: urlLogin + '/restore'
};

export const Routes = [
  {
    label: 'accounts',
    url: URLS.accounts,
    role: [Role.ADMIN],
    group: URLS.admin,
  },
  {
    label: 'interests',
    url: URLS.interests,
    role: [Role.ADMIN],
    group: URLS.admin,
  },
  {
    label: 'awrHelp',
    url: URLS.adminAwrHelp,
    role: [Role.ADMIN],
    group: URLS.admin,
  },
  {
    label: 'admin',
    url: URLS.admin,
    role: [Role.ADMIN],
    group: URLS.admin,
  },
  {
    label: 'connect',
    role: [Role.DM, Role.LEADER, Role.CM, Role.ADMIN],
    url: URLS.seekers,
    group: URLS.connect
  },
  {
    label: 'requests',
    role: [Role.DM, Role.LEADER, Role.CM],
    url: URLS.requests,
    group: URLS.connect
  },
  {
    label: 'reminders',
    role: [Role.DM, Role.LEADER, Role.CM],
    url: URLS.reminders,
    group: URLS.connect
  },
  {
    label: 'inactiveSeekers',
    role: [Role.DM, Role.LEADER, Role.CM],
    url: URLS.seekersInactive,
    group: URLS.connect
  },
  {
    label: 'broadcasts',
    role: [Role.DM, Role.LEADER, Role.CM],
    url: URLS.broadcasts,
    group: URLS.connect
  },
  {
    label: 'prayerList',
    role: [Role.DM, Role.LEADER, Role.CM],
    url: URLS.prayerList,
    group: URLS.connect
  },
  {
    url: URLS.connect,
    role: [Role.DM, Role.LEADER, Role.CM, Role.ADMIN],
    label: 'connect',
  },
  {
    url: URLS.manageTeams,
    role: [Role.DM, Role.LEADER, Role.CM, Role.ADMIN],
    label: 'manageTeams',
    group: URLS.teams,
    fullPath: true,
  },
  {
    url: URLS.teams,
    role: [Role.DM, Role.LEADER, Role.CM, Role.ADMIN],
    label: 'teams'
  },
  {
    url: URLS.campaigns,
    role: [Role.DM, Role.LEADER, Role.CM],
    label: 'campaigns'
  },
  {
    url: URLS.myInformation,
    role: [Role.DM, Role.LEADER, Role.CM],
    label: 'myInformation'
  },
  {
    url: URLS.profile,
    role: [Role.DM, Role.LEADER, Role.CM],
    label: 'profile'
  },
  {
    url: URLS.settings,
    role: [Role.DM, Role.LEADER, Role.CM],
    label: 'settings'
  },
  {
    url: URLS.FAQ,
    role: [Role.DM, Role.LEADER, Role.CM],
    label: 'faqs'
  },
  {
    label: 'knowledgeBase',
    role: [Role.DM, Role.LEADER, Role.CM],
    url: URLS.knowledge
  },
  {
    url: URLS.main,
    role: [Role.DM],
    label: 'mission'
  },
  {
    url: URLS.main,
    role: [Role.LEADER, Role.CM, Role.ADMIN],
    label: 'dashboard'
  },
  {
    label: 'students',
    role: [Role.DM],
    url: URLS.students,
    group: URLS.students
  }
];
