import React, {
  createContext, CSSProperties,
  EventHandler,
  FormEvent,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';

import { Validator } from './Form.utils';
import { useToastEnhanced } from '../../enhanced-components/toaster/ToasterEnhanced';
import { InputTagsValue } from '../input/InputTags';
import { DatePickerValue } from '../date-picker/DatePicker';
import {MultiSelectValue} from '../multi-select/MultiSelect.types';
import {TimePickerValue} from '../time-picker/TimePicker.types';
import { CriteriaList } from '../index';
import { SelectOption } from '../select/Select.types';

const FormContext = createContext<FormContextProps>({} as FormContextProps);

const createEmptyFormValidationResult: () => FormValidationResult = () => {
  return {
    fieldError: '',
    toastError: {
      shown: false,
      title: '',
      checkList: []
    }
  };
};

export type FormValidator = Validator<any>;

type FormContextProps = {
  unregisterField: (name: string) => void,
  registerField: (name: string, validators: FormValidator[]) => void,
  validationResults: FormValidationResults,
  values: FormValues,
  touched: FormTouched,
  wasSubmitted: boolean,
  handleFocus: (fieldName: string) => void,
  handleBlur: (fieldName: string) => void,
  handleChange: (fieldName: string, value: FormValues['field']) => void,
};

type FormValidationResult = {
  fieldError: string,
  toastError: {
    title: string,
    checkList: ReturnType<Validator<any>>[],
  },
}

type FormValidationResults = {
  [field: string]: FormValidationResult,
}

type FormTouched = Set<string>

type FormValues = {
  [field: string]:
    string | number | boolean | undefined |
    SelectOption | MultiSelectValue | InputTagsValue | DatePickerValue | TimePickerValue,
}

export type FormSubmitDataParam<Fields = FormValues> = { values: Fields, isValid: boolean, };

export type FormSubmitHandler<Fields> = (data: FormSubmitDataParam<Fields>) => void | Promise<void>;

export type FormChildrenProps<Fields = FormValues> = {
  isSubmitting: boolean,
  values: Fields,
  submit: () => Promise<void> | void,
  updateValues: (values: Partial<Fields>) => void,
};

type FormProps = {
  className?: string,
  style?: CSSProperties,
  validateFormOnFieldBlur?: boolean,
  fields: FormValues,
  onSubmit: FormSubmitHandler<any>,
  children: ((data: FormChildrenProps<any>) => ReactNode),
}

const Form: React.FC<FormProps> = (
  { fields, children, onSubmit, validateFormOnFieldBlur = true, ...restProps }
) => {
  const { t } = useTranslation();
  const { showToast } = useToastEnhanced();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [wasSubmitted, setWasSubmitted] = useState(false);
  // Is needed to cancel async tasks (e.g. setIsSubmitting after async submit).
  const isMountedRef = useRef(false);
  const formRef = useRef<HTMLFormElement | null>(null);
  const validatorsRef = useRef<{ [field: string]: FormValidator[], } | null>(null);
  // State validation results storing to invoke re-render on re-validation.
  const [validationResults, setValidationResults] = useState(
    Object.keys(fields).reduce((acc: FormValidationResults, fieldName) => {
      acc[fieldName] = createEmptyFormValidationResult();
      return acc;
    }, {})
  );
  const [touched, setTouched] = useState<FormTouched>(new Set());
  const [values, setValues] = useState<FormValues>(fields);

  const getErrorMessageForField = (fieldName: string) => {
    if (fieldName === 'email' || fieldName === 'pastorEmail') {
      return 'errorInvalidEmail';
    }

    if (fieldName === 'password') {
      return 'errorInvalidPass';
    }

    if (fieldName === 'confirmPassword') {
      return 'errorConfirmPass';
    }

    if (fieldName === 'phoneNumber') {
      return 'errorInvalidPhone';
    }

    if (fieldName === 'postalCode') {
      return 'errorInvalidPostal';
    }

    if (fieldName === 'firstName') {
      return 'errorFirstName';
    }

    if (fieldName === 'pastorName') {
      return 'errorPastorName';
    }

    if (fieldName === 'lastName') {
      return 'errorLastName';
    }

    return '';
  };

  const getFieldValidationResult = useCallback((
    fieldName: string, valueImmediate?: FormValues['field']
  ): FormValidationResult => {
    let out: FormValidationResult = createEmptyFormValidationResult();

    if (validatorsRef.current?.[fieldName]) {
      const value = valueImmediate ?? values[fieldName];

      // Goes through each field's validator an defines validation result.
      for (let validator of validatorsRef.current[fieldName]) {
        const validationRes = validator(value);

        if (
          validationRes.label[0] !== 'errorFieldRequired'
        ) { // Crutch: Don't show "Is required" in toast's check list.
          out.toastError.checkList.push(validationRes);
        }

        if (!validationRes.valid && !out.fieldError) {
          if (
            validationRes.label[0] === 'errorFieldRequired'
          ) { // Crutch: Don't show "Is required" toast.
            out.fieldError = 'errorFieldRequired';
            out.toastError.title = '';
          } else if (
            fieldName === 'confirmPassword'
          ) { // Crutch: Don't show "Password doesn't match" toast.
            out.fieldError = 'errorConfirmPass';
            out.toastError.title = '';
          } else {
            out.fieldError = getErrorMessageForField(fieldName);
            out.toastError.title = getErrorMessageForField(fieldName);
          }
        }
      }
    }

    return out;
  }, [values]);

  const getFieldsErrors = useCallback(() => {
    let res: FormValidationResults = {};

    for (let fieldName in fields) {
      res[fieldName] = getFieldValidationResult(fieldName);
    }

    return res;
  }, [fields, getFieldValidationResult]);

  const showFieldValidationErrorToast = useCallback((validationError: FormValidationResult) => {
    if (validationError.toastError.title) {
      let bodyTextLength = 0;
      const validationCriteriaList = validationError.toastError.checkList.map(
        ({ label, valid }) => {
          const criteriaTranslated = t(label[0], label[1]);

          bodyTextLength += criteriaTranslated.length;

          return {
            completed: valid,
            criteria: criteriaTranslated,
          };
        }
      );

      showToast({
        title: t(validationError.toastError.title) + ':',
        body: <CriteriaList items={validationCriteriaList}/>,
      }, {
        type: 'error',
        bodyTextLength,
      });
    }
  }, [showToast, t]);

  const validateForm = useCallback(() => {
    const errors = getFieldsErrors();
    let isValid = true;

    // Finds first possible error and shows toast with it.
    for (let key in errors) {
      if (errors[key].fieldError) {
        showFieldValidationErrorToast(errors[key]);
        isValid = false;
        break;
      }
    }

    setValidationResults(errors);

    return isValid;
  }, [getFieldsErrors, showFieldValidationErrorToast]);

  useEffect(() => {
    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const submit = useCallback(async () => {
    const isValid = validateForm();
    setIsSubmitting(true);
    setWasSubmitted(true);

    await onSubmit({
      values,
      isValid
    });

    if (isMountedRef.current) {
      setIsSubmitting(false);
    }
  }, [onSubmit, validateForm, values]);

  const handleSubmit: EventHandler<FormEvent<HTMLFormElement>> = useCallback(async (e) => {
    e.preventDefault();
    submit();
  }, [submit]);

  const validateField = useCallback((fieldName: string, value: FormValues['field']) => {
    // Sets an error message for the field.
    setValidationResults((errorsOld) => ({
      ...errorsOld,
      [fieldName]: getFieldValidationResult(fieldName, value)
    }));
  }, [getFieldValidationResult]);

  const handleFocus = useCallback((fieldName) => {
    // Resets an error message for the field.
    setValidationResults((errorsOld) => ({
      ...errorsOld,
      [fieldName]: createEmptyFormValidationResult()
    }));

    setTouched((touchedOld) => {
      if (!touchedOld.has(fieldName)) {
        return new Set(touchedOld.add(fieldName));
      }

      return touchedOld;
    });
  }, []);

  const handleBlur = useCallback((fieldName) => {
    if (validateFormOnFieldBlur) {
      validateForm();
    } else {
      validateField(fieldName, values[fieldName]);
    }
  }, [validateFormOnFieldBlur, validateForm, validateField, values]);

  const handleChange = useCallback((fieldName, value) => {
    setValues((valuesOld) => ({
      ...valuesOld,
      [fieldName]: value,
    }));

    validateField(fieldName, value);
  }, [validateField]);

  const registerField = useCallback((fieldName: string, validator: FormValidator[]) => {
    validatorsRef.current = {
      ...(validatorsRef.current || {}),
      [fieldName]: validator
    };
  }, []);

  const unregisterField = useCallback((fieldName: string) => {
    if (validatorsRef.current) {
      delete validatorsRef.current[fieldName];
    }
  }, []);

  const updateValues = useCallback((newValues: FormValues) => {
    setValues((oldValues) => ({
      ...oldValues,
      ...newValues
    }));
  }, []);

  const contextValue = useMemo<FormContextProps>(() => ({
    unregisterField,
    registerField,
    validationResults,
    touched,
    values,
    wasSubmitted,
    handleFocus,
    handleBlur,
    handleChange
  }), [
    values, validationResults, touched, wasSubmitted,
    handleFocus, handleBlur, handleChange,
    unregisterField, registerField
  ]);

  return (
    <FormContext.Provider value={contextValue}>
      <form
        {...restProps}
        ref={formRef}
        onSubmit={handleSubmit}
        noValidate
      >
        {children({ isSubmitting, values, submit, updateValues })}
      </form>
    </FormContext.Provider>
  );
};

export const useFormContext = () => useContext<FormContextProps>(FormContext);

export default Form;
