import React from 'react';
import { useTranslation } from 'react-i18next';

import AeFontIcons from '../../assets/Icons';
import { ButtonProps } from './Button';
import { Button } from '../index';

import './ButtonStopRecord.scss';

const ButtonStopRecord: React.FC<ButtonProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Button
      className='button-stop-record'
      variant='transparent'
      size='sm'
      iconBefore={AeFontIcons.ellipse}
      color='red'
      style={{ textTransform: 'uppercase' }}
      {...props}
    >{t('stop')}</Button>
  );
};

export default ButtonStopRecord;
