import { Role } from './enums';
import { isRoleIncludesLeader, isRoleInlcudesCM, isRoleInlcudesDM } from './profile';

export const DefaultFontSize = 18;

// [1,2,3] [1,2,4] => [3, 4]
export function symmetricDifference(arr1: any, arr2: any) {
  const result = [];
  for (let i = 0; i < arr1.length; i++) {
    if (arr2.indexOf(arr1[i]) === -1) {
      result.push(arr1[i]);
    }
  }
  for (let i = 0; i < arr2.length; i++) {
    if (arr1.indexOf(arr2[i]) === -1) {
      result.push(arr2[i]);
    }
  }
  return result;
}

export function gather<T, K extends keyof T>(arr: Array<T>, key: K): Array<T[K]> {
  return arr?.map((item) => item[key]);
}

type User = {
  firstname: string | null,
  lastname: string | null,
  email?: string | null,
  role?: Role | null,
}

export const makeLabelFullName = (user?: User, withRole = false) => {
  let out = '';

  if (user) {
    const { firstname, lastname, email, role } = user;

    if (firstname) {
      out += firstname;
    }

    if (lastname) {
      out += firstname ? (' ' + lastname) : lastname;
    }

    // FIXME role initially type
    if (withRole && (isRoleInlcudesCM(role as Role))) {
      out += ' ★';
    }

    if (!out) {
      out += email;
    }
  }

  return out || '?';
};

export const makeLabelLocation = (country: string | null, city: string | null) => {
  return (
    (country || '')
    + ((country && city) ? ', ' : '')
    + (city || '')
  );
};

export const getRoleTranslationString = (role: Role) => {
  if (+role === Role.ADMIN) {
    return 'admin';
  }

  if (isRoleInlcudesCM(role)) {
    return 'manager';
  }

  if (isRoleIncludesLeader(role)) {
    return 'leader';
  }

  if (isRoleInlcudesDM(role)) {
    return 'missionary';
  }

  return 'seeker';
};

export function pickFromObj<T extends object, U extends keyof T>(obj: T, values: U[]) {
  return values.reduce((acc, key) => {
    if (key in obj) {
      acc[key] = obj[key] || '';
    }

    return acc;
  }, {} as any);
}

export const convertToNumber = (value: string | number = 0) => {
  let temp = value;
  if (typeof temp === 'string') {
    temp = parseInt(temp, 10);
  }

  if (isNaN(temp)) {
    throw Error('Wrong string');
  }

  return temp;
};

export const hasDecimal = (num: number | string) => convertToNumber(num) % 1 === 0;

/**
 * @description Returns "value" if is in boundaries of "min" and "max"
 * Returns "max" if "value" more than "max"
 * Returns "min" if "value" less than "min"
 */
export const clutch = (value: number, min: number, max: number) => {
  if (min > max) {
    max = min;
    min = max;
  }

  if (isNaN(value)) {
    return min;
  }

  if (value > max) {
    return max;
  } else if (value < min) {
    return min;
  }

  return value;
};

export function getUserName<
  T extends {
    firstname: string | null;
    lastname: string | null;
    email?: string | null;
  }
  >(userData: T, fullname: boolean = true) {
  let name = '';
  if (userData.firstname) {
    name += userData.firstname;
  }
  if (userData.lastname && fullname) {
    name += name ? ' ' + userData.lastname : userData.lastname;
  }
  if (name.length > 0) {
    return name;
  }

  return userData.email || '';
}

export const blobToBase64 = (blob: Blob) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);

    reader.onloadend = () => {
      resolve(reader.result?.toString() || '');
    };

    reader.onerror = () => {
      reject('');
    };
  });
};

export const base64ToBlob = (base64Str: string) => {
  return fetch(base64Str).then((res) => res.blob());
};

export const camelToDash = (s: string) => {
  return s.replace(/[A-Z]/g, m => '-' + m.toLowerCase());
};

export const dashToCamel = (s: string) => {
  return s.replace(/-+(.)/g, (x, chr) => chr.toUpperCase());
};

export const getImageFromFile = (e: any) => {
  const img = new Image();

  const promise = new Promise<{width: number, height: number}>((resolve, reject) => {
    img.onload = () => {
      // Natural size is the actual image size regardless of rendering.
      // The 'normal' `width`/`height` are for the **rendered** size.
      const width  = img.naturalWidth || 0;
      const height = img.naturalHeight || 0;

      // Resolve promise with the width and height
      resolve({ width, height });
    };

    // Reject promise on error
    img.onerror = reject;
  });

  // Setting the source makes it start downloading and eventually call `onload`
  img.src = URL.createObjectURL(e.target.files?.[0]);

  return promise;
};

export const remToPx = (remValue: number) => remValue * DefaultFontSize;
export const bytesToMbs = (size: number) => ~~(size / 1024 / 1024);

export const getBrowser = () => {
  let ua = navigator.userAgent, tem,
    M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: 'IE', version: (tem[1] || '') };
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\bOPR|Edge\/(\d+)/);
    if (tem != null) {
      return { name: 'Opera', version: tem[1] };
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1]);
  }
  return {
    name: M[0],
    version: M[1]
  };
};

export const curryTotal = (total: number) => (value: number): number => {
  const result = (value / total * 100 || 0);

  if (result < 1 && result > 0) {
    return parseInt(result.toFixed(2), 10);
  }

  return Math.round(result);
};

export const fixMePlease = (context: string, ...consoleArgs: any[]) => {
  // eslint-disable-next-line no-console
  console.groupCollapsed('🤧 Fix me, please (' + context + ')');
  // eslint-disable-next-line no-console
  console.log(...consoleArgs);
  // eslint-disable-next-line no-console
  console.groupEnd();
};
