import { isBefore } from 'date-fns';
import parseISO from 'date-fns/parseISO';

import { ListCampaignsItem, ListCampaignsItemConcise } from '../api/campaigns.types';
import { Campaign } from '../api/leaders.types';
import { CommonCampaignItem } from '../components/campaigns/ListCampaignItem';
import { EvangelistItemCampaign } from '../api/evangelists.types';
import { Role } from './enums';

export const getIsCampaignFinished = (campaign: ListCampaignsItem) => {
  return campaign.endDate ? isBefore(parseISO(campaign.endDate), new Date()) : false;
};

export const getIsCampaignPrivate = (campaign: CommonCampaignItem) => {
  return campaign.hidden === '1';
};

export const getIsCampaignActive = <T extends Pick<Campaign, 'active'>>(campaign: T) => {
  return campaign.active === '1';
};

export const filterCampaignsByRole = (campaigns: EvangelistItemCampaign[], role: Role) => {
  const isDM = role === Role.DM;
  const isLeader = role === Role.LEADER;
  const filtered = [];

  for (const campaign of campaigns) {
    const numberRole = +campaign.role;
    const suitableRoleInCampaign = (isDM || isLeader) ? numberRole === role : numberRole >= role;
    if (suitableRoleInCampaign) {
      filtered.push(campaign);
    }
  }

  return filtered;
};

export const ALL_TEAMS_CAMPAIGN_ID = 'summary';

export const ALL_TEAMS_ITEM: ListCampaignsItemConcise = {
  id: ALL_TEAMS_CAMPAIGN_ID,
  name: 'allTeams',
  createDate: '',
  startDate: new Date().toISOString(),
  endDate: null,
  hidden: null,
  status: 'running',
  website: '',
};
