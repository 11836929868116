import React from 'react';

import SelectList, { SelectListProps } from './SelectList';
import { FontIconView, ListItem, Typography } from '../index';
import SelectCheckmark from './SelectCheckmark';

interface SelectListDefaultProps extends Omit<SelectListProps, 'renderItem'> {}

const SelectListDefault: React.FC<SelectListDefaultProps> = (props) => {
  return (
    <SelectList {...props} renderItem={(option, isActive, handleChange) => {
      const key = option.key;

      return (
        <ListItem
          key={key}
          hover
          autoColumns
          directionHorizontal
          gap='md'
          horizontal='space-between'
          vertical='center'
          paddingVertical='md'
          paddingHorizontal='extra-sm'
          color='blue-opacity'
          dataAttributes={{
            'data-id': key,
          }}
          onClick={handleChange}
        >
          <Typography component='body2' color={isActive ? 'blue' : 'text-body'}>
            {option.icon && (<>
              <FontIconView icon={option.icon} {...option.iconProps} />
              &nbsp;
            </>)}
            {option.label}
          </Typography>
          {isActive ? <div><SelectCheckmark />&nbsp;&nbsp;&nbsp;</div> : null}
        </ListItem>
      );
    }} />
  );
};

export default SelectListDefault;
