import React from 'react';
import { useTranslation } from 'react-i18next';
import ScrollBar from 'react-custom-scrollbars-2';

import { ButtonClose, Typography } from '../index';
import { CourseItem } from './CourseItem';
import { Course } from './types';
import Row from '../row/Row';
import { useSidePanelContext } from '../side-panel/SidePanelProvider';

import './courses.scss';

export const MyCourses: React.FC<{ courses: Course[] | [] }> = ({ courses = [] }) => {
  const { t } = useTranslation();
  const { closePanel } = useSidePanelContext();

  return (
    <Row gap="extra-lg" className="side-panel__inner-wrap">
      <Typography component='h3' variant='poppins' threeDots>{t('yourCourses')}</Typography>
      <ScrollBar autoHide>
        <div className="courses-grid">
          {courses.map((item) => {
            return <CourseItem key={item.trainingId} item={item} />;
          })}
        </div>
      </ScrollBar>
      <Row layout="flex" directionHorizontal horizontal="end">
        <ButtonClose onClick={closePanel} />
      </Row>
    </Row>
  );
};
