import { useCallback, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

import { browserLanguage, fallbackLanguage } from '../utils/constants';
import { getLangPartUntilDash } from '../utils/user-agent';
import { getAvailableLanguagesRequest } from '../api/languages';
import { PromiseResolveValue } from '../types';
import {
  QUERY_KEY_AVAILABLE_LANGUAGES,
  useGetAvailableLanguages,
  useGetUserLanguages
} from '../query/languages';

// Use this hook to update the application language.
export const useSetI18Language = () => {
  const queryClient = useQueryClient();
  const { i18n } = useTranslation();

  const setI18Language = useCallback(async (
    language: string // "en", "eng", "en-us"
  ) => {
    let newLanguage;

    if (language.length === 2) { // parse "en"
      newLanguage = language;
    } else if (language.length === 3) { // parse "eng"
      const availableLanguages = queryClient.getQueryData<
        PromiseResolveValue<ReturnType<typeof getAvailableLanguagesRequest>> | undefined
      >(QUERY_KEY_AVAILABLE_LANGUAGES);

      const languageCode = availableLanguages?.languages?.find(
        (item) => item.language === language
      )?.languageCode;

      if (languageCode) {
        newLanguage = languageCode;
      }
    } else { // parse "en-us"
      newLanguage = getLangPartUntilDash(language);
    }

    if (newLanguage && newLanguage !== getLangPartUntilDash(i18n.language)) {
      await i18n.changeLanguage(newLanguage);
    }
  }, [queryClient, i18n]);

  return { setI18Language };
};

export const useUserPrimaryLanguage = () => {
  const { getAvailableLanguageByLanguage } = useGetAvailableLanguages();
  const { userLanguages } = useGetUserLanguages();

  const userPrimaryLanguageObj = useMemo(() => {
    if (userLanguages.primaryLanguage) {
      return getAvailableLanguageByLanguage(userLanguages.primaryLanguage);
    }

    return undefined;
  }, [getAvailableLanguageByLanguage, userLanguages.primaryLanguage]);

  return { userPrimaryLanguageObj };
};

// Use this hook to get language string for server api.
export const useUiLanguage = () => {
  const { availableLanguages } = useGetAvailableLanguages();
  const { userPrimaryLanguageObj } = useUserPrimaryLanguage();

  const browserLanguageObj = useMemo(() => {
    const navLangLowerCase = browserLanguage
      ? browserLanguage.toLowerCase()
      : fallbackLanguage.languageCode;
    const navLangLowerCaseBeforeDash = getLangPartUntilDash(navLangLowerCase);

    return availableLanguages.find(
      ({languageCode}) => {
        const langLowerCase = languageCode.toLowerCase();

        return navLangLowerCase === langLowerCase
          || navLangLowerCaseBeforeDash === getLangPartUntilDash(langLowerCase);
      }
    );
  }, [availableLanguages]);

  return userPrimaryLanguageObj || browserLanguageObj || fallbackLanguage;
};
