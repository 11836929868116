import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { PanelStackItem, useSidePanelContext } from './SidePanelProvider';
import Backdrop from '../backdrop/Backdrop';
import {useListenKey} from '../../hooks/useListenKey';

import './side-panel.scss';

export const SidePanel = ({content }: {content: PanelStackItem[]}) => {
  const { closePanel } = useSidePanelContext();
  const nodeRef = React.useRef(null);

  useListenKey({
    enabled: !!content.length,
    key: 'Escape',
    onKeyDown: () => {
      closePanel();
    },
  });

  return (
    <TransitionGroup component={null}>
      {content.map((item, index: number) => {
        return (
          <CSSTransition
            key={index}
            in={!!item.component}
            classNames="panel"
            nodeRef={nodeRef}
            timeout={250}
            unmountOnExit
          >
            <div className="side-panel__animation" ref={nodeRef}>
              <div className="side-panel__inner">
                {item.component}
              </div>
              <Backdrop onClick={closePanel} className="side-panel__backdrop" />
            </div>
          </CSSTransition>
        );
      })}
    </TransitionGroup>
  );
};
