import { apiRequest } from './api';
import { ApiParamUserData } from './api.types';

export type Prayer = {
  categoryId: string;
  creationDate: string;
  id: string;
  lastPrayed?: string;
  messageId?: string;
  prayerId: string;
  requestId?: string;
  rowIndex: number;
  seekerId: string;
  shared: boolean;
  text: string;
}

export type ModifyPrayerListAction = 'add' | 'edit' | 'delete';

type ModifyPrayerListValues = {
  prayerId: string,
  categoryId: string,
  text: string,
  seekerId: string,
  messageId: string,
  lastPrayed: string,
  shared: boolean,
  // {['shared']} - Whether or not this request is shared in general pool - FUNCTIONALITY DISABLED
};

export type ModifyPrayerListCategoriesValues = {
  categoryId?: string;
  title: string;
  permanent?: string | null;
};

export type PrayerListCategoryApi = {
  categoryId: string;
  permanent: string | null;
  title: string;
}

export type PrayerListCategory = PrayerListCategoryApi & {
  id: string;
}

const normalizeCategories = (categories: PrayerListCategoryApi[]) => {
  return categories.map(item => ({ ...item, id: item.categoryId }));
};

export const getPrayerListCategoriesRequest = (userData: ApiParamUserData) => {
  return apiRequest<{ categories: PrayerListCategory[], }>
  ('getPrayerlistCategories', userData).then((response) => (
    normalizeCategories(response.categories)
  ));
};

// Get prayer list for specified evangelist
export const getPrayerListRequest = async (userData: ApiParamUserData) => {
  return await apiRequest<{
    prayerlist: Prayer[]
  }>('getPrayerlist', {
    id: userData.id,
    agentToken: userData.agentToken
  }).then((result) => result.prayerlist);
};

// edit/modify prayer list for specified evangelist
const modifyPrayerListRequest = async <ValuesType>(userData: ApiParamUserData, actions: {
  action: ModifyPrayerListAction,
  values: ValuesType
}[]) => {
  return apiRequest('modifyPrayerlist', {
    id: userData.id,
    agentToken: userData.agentToken,
    actions
  });
};

export type AddPrayerRequestValues = {
  categoryId?: string;
  text: string;
  seekerId?: string;
  messageId?: string;
}

export const addPrayerRequest = (
  userData: ApiParamUserData,
  prayers: AddPrayerRequestValues[],
) => {
  return modifyPrayerListRequest<AddPrayerRequestValues>(
    userData,
    prayers.map((prayer) => ({
      action: 'add',
      values: prayer,
    }))
  );
};

export type EditPrayerRequestValues = {
  categoryId?: string;
  text: string;
  seekerId?: string;
  messageId?: string;
  lastPrayed?: string;
  shared?: boolean;
  prayerId: string;
}

export const editPrayerRequest = (
  userData: ApiParamUserData,
  prayers: EditPrayerRequestValues[],
) => {
  return modifyPrayerListRequest<EditPrayerRequestValues>(
    userData,
    prayers.map((newPrayerValues) => ({
      action: 'edit',
      values: {
        ...newPrayerValues,
      }
    }))
  );
};

export type DeletePrayerRequestValues = Pick<ModifyPrayerListValues, 'prayerId'>;

export const deletePrayerRequest = (
  userData: ApiParamUserData, prayerIds: string[]
) => {
  return modifyPrayerListRequest<DeletePrayerRequestValues>(
    userData,
    prayerIds.map((id) => ({
      action: 'delete',
      values: {
        prayerId: id,
      }
    }))
  );
};

// edit/modify prayer categories list for specified evangelist
const modifyPrayerlistCategoriesRequest = async <ValuesType>(userData: ApiParamUserData, actions: {
  action: ModifyPrayerListAction,
  values: ValuesType
}[]) => {
  return apiRequest('modifyPrayerlistCategories', {
    id: userData.id,
    agentToken: userData.agentToken,
    actions
  });
};

export const addPrayerListCategoryRequest = (
  userData: ApiParamUserData,
  categories: ModifyPrayerListCategoriesValues[],
) => {
  return modifyPrayerlistCategoriesRequest<ModifyPrayerListCategoriesValues>(
    userData,
    categories.map((category) => ({
      action: 'add',
      values: category,
    }))
  );
};

export const deletePrayerListCategoryRequest = (
  userData: ApiParamUserData,
  categories: ModifyPrayerListCategoriesValues[],
) => {
  return modifyPrayerlistCategoriesRequest<ModifyPrayerListCategoriesValues>(
    userData,
    categories.map((category) => ({
      action: 'delete',
      values: category,
    }))
  );
};
