import { useCallback } from 'react';
import { Color } from '../types';
import { RoleStatus } from '../utils/enums';

export const useStatusAndStatusColor = () => {
  return useCallback((status: RoleStatus | undefined) => {
    let statusTextColor: Color = 'text-title-light';
    let statusText: string = 'unknown';

    if (status === 'active') {
      statusTextColor = 'green';
      statusText = 'active';
    } else if (status === 'denied') {
      statusTextColor = 'red';
      statusText = 'profileStatusDenied';
    } else if (status === 'pending') {
      statusTextColor = 'orange';
      statusText = 'pending';
    }

    return { statusText, statusTextColor };
  }, []);
};
