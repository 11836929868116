import { apiRequest } from './api';
import {
  GetRequestsRequestStatus,
  RequestCategoryItemType,
  RequestDenyReason,
  RequestItemType,
  RequestStatus
} from './request.types';
import { normalizeRequestCategoryItem } from './request.utils';
import { ApiParamUserData } from './api.types';

//  Deny a request for an evangelist and return to unassigned queue
export const returnRequestRequest = (
  userData: ApiParamUserData, requestId: string, reason: RequestDenyReason
) => {
  return apiRequest('returnRequest', {
    id: userData.id,
    agentToken: userData.agentToken,
    requestId,
    reason
  });
};

// Set a request status to ‘open’ or ‘closed’
export const setRequestStatusRequest = (
  userData: ApiParamUserData, requestId: string, status: RequestStatus
) => {
  return apiRequest('setRequestStatus', {
    id: userData.id,
    agentToken: userData.agentToken,
    requestId,
    status
  });
};

type GetRequestsParams = {
  status?: GetRequestsRequestStatus, // “active” by default or “inactive”
  id?: string[], // array of DMs ids, whom requests will be fetched (leader mode only)
  leaderId?: string, //  user id, which should be used along with 'id' array (leader mode only)
  requestId?: string[], // array of desired requests
}

// Get list of all requests assigned to this evangelist
export const getRequestsRequest = async (
  userData: ApiParamUserData,
  params?: GetRequestsParams
) => {
  return apiRequest<{
    requests: RequestItemType[];
  }>('getRequests', {
    id: params?.id || userData.id,
    leaderId: params?.id ? userData.id : undefined,
    agentToken: userData.agentToken,
    ...params
  }).then((res) => res.requests);
};

// Get list of types (prayer, question, etc) that Orion currently supports
export const fetchRequestCategories = async (
  userData: ApiParamUserData,
  language: string[] // 3-digit language code
) => {
  return apiRequest<{
    categories: RequestCategoryItemType[],
    success: string,
  }>('getRequestCategories', {
    evangelistId: userData.id,
    agentToken: userData.agentToken,
    language
  }).then((response) => {
    return response.categories.map(normalizeRequestCategoryItem);
  });
};
