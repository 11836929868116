import { useCallback, useEffect, useRef, useState } from 'react';

import { StorageManagerReturnType } from '../utils/storage';

type UpdateValueCallbackFn<T> = (oldData: T | null) => T;
export type StorageManagerSetFn<T> = (dataOrCallback: T | UpdateValueCallbackFn<T>) => void;

export const useStorageManager = <T>(storageManager: StorageManagerReturnType<T>) => {
  const firstRender = useRef(true);

  const getValue = useCallback(() => {
    return storageManager.load();
  }, [storageManager]);

  // Update value for the new {storageManager}.
  // {storageManager} can be changed when its key was updated (e.g. {Chat} message queue).
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      setValue(getValue());
    }
  }, [getValue, storageManager]);

  const [value, setValue] = useState(getValue());

  const updateValue: StorageManagerSetFn<T> = useCallback((dataOrCallback) => {
    let newData = dataOrCallback instanceof Function ? dataOrCallback(getValue()) : dataOrCallback;

    storageManager.save(newData);

    setValue(newData);
  }, [storageManager, getValue]);

  const removeValue = useCallback(() => {
    storageManager.remove();

    setValue(null);
  }, [storageManager]);

  return {
    value,
    updateValue,
    removeValue,
    getValue,
  };
};
