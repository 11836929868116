import React, { useState } from 'react';
import classnames from 'classnames';

import AeFontIcons from '../../assets/Icons';
import { Button, Tag } from '../index';
import Input, { InputProps } from './Input';
import { EventKey } from '../../utils/dom';

import './InputTags.scss';

export type InputTagsValue = string[];

export type InputTagsProps = InputProps & {
  value: InputTagsValue,
  onChange: (event: any, items: InputTagsValue) => void,
}

const InputTags: React.FC<InputTagsProps> = ({ value, onChange, placeholder, ...inputProps }) => {
  const [inputValue, setInputValue] = useState('');

  const handleReset = () => {
    onChange(null, []);
  };

  const handleAdd = () => {
    if (inputValue) {
      if (!value.includes(inputValue)) {
        onChange(null, [...value, inputValue]);
        setInputValue('');
      }
    }
  };

  const handleRemove = (tag: InputTagsValue[0]) => {
    onChange(null, value.filter(v => v !== tag));
  };

  const handlePop = () => {
    if (value.length) {
      if (onChange) {
        onChange(null as any, value.slice(0, -1));
      }
    }
  };

  return (
    <Input
      type='text'
      className="input-tags__input"
      wrapperProps={{
        className: classnames('input-tags', {
          'has-tags': !!value.length,
        }),
      }}
      placeholder={value.length ? '' : placeholder}
      slotBefore={value.map((tag) => (
        <Tag
          key={tag}
          className='input-tags__tag'
          onRemove={() => {
            handleRemove(tag);
          }}
        >{tag}</Tag>
      ))}
      slotAfter={
        <Button
          className='input-tags__btn-reset'
          type='button'
          variant='transparent'
          onClick={handleReset}
          iconBefore={AeFontIcons.close}
        />
      }
      value={inputValue}
      onChange={(e) => {
        setInputValue(e.currentTarget.value);
      }}
      onKeyDown={(e) => {
        const { key } = e.nativeEvent;

        if (key === EventKey.enter || key === EventKey.comma) {
          handleAdd();
          e.preventDefault(); // Prevent form submit and prevent write comma to input value.
        } else if (key === EventKey.backspace && !inputValue) {
          handlePop();
        }
      }}
      {...inputProps}
    />
  );
};

export default InputTags;
