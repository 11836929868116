import { useMainContext } from '../MainProvider';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {
  fetchResourcesContent,
  fetchResourcesList,
  ResourcesListType, Template,
} from '../api/resources';
import {KnowledgeContentData, modifyKnowledgeContentRequest} from '../api/knowledge';
import {useTranslation} from 'react-i18next';
import {useToastEnhanced} from '../enhanced-components/toaster/ToasterEnhanced';
import {PromiseResolveValue, UpdateQueryDataFn} from '../types';
import { urlify } from '../utils/string';

export const QUERY_KEY_FAQ_LIST = 'faq-list';
const QUERY_KEY_KNOWLEDGE_LIST = 'knowledge-list';
const QUERY_KEY_FAQ_CONTENT = 'resource-content';

const emptyResources = [] as [];
const emptyResourceContent = [] as [];

type ResourceIdType = string | '';

const getResourceContentKey = (resourceId: ResourceIdType, language?: string) => {
  const key = [QUERY_KEY_FAQ_CONTENT, resourceId];
  if (language) {
    key.push(language);
  }
  return key;
};

export const useGetResources = (type: ResourcesListType, search: string, language: string) => {
  const { userData } = useMainContext();
  
  const key = type === 'faq' ? QUERY_KEY_FAQ_LIST : QUERY_KEY_KNOWLEDGE_LIST;

  const {
    data = emptyResources,
    isLoading,
    refetch: refetchResources
  } = useQuery([key, { q: search }, { language }], () => (
    fetchResourcesList(userData, { language, search: search, type })
  ));

  return {
    resources: data,
    isLoadingResources: isLoading,
    refetchResources
  };
};

export const useGetResourcesContent = (parentId: ResourceIdType, language: string) => {
  const { userData } = useMainContext();
  const queryKey = getResourceContentKey(parentId, language);

  const {
    data = emptyResourceContent,
    isLoading
  } = useQuery(queryKey, () => (
    fetchResourcesContent(userData, language, {
      resourceIds: parentId ? [parentId] : [],
      parentIds: parentId ? [parentId] : []
    })
  ), { enabled: !!parentId });

  return {
    resourceContent: data,
    isLoadingResourceContent: isLoading
  };
};

export const unshiftToResourcesContent: UpdateQueryDataFn<{
  parentId: string;
  item: Template
}> = (queryClient, options) => {
  const queryKey = getResourceContentKey(options.parentId);

  queryClient.setQueryData<
    PromiseResolveValue<ReturnType<typeof fetchResourcesContent>> | undefined
  >(queryKey, (old) => {
    if (old) {
      return [options.item, ...old];
    }

    return old;
  });
};

export const updateResourceContentItem: UpdateQueryDataFn<{
  resourceId: ResourceIdType,
  parentId: string;
  newProperties: Partial<Template>,
}> = (queryClient, options) => {
  const queryKey = getResourceContentKey(options.parentId);

  queryClient.setQueryData<
    PromiseResolveValue<ReturnType<typeof fetchResourcesContent>> | undefined
  >(queryKey, (old) => {
    if (old) {
      const { resourceId } = options;

      return old.map((item) => {
        if (item.id === resourceId) {
          return {
            ...item,
            ...options.newProperties,
          };
        }

        return item;
      });
    }

    return old;
  });
};

export const useModifyKnowledgeContent = (parentId: ResourceIdType) => {
  const queryClient = useQueryClient();
  const {t} = useTranslation();
  const { userData } = useMainContext();
  const {showToast} = useToastEnhanced();

  const modifyKnowledgeContentMutation = useMutation((data: KnowledgeContentData) => (
    modifyKnowledgeContentRequest(userData, data)
  ), {
    onSuccess: (response, payloadData) => {
      if (payloadData.action === 'add') {
        const payloadText = payloadData.text || '';
        unshiftToResourcesContent(queryClient, {
          parentId,
          item: {
            id: payloadData.resourceId,
            resource: payloadData.text,
            title: payloadData.title || '',
            text: payloadText,
            textUrlified: urlify(payloadText),
            type: 'text',
            evangelistId: userData.id,
            language: payloadData.language || '',
            parentId: payloadData.parentId || '',
          },
        });
      } else if (payloadData.action === 'edit') {
        const payloadText = payloadData.text || '';
        updateResourceContentItem(queryClient, {
          resourceId: payloadData.resourceId,
          parentId,
          newProperties: {
            title: payloadData.title,
            text: payloadText,
            textUrlified: urlify(payloadText),
          },
        });
      }

      showToast({ title: t('changesSuccess'), });
    },
  });

  return {
    modifyKnowledgeContentMutation,
  };
};
