import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../index';
import { ButtonProps } from '../button/Button';

import './ModalActions.scss';
import { useModalContext } from './ModalProvider';

interface ModalBaseActionsProps {
  btnOk?: boolean;
  btnCancel?: boolean;
  btnCancelProps?: ButtonProps;
  btnOkProps: Omit<ButtonProps, 'onClick'> & {
    // if return "true" closes the modal
    onClick?: (e: SyntheticEvent<HTMLButtonElement, MouseEvent>)
      => Promise<boolean | void> | boolean | void,
  };
}

const ModalActions: React.FC<ModalBaseActionsProps> = (props) => {
  const { t } = useTranslation();
  const { closeModal } = useModalContext();

  return (
    <div className='modal-actions'>
      {
        props.btnCancel !== false && (
          <Button
            type='button'
            size='sm'
            minWidth='sm'
            color='gray'
            variant='opacity'
            onClick={closeModal}
            {...props.btnCancelProps}
          >
            {t('cancel') || props.btnCancelProps?.children}
          </Button>
        )
      }
      {
        props.btnOk !== false && (
          <Button
            type='submit'
            size='sm'
            minWidth='sm'
            {...props.btnOkProps}
            onClick={async (e) => {
              if (props.btnOkProps?.onClick) {
                const result = await props.btnOkProps.onClick(e);

                if (result) {
                  closeModal();
                }
              }
            }}
          >
            {props.btnOkProps.children || t('ok')}
          </Button>
        )
      }
    </div>
  );
};

export default ModalActions;
