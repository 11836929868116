import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useModalContext } from '../../components/modal/ModalProvider';
import Row from '../../components/row/Row';
import { Template } from '../../api/resources';
import {useUiLanguage} from '../../hooks/useLanguage';
import { CHAT_MESSAGE_LIMIT, TEMPLATE_TITLE_LIMIT } from '../../utils/constants';
import {useModifyKnowledgeContent} from '../../query/resources';
import { renderField } from '../../components/custom-field/customField';
import { Input, ModalActions, TextArea } from '../../components';

interface AddTextTemplatePopupProps {
  parentId: string;
  item?: Template;
  isEdit?: boolean;
  onCallback?: (item: any) => void;
}

type Inputs = {
  title: string;
  text: string;
};

const ModalModifyTextTemplate: React.FC<AddTextTemplatePopupProps> = ({
  item,
  parentId,
  isEdit = false,
  onCallback
}) => {
  const { t } = useTranslation();
  const { language } = useUiLanguage();
  const { closeModal } = useModalContext();

  const { modifyKnowledgeContentMutation } = useModifyKnowledgeContent(parentId);

  const KnowledgeContent = Yup.object().shape({
    title: Yup.string()
      .max(TEMPLATE_TITLE_LIMIT, t('titleLengthValidate', { value: TEMPLATE_TITLE_LIMIT }))
      .required(t('errorFieldRequired')),
    text: Yup.string()
      .required(t('errorFieldRequired'))
      .max(CHAT_MESSAGE_LIMIT, t('templateMessageLimit', { value: CHAT_MESSAGE_LIMIT }))
  });

  let initValues = { title: '', text: '' };

  if (item && isEdit) {
    initValues = { title: item.title, text: item.text };
  }

  const { register, handleSubmit, formState: { errors, isSubmitting, isValid } } = useForm<Inputs>({
    resolver: yupResolver(KnowledgeContent),
    mode: 'onChange',
  });


  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (item?.title === data.title && item?.text === data.text) {
      closeModal();
      return;
    }

    await modifyKnowledgeContentMutation.mutateAsync({
      language,
      action: isEdit ? 'edit' : 'add',
      resourceId: item ? item.id : uuidv4(),
      parentId: parentId || null,
      ...data
    });

    closeModal();

    if (onCallback) {
      onCallback(data);
    }
  };


  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Row style={{
        minHeight: 404,
        gridTemplateRows: 'auto 1fr',
      }}>
        {
          renderField(Input, {
            ...register('title'),
            showMarkRequired: true,
            defaultValue: initValues.title || '',
            errorText: errors.title?.message || '',
            labelText: t('templateTitle'),
            style: {
              height: '100%',
            },
          })
        }
        {
          renderField(TextArea, {
            ...register('text'),
            defaultValue: initValues.text || '',
            errorText: errors.text?.message || '',
            labelText: t('enterMessageHint'),
            textareaHeight: 'extra-lg',
          })
        }
      </Row>
      <ModalActions
        btnCancelProps={{
          disabled: isSubmitting,
        }}
        btnOkProps={{
          disabled: isSubmitting || !isValid,
          children: t(isEdit ? 'save' : 'add'),
        }}
      />
    </form>
  );
};

export const useModalModifyTextTemplate = () => {
  const { t } = useTranslation();
  const { openModal } = useModalContext();

  const openModalTextTemplate = (
    parentId: string,
    item?: Template,
    isEdit?: boolean,
    onCallback?: AddTextTemplatePopupProps['onCallback'],
  ) => {
    openModal(<ModalModifyTextTemplate
      parentId={parentId}
      item={item}
      isEdit={isEdit}
      onCallback={onCallback}
    />, {
      title: t(isEdit ? 'editTextTemplate' : 'addTextTemplate'),
      titleAlign: 'center',
      widthSize: 'sm',
    });
  };

  return {
    openModalTextTemplate,
  };
};
