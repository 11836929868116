import React from 'react';

import { Checkbox, FontIconView } from '../index';
import UserItem, { UserItemType, UserItemProps } from './UserItem';
import Button from '../button/Button';
import AeFontIcons from '../../assets/Icons';

type SeekerControlItemProps = Omit<UserItemProps, 'slotAfter'> & {
  onClickBtnRemove?: (item: UserItemType) => void,
  isChecked?: boolean,
  active?: boolean,
};

const UserItemWithActions: React.FC<SeekerControlItemProps> = React.memo((props) => {
  return (
    <UserItem
      rootProps={{
        active: props.active,
        hover: true,
        paddingHorizontal: 'tiny',
      }}
      item={props.item}
      onClick={props.onClick}
      slotAfter={
        props.onClickBtnRemove
          ? (
            <Button
              type='button'
              variant='transparent'
              color='red'
              iconBefore={<FontIconView icon={AeFontIcons.delete} />}
              style={{
                paddingTop: 0,
                paddingBottom: 0,
              }}
              onClick={() => {
                if (props.onClickBtnRemove) {
                  props.onClickBtnRemove(props.item);
                }
              }}
            />
          )
          : (props.isChecked !== undefined)
            ? (
              <Checkbox
                value={props.isChecked}
                onClick={(e) => {
                  e.preventDefault(); // prevent fire double {onClick} event.
                }}
                onChange={() => {}}
              />
            )
            : ''
      }
    />
  );
});

export default UserItemWithActions;
