import React, { CSSProperties } from 'react';

import './Paper.scss';

interface PaperProps {
  className?: string,
  style?: CSSProperties,
}

const Paper: React.FC<PaperProps> = (props) => {
  return (
    <div
      className={'paper' + (props.className ? (' ' + props.className) : '')}
      style={props.style}
    >{props.children}</div>
  );
};

export default Paper;
