import React, { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  InputSearch,
  Loader,
  NoDataTemplate, Paper,
  Row,
  SelectVariantButton,
  TabsHead
} from '../../components';
import { Template, Resource } from '../../api/resources';
import { useUrlQuery } from '../../hooks/useUrlQuery';
import { Templates } from './Templates';
import useDebounce from '../../hooks/useDebounce';
import { useGetResources, useGetResourcesContent } from '../../query/resources';
import { ResourcesView } from '../settings/ResourcesView';
import { useGetCurrentTreeLanguage } from '../../hooks/useGetCurrentTreeLanguage';
import { SelectOption } from '../../components/select/Select.types';
import { findNode } from '../../utils/array';

import './knowledge-page.scss';

interface KnowledgePageProps {
  attached?: Template[];
  handleClickAttachItems?: () => void;
  handleClickToggleAttach?: (item: Template) => void;
  clearAttachment?: () => void;
}

const findSearchResults = (tree: Resource[], id: string | number | null) => {
  let result = false;

  if (!tree || !tree.length) {
    return result;
  }

  for (let i = 0; i < tree.length; i++) {
    const item = tree[i];

    if (item.id === id) {
      return item.searchResults ? item.searchResults > 0 : false;
    }

    if (item.children) {
      result = findSearchResults(item.children, id) as boolean;

      if (result) {
        return result;
      }
    }
  }

  return result;
};

const KnowledgePage = ({
  attached,
  handleClickAttachItems,
  handleClickToggleAttach,
  clearAttachment
}: KnowledgePageProps) => {
  const query = useUrlQuery();
  const history = useHistory();
  const { t } = useTranslation();
  const knowledgeId = query.get('knowledgeId') || '';
  const [currentTab, setCurrentTab] = useState(0);
  const [search, setSearch] = useState<string>('');
  const [tabs, setTabs] = useState<Resource[]>([]);
  const debouncedSearch = useDebounce(search, 500);
  const { language, saveTreeLanguage, availableTreeLanguages } = useGetCurrentTreeLanguage();
  const [attachmentLanguage, setAttachmentLanguage] = useState(language.language);

  const { resources, isLoadingResources } = useGetResources(
    'kb',
    debouncedSearch,
    attachmentLanguage
  );
  const { resourceContent, isLoadingResourceContent } = useGetResourcesContent(
    knowledgeId,
    attachmentLanguage
  );

  // Update tabs when resources are fetched.
  useEffect(() => {
    setTabs(resources);
  }, [resources]);

  // Keep actual current tab regarding the {knowledgeId}
  useEffect(() => {
    if (resources && knowledgeId) {
      const trace: Resource[] = [];
      findNode(resources, knowledgeId, trace);
      const currentTab = trace[trace.length - 1];

      if (currentTab) {
        setCurrentTab(resources.indexOf(currentTab));
      }
    }
  }, [resources, knowledgeId]);

  useEffect(() => {
    if (debouncedSearch) {
      query.set('search', debouncedSearch);
    } else {
      query.delete('search');
    }

    historyReplace();
  }, [debouncedSearch]);

  const [treeLanguageOptions, selectedLanguage] = useMemo(() => {
    const options = availableTreeLanguages.map((item) => ({ 
      key: item.language, 
      label: item.name 
    }));
    const currentOption = options.find((item) => item.key === language.language);
    return [options, currentOption];
  }, [availableTreeLanguages, language]);

  const handleChangeId = (id: string) => {
    if (id === knowledgeId) {
      return;
    }

    query.set('knowledgeId', id);
    historyReplace();
  };

  const historyReplace = () => {
    history.replace({ pathname: history.location.pathname, search: query.toString() });
  };

  const handleChangeLanguageOption = (_: any, value: SelectOption) => {
    saveTreeLanguage(value);
    setAttachmentLanguage(value.key);
    if (attachmentLanguage !== value.key) {
      query.set('knowledgeId', '');
      historyReplace();
    }
  };

  const currentTabResources = useMemo(() => {
    return resources.find((item) => item.id === resources[currentTab]?.id)?.children || [];
  }, [resources, currentTab]);

  const hasSearchResults = findSearchResults(currentTabResources, knowledgeId);

  const renderTemplates = () => {
    let templatesView;

    if (search && !hasSearchResults) {
      templatesView = (
        <NoDataTemplate title={t('noResultsFound')} message={t('noContentMatchCriteria')}/>
      );
    } else if (knowledgeId) {
      templatesView = (
        <Templates
          knowledgeId={knowledgeId}
          tree={resources}
          content={resourceContent}
          isLoading={isLoadingResourceContent}
          attached={attached}
          onClickAttachItems={handleClickAttachItems}
          handleClickToggleAttach={handleClickToggleAttach}
          handleClickClearAttached={clearAttachment}
        />
      );
    } else {
      templatesView = (
        <NoDataTemplate title={t('noCategorySelected')} message={t('chooseCategory')}/>
      );
    }

    return templatesView;
  };

  const renderTitle = () => {
    return (
      <>
        <Row layout='flex'
          directionHorizontal
          horizontal='space-between'
          vertical='center'
          style={{ flex: 1 }}>
          {!tabs.length ? (
            <Loader />
          ) : (
            <TabsHead
              current={currentTab}
              items={tabs.map((item) => t(item.title))}
              onClickTab={(index) => {
                if (clearAttachment) {
                  clearAttachment();
                }
                handleChangeId(tabs[index].id);
                setCurrentTab(index);
              }}
            />
          )}
          <SelectVariantButton
            listProps={{
              withFilter: false
            }}
            popupMinWidth='sm'
            defaultValue={selectedLanguage}
            onChange={handleChangeLanguageOption}
            options={treeLanguageOptions}
          />
        </Row>
        <InputSearch
          size='sm'
          wrapperProps={{
            style: {
              width: 244
            }
          }}
          value={search}
          onChange={(e) => setSearch(e.currentTarget.value)}
        />
      </>
    );
  };

  return (
    <Paper style={{ height: '100%' }}>
      <ResourcesView
        tree={currentTabResources}
        search={search}
        renderTitle={renderTitle}
        renderContent={renderTemplates}
        parentId={resources[currentTab]?.id}
        activeId={knowledgeId}
        isLoading={isLoadingResources}
        handleChangeId={handleChangeId}
        notFound={!!(debouncedSearch && !currentTabResources.length)}
      />
    </Paper>
  );
};

export default KnowledgePage;
