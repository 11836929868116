import { MessageHistoryItemApi } from './chat.types';
import { ColorScheme, EnumLiteralsOf, PageDataItem } from '../types';
import { Role } from '../utils/enums';

export type ChatItemAwrCampaignTypeApi = {
  date: string
  evangelistId: string
  firstname: string
  id: string
  language: string
  lastname: string
  messageCounts: {
    unread: string,
    outgoing: string,
    incoming: string,
    ungraded: null
  }
  sourceType: string
  text: string
};

export type ChatItemCampaignTypeApi = {
  campaignId: string
  incoming: string
  lastMessage?: MessageHistoryItemApi
  name: string
  unread: string
  role: Role | null
};

export type ChatItemCampaignType = {
  campaignId: string
  name: string
  date: string
  text: string
  audio: string
  unread: number,
  incoming: number,
  outgoing: number, // for consistency
  ungraded: number, // for consistency
  role: Role | null
};

export const StatTypes = {
  INCOMING: 'incomingMsgs',
  OUTGOING: 'outgoingMsgs',
  INTERESTS: 'interestsArr',
  DMS: 'dmsArr',
};

export type StatType = EnumLiteralsOf<typeof StatTypes>;

export const CampaignStatuses = {
  NOT_STARTED: 0,
  RUNNING: 1,
  FINISHED: 2,
};

export type MyCampaignApi = {
  campaignId: string,
  active: '0' | '1',
  role: string
}

export type MyCampaign = {
  campaignId: string,
  active: '0' | '1',
  role: Role
}

export type DmRatingFromApi = {
  id: string;
  firstname: string | null;
  lastname: string | null;
  chatScore: string | null;
  responseTime: string | null;
  score: string | null;
  reportCount: string | null;
};

export type DmRating = Omit<
  DmRatingFromApi, 'chatScore' | 'responseTime' | 'score' | 'reportCount'
> & {
  chatScore: string | number;
  responseTime: number;
  score: number;
  reportCount: number;
};

type RequestDataType = {
  categoryId: string;
  country: string | null;
  language: string;
  status: string;
};

type CampaignStatisticsFromApi = {
  openRequestCount: number;
  closedRequestCount: number;
  startDate: string;
  requestCount: number;
  unassignedCount: number;
  dmCount: number;
  interestCount: number;
  incomingMsgs: Array<Record<number, number>> | Record<string, number>;
  outgoingMsgs: Array<Record<number, number>> | Record<string, number>;
  interestsArr: Array<Record<number, number>> | Record<string, number>;
  dmsArr: Array<Record<number, number>> | Record<string, number>;
  capacities: Record<string, Record<string, number>>;
  chatData: Record<string, string>;
  interestIds: Array<string>;
  countryCount: number;
  countryData: Array<{ country: string | null, count: string }>;
  incomingCount: number;
  requestResponseRate: number;
  unassignedData: any; // fix
  dmData: DmRatingFromApi[];
  requestData: RequestDataType[];
};

export type CampaignsSummaryFromApi = CampaignStatisticsFromApi & {
  summary: boolean;
};

export type ListCampaignsItemConciseApi = {
  campaignId: string,
  name: string,
  createDate: string,
  startDate: string,
  endDate: string | null,
  hidden: string | null;
  status: 'running' | 'finished',
  website: string,
}

export type ListCampaignsItemConcise = Omit<ListCampaignsItemConciseApi, 'campaignId'> & {
  id: string;
}

export type CreateCampaignItemFromApi = {
  name: string;
  notifyEmail: string | null;
  hidden: string | null;
  createDate: string;
  startDate: string;
  endDate: string | null;
  description: string | null;
  exclude: string | null;
  conference: string | null;
  banner: File | string | null;
  website: string;
  contactCode: string;
  id: string;
};

export type ListCampaignsItemFromApi = {
  name: string;
  notifyEmail: string | null;
  hidden: string | null;
  createDate: string;
  startDate: string;
  endDate: string | null;
  description: string | null;
  exclude: string | null;
  conference: string | null;
  banner: File | string | null;
  website: string;
  contactCode: string;
  campaignId: string;
  campaignStatistics: CampaignStatisticsFromApi;
  campaignStatus: 'running' | 'finished';
};

export type ListCampaignsItem = PageDataItem & {
  name: string;
  notifyEmail: string | null;
  hidden: string | null;
  createDate: string;
  startDate: string;
  endDate: string | null;
  description: string | null;
  exclude: string | null;
  conference: string | null;
  banner: File | string | null;
  website: string;
  contactCode: string;
  status: 'running' | 'finished';
  stats: CampaignStat[];
  totalDms: number;
  totalRequests: number;
  waitingRequests: number;
  openRequestCount: number;
  closedRequestCount: number;
  totalInterests: number;
  totalCountries: number;
  incomingCount: number;
  requestResponseRate: number;
  unassignedData: any[]; // fix
  interestIds: Array<string>;
  chatActivity: Array<{title: string, score: number}>;
  chatData: Record<string, number>;
  dmData: DmRating[];
  capacities: Record<string, Record<string, number>>;
  countryData: Array<{ country: string | null, count: string }>;
  requestData: RequestDataType[];
  totalCount?: number; 
}

export type CampaignStat = {
  id: string;
  teamId: string;
  type: StatType;
  day: number;
  count: number;
};

export type CapacityData = {
  allData: {
    data: Array<{title: string, value: number}>;
    totalCount: number;
  };
  datasets: Array<{backgroundColor: Array<ColorScheme | string>, data: Array<number>, spacing: number}>;
  labels: Array<string>;
};

export type ModifyCampaignsRequestAction = {
  action: 'delete' | 'add' | 'activate' | 'deactivate'
  id: string;
  role?: string | number;
}
