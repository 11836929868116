import React, {forwardRef} from 'react';
import classNames from 'classnames';
import { ScrollbarProps, Scrollbars } from 'react-custom-scrollbars-2';

import './scroll.scss';

export interface ScrollProps extends Omit<ScrollbarProps, 'renderView' | 'ref'> {
  horizontal?: boolean;
  // Use this in addition with {maxHeight} or {height} when need {Scroll} to take its content place
  staticScrollView?: boolean;
  // Use this to restrict height overflow when 2+ children in render view.
  flexColumn?: boolean;
  fixScrollbarCoversContent?: boolean;
}

const Scroll = forwardRef<Scrollbars, ScrollProps>(({
  horizontal,
  className,
  flexColumn,
  staticScrollView,
  fixScrollbarCoversContent,
  ...props
}: ScrollProps, ref) => {
  return (
    <Scrollbars
      ref={ref}
      className={classNames('scrollbars-wrapper', className, {
        'static': staticScrollView,
        'horizontal': horizontal,
        'flex-column': flexColumn,
        'gap-for-scrollbar': fixScrollbarCoversContent,
      })}
      {...props}
    />
  );
});

Scroll.defaultProps = {
  autoHide: true,
};

export default Scroll;
