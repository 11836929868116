import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import ScrollBar from 'react-custom-scrollbars-2';

import FontIconView from '../../assets/FontIconView';
import AeFontIcons from '../../assets/Icons';
import { Button, ButtonClose, Loader } from '../index';
import { Course, Lesson } from './types';
import { fetchCoursesLessons } from '../../api/account';
import { useMainContext } from '../../MainProvider';
import { ProgressBar } from '../progress-bar/ProgressBar';
import { timeConvert } from '../../utils/time';

interface CourseInfoProps {
  closePanel: () => void;
  item: Course;
}

export const calculateTextHeight = (text: string, styles: string) => {
  let height;
  const div = document.createElement('div');
  div.setAttribute('style', styles);
  div.innerHTML = text;

  document.body.appendChild(div);

  height = div.clientHeight;
  div.parentNode!.removeChild(div);

  return height;
};

const descDefaultHeight = 97;
const moreTitle = 'more';
const lessTitle = 'less';

type DefaultState = {
  height: string | number;
  isShowBtn: boolean;
  btnTitle: string;
}

const defaultState = { height: descDefaultHeight, isShowBtn: false, btnTitle: moreTitle };

export const CourseInfo: React.FC<CourseInfoProps> = ({ closePanel, item }) => {
  const { t } = useTranslation();
  const { userData } = useMainContext();
  const [state, setState] = useState<DefaultState>(defaultState);

  useEffect(() => {
    const textHeight = calculateTextHeight(
      item.description,
      'width:414px;font-size:0.9rem;line-height:1.5'
    );

    setState((oldState) => {
      return {
        ...oldState,
        isShowBtn: textHeight > descDefaultHeight,
        height: textHeight > descDefaultHeight ? descDefaultHeight : textHeight,
      };
    });
  }, [item.description]);

  const {
    data: lessons = [],
    isLoading: isLoadingLessons
  } = useQuery<Lesson[], Error>('lessons', () => {
    return fetchCoursesLessons(userData, item.trainingId);
  }, { enabled: !!item.trainingId });

  const toggleBtn = () => {
    if (typeof state.height === 'string') {
      setState({ ...state, height: descDefaultHeight, btnTitle: moreTitle });
    } else {
      setState({ ...state, height: '100%', btnTitle: lessTitle });
    }
  };

  return (
    <div className="side-panel__inner-wrap">
      <div className="side-panel__title">
        <span className="side-panel__title-text">{t('courseInfo')}</span>
      </div>
      <div className="side-panel__content">
        <ScrollBar autoHide>
          <div className="courses-info">
            <div className="courses-item">
              <div className="courses-item__icon">
                <img src={item.icon} alt={item.title} />
              </div>
              <div className="courses-item__description">
                <span className="courses-item__title">{item.title}</span>
                <div className="flex-row">
                  <div className="courses-item__text">
                    <FontIconView icon={AeFontIcons.stack} />
                    <span>{item.lessons} {t('lessons')}</span>
                  </div>
                  <div className="courses-item__text">
                    <FontIconView icon={AeFontIcons.time} />
                    <span>{timeConvert(item.duration)}</span>
                  </div>
                </div>
                <ProgressBar percentage={item.progress} type='line' color="green" />
              </div>
            </div>
            <div className="courses-info__description" style={{ height: state.height }}>
              {item.description}
            </div>
            {state.isShowBtn && (
              <div className="more-btn" onClick={toggleBtn}>
                <span className="more-btn__text">{t(state.btnTitle)}</span>
                <FontIconView icon={AeFontIcons.arrowRight} />
              </div>
            )}
            {isLoadingLessons ? (
              <Loader />
            ) : (
              <div className="courses-info__lessons">
                {lessons.map((item) => {
                  const activeItem = lessons.find((item) => item.progress < 100);
                  const isActive = item.lessonId === activeItem?.lessonId;

                  return (
                    <a
                      href={item.url}
                      rel="noreferrer"
                      target="_blank"
                      key={item.lessonId}
                      className={classNames('lesson-item', { 'is-active': isActive })}
                    >
                      <div>
                        <div className="lesson-item__subtitle">{t('lesson')} {item.lesson}</div>
                        <div className="lesson-item__title">{item.title}</div>
                      </div>
                      {isActive && (
                        <div>
                          <Button
                            variant='outline'
                            color='blue'
                            size='sm'
                          >
                            {t('start')}
                          </Button>
                        </div>
                      )}
                    </a>
                  );
                })}
              </div>
            )}
          </div>
        </ScrollBar>
      </div>
      <div className="side-panel__actions flex-end">
        <ButtonClose onClick={closePanel} />
      </div>
    </div>
  );
};
