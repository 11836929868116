import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

import { useMainContext } from '../MainProvider';
import {
  addOrDeleteLocationsRequest, getCountriesRequest,
  fetchUserLocations,
  OneOfUserLocations,
  UserLocations, getCitiesByCountryRequest, Country
} from '../api/account';
import { useToastEnhanced } from '../enhanced-components/toaster/ToasterEnhanced';
import { useGetByIdWithCache } from '../hooks/useCache';

export const QUERY_KEY_USER_LOCATIONS = 'locations';
export const QUERY_KEY_COUNTRIES = 'countries';
export const QUERY_KEY_CITIES = 'cities';

const emptyUserLocations: UserLocations = {
  cities: [],
  countries: [],
  postalCodes: []
};

export const useGetLocations = () => {
  const { userData, isAuth } = useMainContext();

  const {
    data = emptyUserLocations,
    isLoading
  } = useQuery(QUERY_KEY_USER_LOCATIONS, () => (
    fetchUserLocations(userData)
  ), {
    enabled: isAuth,
  });

  return {
    userLocations: data,
    isUserLocationsLoading: isLoading,
  };
};

export const useModifyLocations = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { showToast } = useToastEnhanced();
  const { userData } = useMainContext();

  const addMutation = useMutation((data: OneOfUserLocations) => {
    return addOrDeleteLocationsRequest(userData, data, 'add');
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY_USER_LOCATIONS);
      showToast({ title: t('changesSuccess') });
    }
  });

  const deleteMutation = useMutation((data: OneOfUserLocations) => {
    return addOrDeleteLocationsRequest(userData, data, 'delete');
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY_USER_LOCATIONS);
      showToast({ title: t('changesSuccess') });
    }
  });

  return {
    addLocationsMutation: addMutation,
    deleteLocationsMutation: deleteMutation
  };
};

const countriesDefault: [] = [];

const getCountryAbbreviation = (item: Country) => item.abbreviation;

export const useGetCountries = () => {
  const {
    data = countriesDefault,
    isLoading
  } = useQuery(QUERY_KEY_COUNTRIES, getCountriesRequest);

  const get = useGetByIdWithCache(QUERY_KEY_COUNTRIES, data, getCountryAbbreviation);

  return {
    countries: data,
    isCountriesLoading: isLoading,
    getCountryByAbbreviation: get,
  };
};

const citiesDefault: [] = [];

export const useGetCities = (country: string) => {
  const {
    data = citiesDefault,
    isLoading,
    isError,
  } = useQuery([QUERY_KEY_CITIES, country], () => {
    return getCitiesByCountryRequest(country);
  }, {
    enabled: !!country,
    onError: () => {},
  });

  return {
    cities: data,
    isCitiesLoading: isLoading,
    isCitiesError: isError,
  };
};
