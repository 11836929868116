import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useMainContext } from '../MainProvider';
import {
  getProfileRequest,
  ProfileCampaign,
  ProfileCategoryLimit,
  ProfileLanguages, setProfileRequest, SetProfileRequestCategory
} from '../api/account';
import { Profile } from '../api/account.types';
import { QUERY_KEY_REQUEST_CATEGORIES } from './request';
import { PromiseResolveValue, UpdateQueryDataFn } from '../types';
import { useGetByIdWithCache } from '../hooks/useCache';
import { updateCampaignMembersItemById } from './campaigns';
import { Role } from '../utils/enums';
import { updateChatListLeadersItem } from './leaders';
import { updateChatListEvangelistItem, updateEvangelistsFieldsById } from './evangelists';
import { AdminEvangelistItem } from '../api/evangelists.types';

export const QUERY_KEY_PROFILE = 'profile';

export type GetProfileRequestReturnValue =
  PromiseResolveValue<ReturnType<typeof getProfileRequest>> | undefined;

export const updateProfileById: UpdateQueryDataFn<{
  id: string;
  newValues: Partial<{
    profile: Profile,
    categories: ProfileCategoryLimit[],
    languages: ProfileLanguages,
    campaigns: ProfileCampaign[],
  }>;
}> = (
  queryClient, options
) => {
  const queryKey = [QUERY_KEY_PROFILE, options.id];

  queryClient.setQueryData<GetProfileRequestReturnValue>(queryKey, (oldQueryData) => {
    if (oldQueryData) {
      const newValuesCategories = options.newValues?.categories;

      if (newValuesCategories) {
        // TODO: create and use hook {useUpdateRequestsCategories} from {hooks/request.ts}
        queryClient.invalidateQueries(QUERY_KEY_REQUEST_CATEGORIES);
      }

      return {
        ...oldQueryData,
        profile: {
          ...oldQueryData.profile,
          ...options.newValues?.profile,
        },
        categories: newValuesCategories
          ? oldQueryData.categories.map((item) => {
            return {
              ...item,
              ...newValuesCategories.find(({ categoryId }) => categoryId === item.categoryId),
            };
          })
          : oldQueryData.categories
      };
    }

    return oldQueryData;
  });
};

export const useSetProfile = (options?: { evangelistId: string }) => {
  const queryClient = useQueryClient();
  const { userData } = useMainContext();
  const { evangelistId } = options || {};

  const setProfileMutation = useMutation((params: {
    id: string,
    profile: Profile,
    categories?: SetProfileRequestCategory[],
  }) => {
    return setProfileRequest(userData, params.profile, evangelistId, params.categories);
  }, {
    onSuccess: (mutationResponse, mutationPayload) => {
      updateProfileById(queryClient, {
        id: evangelistId || mutationPayload.id,
        newValues: mutationResponse,
      });
      if (evangelistId) {
        const { firstname, lastname, } = mutationResponse.profile;
        updateCampaignMembersItemById(queryClient, {
          memberId: evangelistId,
          newProperties: {
            firstname, lastname,
          }
        });
        // update accounts for admin
        if (userData.role === Role.ADMIN) {
          updateEvangelistsFieldsById(queryClient, {
            id: evangelistId || mutationPayload.id,
            profile: {
              firstname: mutationPayload.profile.firstname,
              lastname: mutationPayload.profile.lastname,
              email: mutationPayload.profile.email,
              phone: mutationPayload.profile.phone,
            } as AdminEvangelistItem
          });
        }

        updateChatListEvangelistItem(queryClient, {
          filter: evangelistId,
          newProperties: { firstname: firstname || undefined, lastname: lastname || undefined, }
        });
        updateChatListLeadersItem(queryClient, {
          filter: evangelistId,
          newProperties: { firstname: firstname || undefined, lastname: lastname || undefined, }
        });
      }
    }
  });

  return {
    setProfileMutation,
  };
};

export const profileDefault: {
  profile: Profile,
  categories: ProfileCategoryLimit[],
  languages: ProfileLanguages,
  campaigns: ProfileCampaign[],
} = {
  profile: {} as Profile, // FIXME: Coercive 'as' - possible bug.
  categories: [],
  languages: [],
  campaigns: [],
};

const getProfileCategoryLimitId = (item: ProfileCategoryLimit) => item.categoryId;

export const useGetProfile = (evangelistId?: string) => {
  const { userData } = useMainContext();
  const id = evangelistId || userData.id;
  const isMyId = evangelistId ? evangelistId === userData.id : true;
  const isRoleAdmin = userData.role === Role.ADMIN;

  const {
    isLoading,
    isError,
    refetch,
    data = profileDefault,
  } = useQuery([QUERY_KEY_PROFILE, id], () => {
    if (!userData.agentToken || (isRoleAdmin && isMyId)) {
      return Promise.resolve(profileDefault);
    }

    return getProfileRequest(userData, evangelistId, isRoleAdmin);
  }, {
    enabled: !!id,
  });

  const get = useGetByIdWithCache(
    QUERY_KEY_PROFILE + id, data.categories, getProfileCategoryLimitId
  );

  return {
    isProfileLoading: isLoading,
    isProfileError: isError,
    refetchProfile: refetch,
    profileData: data,
    getProfileCategoryById: get,
  };
};
