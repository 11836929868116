import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Row, Typography } from '../../../components';
import TemplateMailSent from '../TemplateMailSent';
import { useModalContext } from '../../../components/modal/ModalProvider';
import AeFontIcons from '../../../assets/Icons';

type ViewRestoreMailSentProps = {
  email: string,
  btnOkText: string,
  resendFn: () => void | Promise<void>,
  onClickSuccess: () => void,
  size: 'sm' | 'lg',
}

const ViewRestoreMailSent = (
  { email, onClickSuccess, btnOkText, size, resendFn }: ViewRestoreMailSentProps
) => {
  const { t } = useTranslation();
  const [isFetching, setIsFetching] = useState(false);

  return (
    <TemplateMailSent
      size={size}
      icon={AeFontIcons.letter}
      title={t('passwordReset')}
      slotContent={(
        <>
          <div>
            {t('weSentLinkTo')}&nbsp;
            <Typography
              component='body1'
              tagName='span'
              color='blue'
            >{email}</Typography>
          </div>
          {t('followEmailInstructions')}
        </>
      )}
      slotActions={(
        <Row horizontal={size === 'sm' ? 'center' : undefined}>
          <Button
            disabled={isFetching}
            minWidth={size === 'sm' ? size : undefined}
            style={{ width: size === 'sm' ? '' : '100%' }}
            size={size}
            onClick={onClickSuccess}
          >{btnOkText}</Button>
        </Row>
      )}
      onResendEmail={async () => {
        setIsFetching(true);
        await resendFn();
        setIsFetching(false);
      }}
    />
  );
};

const ModalRestoreMailSent = (props: Omit<ViewRestoreMailSentProps, 'onClickSuccess'>) => {
  const { closeModal } = useModalContext();

  return <ViewRestoreMailSent {...props} onClickSuccess={closeModal} />;
};

export const useModalRestoreMailSent = () => {
  const { t } = useTranslation();
  const { openModal } = useModalContext();

  const openModalRestoreMailSent = (
    email: string,
    resendFn: ViewRestoreMailSentProps['resendFn'],
  ) => {
    openModal((
      <ModalRestoreMailSent
        size='sm'
        btnOkText={t('ok')}
        email={email}
        resendFn={resendFn}
      />
    ), {
      widthSize: 'md'
    });
  };

  return {
    openModalRestoreMailSent,
  };
};

export default ViewRestoreMailSent;
