import { FontIconViewIcon } from '../assets/FontIconView';
import AeFontIcons from '../assets/Icons';
import { LESSON_ID_SEPARATOR } from '../pages/students/StudentsPage.constans';
import { colors } from '../utils/theme';
import {
  DaysSinceTodayLabel,
  formatDateToUiDate,
  getDaysCountSinceTodayLabel,
  toLocalDateString
} from '../utils/time';
import {
  CommandItem,
  CommandItemApi,
  HistoryItem,
  HistoryItemApi,
  HistoryItemLesson,
  StudentItem,
  StudentItemApi,
  studentStatus,
  StudentStatusType,
  StudyItem,
  StudyItemApi,
  StudyLimitItem,
  StudyLimitItemApi
} from './students.types';

export const normalizeStudentChatItem = (item: StudentItemApi): StudentItem => {
  const {
    seriesId,
    status,
    language,
    aveListenPercent: aveStudentListenPercent,
    lessonsSent
  } = item.lastStudy;
  const {
    lessonIndex,
    lessonTitle,
    lessonTopic,
    aveListenPercent: aveLessonListenPercent,
    lastProgressDate,
    lessonUrl: url,
    lessonDate
  } = item.lastStudy?.lastLesson || {};

  return {
    id: item.id,
    firstname: item.firstname,
    lastname: item.lastname,
    lastContact: item.lastContact,
    lastProgressDate: lastProgressDate || null,
    createDate: item.createDate,
    aveStudentListenPercent: Number(aveStudentListenPercent),
    aveLessonListenPercent: aveLessonListenPercent ? Number(aveLessonListenPercent) : 0,
    lessonIndex: lessonIndex ? Number(lessonIndex) : 0,
    lessonTitle: lessonTitle || '',
    lessonTopic: lessonTopic || '',
    seriesId,
    status,
    lessonsSent,
    language,
    lessonDate: lessonDate || '',
    url: url || ''
  };
};

export const normalizeStudyLimitItem = (item: StudyLimitItemApi): StudyLimitItem[] => {
  const limitsOfType: StudyLimitItem[] = [];
  let color = '';
  let limit = -1;

  const { colors, limits } = item;
  for (let i = 0; i < colors.length; i++) {
    color = colors[i];
    if (i < limits.length) {
      limit = limits[i];
    }
    limitsOfType.push({
      color: `#${color}`,
      daysLimit: limit
    });
  }

  limitsOfType.sort((prev, next) => prev.daysLimit - next.daysLimit);
  return limitsOfType;
};

export const normalizeStudyItem = (item: StudyItemApi): StudyItem => {
  return {
    seriesId: item.study.seriesId,
    title: item.study.title,
    language: item.study.language,
    firstIndex: Number(item.study.firstIndex),
    totalLessons: Number(item.study.totalLessons),
    lessons: item.lessons.map((lessonItem) => ({
      id: `${item.study.seriesId}${LESSON_ID_SEPARATOR}${lessonItem.index}`,
      index: Number(lessonItem.index),
      topic: lessonItem.topic
    }))
  };
};

export const normalizeHistoryItem = (item: HistoryItemApi, itemIndex: number): HistoryItem => {
  return {
    id: itemIndex.toString(),
    seriesId: item.seriesId,
    status: item.status,
    statusDate: item.statusDate,
    language: item.language,
    aveListenPercent: Number(item.aveListenPercent),
    lessonsSent: item.lessonsSent,
    lessons: item.lessons.map((lessonItem, index) => ({
      id: index.toString(),
      lessonDate: lessonItem.lessonDate,
      lessonIndex: Number(lessonItem.lessonIndex),
      lessonTitle: lessonItem.lessonTitle,
      lessonTopic: lessonItem.lessonTopic,
      lessonUrl: lessonItem.lessonUrl,
      curLessonTime: Number(lessonItem.curLessonTime),
      totalTime: Number(lessonItem.totalTime),
      aveListenPercent: Number(lessonItem.aveListenPercent),
      lastProgressDate: lessonItem.lastProgressDate
    }))
  };
};

export const normalizeCommandItem = (item: CommandItemApi): CommandItem => {
  return {
    command: item.command,
    key: item.key,
    status: item.status,
    description: item.description,
    confirmMsg: item.confirmMsg,
    successMsg: item.successMsg,
    params: item.params.map((paramItem) => ({
      key: paramItem.key.toString(),
      text: paramItem.text.toString(),
      language: paramItem?.language
    }))
  };
};

export const getLessonIndex = (
  item: StudentItem | { firstIndex: number; lessonIndex: number } | undefined
): number => {
  if (!item || !item.lessonIndex) {
    return 0;
  }
  return item.firstIndex === 0 ? item.lessonIndex + 1 : item.lessonIndex;
};

export const getStatusIconAndColor = (
  status: StudentStatusType
): { icon: FontIconViewIcon; color?: string } => {
  if (status === studentStatus.SUBSCRIBED) {
    return { icon: AeFontIcons.checkmarkRound, color: colors.green };
  } else if (status === studentStatus.COMPLETED) {
    return { icon: AeFontIcons.checkmarkRound, color: colors.grayDark };
  } else if (status === studentStatus.UNSUBSCRIBED) {
    return { icon: AeFontIcons.closeRound, color: colors.redMedium };
  } else if (status === studentStatus.CHANGED) {
    return { icon: AeFontIcons.checkmarkRound, color: colors.orangeDark };
  }
  return { icon: AeFontIcons.checkmarkRound };
};

export const getTextColor = (limits: StudyLimitItem[], value: number) => {
  let color;
  limits.forEach((item) => {
    if (value >= item.daysLimit) {
      color = item.color;
    }
  });
  return color;
};

export const getLessonDateLabel = (
  lesson: HistoryItemLesson,
  isLatestLesson: boolean
): DaysSinceTodayLabel | string => {
  const date = lesson.lastProgressDate || lesson.lessonDate;
  if (isLatestLesson) {
    return getDaysCountSinceTodayLabel(date);
  }
  return formatDateToUiDate(toLocalDateString(date), 'message');
};

export const getLimits = (limitsStr: string): number[] => {
  const limits = limitsStr.split('-');
  return limits.map((item) => Number(item));
};
