import {useEffect} from 'react';
import { QueryClient, useQueryClient } from 'react-query';

import {pushToMessageHistory, updateChatItem} from '../query/chat';
import {useMainContext} from '../MainProvider';
import {MessageItemType} from '../api/chat.types';

const createHandlerWindowMessage = (
  queryClient: QueryClient, userDataId: string, isUiRoleDM: boolean,
) => {
  return (event: CustomEvent<MessageItemType>) => {
    const message = event.detail;

    const isMessageOutgoing = message.sourceId === userDataId;
    const seekerId = isMessageOutgoing ? message.destId : message.sourceId;
    const seekerType = isMessageOutgoing ? message.destType : message.sourceType;

    const { status } = pushToMessageHistory(queryClient, {
      recipientId: seekerId,
      message,
    });

    const isCanIncreaseCounter = status !== 'fail/already-exists';

    updateChatItem(queryClient, {
      filter: seekerId,
      recipientType: seekerType,
      seekersListStatus: 'active',
      isUiRoleNotDM: !isUiRoleDM,
      newProperties: {
        text: message.text,
        date: message.sendDate,
      },
      messagesCountsFactors: {
        unread: isCanIncreaseCounter ? 1 : 0,
        incoming: isCanIncreaseCounter ? 1 : 0,
      },
    });
  };
};

const useNoPushNotifications = () => {
  const queryClient = useQueryClient();
  const { userData, isUiRoleDM, isInitDataLoaded, isAuth } = useMainContext();

  useEffect(() => {
    let handleWindowMessage: (e: CustomEvent<MessageItemType>) => void;

    if (isInitDataLoaded && isAuth) {
      handleWindowMessage = createHandlerWindowMessage(queryClient, userData.id, isUiRoleDM);

      window.addEventListener('fetchedNewMessageWithAutoRefresh', handleWindowMessage);
    }

    return () => {
      window.removeEventListener('fetchedNewMessageWithAutoRefresh', handleWindowMessage);
    };
  }, [userData.id, queryClient, isUiRoleDM, isInitDataLoaded, isAuth]);
};

export default useNoPushNotifications;
