import React from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonBookmark, Checkbox, FontIconView } from '../index';
import AeFontIcons from '../../assets/Icons';
import { ButtonBookmarkProps } from './ButtonBookmark';

import './ButtonBookmarkAttachToggle.scss';

interface ButtonBookmarkAttachToggleProps extends ButtonBookmarkProps {
  isAttached?: boolean;
  isDetachOnly?: boolean;
}

const ButtonBookmarkAttachToggle: React.FC<ButtonBookmarkAttachToggleProps> = ({
  isDetachOnly, isAttached, ...restProps
}) => {
  const { t } = useTranslation();

  return (
    <ButtonBookmark className='btn-bookmark-attach-toggle' color='blue-dark' {...restProps}>
      {t(isAttached ? 'detach' : 'attach')}&nbsp;
      {
        isDetachOnly ? (
          <FontIconView
            className='btn-bookmark-attach-toggle_icon-detach'
            icon={AeFontIcons.delete}
          />
        ) : (
          <Checkbox
            color='green'
            readOnly
            value={!!isAttached}
            onClick={(e) => {
              e.preventDefault(); // Prevent fire {onClick} twice as {Checkbox} has {label} inside.
            }}
          />
        )
      }
    </ButtonBookmark>
  );
};

ButtonBookmarkAttachToggle.defaultProps = {
  isAttached: true,
};

export default ButtonBookmarkAttachToggle;
