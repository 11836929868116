import { useCallback, useMemo } from 'react';
import { useGetRequests, useGetRequestsCategories } from '../../query/request';
import { getIsRequestClosed } from '../../utils/requests';
import { MessageItemType } from '../../api/chat.types';

export const useGetRequestInfo = (messageHistoryFlat:  MessageItemType[]) => {
  const requestIds = useMemo(() => {
    const result = new Set<string>();
    for (let i = 0; i < messageHistoryFlat.length; i++) {
      const message = messageHistoryFlat[i];

      if (!message.requestId) {
        continue;
      }

      result.add(message.requestId);
    }

    return Array.from(result);
  }, [messageHistoryFlat]);

  const requestIdsLength = useMemo(() => requestIds.length, [requestIds]);

  const { getRequestCategoryById } = useGetRequestsCategories();
  const { getRequestById } = useGetRequests({
    status: 'active',
    requestId: requestIds,
  }, {
    enabled: !!requestIdsLength,
  });

  return useCallback((rId) => {
    const request = rId ? getRequestById(rId) : undefined;

    const requestCategory = request
      ? getRequestCategoryById(request.request.categoryId)
      : undefined;

    if (request || requestCategory) {
      return {
        categoryName: requestCategory?.name,
        isClosed: request ? getIsRequestClosed(request) : undefined,
      };
    }

    return undefined;
  }, [getRequestById, getRequestCategoryById]);
};
