import React, {forwardRef} from 'react';

import {DividerVertical, Input, Row, Typography} from '..';
import { InputProps } from './Input';

export type InputPhoneNumberProps = InputProps & {
  code: string | number | null,
};

const InputPhoneNumber: React.FC<InputPhoneNumberProps> =
  forwardRef<HTMLInputElement, InputPhoneNumberProps>(({
    code, className, ...restProps
  }, ref) => {
    return (
      <Input
        ref={ref}
        type='text'
        slotBefore={code ? (
          <Row layout='flex' directionHorizontal vertical='center' style={{ flexShrink: 0, }}>
            <Typography component={restProps.size === 'sm' ? 'body3' : 'body2'}>+{code}</Typography>
            <DividerVertical />
          </Row>
        ) : undefined}
        {...restProps}
      />
    );
  });

export default InputPhoneNumber;
