import React, { useCallback, useRef } from 'react';
import { DefaultPanel } from './DefaultPanel';
import { useSidePanelContext } from './SidePanelProvider';
import ViewSetupLimits, { ViewSetupLimitsRef } from '../../pages/dashboard/ViewSetupLimits';

const MyRequestLimits = () => {
  const refView = useRef<ViewSetupLimitsRef>(null);
  const { closePanel } = useSidePanelContext();

  const submit = async () => {
    await refView.current?.submit();
    closePanel();
  };

  return (
    <DefaultPanel title="myRequestLimits" onClickSave={submit}>
      <ViewSetupLimits ref={refView} />
    </DefaultPanel>
  );
};

export const usePanelMyRequestLimits = () => {
  const { openPanel } = useSidePanelContext();

  const openPanelMyRequestLimits = useCallback(() => {
    openPanel({
      component: <MyRequestLimits />,
    });
  }, [openPanel]);

  return {
    openPanelMyRequestLimits,
  };
};
