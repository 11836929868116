import React, { useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Checkbox, Divider, Row, Typography } from '../../components';
import { SetProfileRequestCategory } from '../../api/account';
import { useGetRequestsCategories } from '../../query/request';
import { useMainContext } from '../../MainProvider';
import { useGetProfile, useSetProfile } from '../../query/profile';
import { useModalContext } from '../../components/modal/ModalProvider';
import { useToastEnhanced } from '../../enhanced-components/toaster/ToasterEnhanced';

export type ViewSetupLimitsRef = {
  submit: () => Promise<any>
}

const ViewSetupLimits = React.forwardRef<ViewSetupLimitsRef>((props, ref) => {
  const { t } = useTranslation();

  const { userData } = useMainContext();
  const { openModal } = useModalContext();
  const { showToast } = useToastEnhanced();
  const { profileData } = useGetProfile(userData.id);
  const { setProfileMutation } = useSetProfile();
  const { getRequestCategoryById } = useGetRequestsCategories({
    staleTime: 0,
  });
  const { getProfileCategoryById } = useGetProfile(userData.id);

  const { categories } = profileData;

  const [values, setValues] = useState<SetProfileRequestCategory[]>(categories);

  // only 'Prayer' category is supported for now
  const _TEMPORARY_profileRequestCategories = values.filter((r) => r.categoryId === '1');

  const renderStatusSwitch = (
    label: string,
    item: SetProfileRequestCategory,
    field: 'externalStatus' | 'teamStatus' | 'privateStatus'
  ) => {
    return (
      <Checkbox
        value={item[field] === '1'}
        onChange={(_, value) => {
          setValues([
            {
              ...item,
              [field]: value ? '1' : '0',
            }
          ]);
        }}
        slotBefore={<Typography
          component='body1'
          tagName='div'
          style={{ width: '100%', }}
        >{label}</Typography>}
      />
    );
  };


  const saveHandler = () => setProfileMutation.mutateAsync({
    id: userData.id,
    categories: values,
    profile: profileData.profile,
  }).then(() => {
    showToast({ title: t('changesSuccess') });
  });

  const submit = () => {
    const isLimitsUpdated = categories.some((item, index) => {
      const localCategory = values[index];
      return (
        item.externalStatus !== localCategory.externalStatus
        || item.teamStatus !== localCategory.teamStatus
        || item.privateStatus !== localCategory.privateStatus
      );
    });

    if (isLimitsUpdated) {
      const isAllZero = values.every((item) => (
        item.externalStatus === '0'
        && item.teamStatus === '0'
        && item.privateStatus === '0'
      ));

      if (isAllZero) {
        return new Promise((resolve) => {
          openModal((
            <p>{t('requestsToZeroMsg')}</p>
          ), {
            title: t('confirmation'),
            widthSize: 'sm',
            okButton: true,
            cancelButton: true,
            okButtonProps: {
              children: t('confirm'),
              color: 'red',
              onClick: async () => {
                await saveHandler();

                resolve(true);
              },
            },
          });
        });
      }

      return saveHandler();
    }

    return Promise.resolve();
  };


  const refValue: ViewSetupLimitsRef = {
    submit: submit,
  };
  useImperativeHandle(ref, () => refValue);

  useEffect(() => {
    setValues(categories);
  }, [categories]);

  return (
    <Row gap='extra-lg'>
      <Row gap='md'>
        {
          _TEMPORARY_profileRequestCategories.map((item) => {
            const requestCategory = getRequestCategoryById(item.categoryId);
            const requests = getProfileCategoryById(item.categoryId)?.currentRequests || '0';

            return (
              <Row key={item.categoryId} gap='extra-sm'>
                <Row
                  gap='lg'
                  autoColumns
                  directionHorizontal
                  horizontal='space-between'
                  vertical='center'
                >
                  <Typography component='h3' tagName='span'>
                    {requestCategory ? t(requestCategory?.name) : ''}
                  </Typography>
                  <Typography component='body3' color='text-title-light'>
                    &nbsp;
                    ({t('available', {
                      number: requestCategory ? requestCategory.waiting : '0',
                    })}, {t('unassigned')}: {requests})
                  </Typography>
                </Row>
                {renderStatusSwitch(t('Private Requests'), item, 'privateStatus')}
                {renderStatusSwitch(t('Team Requests'), item, 'teamStatus')}
                {renderStatusSwitch(t('External Requests'), item, 'externalStatus')}
                <Divider />
              </Row>
            );
          })
        }
      </Row>

      <Button
        style={{ width: '100%' }}
        variant='outline'
        color='blue'
        onClick={() => {
          setValues(_TEMPORARY_profileRequestCategories.map(({categoryId}) => ({
            categoryId,
            externalStatus: '0',
            teamStatus: '0',
            privateStatus: '0',
          })));
        }}
      >{t('setAllToZero')}</Button>

      <Button
        style={{ width: '100%' }}
        variant='outline'
        color='blue'
        onClick={() => {
          setValues(_TEMPORARY_profileRequestCategories.map((category) => ({
            ...category,
            teamStatus: '0',
          })));
        }}
      >{t('setTeamToZero')}</Button>

      <Typography color='text-title-light' component='body3'>{t('limitsToZeroDesc')}</Typography>
    </Row>
  );
});

export default ViewSetupLimits;
