import React, {forwardRef} from 'react';
import classnames from 'classnames';

import { Input } from '../index';
import { InputProps } from '../input/Input';
import SelectArrow from './SelectArrow';

import './SelectInput.scss';

export type SelectInputProps = InputProps & {
  isOpen: boolean,
};

const SelectInput = forwardRef<HTMLInputElement, SelectInputProps>(({
  className, isOpen, slotAfter, wrapperProps, ...restProps
}, ref) => {
  return (
    <Input
      readOnly
      wrapperProps={{
        className: 'select-input',
        ...wrapperProps
      }}
      className={classnames('select-input_input', className)}
      slotAfter={(inputProps) => {
        return (
          <>
            {typeof slotAfter === 'function' ? slotAfter(inputProps) : slotAfter}
            <SelectArrow className='select-input_arrow' isRotated={isOpen} />
          </>
        );
      }}
      {...restProps}
      ref={ref}
    />
  );
});

export default SelectInput;
