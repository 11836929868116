import React, { forwardRef } from 'react';
import classNames from 'classnames';

import { ColorScheme } from '../../types';
import { COMPONENT_COLOR_DEFAULT } from '../../utils/constants';
import AeFontIcons from '../../assets/Icons';
import FontIconView from '../../assets/FontIconView';

import './progress-bar.scss';

type BarType = 'circle' | 'line' | 'multiple';

interface Options {
  strokeWidthBg?: number;
  strokeWidthProgress?: number;
  sqSize?: number;
}

interface ProgressBarProps {
  children?: React.ReactNode;
  options?: Options;
  percentage: string | number | number[];
  type: BarType;
  color?: ColorScheme | ColorScheme[];
  showIsCompleted?: boolean;
}

interface CircularBarProps {
  progress: number;
  children?: React.ReactNode;
  strokeWidthBg?: number;
  strokeWidthProgress?: number;
  sqSize?: number;
  color?: ColorScheme;
  showIsCompleted?: boolean;
}

interface LineBarProps {
  progress: number;
  progressElementProps?: React.HTMLProps<HTMLDivElement>;
  color?: ColorScheme;
}

interface LineMultipleBarProps {
  progress: number[];
  progressElementProps?: React.HTMLProps<HTMLDivElement>
  color?: ColorScheme;
}

const CircularProgressBar: React.FC<CircularBarProps> = ({
  progress,
  children,
  strokeWidthBg = 3,
  strokeWidthProgress = 3,
  sqSize = 80,
  color,
  showIsCompleted = false,
}) => {
  const radius = (sqSize - Math.max(strokeWidthBg, strokeWidthProgress)) / 2;
  // Enclose circle in a circumscribing square
  const viewBox = `0 0 ${sqSize} ${sqSize}`;
  // Arc length at 100% coverage is the circle circumference
  const dashArray = radius * Math.PI * 2;
  // Scale 100% coverage overlay with the actual percent
  const dashOffset = dashArray - dashArray * progress / 100;

  const isCompleted = progress >= 100;

  return (
    <div
      className={classNames('progress-bar', 'circle', color)}
      style={{ width: sqSize, height: sqSize }}
    >
      <svg width={sqSize} height={sqSize} viewBox={viewBox}>
        <circle
          className="circle-background"
          cx={sqSize / 2}
          cy={sqSize / 2}
          r={radius}
          strokeWidth={`${strokeWidthBg}px`}
        />
        <circle
          className="circle-progress"
          cx={sqSize / 2}
          cy={sqSize / 2}
          r={radius}
          strokeWidth={`${strokeWidthProgress}px`}
          // Start progress marker at 12 O'Clock
          transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
          style={{
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset
          }}
        />
      </svg>
      {(isCompleted && showIsCompleted) && (
        <div className="achievement-item__completed">
          <FontIconView icon={AeFontIcons.check} />
        </div>
      )}
      <div className="progress-bar__content" style={{ width: sqSize }}>
        {children}
      </div>
    </div>
  );
};

export const LineProgressBar =
  forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement> & LineBarProps>(({
    className, progress, color, progressElementProps, ...restProps
  }, ref) => {
    return (
      <div
        ref={ref}
        className={classNames('progress-bar', 'line', color, className)}
        {...restProps}
      >
        <div
          {...progressElementProps}
          className={classNames('progress-bar__filler', progressElementProps?.className)}
          style={{ width: `${progress}%` }}
        />
      </div>
    );
  });

export const LineMultipleProgressBar =
  forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement> & LineMultipleBarProps>(({
    className, progress, color, progressElementProps, ...restProps
  }, ref) => {
    const data = progress.reduce((acc, item, index) => {
      acc.push({ progress: item, color: color?.[index] || 'blue' });

      return acc;
    }, [] as Array<{ progress: number, color: string }>);

    const isAllZero = data.every((item: any) => item.progress === 0);

    return (
      <div
        ref={ref}
        className={classNames('progress-bar', 'line', 'multiple', className, isAllZero && 'border')}
        {...restProps}
      >
        {data.map((item: any) => {
          return (
            <div
              key={item.color}
              {...progressElementProps}
              className={
                classNames('progress-bar__filler', item.color, progressElementProps?.className)
              }
              style={{ width: `${item.progress}%` }}
            />
          );
        })}
      </div>
    );
  });

LineProgressBar.defaultProps = {
  color: COMPONENT_COLOR_DEFAULT,
};

CircularProgressBar.defaultProps = {
  color: COMPONENT_COLOR_DEFAULT,
};

const prepareProgress = (percentage: string | number | number[], type: BarType) => {
  if (type === 'multiple') {
    return percentage;
  }

  if (Array.isArray(percentage)) {
    return percentage[0];
  }

  let updatedPercentage = percentage;
  if (typeof updatedPercentage === 'string') {
    updatedPercentage = parseInt(updatedPercentage, 10);
  }
  // Percentage can't be more than 100%
  updatedPercentage = updatedPercentage >= 100 ? 100 : updatedPercentage;

  return updatedPercentage;
};

const prepareColor = (color: ColorScheme | ColorScheme[] = 'blue', type: BarType) => {
  if (type === 'multiple') {
    return color;
  }

  if (Array.isArray(color)) {
    return color[0];
  }

  return color;
};

export const ProgressBar: React.FC<ProgressBarProps> = ({
  options,
  type,
  percentage,
  color,
  ...restProps
}) => {
  const preparedProgress = prepareProgress(percentage, type);
  const preparedColor = prepareColor(color, type);

  return type === 'circle' ? (
    <CircularProgressBar
      progress={preparedProgress as number}
      color={preparedColor as ColorScheme}
      {...options}
      {...restProps}
    />
  ) : type === 'line' ? (
    <LineProgressBar
      progress={preparedProgress as number}
      color={preparedColor as ColorScheme}
      {...restProps}
    />
  ) : (
    <LineMultipleProgressBar
      progress={preparedProgress as number[]}
      color={preparedColor as ColorScheme}
      {...restProps}
    />
  );
};
