import { useMemo } from 'react';

import { useGetRequestsCategories } from '../query/request';
import { useMainContext } from '../MainProvider';
import { useGetProfile } from '../query/profile';

type UserRequestsType = {
  unassigned: number,
  seekersAmount: number,
  total: number,
};

export const useUserRequests = () => {
  const { requestCategories } = useGetRequestsCategories();
  const { userData } = useMainContext();
  const { getProfileCategoryById } = useGetProfile(userData.id);

  const userRequests: UserRequestsType = useMemo(() => {
    const defaultValues = { unassigned: 0, seekersAmount: 0, total: 0 };
    if (!requestCategories?.length) {
      return defaultValues;
    }

    return requestCategories.reduce((acc, item) => {
      const profileCategory = getProfileCategoryById(item.id);
      if (!profileCategory) {
        return defaultValues;
      }
      acc.unassigned += Number(item.waiting);
      acc.seekersAmount += Number(profileCategory?.currentRequests);
      acc.total += Number(profileCategory?.categoryLimit);

      return acc;
    }, defaultValues);
  }, [getProfileCategoryById, requestCategories]);

  return userRequests;
};
