import React from 'react';

import { FontIconView, Row, Typography } from '../../components';
import { useModalTextTemplate } from './ModalTextTemplate';
import { TemplateItemProps } from './Templates.types';
import { useUrlQuery } from '../../hooks/useUrlQuery';
import { highlightSearch } from '../../utils/dom';
import AeFontIcons from '../../assets/Icons';

const TextTemplateItem = ({
  style,
  item,
  clamp,
  slotBtnAction,
}: TemplateItemProps) => {
  const query = useUrlQuery();
  const search = query.get('search') || '';
  const { openModalTextTemplate } = useModalTextTemplate();

  const isCustomTemplate = !!item.evangelistId;

  const handleClick = () => {
    openModalTextTemplate(item, isCustomTemplate);
  };

  return (
    <div key={item.id} className="card template-item text-template" style={style}>
      {isCustomTemplate && <div className="template-item__user">
        <FontIconView icon={AeFontIcons.profileFill} />
      </div>}
      <div className="template-item__inner" onClick={handleClick}>
        <Typography
          component='body3'
          clamp={clamp}
          dangerouslySetInnerHTML={{ __html: highlightSearch(item.textUrlified, search) }}
        />
      </div>
      <Row
        directionHorizontal
        style={{ gridTemplateColumns: '1fr auto' }}
        vertical='center'
        horizontal="space-between"
        gap="sm"
      >
        <Typography
          threeDots
          component='h5'
          color='text-title'
          variant='poppins'
        >{item.title}</Typography>
        {slotBtnAction}
      </Row>
    </div>
  );
};

export default TextTemplateItem;
