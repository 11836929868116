import React  from 'react';

import { Image, Typography } from '../../components';
import { TemplateItemProps } from './Templates.types';

const ImageTemplateItem = ({
  className,
  style,
  item,
  isShowTitle,
  slotBtnAction,
}: TemplateItemProps) => {
  return (
    <div
      className={'template-item image-template ' + (className ? ('' + className) : '')}
      style={style}
    >
      <div className="template-item__inner">
        <a href={item.thumbnail} target='_blank' rel='noreferrer'>
          {
            item.thumbnail && (
              <Image src={item.thumbnail} title={item.title} />
            )
          }
        </a>
        <div className="template-item__slot-absolute">{slotBtnAction}</div>
      </div>
      {
        isShowTitle && (
          <Typography component='h5' variant='poppins'>
            {item.title}
          </Typography>
        )
      }
    </div>
  );
};

export default ImageTemplateItem;
