import React from 'react';
import classnames from 'classnames';

import './Backdrop.scss';

const Backdrop: React.FC<React.HTMLProps<HTMLDivElement>> = ({ className, ...restProps }) => {
  return (
    <div className={classnames('backdrop', className)} {...restProps} />
  );
};

export default Backdrop;
