import React  from 'react';
import { useTranslation } from 'react-i18next';

import { CheckList, FormField, Form, ModalActions } from '../../../components';
import Row from '../../../components/row/Row';
import { FormChildrenProps, FormSubmitHandler } from '../../../components/form/Form';
import {MultiSelectOption} from '../../../components/multi-select/MultiSelect.types';
import { SelectOption } from '../../../components/select/Select.types';
import { useGetAvailableLanguages } from '../../../query/languages';

type ModalUserLanguagesFormData = {
  languages: SelectOption[],
};

type ModalUserLanguagesProps = {
  languagesAdded: string[],
  onCancel: () => void,
  onAddLanguages: FormSubmitHandler<ModalUserLanguagesFormData>,
}

const ModalUserLanguages: React.FC<ModalUserLanguagesProps> = ({
  languagesAdded, onAddLanguages, onCancel
}) => {
  const { t } = useTranslation();

  const {
    availableRequestLanguages,
    isAvailableLanguagesLoading,
  } = useGetAvailableLanguages();

  const languagesOptions: MultiSelectOption[] =
    availableRequestLanguages?.filter(({language}) => !languagesAdded.includes(language))
      .map((item) => {
        return {
          key: item.language,
          label: item.name,
        };
      }) || [];

  const fields: ModalUserLanguagesFormData = {
    languages: [],
  };

  return (
    <Form
      style={{ height: '21.4rem' }}
      fields={fields}
      onSubmit={onAddLanguages}
    >
      {
        ({values}: FormChildrenProps<ModalUserLanguagesFormData>) => (
          <Row gap='extra-lg' style={{
            height: '100%',
            alignContent: 'space-between',
          }}>
            <FormField
              name='languages'
              component={CheckList}
              size='sm'
              componentProps={{
                isLoading: isAvailableLanguagesLoading,
                options: languagesOptions,
              }}
            />

            <ModalActions
              btnCancelProps={{
                onClick: onCancel,
              }}
              btnOkProps={{
                disabled: !values.languages.length,
                children: t('add'),
              }}
            />
          </Row>
        )
      }
    </Form>
  );
};

export default ModalUserLanguages;
