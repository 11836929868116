import { apiRequest } from './api';
import {
  CampaignsSummaryFromApi, CreateCampaignItemFromApi,
  ListCampaignsItemConcise,
  ListCampaignsItemConciseApi,
  ListCampaignsItemFromApi,
  ModifyCampaignsRequestAction,
  MyCampaignApi
} from './campaigns.types';
import { ApiParamUserData } from './api.types';
import {
  normalizeListCampaigns,
  normalizeCampaignSummaryItem,
  normalizeConciseCampaignItem, normalizeMyCampaign
} from './campaigns.utils';
import { Role } from '../utils/enums';


// Get list of campaigns that this evangelist has registered for
export const getMyCampaignsRequest = (userData: ApiParamUserData) =>
  apiRequest<{ campaigns: MyCampaignApi[] }>('getCampaigns', userData)
    .then((response) => {
      return response.campaigns.map(normalizeMyCampaign);
    });

type ListCampaignParams = {
  all: boolean;
  extended: boolean;
  summary?: boolean;
  summaryOnly?: boolean;
  campaignIds?: string[];
  offset?: number;
  limit?: number;
  search?: string;
  agentToken?: string;
  id?: string;
}

// update if 'createCampaign' API changed
export type CreateCampaignRequest = Pick<
  ListCampaignsItemFromApi,
  'name' | 'description' | 'startDate' | 'endDate' | 'hidden' | 'conference'
> & { id: string }

export type UpdateCampaignRequest = Partial<ListCampaignsItemFromApi> & { newManagerId?: string };

// Get list of all campaigns currently in the Orion system
export const getListCampaignsRequest = (
  params: ListCampaignParams,
  conciseCampaigns?: ListCampaignsItemConcise[]
) =>
  apiRequest<{
    totalCount: number;
    summary: CampaignsSummaryFromApi;
    campaigns: ListCampaignsItemFromApi[];
    agentToken: string,
    id: string,
  }>('listCampaigns', params).then((response) => {
    const { totalCount, summary, campaigns = [] } = response;
    const normalizedCampaigns = normalizeListCampaigns(campaigns, totalCount);
    if (summary) {
      normalizedCampaigns.unshift(
        normalizeCampaignSummaryItem(summary, conciseCampaigns || [] /* campaigns */)
      );
    }
    return normalizedCampaigns;
  });

// FIXME: If you know what's the meaning of this request,
//  please, add an explanatory comment.
//  Now, it looks like abstract something.
export const getConciseListCampaignsRequest = (userData: ApiParamUserData) => {
  const params: { id?: string, vetterId?: string } = {
    id: userData.id
  };
  if (userData.role === Role.ADMIN) {
    params.vetterId = userData.id;
  }
  return apiRequest<{ campaigns: ListCampaignsItemConciseApi[] }>('getCampaignList', {
    agentToken: userData.agentToken,
    ...params
  }).then((response) => {
    return response.campaigns.map(normalizeConciseCampaignItem);
  });
};


export const getCampaignList = (userData: ApiParamUserData) => {
  const params: { id?: string, vetterId?: string, evanList: boolean } = {
    id: userData.id,
    evanList: true,
  };
  if (userData.role === Role.ADMIN) {
    params.vetterId = userData.id;
  }

  return apiRequest<{ campaigns: ListCampaignsItemConciseApi[] }>('getCampaignList', {
    agentToken: userData.agentToken,
    ...params
  }).then((response) => {
    return response.campaigns.map(normalizeConciseCampaignItem);
  });
};

// Modify the active campaigns for an evangelist
export const modifyCampaignsRequest = (
  userData: ApiParamUserData,
  campaigns: ModifyCampaignsRequestAction[],
  evangelistId?: string,
) => {

  const params: {
    id: string;
    leaderId?: string;
    vetterId?: string;
  } = {
    id: userData.id,
  };

  if (evangelistId) {
    params.id = evangelistId;
    if (userData.role === Role.ADMIN) {
      params.vetterId = userData.id;
    } else {
      params.leaderId = userData.id;
    }
  }

  return (
    apiRequest('modifyCampaigns', {
      ...params,
      agentToken: userData.agentToken,
      campaigns,
    })
  );
};

export const createCampaignRequest = (
  userData: ApiParamUserData,
  data: CreateCampaignRequest,
) => {
  const params: CreateCampaignRequest & { vetterId?: string } = data;

  if (userData.role === Role.ADMIN) {
    params.vetterId = userData.id;
  }

  return (
    apiRequest<{
      campaignId: number,
      campaignData: CreateCampaignItemFromApi,
    }>('createCampaign', {
      ...params,
      agentToken: userData.agentToken,
    })
  );
};

export const deleteCampaignRequest = (userData: ApiParamUserData, campaignId: string) => {
  const params: {
    id: string,
    agentToken: string,
    vetterId?: string,
  } = {
    id: userData.id,
    agentToken: userData.agentToken,
  };

  if (userData.role === Role.ADMIN) {
    params.vetterId = userData.id;
  }

  return apiRequest('deleteCampaign', { ...params, campaignId });
};

export const updateCampaignRequest = (
  userData: ApiParamUserData,
  data: UpdateCampaignRequest,
) => {
  const params: {
    id: string,
    agentToken: string,
    vetterId?: string,
  } = {
    id: userData.id,
    agentToken: userData.agentToken,
  };

  if (userData.role === Role.ADMIN) {
    params.vetterId = userData.id;
  }

  return apiRequest('updateCampaign', { ...params, ...data });
};
