import React from 'react';
import classnames from 'classnames';
import { ColorScheme } from '../../types';

import './Badge.scss';

type BadgeVariant = 'outline';

type BadgeProps = {
  className?: string,
  color?: ColorScheme,
  variant?: BadgeVariant,
};

const Badge: React.FC<BadgeProps> = (props) => {
  return (
    <div
      className={classnames('badge', props.variant, props.className, props.color)}
    >{props.children}</div>
  );
};

Badge.defaultProps = {
  color: 'blue',
};

export default Badge;
