import { apiRequest } from './api';
import { ChannelListItem } from './channels.types';
import { ApiParamUserData } from './api.types';

// Get list of available channels for the Orion system
export const getChannelListRequest = (userData: ApiParamUserData) => {
  return apiRequest<{
    channels: ChannelListItem[],
  }>('getChannelList', {
    id: userData.id,
    agentToken: userData.agentToken,
  }).then((response) => response.channels);
};
