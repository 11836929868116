import React from 'react';
import { useTranslation } from 'react-i18next';

import { Loader, NoDataTemplate, Row, Typography } from '../../components';
import { TreeView } from '../../components/tree/TreeView';
import { TreeItem } from '../../api/resources';

import './ResourcesView.scss';

interface ResourcesViewProps {
  renderTitle: () => React.ReactNode;
  renderContent: () => React.ReactNode;
  tree: TreeItem[];
  isLoading: boolean;
  notFound: boolean;
  handleChangeId: (id: string) => void;
  activeId?: string | null;
  parentId?: string;
  isFaq?: boolean;
  search?: string;
}

export const ResourcesView: React.FC<ResourcesViewProps> = ({
  renderTitle,
  renderContent,
  tree,
  isLoading,
  notFound,
  handleChangeId,
  activeId,
  parentId,
  isFaq = false,
  search,
}) => {
  const { t } = useTranslation();

  const renderTreeView = () => {
    let treeView;

    if (isLoading) {
      treeView = <Loader />;
    } else if (notFound) {
      treeView = (
        <NoDataTemplate title={t('noResultsFound')} message={t('noContentMatchCriteria')}/>
      );
    } else {
      treeView = (
        <TreeView
          tree={tree}
          parentId={parentId}
          activeId={activeId}
          onItemClick={handleChangeId}
          isFaq={isFaq}
        />
      );
    }

    return treeView;
  };

  return (
    <Row
      layout='flex'
      paddingHorizontal='extra-lg'
      style={{ height: '100%' }}
    >
      <div className='resources-view_header-inner'>
        {renderTitle()}
      </div>
      <div className='resources-view_content-wrap'>
        <Row border='right' layout='flex'>
          {search && (
            <Row paddingVertical='lg'>
              <Typography component="h4">{t('searchFor')} {`'${search}'`}</Typography>
            </Row>
          )}
          {renderTreeView()}
        </Row>
        {renderContent()}
      </div>
    </Row>
  );
};
