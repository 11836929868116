import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, FontIconView, NoDataTemplate } from '../index';
import AeFontIcons from '../../assets/Icons';

interface ViewErrorNoDataProps {
  isFetchError?: boolean; // if "true" shows another text.
  refetchFn?: (...args: any) => any;
}

const ViewErrorNoData = (props: ViewErrorNoDataProps) => {
  const { t } = useTranslation();

  const { refetchFn } = props;

  return (
    <NoDataTemplate
      title={t(props.isFetchError ? 'errorFetchTitle' : 'errorCantShowDataTitle')}
      message={t(props.isFetchError ? 'errorFetchDesc' : 'errorCantShowDataDesc')}
    >
      {
        refetchFn
          ? (
            <Button
              variant='outline'
              color='blue'
              onClick={() => {
                refetchFn();
              }}
              iconBefore={<FontIconView icon={AeFontIcons.refresh} />}
            >{t('refresh')}</Button>
          )
          : ''
      }
    </NoDataTemplate>
  );
};

export default ViewErrorNoData;
