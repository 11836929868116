import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useMainContext } from '../MainProvider';
import {
  addReminderRequest,
  getRemindersRequest,
  ModifyReminderRequestReminder,
  modifyRemindersRequest
} from '../api/reminder';
import { useToastEnhanced } from '../enhanced-components/toaster/ToasterEnhanced';
import { PromiseResolveValue } from '../types';
import { useGetByIdWithCache } from '../hooks/useCache';
import {ReminderItemType} from '../api/reminder.types';

export const QUERY_KEY_REMINDERS = 'reminders';

export const useAddReminder = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { showToast } = useToastEnhanced();
  const { userData } = useMainContext();

  const addMutation = useMutation((reminder: {
    title: string,
    schedule: string,
    requestId?: string | null,
    seekerId?: string,
  }) => {
    return addReminderRequest(userData, reminder);
  }, {
    onSuccess: (response, mutationPayload) => {
      queryClient.setQueryData<
        PromiseResolveValue<ReturnType<typeof getRemindersRequest>> | undefined
        >(QUERY_KEY_REMINDERS, (oldQueryData) => {
          if (oldQueryData) {
            oldQueryData.push({
              id: userData.id,
              reminderId: response.reminderId.toString(),
              title: mutationPayload.title,
              seekerId: mutationPayload.seekerId || null,
              schedule: mutationPayload.schedule,
              requestId: mutationPayload.requestId || null,
              sent: null,
              status: 'waiting'
            });

            return [...oldQueryData];
          }

          return oldQueryData;
        });

      showToast({
        title: t('reminderAdded')
      });
    }
  });

  return {
    addReminderMutation: addMutation
  };
};

export const useModifyReminder = () => {
  const { t } = useTranslation();
  const { showToast } = useToastEnhanced();
  const queryClient = useQueryClient();
  const { userData } = useMainContext();

  const modifyMutation = useMutation((reminder: ModifyReminderRequestReminder) => {
    return modifyRemindersRequest(userData, reminder);
  }, {
    onSuccess: (response, mutationPayload) => {
      queryClient.setQueryData<
        PromiseResolveValue<ReturnType<typeof getRemindersRequest>> | undefined
        >(QUERY_KEY_REMINDERS, (oldQueryData) => {
          if (oldQueryData) {
            if (mutationPayload.action === 'edit') {
              return oldQueryData.map((item) => {
                if (item.reminderId === mutationPayload.reminderId) {
                  return {
                    ...item,
                    title: mutationPayload.title || item.title,
                    schedule: mutationPayload.schedule || item.schedule
                  };
                }

                return item;
              });
            } else if (mutationPayload.action === 'delete') {
              return oldQueryData.filter((item) => {
                return item.reminderId !== mutationPayload.reminderId;
              });
            }
          }

          return oldQueryData;
        });

      showToast({
        title: t(mutationPayload.action === 'delete' ? 'success' : 'reminderEdited')
      });
    }
  });

  return {
    modifyReminderMutation: modifyMutation
  };
};

const remindersInitialData: [] = [];

const getReminderId = (item: ReminderItemType) => item.id;

export const useGetReminders = () => {
  const { userData } = useMainContext();

  const { isLoading, data = remindersInitialData } = useQuery(
    QUERY_KEY_REMINDERS,
    () => getRemindersRequest(userData), {
      staleTime: 0, // Refetch each time hook is called.
    });
  const get = useGetByIdWithCache('reminders', data, getReminderId);

  return {
    isRemindersLoading: isLoading,
    reminders: data,
    getReminderById: get
  };
};
