import React, { useEffect, useState } from 'react';
import { MsgType } from '../../api/chat.types';
import GradeStarsView from './GradeStarsView';
import { useMainContext } from '../../MainProvider';
import { useSetVetterMessageStatus } from '../../query/chat';

const SET_SCORE_TIMEOUT = 1000;

type GradeStarsViewWithAPIProps = {
  recipientId?: string,
  messageId?: string
  msgType?: MsgType
  score: number,
  evangelistId?: string,
}

export const GradeStarsViewWithAPI = ({
  recipientId,
  messageId,
  msgType,
  score,
  evangelistId
}: GradeStarsViewWithAPIProps) => {
  const { isUiRoleNotDM } = useMainContext();
  const isSmallMode = !!(recipientId && messageId);
  const [localScore, setLocalScore] = useState(score);

  const { setVetterMessageStatusMutation } = useSetVetterMessageStatus();

  //Update local score when score within message is updated
  useEffect(() => {
    setLocalScore(score);
  }, [score]);

  //Update score with debounce after user clicked on star
  useEffect(() => {
    let timeout: number | undefined;
    if (isSmallMode && score !== localScore) {
      timeout = window.setTimeout(() => {
        setVetterMessageStatusMutation.mutateAsync({
          isUpdate: !!score,
          recipientId,
          messageId,
          msgStatus: localScore >= 2 ? 'approved' : 'denied',
          msgScore: localScore.toString(),
          msgType,
          evangelistId
        });
      }, SET_SCORE_TIMEOUT);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSmallMode, localScore]);

  if (isUiRoleNotDM) {
    return (
      <GradeStarsView isSmall score={localScore} onSetScore={setLocalScore} />
    );
  }

  return <div />;
};
