import { useGetAvailableLanguages, useGetTreeLanguages } from '../query/languages';

export const useGetAvailableTreeLanguages = (isFaq?: boolean) => {
  const { treeLanguages } = useGetTreeLanguages();
  const { availableRequestLanguages } = useGetAvailableLanguages();

  const treeLanguagesIds = treeLanguages
    .filter((item) => (isFaq ? item.isFaq : item.isAttachment))
    .map((item) => item.id);

  return availableRequestLanguages.filter((item) => treeLanguagesIds.includes(item.language));
};
