import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import Button from '../button/Button';
import { useModalContext } from '../modal/ModalProvider';
import { useToastEnhanced } from '../../enhanced-components/toaster/ToasterEnhanced';
import { BaseUrl, SERVER_URL } from '../../utils/constants';
import {devDataStorage} from './ChangeServerPopup.utils';
import { Input, Select } from '../index';
import { renderField } from '../custom-field/customField';

import './server-popup.scss';

const serversOptions = [
  { key: SERVER_URL.prod, label: 'awrchatbot (prod)' },
  { key: SERVER_URL.dev, label: 'awrinfo (dev)' },
  { key: SERVER_URL.hope, label: 'awrhope (hope)' },
  { key: SERVER_URL.hope_staging, label: 'awrhope(staging)'}
];

const SecurePin = '7777';

type Inputs = {
  pinCode: string;
  apiUrlNew: string;
};

export const ChangeServerPopup: React.FC = () => {
  const [showServers, setShowServers] = useState(false);
  const { showToast } = useToastEnhanced();
  const { closeModal } = useModalContext();

  const ChangeServerSchema = Yup.object().shape({
    pinCode: Yup.string().oneOf([SecurePin], 'Wrong PIN code')
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm<Inputs>({
    resolver: yupResolver(ChangeServerSchema),
    defaultValues: {
      apiUrlNew: window.apiUrlNew || BaseUrl,
    },
  });

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (!showServers) {
      setShowServers(true);
    } else {
      window.apiUrlNew = data.apiUrlNew;

      devDataStorage.save({
        apiUrlNew: data.apiUrlNew,
      });

      showToast({ title: 'Server success changed'});
      closeModal();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <div className="server-popup">
        {showServers
          ? renderField(Select, {
            ...register('apiUrlNew'),
            errorText: errors.apiUrlNew?.message || '',
            labelText: 'Server url',
            placeholder: 'Enter Server url',
            options: serversOptions,
          })
          : renderField(Input, {
            ...register('pinCode'),
            errorText: errors.pinCode?.message || '',
            labelText: 'Pin code',
            placeholder: 'Enter a pin',
          })}
        <div className="server-popup__actions">
          <Button type="submit" disabled={isSubmitting}>Apply</Button>
        </div>
      </div>
    </form>
  );
};
