import React from 'react';

export interface WithOrientationChangeState {
  orientation: string | null
  type: string | null
  angle: number | ScreenOrientation | null
  isLandscape: boolean
  isPortrait: boolean
}

export const withOrientationChange = (
  WrappedComponent: React.ComponentType<WithOrientationChangeState>
) => {
  return class extends React.Component<{}, WithOrientationChangeState> {
    constructor(props: {}) {
      super(props);

      this.onOrientationChange = this.onOrientationChange.bind(this);

      this.state = {
        orientation: null,
        type: null,
        angle: null,
        isLandscape: false,
        isPortrait: false
      };

      this.onOrientationChange(null);
    }

    onOrientationChange(event: any) {
      let orientation = 'portrait';
      let type = 'primary';
      let angle: ScreenOrientation | number = 0;

      if (window.orientation) {
        angle = window.orientation;
        orientation = Math.abs(angle) === 90 ? 'landscape' : 'portrait';
      }

      if (window.screen.orientation) {
        [orientation, type] = window.screen.orientation.type.split('-');
        angle = window.screen.orientation;
      }
      this.setState({
        orientation,
        type,
        angle,
        isPortrait: orientation === 'portrait',
        isLandscape: orientation === 'landscape'
      });
    }

    componentDidMount() {
      if (window.screen.orientation && 'onchange' in window.screen.orientation) {
        window.screen.orientation.addEventListener('change', this.onOrientationChange);
      } else if ('onorientationchange' in window) {
        window.addEventListener('orientationchange', this.onOrientationChange);
      } else {
        console.warn('No orientationchange events');
      }
    }

    componentWillUnmount() {
      if (window.screen.orientation && 'onchange' in window.screen.orientation) {
        window.screen.orientation.removeEventListener('change', this.onOrientationChange);
      } else if ('onorientationchange' in window) {
        window.removeEventListener('orientationchange', this.onOrientationChange);
      }
    }

    render() {
      const { isLandscape, isPortrait, orientation, type, angle } = this.state;

      return (
        <WrappedComponent
          {...this.props}
          isLandscape={isLandscape}
          isPortrait={isPortrait}
          orientation={orientation}
          type={type}
          angle={angle}
        />
      );
    }
  };
};
