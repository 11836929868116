import {apiRequest} from './api';
import {Language} from './languages.types';
import { ApiParamUserData } from './api.types';

// Get languages that Orion currently supports
export const getAvailableLanguagesRequest = () => apiRequest<{
  languages: Language[],
  requestLanguages: Language[]
}>('getAvailableLanguages').then((response) => {
  return {
    languages: response.languages,
    requestLanguages: response.requestLanguages,
  };
});

// Get list of languages supported by evangelist
export const fetchUserLanguages = (userData: ApiParamUserData) =>
  apiRequest<{
    languages: string[]
    primaryLanguage: string
  }>('getLanguages', userData).then((response) => {
    return {
      languages: response.languages,
      primaryLanguage: response.primaryLanguage,
    };
  });

export type RequestModifyLanguagesActions = 'add' | 'delete' | 'primary';

// Add or delete languages supported by evangelist
export const modifyUserLanguagesRequest = (
  userData: ApiParamUserData, action: RequestModifyLanguagesActions, language: string | string[]
) =>
  apiRequest('modifyLanguages', {
    id: userData.id,
    agentToken: userData.agentToken,
    action,
    language,
  });

export const fetchTranslationRequest = async (language:string) => {
  const result = await apiRequest('getLanguageTranslation', {
    language,
    platform: 'app',
  });
  
  let translationMap: any = {};
  if (result.translation) {
    result.translation.forEach((item: any) => {
      if (item.value) {
        translationMap[item.key] = item.value;
      }
    });
  }
  return translationMap;
};
  
const TranslateCacheTime = 1000 * 60 * 60 * 24;
  
export const loadTranslations = async (i18n:any, lang:string) => {
  const langCode = lang.length > 2 ? lang.slice(0, -1) : lang;
  await i18n.changeLanguage(langCode);
  try {
    let langKeys:any = {};
   
    const translation = await fetchTranslationRequest(lang);
    langKeys = { ...langKeys, ...translation };
    langKeys.lu = new Date().toJSON();
    await i18n.addResourceBundle(langCode, 'translation', langKeys, true, true);
  } catch (error) {
    console.log('trarslations error', error);
  }

  return langCode;
};
