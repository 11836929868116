import React, { useCallback, useEffect, useState } from 'react';
import {useTranslation} from 'react-i18next';

import {
  AudioPlayer,
  Badge,
  Button,
  ButtonBookmarkAttachToggle,
  ButtonStopRecord,
  FontIconView,
  Row,
  TextArea,
  Typography
} from '../../index';
import {RecordData, useRecordAudio} from '../../../hooks/useRecordAudio';
import {CHAT_MESSAGE_LIMIT} from '../../../utils/constants';
import {formatDateToUiDate} from '../../../utils/time';
import {useAudioPlayerContext} from '../../audioplayer/AudioPlayerProvider';
import {useToastEnhanced} from '../../../enhanced-components/toaster/ToasterEnhanced';
import {Template} from '../../../api/resources';
import ImageTemplateItem from '../../../pages/knowledge/ImageTemplateItem';
import VideoTemplateItem from '../../../pages/knowledge/VideoTemplateItem';
import { useModalKnowledge } from '../../../pages/knowledge/ModalKnowledge';
import Scroll from '../../scroll/Scroll';
import {getMessageTextClear, REG_EXP_MESSAGE_VOICE} from '../../../utils/chat';
import {Tooltip} from '../../tooltip/Tooltip';
import {MessageItemType} from '../../../api/chat.types';
import {ColorScheme} from '../../../types';
import {ChatInputSubmitData, ChatInputSubmitHandler} from './ChatInput.types';
import AeFontIcons from '../../../assets/Icons';
import { interpolateTextTemplate } from '../../../utils/string';
import { useGetProfile } from '../../../query/profile';
import { makeLabelFullName } from '../../../utils/common';
import { useMainContext } from '../../../MainProvider';

import './ChatInput.scss';

type ChatInputProps = {
  color: ColorScheme,
  chatId: string | number,
  recipientFullName?: string,
  replyMessage?: MessageItemType | undefined,
  isAwrHelpChat?: boolean,
  isCompact?: boolean,
  isShowMicIcon?: boolean,
  canSendAttachments: boolean,
  onCloseReplyMessage?: () => void,
  onSubmit: ChatInputSubmitHandler
}

const ChatInput: React.FC<ChatInputProps> = React.memo(({
  chatId, canSendAttachments, replyMessage, color,
  onSubmit, onCloseReplyMessage, isAwrHelpChat, isCompact, recipientFullName, isShowMicIcon
}) => {
  const { t } = useTranslation();
  const { showToast } = useToastEnhanced();
  const { profileData } = useGetProfile();
  const { isPlaying, pause } = useAudioPlayerContext();
  const {
    isRecording,
    startRecordAudio,
    stopRecordAudio,
    recordTime,
    isPreparingResult
  } = useRecordAudio();
  const { openModalKnowledge } = useModalKnowledge();
  const [text, setText] = useState('');
  const [recordData, setRecordData] = useState<RecordData | null>(null);
  const [attachments, setAttachments] = useState<Template[]>([]);
  const { isRoleAdmin, userData } = useMainContext();

  const isSendButtonRecordOne = !text.trim() && canSendAttachments && !isRoleAdmin;
  const showRecordView = isRecording || isPreparingResult;
  const showRecordResultView = !showRecordView && recordData;
  const showMessageView = !showRecordView && !showRecordResultView;

  const handleClickAddAttachments = () => {
    pause();

    openModalKnowledge(attachments, (attachments) => {
      let attachmentsNew:  Template[] = [];

      setText((textOld) => {
        let textNew = textOld;

        // Set 'text' attachments as the message field value, attach the rest to the message.
        for (let i = 0; i < attachments.length; i++) {
          if (attachments[i].type === 'text') {
            textNew += attachments[i].text;
          } else {
            attachmentsNew.push(attachments[i]);
          }
        }

        return textNew;
      });

      setAttachments(attachmentsNew);
    });
  };

  const renderTemplateBtnAction = (attachment: Template) => {
    return (
      <ButtonBookmarkAttachToggle isDetachOnly onClick={() => {
        setAttachments((oldAttachments) => {
          const attachmentId = attachment.id;
          return oldAttachments.filter((item) => {
            return item.id !== attachmentId;
          });
        });
      }}/>
    );
  };

  const handleSubmit = () => {
    if (text.trim() || recordData) {
      const sendData: ChatInputSubmitData = {
        text,
      };

      if (attachments) {
        for (let i = 0; i < attachments.length; i++) {
          sendData.text += `{${attachments[i].type}: ${attachments[i].id}} `;
        }
      }

      if (recordData) {
        sendData.text += `{voice: ${recordData.duration}} `;
        sendData.filename = recordData.filename;
        sendData.blob = recordData.blob;
      }

      if (replyMessage) {
        sendData.replyId = replyMessage.id;

        if (replyMessage.requestId) {
          sendData.requestId = replyMessage.requestId;
        }
      }

      if (isAwrHelpChat) {
        sendData.msgVetterId = userData.id;
      }

      onSubmit(sendData)
        .then(() => {
          setText('');
          setRecordData(null);
          setAttachments([]);

          if (onCloseReplyMessage) {
            onCloseReplyMessage();
          }
        });
    } else {
      showToast({ title: t('enterMessageHint') }, {
        type: 'error'
      });
    }
  };

  // Pause audio playing/recording after chat is changed.
  useEffect(() => {
    if (isPlaying && typeof pause === 'function') {
      pause();
    }

    if (isRecording && typeof stopRecordAudio === 'function') {
      stopRecordAudio().catch(() => {});
    }
    // Dep. is missing intentionally. May be need to move this logic.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId]);

  // Pause audio after recording is started.
  useEffect(() => {
    if (isRecording && isPlaying && typeof pause === 'function') {
      pause();
    }
    // Dep. is missing intentionally. May be need to move this logic.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRecording]);

  // Pause recording after audio is started.
  useEffect(() => {
    if (isPlaying && isRecording && typeof stopRecordAudio === 'function') {
      stopRecordAudio();
    }
    // Dep. is missing intentionally. May be need to move this logic.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying]);

  // e.g. replace {name} with recipient name on text change.
  useEffect(() => {
    setText(
      interpolateTextTemplate(text, profileData.profile.firstname, recipientFullName)
    );
  }, [text, profileData.profile, recipientFullName]);

  const renderSendOrMicroIcon = () => {
    let fontIcon: JSX.Element | undefined | null;
    if (isShowMicIcon) {
      fontIcon = isSendButtonRecordOne 
        ? <FontIconView icon={AeFontIcons.micro} style={{ fontSize: '1.3rem' }} /> 
        : <FontIconView icon={AeFontIcons.letter} />;
    } else {
      fontIcon = isSendButtonRecordOne ? null : <FontIconView icon={AeFontIcons.letter} />;
    }

    if (!fontIcon) {
      return null;
    }

    return (
      <Tooltip content={t(isSendButtonRecordOne ? 'recordVoice' : 'send')}>
        <Button
          style={{ flexShrink: 0 }}
          color={color}
          variant="circle"
          onClick={async () => {
            if (isSendButtonRecordOne && startRecordAudio) {
              startRecordAudio().catch(() => {
                showToast(
                  {
                    title: t('errorRecordPermissions')
                  },
                  {
                    type: 'error',
                    showDuration: 'md'
                  }
                );
              });
            } else {
              handleSubmit();
            }
          }}>
          {fontIcon}
        </Button>
      </Tooltip>
    );
  };
  
  return (
    <Row
      className='chat-input'
      layout='flex'
      horizontal='center'
    >
      {
        !!attachments.length && (
          <Row
            className='chat-input_attachments'
            layout='flex'
            directionHorizontal
            horizontal='space-between'
            vertical='center'
            border='top'
            paddingVertical='sm'
            paddingHorizontal='extra-lg'
            bgColor='bg-light'
          >
            <div className='chat-input_attachment-actions'>
              <Typography component='body2' color='text-title'>
                {t('attachments')}
              </Typography>
              <Badge color='text-title-light'>{attachments.length}</Badge>
              <Button
                size='sm'
                variant='link'
                color='blue'
                onClick={() => {
                  setAttachments([]);
                }}
              >{t('detachAll')}</Button>
            </div>
            <Scroll staticScrollView className='chat-input_attachments-scroll'>
              <Row
                className='chat-input_attachments-scroll_inner'
                layout='flex'
                directionHorizontal
              >
                {
                  attachments.map((attachment) => {
                    if (attachment?.type === 'image') {
                      return (
                        <ImageTemplateItem
                          key={attachment.id}
                          className='chat-input_attachment'
                          slotBtnAction={renderTemplateBtnAction(attachment)}
                          item={attachment}
                        />
                      );
                    } else if (attachment?.type === 'video') {
                      return (
                        <VideoTemplateItem
                          key={attachment.id}
                          className='chat-input_attachment'
                          slotBtnAction={renderTemplateBtnAction(attachment)}
                          item={attachment}
                        />
                      );
                    }

                    return null;
                  })
                }
              </Row>
            </Scroll>
          </Row>
        )
      }

      {
        replyMessage && (
          <Row
            border='top'
            directionHorizontal
            vertical='center'
            autoColumns
            horizontal='space-between'
            gap='md'
            paddingVertical='extra-sm'
            paddingHorizontal='extra-lg'
          >
            <Row directionHorizontal layout='flex'>
              <Typography
                style={{ flexShrink: 0 }}
                component='body2'
                color='blue'
              >{t('replyTo')}</Typography>&nbsp;
              <Typography
                threeDots
                component='body2'
              >
                {
                  getMessageTextClear(replyMessage.text)
                  + (REG_EXP_MESSAGE_VOICE.test(replyMessage.text) ? t('voice_message') : '')
                }
              </Typography>
            </Row>
            <Button
              variant='transparent'
              color='gray'
              iconBefore={<FontIconView icon={AeFontIcons.close} />}
              onClick={() => {
                if (onCloseReplyMessage) {
                  onCloseReplyMessage();
                }
              }}
            />
          </Row>
        )
      }

      <div style={{ minHeight: '4.2rem' }}>
        {
          !showMessageView && (
            <Row
              directionHorizontal
              gap='biggest'
              vertical='center'
              horizontal='end'
              paddingVertical='lg'
              paddingHorizontal='extra-lg'
              border='top'
              style={{
                width: '100%',
                height: '100%',
                gridTemplateColumns: recordData ? '1fr auto auto' : 'auto auto auto'
              }}
            >
              {
                recordData && (
                  <AudioPlayer
                    sliderColor='blue'
                    variant='record'
                    durationPredefined={recordData.duration}
                    style={{ width: '100%' }}
                    src={recordData.url}
                  />
                )
              }
              {
                !recordData && (
                  <ButtonStopRecord
                    onClick={async () => {
                      if (stopRecordAudio) {
                        const recordData = await stopRecordAudio();

                        if (recordData) {
                          setRecordData(recordData);
                        }
                      }
                    }}
                  />
                )
              }
              {
                !recordData && (
                  <Typography
                    color='blue'
                    component='h5'
                  >{formatDateToUiDate(recordTime, 'duration')}</Typography>
                )
              }
              <Tooltip content={t('remove')}>
                <Button
                  style={{ flexShrink: 0 }}
                  variant='transparent'
                  color='gray'
                  onClick={async () => {
                    if (isRecording && stopRecordAudio) {
                      await stopRecordAudio();
                    }

                    setRecordData(null);
                  }}
                >
                  <FontIconView icon={AeFontIcons.trash} />
                </Button>
              </Tooltip>
              {
                recordData && (
                  <Tooltip content={t('send')}>
                    <Button
                      color='blue'
                      variant='transparent'
                      style={{ flexShrink: 0 }}
                      onClick={handleSubmit}
                    ><FontIconView icon={AeFontIcons.letter} /></Button>
                  </Tooltip>
                )
              }
            </Row>
          )
        }

        {
          showMessageView && (
            <Row
              border='top'
              style={{
                width: '100%',
                height: '100%',
                gridTemplateColumns: canSendAttachments ? 'auto 1fr auto' : '1fr auto',
              }}
              directionHorizontal
              gap={isCompact ? 'md' : 'biggest'}
              vertical='center'
              paddingVertical={isCompact ? 'sm' : 'lg'}
              paddingHorizontal='extra-lg'
            >
              {
                canSendAttachments && (
                  <Tooltip content={t('attachMedia')}>
                    <Button
                      style={{ flexShrink: 0 }}
                      variant='transparent'
                      color='gray-light'
                      onClick={handleClickAddAttachments}
                    >
                      <FontIconView icon={AeFontIcons.attachment} style={{ fontSize: '1.1rem' }} />
                    </Button>
                  </Tooltip>
                )
              }
              <TextArea
                submitOnEnter
                clearButton
                placeholder={t('hintMessage')}
                value={text}
                wrapperProps={{
                  borderRadius: 'extra-lg',
                  style: {
                    width: '100%',
                    height: '100%',
                    minHeight: 0, // Fix height overflow when many text in the field.
                  }
                }}
                onChange={(e) => {
                  const newValue = e.currentTarget.value;

                  if (newValue.length > CHAT_MESSAGE_LIMIT) {
                    showToast({
                      title: t('chatMessageLimit', {
                        value: CHAT_MESSAGE_LIMIT,
                      })
                    }, {
                      type: 'error'
                    });

                    setText(newValue.slice(0, CHAT_MESSAGE_LIMIT));
                  } else {
                    setText(newValue);
                  }
                }}
                onSubmit={handleSubmit}
              />
              {renderSendOrMicroIcon()}
            </Row>
          )
        }
      </div>
    </Row>
  );
});

export default ChatInput;
