import { AWRUserId, BaseUrl } from '../utils/constants';
import { ApiRequestError, consoleLogRequestResult, token } from './api.utils';
import { ApiParamUserData } from './api.types';

export const baseRequest = <ReturnType>(
  url: string, isResponseTypeString?: boolean, requestInit?: RequestInit
): Promise<ApiRequestError & ReturnType> => {
  const timeStart = performance.now();
  try {
    return fetch(url, {
      method: 'POST',
      ...requestInit
    })
      .then(async (response) => {
        let result;

        if (response.status === 401) {
          throw new ApiRequestError(response.statusText, 'unauthorized');
        }

        if (isResponseTypeString) { // Handle text responses (e.g. upload audio).
          result = await response.text();
        } else {
          result = await response.json();
        }

        consoleLogRequestResult(
          requestInit?.body?.toString().match(/"cmd":"(\w*)"+/)?.pop() ||  url,
          (performance.now() - timeStart).toFixed(),
          requestInit?.body,
          result,
        );

        return result;
      })
      .catch((e) => {
        consoleLogRequestResult(
          requestInit?.body?.toString().match(/"cmd":"(\w*)"+/)?.pop() ||  url,
          (performance.now() - timeStart).toFixed(),
          requestInit?.body,
          e,
          true,
        );
        throw new ApiRequestError(e.message, e.status || 'error');
      });
  } catch (e: any) {
    throw new ApiRequestError(e.message, 'network_error');
  }
};

export async function apiRequest<ReturnType = Record<string, any>>(
  command: string, params?: Record<string, any>, headers?: Record<string, string>,
) {
  const isMultipart = headers?.['Content-Type'] === 'multipart/form-data';

  return baseRequest<ReturnType>((window.apiUrlNew || BaseUrl) + '/appapi.php', false, {
    headers: headers ? headers : {
      'Content-Type': 'application/json'
    },
    body: isMultipart ? params as BodyInit : JSON.stringify({
      token,
      cmd: command,
      ...params,
    }),
  })
    .then((result) => {
      if (result.status === 'error' && params?.id !== AWRUserId) {
        throw new ApiRequestError(
          'Server responded with the error status: ' +
            (result.cmdResponse || result.message || command),
          result.status
        );
      }

      return result;
    })
    .catch((e: ApiRequestError) => {
      throw new ApiRequestError(e.message || command, e.status);
    });
}

// Get motivational message for DM
export const fetchMotivationalQuote = async (
  userData: ApiParamUserData,
  language: string, // 3-digit language code
) => {
  return apiRequest<{
    quote: string,
    reference: string,
  }>('getMotivationalQuote', {
    id: userData.id,
    agentToken: userData.agentToken,
    language
  });
};

export const fetchRefreshAgentToken = async (data: ApiParamUserData & {
  username: string,
}) => apiRequest<{
  token: string,
}>('refreshAgentToken', data);
