import React from 'react';
import { useTranslation } from 'react-i18next';
import ScrollBar from 'react-custom-scrollbars-2';

import { ButtonClose, Typography } from '../index';
import { Achievement } from './types';
import { AchievementItem } from './AchievementItem';
import Row from '../row/Row';
import { useSidePanelContext } from '../side-panel/SidePanelProvider';

import './achievement.scss';

export const MyAchievements: React.FC<{ achievements: Achievement[] | [] }> = ({
  achievements = []
}) => {
  const { t } = useTranslation();
  const { closePanel } = useSidePanelContext();


  return (
    <Row gap="extra-lg" className="side-panel__inner-wrap">
      <Typography component='h3' variant='poppins' threeDots>{t('yourAchievements')}</Typography>
      <ScrollBar autoHide>
        <div className='achievements-grid'>
          {achievements.map((item) => <AchievementItem key={item.keyName} item={item} />)}
        </div>
      </ScrollBar>
      <Row layout='flex' directionHorizontal horizontal='end'>
        <ButtonClose onClick={closePanel} />
      </Row>
    </Row>
  );
};
