import React from 'react';
import { Paper } from '../components';

import './Page404.scss';

const Page404 = () => {
  return (
    <Paper className="page-404">
      <h2>Page 404</h2>
      Page not found or in develop
    </Paper>
  );
};

export default Page404;
