import { apiRequest, baseRequest } from './api';
import { ApiParamUserData } from './api.types';

export type Settings = {
  audioUploadURL: string;
  awrDMAppShareAndroid: string;
  awrDMAppShareIphone: string;
  awrFacebook: string;
  awrTwitter: string;
  awrYouTube: string;
  contactEmail: string;
  maxChatAudioLen: number;
  maxChatTextLen: number;
  notificationTimeout: number;
  trainingVideos: string;
};

type SettingsApi = {
  [key: string]: string | number;
}

export const getSettingsRequest = async (userData: ApiParamUserData) => {
  return await apiRequest('getSettings', {
    evangelistId: userData.id,
    agentToken: userData.agentToken
  }).then((response) => {
    return response.settings.reduce((acc: SettingsApi, obj: SettingsApi) => {
      const key = Object.keys(obj)[0];
      acc[key] = obj[key];

      return acc;
    }, {} as Settings);
  });
};

export const getAppVersionRequest = () => {
  return baseRequest<{
    version: string,
  }>(window.location.origin + '/manifest.json', false, {
    method: 'get',
  }).then((response) => response.version);
};
