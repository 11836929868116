import React, { useEffect, useState } from 'react';

import { Row, Typography } from '../components';
import { VERSION_DIFFERS_TIME_RELOAD } from '../utils/constants';

const VersionDiffersLayout = React.memo(() => {
  const [secondsRemaining, setSecondsRemaining] = useState(VERSION_DIFFERS_TIME_RELOAD);

  useEffect(() => {
    const timeout = setTimeout(() => {
      window.location.reload();
    }, VERSION_DIFFERS_TIME_RELOAD * 1000);

    const interval = setInterval(() => {
      setSecondsRemaining((secondsRemainingOld) => {
        return Math.max(0, secondsRemainingOld - 1);
      });
    }, 1000);

    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, []);

  return (
    <Row style={{ height: '100%' }} vertical='center' horizontal='center'>
      <div>
        <Typography component='h2' align='center'>
          The versions of your application and the server application are different!
        </Typography>
        <Typography component='h3' align='center'>
          Application will be reloaded in {secondsRemaining} seconds...
        </Typography>
      </div>
    </Row>
  );
});

export default VersionDiffersLayout;
