import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import Row from '../row/Row';
import { Typography } from '../index';
import { ListCampaignsItem, ListCampaignsItemConcise } from '../../api/campaigns.types';
import { Tooltip } from '../tooltip/Tooltip';

import './ListCampaignItem.scss';

export type CommonCampaignItem = ListCampaignsItemConcise | ListCampaignsItem;

export type ListCampaignItemProps = {
  listCampaignsItem: CommonCampaignItem,
  onClickTitle?: (item: CommonCampaignItem) => void;
  slotActions?: (item: CommonCampaignItem) => ReactNode;
};

const ListCampaignItem: React.FC<ListCampaignItemProps> = (props) => {
  const { t } = useTranslation();

  const isRunning = props.listCampaignsItem.status === 'running';

  const title = isRunning
    ? <b>{props.listCampaignsItem.name}</b>
    : props.listCampaignsItem.name;

  return (
    <li className={classnames('list-campaign-item', {
      'hover': props.onClickTitle,
    })}>
      <Row vertical='center' directionHorizontal layout='flex'>
        <Typography
          component='h3'
          tagName='span'
          color={isRunning ? 'blue' : 'gray-light'}
        >&bull;</Typography>
        &nbsp;&nbsp;
        <div onClick={() => {
          if (props.onClickTitle) {
            props.onClickTitle(props.listCampaignsItem);
          }
        }}>
          <Tooltip content={t('info')}>
            <Typography
              threeDots
              component='body3'
              color={isRunning ? 'text-title' : 'text-title-light'}
              className='list-campaign-item_title'
            >{title}</Typography>
          </Tooltip>
        </div>
        <Typography component='body3'>
          &nbsp;-&nbsp;{t(props.listCampaignsItem.status)}
        </Typography>
      </Row>

      {props.slotActions && props.slotActions(props.listCampaignsItem)}
    </li>
  );
};

export default ListCampaignItem;
