import { apiRequest } from './api';
import { MessageStatus, SendMessageFromEvangelistMsgData } from './chat.types';
import { normalizeSendMessageFromEvangelistMsgData } from './chat.utils';
import { normalizeBroadcastItem, normalizeBroadcastMessageItem } from './broadcasts.utils';
import {
  BroadcastFromApiType,
  GetBroadcastMessagesRequestMessage
} from './broadcasts.types';
import { ApiParamUserData } from './api.types';

export const getBroadcastListsRequest = (userData: ApiParamUserData) =>
  apiRequest<{
    lists: BroadcastFromApiType[]
  }>('getBroadcastLists', {
    evangelistId: userData.id,
    agentToken: userData.agentToken,
  }).then((response) => response.lists.map(normalizeBroadcastItem));

export type ModifyBroadcastListAction = {
  action: 'create' | 'delete' | 'rename' | 'addSeeker' | 'removeSeeker',
  // Example values:
  // "actions":[{"action":"create", "values":"testx"}
  // {"action":"addSeeker", "values":["67", "68"]}]
  values?: string | string[],
};

// create/manage broadcast list
export const modifyBroadcastList = (
  userData: ApiParamUserData, params: {
    actions: ModifyBroadcastListAction[], broadcastId?: string
  }
) =>
  apiRequest<{
    broadcastId?: string, // new ID for action = “create” only
  }>('modifyBroadcastList', {
    evangelistId: userData.id,
    agentToken: userData.agentToken,
    ...params
  });


// Get history of broadcast messages for broadcast list
export const getBroadcastMessagesRequest = (userData: ApiParamUserData, params: {
  broadcastId?: string, // ID of broadcast list
  categories?: string[] // list of message categories to return
}) =>
  apiRequest<{ messages: GetBroadcastMessagesRequestMessage[] }>('getBroadcastMessages', {
    evangelistId: userData.id,
    agentToken: userData.agentToken,
    ...params,
  }).then((response) => response.messages.map(normalizeBroadcastMessageItem));

export type MessageToBroadcastType = {
  categories?: string[], // list of seeker categories to target this message
  // Besides 0, can contain one or more categoryIds from getRequestCategories
  text: string, // text of message to broadcast (optional if audio provided)
  audio?: string, // filename of audio to send (optional if text provided)
};

// Send a message to a broadcast list
export const sendBroadcastMessage = (
  userData: ApiParamUserData, broadcastId: string, message: MessageToBroadcastType
) =>
  apiRequest<{
    msgData: SendMessageFromEvangelistMsgData
    msgId: number
    msgStatus: MessageStatus
    sendTime: string
  }>('sendBroadcastMessage', {
    evangelistId: userData.id,
    agentToken: userData.agentToken,
    broadcastId,
    ...message
  }).then((response) => {
    return {
      ...response,
      msgData: normalizeSendMessageFromEvangelistMsgData(response.msgData, userData.id),
    };
  });
