import React, { ForwardRefExoticComponent } from 'react';

import Field, { FieldProps } from '../form/field/Field';

export const renderField = <T extends React.FC<any> | ForwardRefExoticComponent<any>>(
  Component: T,
  props: Omit<FieldProps, 'children'> &
    // infer Props from either regular functional component or ref forwarded functional component.
    (T extends React.FC<infer TProps>
      ? TProps
      : (T extends ForwardRefExoticComponent<infer TProps> ? TProps : never)
    ),
) => {
  const {
    ref,
    style,
    size = 'sm',
    name,
    errorText,
    showMarkRequired,
    labelText,
    hasErrorGap = true,
    disabled,
    tagName,
    defaultValue,
    ...restProps
  } = props;

  const componentProps = {
    ...restProps,
    disabled,
    defaultValue,
    size,
    name,
    style,
    invalid: !!errorText,
  };

  return Field({
    size,
    style,
    errorText,
    disabled,
    tagName,
    showMarkRequired,
    labelText,
    hasErrorGap,
    children: 'render' in Component
      // @ts-ignore
      ? Component.render(componentProps, ref)
      : Component(componentProps),
  });
};
