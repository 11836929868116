import {MESSAGE_LIST_MESSAGES_ELEMENTS_WRAPPER_ID} from './MessageList.constants';

export const getMessageListElements = () => {
  const messagesWrapperElement = document.getElementById(MESSAGE_LIST_MESSAGES_ELEMENTS_WRAPPER_ID);

  return {
    scrollView: messagesWrapperElement?.parentElement,
    messagesElements: messagesWrapperElement?.children || null,
  };
};
