import React, { ReactNode } from 'react';
import classNames from 'classnames';

import Scroll from '../../components/scroll/Scroll';
import { Template } from '../../api/resources';
import { TemplateItemProps } from './Templates.types';
import { Row } from '../../components';

type TemplateSliderSize = 'sm' | 'md' | 'lg';

const TemplateSliderSizeToHeightMap: {[key: string]: number} = {
  sm: 100,
  md: 136,
  lg: 187,
};

const TemplateItemSizeToHeightMap: {[key: string]: number} = {
  sm: 100,
  md: 136,
  lg: 167,
};

interface TemplateSliderProps {
  items: Template[];
  size?: TemplateSliderSize;
  sliderView?: boolean;
  templateItemComponent: React.FC<TemplateItemProps>;
  renderTitle?: () => void;
  slotItemsBefore?: ReactNode;
  renderSlotBtnAction: (item: TemplateItemProps['item']) => ReactNode;
}

const TemplateSlider = ({
  items,
  size,
  templateItemComponent: TemplateItem,
  renderTitle,
  sliderView,
  slotItemsBefore,
  renderSlotBtnAction,
}: TemplateSliderProps) => {
  return (
    <Row layout='flex'>
      {renderTitle && renderTitle()}
      <div className="template-slider-container">
        <Scroll
          horizontal={sliderView}
          style={{ height: size ? TemplateSliderSizeToHeightMap[size] : '100%' }}
        >
          <div className={classNames('scroll-content', { 'slider-view': sliderView })}>
            {slotItemsBefore}

            {items.map((item) => {
              return (
                <TemplateItem
                  key={item.id}
                  style={{ height: size ? TemplateItemSizeToHeightMap[size] : 'auto' }}
                  clamp={6}
                  item={item}
                  slotBtnAction={renderSlotBtnAction(item)}
                />
              );
            })}
          </div>
        </Scroll>
      </div>
    </Row>
  );
};

export default TemplateSlider;
