import { EnumLiteralsOf } from '../types';

export type MessageStatus = 'ignored' | 'viewed' | 'sent' | 'queue'
  | 'remove' | 'sending' | 'error' | 'errorSend' | 'received' | 'undelivered';

export const MessageReviewStatuses = {
  APPROVED: 'approved',
  VERIFYING: 'verifying',
  DENIED: 'denied',
  IGNORED: 'ignored',
} as const;

export type MessageReviewStatus = EnumLiteralsOf<typeof MessageReviewStatuses>;

export const SourceDestTypes = {
  EVANGELIST: 'e',
  SEEKER: 's',
  CAMPAIGN: 'c',
  TRANSFER: 'o',
  LEADER: 'l',
  AWR: 'a',
} as const;

export type SourceDestType = EnumLiteralsOf<typeof SourceDestTypes>

export type MsgType =
  'text' // text or text + attachments
  | 'notification'
  | 'broadcast'
  | 'reminder'
  | 'both'; // audio or audio + attachments

export const DestTypes: { [field: string]: SourceDestType } = {
  EVANGELIST: 'e',
  SEEKER: 's',
  CAMPAIGN: 'c',
};

export type MessageHistoryItemApi = {
  audio: string | null
  date: string
  destId: string
  destType: SourceDestType
  direction: 'to' | 'from'
  id: string
  msgNotes: string | null
  msgScore: string | null
  msgStatus: MessageReviewStatus
  msgVetterId: string | null
  msgType: MsgType
  replyId: string | null
  quoteId: string | null
  requestId: string | null
  rowIndex: number
  sendDate: string
  sendId: string
  sent: string | null
  sourceId: string
  sourceType: SourceDestType
  status: MessageStatus
  text: string
  audioSupported?: boolean
};
export type MessageItemType = MessageHistoryItemApi & {
  textClearUrlified: string;
};

export type GetMessageUpdatesRequestMessage = {
  audio: string | null
  date: string
  destId: string
  destType: SourceDestType
  id: string
  msgNotes: string | null
  msgScore: string | null
  msgStatus: MessageReviewStatus
  msgType: MsgType
  replyId: string | null
  quoteId: string | null
  requestId: string | null
  rowIndex: number
  sent: string | null
  sourceId: string
  sourceType: SourceDestType
  status: MessageStatus
  text: string
  messageId: string
  morningStarId: null
  msgVetterId: null
  received: null
  sourceSubType: null
  statusChange: null
}

export type SendMessageFromEvangelistMsgData = {
  messageId: '';
  msgVetterId: null;
  sourceSubType: null;
  statusChange: null;
  audio: string | null;
  date: string;
  destId: string;
  destType: SourceDestType;
  id: string;
  msgNotes: string | null;
  msgScore: string | null;
  msgStatus: MessageReviewStatus;
  msgType: MsgType;
  replyId: string | null;
  quoteId: string | null;
  requestId: string | null;
  rowIndex: number;
  sent: string | null;
  sourceId: string;
  sourceType: SourceDestType;
  status: MessageStatus;
  text: string;
};

export const ChatListChatTypes = {
  SEEKERS: '1',
  DMS_FOR_LEADER: '2',
  LEADER_CAMPAIGN_OR_GROUP_CHATS: '4',
  LEADER_CM_FOR_DM: '24',
} as const;

// Bit mask of desired chat types
export type ChatListChatType = EnumLiteralsOf<typeof ChatListChatTypes>;

export type MessageFromEvangelistDestType = typeof SourceDestTypes.AWR
  | typeof SourceDestTypes.EVANGELIST
  | typeof SourceDestTypes.SEEKER
  | typeof  SourceDestTypes.CAMPAIGN;

export type MessageFromEvangelistData = {
  destType: MessageFromEvangelistDestType,
  sourceSubType?: typeof SourceDestTypes.EVANGELIST | typeof SourceDestTypes.LEADER,
  categoryId?: number, // integer ID of type of message (1=prayer,2=question,3=study,4=chat)
  text: string,
  audio?: any,
  replyId?: string,
  quoteId?: string,
  requestId?: string,
}

export type SetMessageViewedRequestStatus = 'viewed' | 'received';

export type ChatItemLeaderCMTypeApi = {
  id: number
  firstname: string
  lastname: string
  lastMessage: {
    text: string
    date: string
    sourceType: SourceDestType
  } | null
  msgCounts: {
    incoming: string
    outgoing: string
    unread: string
    ungraded: null
  }
};

export type ChatItemLeaderCMType = {
  id: number
  firstname: string
  lastname: string
  text: string
  date: string
  sourceType: SourceDestType | null
  incoming: number
  outgoing: number
  unread: number
  ungraded: number
};
