import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, RouteComponentProps } from 'react-router-dom';

import { Button, Divider, Typography } from '../../../components';
import FormLogin  from './FormLogin';

import { useMainContext } from '../../../MainProvider';
import { useModalContext } from '../../../components/modal/ModalProvider';
import {
  registerAccountTypeCM,
  AuthAccountsTypes,
  registerAccountTypeDM,
  URLS,
} from '../../../utils/constants';
import Row from '../../../components/row/Row';
import AuthContainer from '../AuthContainer';
import { ChangeServerPopup } from '../../../components/change-server/ChangeServerPopup';
import useLongPress from '../../../hooks/useLongPress';

type Props = RouteComponentProps<{ type: AuthAccountsTypes }>;

const PageLogin: React.FC<Props> = ({ history }) => {
  const { t } = useTranslation();
  const { logout } = useMainContext();
  const { openModal, closeModal } = useModalContext();

  const onLongPress = () => openModalChangeServer();

  const longPressEvent = useLongPress(onLongPress, () => {}, {
    shouldPreventDefault: true,
    delay: 500,
  });

  // Prevent go back to the pages of "Login" layout, which demands the user to be authorized.
  useEffect(() => {
    // @ts-ignore
    const unblock = history.block((location, action) => {
      const sLocation = location.pathname;

      const isGoBack = action === 'POP';
      const isCanGoBack =
        sLocation.includes(URLS.registerStepVerification)
        && sLocation.includes(URLS.registerStepSetup);

      return isGoBack ? isCanGoBack : true;
    });

    return () => {
      unblock();
    };
  }, [history]);

  useEffect(() => {
    // May be bad logic. Prevent user has userData on login page.
    // e.g.:
    // -> Login your account
    // -> Be redirected to register page in case profile is not filled or email is not verified
    // -> Go 'Back'
    // -> Go 'Login'
    logout();
  }, [logout]);

  const handleClickBecomeCM = () => {
    openModal((
      <Typography component='body3' style={{maxWidth: 340}}>
        {t('becomeManagerWarning')}
      </Typography>
    ), {
      title: t('areYouSure') + '?',
      widthSize: 'sm',
      cancelButton: true,
      okButton: true,
      okButtonProps: {
        children: t('confirm'),
        onClick: () => {
          closeModal();
          history.push(URLS.register + '?type=' + registerAccountTypeCM);
        }
      }
    });
  };

  const openModalChangeServer = () => {
    openModal(<ChangeServerPopup />, {
      widthSize: 'sm',
      title: 'Enter PIN code'
    });
  };

  // Fix can't long tap in BS, Ipad, IOS 12.
  useEffect(() => {
    // @ts-ignore
    window.callDevPopup = openModalChangeServer;
  }, []);

  return (
    <AuthContainer>
      <Row gap='extra-lg'>
        {/*// @ts-ignore*/}
        <div onContextMenu={(event)=> {
          event.preventDefault();
          openModalChangeServer();
        }} {...longPressEvent}>
          <Typography component='h2' variant='poppins'>
            {t('signIn')}
          </Typography>
        </div>

        <div>
          <span>{t('haveNoAccount')}</span>&nbsp;
          <Link
            to={URLS.register + '?type=' + registerAccountTypeDM}
          >{t('signUpAs')} {t('missionary')}</Link>
        </div>

        <FormLogin />

        <Divider>{t('or')}</Divider>

        <Button
          style={{ width: '100%' }}
          type="button"
          variant='outline'
          color='blue'
          onClick={handleClickBecomeCM}
        >{t('become')} {t('campaignManager')}</Button>
      </Row>
    </AuthContainer>
  );
};

export default PageLogin;
