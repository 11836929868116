import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';

import {useToastEnhanced} from '../enhanced-components/toaster/ToasterEnhanced';

export const useCopy = () => {
  const { t } = useTranslation();
  const { showToast } = useToastEnhanced();

  const copy = useCallback(async (text, successTitle = t('textCopied')) => {
    await navigator.clipboard.writeText(text);
    showToast({
      title: successTitle,
    });
  }, [t, showToast]);

  return {
    copy,
  };
};
