import i18n from 'i18next'; 
import HttpApi from 'i18next-http-backend';
import { makePublicUrl } from './url';
import {fallbackLanguage} from './constants';

i18n
  .use(HttpApi)
  // .use(LanguageDetector)
  .init({
    // lng:"en",
    lng: localStorage.getItem('userLang') || 'en',
    fallbackLng: fallbackLanguage.languageCode,
    debug: false, 

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ','
    },
    detection: {
      order: ['navigator', 'cookie', 'path', 'localStorage', 'subdomain', 'queryString', 'htmlTag'],
      lookupFromPathIndex: 0,
      caches: ['cookie'],
      cookieMinutes: 10000000,
      lookupQuerystring: 'i18next'
    },
    backend: {
      loadPath: makePublicUrl('/locales/{{lng}}/common.json')
    },
    react: {
      wait: true,
      useSuspense: false
    }
  });

export default i18n;
