import React, { ReactNode, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import {useTranslation} from 'react-i18next';

import {
  InputSearch,
  Loader,
  NoDataTemplate,
} from '../index';
import { InputProps } from '../input/Input';
import Scroll, {ScrollProps} from '../scroll/Scroll';

import './SelectList.scss';
import {
  makeStatelessFormValueUpdateEvent,
  parseSelectValueToPrimitive,
  parseSelectValueToSelectOption
} from './Select.utils';
import { StatelessFormValueUpdateEvent, SelectOption, SelectValue } from './Select.types';

export type SelectListRenderItemFn = (
  option: SelectOption,
  isActive: boolean,
  handleChange: (e: SyntheticEvent<HTMLLIElement, MouseEvent>) => void,
) => ReactNode;

export type SelectListProps = {
  withFilter?: boolean,
  isLoading?: boolean,
  canUnselect?: boolean,
  
  filter?: string,
  name?: string,
  onChangeFilter?: InputProps['onChange'],

  value?: SelectValue,
  defaultValue?: SelectValue,
  onChange: (e: StatelessFormValueUpdateEvent, value: SelectOption) => void,
  onBlur?: (e: any) => void,
  onFocus?: (e: any) => void,

  options: SelectOption[],

  noDataTitle?: string;
  noDataMessage?: string;

  searchInputProps?: Partial<InputProps>,
  scrollbarsProps?: ScrollProps,
  renderItem: SelectListRenderItemFn;
};

const SelectList: React.FC<SelectListProps> = ({
  value: valueExternal, defaultValue, withFilter, searchInputProps, options, scrollbarsProps,
  filter, onChange, onChangeFilter, name, canUnselect,
  isLoading, noDataTitle, noDataMessage, renderItem,
}) => {
  const { t } = useTranslation();
  const listRef = useRef<any | null>(null);
  const [valueLocal, setValueLocal] = useState<SelectValue>(defaultValue || '');

  const optionsFiltered = filter
    ? options.filter(({ label }) => label.toLowerCase().includes(filter.toLowerCase()))
    : options;

  const valueKey = parseSelectValueToPrimitive(valueExternal ?? valueLocal);

  const handleChange = useCallback((
    e: { preventDefault: () => void, }, newValue: SelectOption
  ) => {
    setValueLocal(newValue);
    
    onChange(makeStatelessFormValueUpdateEvent(name, newValue.key, e.preventDefault), newValue);
  }, [name, onChange]);

  // Scroll to current option list item.
  useEffect(() => {
    if (isLoading) {
      return;
    }

    const elementListContainer = listRef?.current.container;

    if (!elementListContainer) {
      return;
    }

    const elementOptionCurrent = elementListContainer.querySelector('[data-id="' + valueKey + '"]');

    if (valueKey && !elementOptionCurrent) {
      // eslint-disable-next-line no-console
      console.log(
        'Initial scroll select list failed! Possibly set "data-id={option.key}" to each list item',
        'background: black; color: white;'
      );
    }

    if (elementOptionCurrent) {
      listRef?.current.scrollTop(elementOptionCurrent.offsetTop);
    }
    // Dependencies are missing intentionally. May be bad logic.
  }, [isLoading, options]); // eslint-disable-line

  // Dispatch {onChange} on change {defaultValue}.
  useEffect(() => {
    if (defaultValue) {
      handleChange({ preventDefault() {} }, parseSelectValueToSelectOption(defaultValue));
    }
  }, [handleChange, defaultValue]);

  return (
    <div className='select-list'>
      {withFilter && (
        <InputSearch
          wrapperProps={{
            className: 'select-list_input',
          }}
          value={filter}
          onChange={onChangeFilter}
          {...searchInputProps}
        />
      )}
      <Scroll flexColumn {...scrollbarsProps} ref={listRef}>
        {
          isLoading ? <Loader /> : (
            <ul>
              {
                optionsFiltered.length
                  ? optionsFiltered.map((option) => {
                    return renderItem(option, option.key === valueKey, (e) => {
                      handleChange(e, (canUnselect && valueKey === option.key)
                        ? parseSelectValueToSelectOption('') : option);
                    });
                  }) : (
                    <NoDataTemplate
                      title={filter ? t('noResultsFound') : noDataTitle}
                      message={filter ? t('noContentMatchCriteria') : noDataMessage}
                    />
                  )
              }
            </ul>
          )
        }
      </Scroll>
    </div>
  );
};

export default SelectList;
