import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, FormField, Input, Select } from '../../../components';
import Form, { FormSubmitHandler } from '../../../components/form/Form';
import { isRequired } from '../../../components/form/Form.utils';
import { FieldFirstName, FieldLastName, FieldPhoneNumber } from '../../../components/form/Fields';
import Row from '../../../components/row/Row';
import { Country } from '../../../api/account';
import { useGetCountries } from '../../../query/locations';
import { SelectOption } from '../../../components/select/Select.types';

type FormProfileFields = {
  firstName: string,
  lastName: string,
  homeChurchName?: string,
  country: SelectOption,
  phoneNumber: string,
  city: string,
  postalCode: number | undefined,
}

type FormProfileProps = {
  showHomeChurchNameField: boolean,
  initialValues: FormProfileFields,
  onSubmit: FormSubmitHandler<FormProfileFields>,
  onBack: () => void,
}

const FormProfile: React.FC<FormProfileProps> = ({
  showHomeChurchNameField, initialValues, onBack, onSubmit
}) => {
  const { t } = useTranslation();

  const { countries = [], isCountriesLoading } = useGetCountries();

  const [country, setCountry] = useState<Country>({
    country: countries?.find(
      (i) => i.abbreviation === initialValues.country.key
    )?.country || '',
    code: null,
    abbreviation: '',
  });

  const countriesOptions = useMemo(() => {
    return countries.map(({ country, abbreviation }) => {
      return {
        key: abbreviation,
        label: country,
      };
    });
  }, [countries]);
/*
  useEffect(() => {
    // Fix city select is not fetched if country is represented in the profile info,
    // because cities request needs 'country' (name), but not country's code that is in the profile.
    if (initialValues.country && countries && !country.code) {
      const suitableCountry = countries.find(
        (i) => i.abbreviation === initialValues.country.key
      );

      if (suitableCountry) {
        setCountry(suitableCountry);
      }
    }
    // Dependencies are missing intentionally. May be bad logic.
  }, [countries]); // eslint-disable-line
*/
  return (
    <Form
      style={{ height: '100%' }}
      fields={initialValues}
      onSubmit={onSubmit}
    >
      {
        ({ isSubmitting }) => (
          <Row gap='md'>
            <Row gap='lg' directionHorizontal>
              <FieldFirstName
                labelText={t('firstName')}
              />
              <FieldLastName
                labelText={t('lastName')}
              />
            </Row>

            {
              showHomeChurchNameField && (
                <FormField
                  component={Input}
                  componentProps={{
                    placeholder: t('enterHomeChurchName'),
                    type: 'text',
                    autoComplete: 'off',
                  }}
                  showMarkRequired
                  validators={[isRequired]}
                  name='homeChurchName'
                  labelText={t('homeChurchName')}
                />
              )
            }

            <Row gap='lg' directionHorizontal>
              <FormField
                componentProps={{
                  disabled: isCountriesLoading,
                  placeholder: t('enterCountry'),
                  options: countriesOptions,
                  popupMinWidth: 'lg',
                  onChange: (e: any, { key }: any) => {
                    const newCountry = countries?.find(({abbreviation}) => abbreviation === key);

                    if (newCountry) {
                      setCountry(newCountry);
                    }
                  },
                }}
                component={Select}
                validators={[isRequired]}
                name='country'
                showMarkRequired
                labelText={t('country')}
              />
              <FieldPhoneNumber
                code={country.code || ''}
                componentProps={{
                  disabled: !country.code,
                }}
                labelText={t('phoneNumber')}
              />
            </Row>

            <Row gap='lg' directionHorizontal>
              <FormField
                component={Input}
                componentProps={{
                  type: 'text',
                  autoComplete: 'off',
                  placeholder: t('enterCity'),
                }}
                showMarkRequired
                name='city'
                labelText={t('city')}
              />
              <FormField
                component={Input}
                componentProps={{
                  placeholder: t('enterPostalCode'),
                  type: 'text',
                  autoComplete: 'off',
                }}
                name='postalCode'
                labelText={t('postalCode')}
              />
            </Row>

            <Row gap='lg' directionHorizontal>
              <Button
                color='gray'
                variant='opacity'
                type='button'
                onClick={onBack}
              >{t('back')}</Button>
              <Button
                disabled={isSubmitting}
                type='submit'
              >{t('continue')}</Button>
            </Row>
          </Row>
        )
      }
    </Form>
  );
};

export default FormProfile;
