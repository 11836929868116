import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import {
  Button,
  ButtonBookmark,
  ButtonBookmarkAttachToggle,
  FontIconView,
  Loader,
  Row,
  Typography,
  NoDataTemplate
} from '../../components';
import TemplateSlider from './TemplateSlider';
import TextTemplateItem from './TextTemplateItem';
import VideoTemplateItem  from './VideoTemplateItem';
import ImageTemplateItem  from './ImageTemplateItem';
import { AttachmentType, Resource, Template } from '../../api/resources';
import Scroll from '../../components/scroll/Scroll';
import { useUrlQuery } from '../../hooks/useUrlQuery';
import {Tooltip} from '../../components/tooltip/Tooltip';
import { useModalModifyTextTemplate } from './ModalModifyTextTemplate';
import AeFontIcons from '../../assets/Icons';
import { useModalShare } from '../../components/modal/ModalShare';
import { isDev } from '../../utils/constants';
import { interpolateTextTemplate } from '../../utils/string';
import { useGetProfile } from '../../query/profile';
import { makeLabelFullName } from '../../utils/common';

import './templates.scss';
import { findNode } from '../../utils/array';

interface TemplatesProps {
  attached?: Template[];
  tree: Resource[];
  content: Template[];
  isLoading: boolean;
  knowledgeId: string;
  onClickAttachItems?: () => void;
  handleClickClearAttached?: () => void;
  handleClickToggleAttach?: (item: Template) => void;
}

export const  Templates: React.FC<TemplatesProps> = ({
  tree,
  content,
  attached,
  isLoading,
  knowledgeId,
  onClickAttachItems,
  handleClickToggleAttach,
  handleClickClearAttached,
}) => {
  const { t } = useTranslation();
  const query = useUrlQuery();
  const { profileData } = useGetProfile();
  const { openModalTextTemplate } = useModalModifyTextTemplate();
  const [shownType, setShownType] = useState<AttachmentType | undefined>();
  const { openModalShare } = useModalShare();
  const search = query.get('search');

  const isAttachMode = !!handleClickToggleAttach;

  const itemIdsWithSearchResults: string[] | null = useMemo(() => {
    if (search) {
      if (tree && knowledgeId) {
        const currentNode = findNode(tree, knowledgeId);
        if (currentNode?.children) {
          return currentNode.children.filter((c) => {
            // This is right condition!
            // Because it's expected for now, that server returns {searchWords: "" (empty string)}
            // It's server issue, but for now this crunch is expected!
            return 'searchWords' in c;
            // ==============================;
          }).map((c) => c.id);
        }
      }
    }

    return null;
  }, [tree, search, knowledgeId]);

  const [
    { textList, imageList, videoList },
    isAvailableLessThanTwoLists
  ] = useMemo(() => {
    const lists = content.reduce((acc, item) => {
      if (item.type === 'audio') {
        acc.audioList.push(item);
      } else {
        const searchFilterOK = !itemIdsWithSearchResults || itemIdsWithSearchResults.includes(item.id);

        if (searchFilterOK) {
          if (item.type === 'text') {
            if (item?.text.length > 0) {
              acc.textList.push(item);
            }
          }
          if (item.type === 'image') {
            acc.imageList.push(item);
          }
          if (item.type === 'video') {
            acc.videoList.push(item);
          }
        }
      }

      return acc;
    }, {
      textList: [],
      imageList: [],
      videoList: [],
      audioList: []
    } as { [key: string]: Template[]});

    const listsValues = Object.values(lists);
    let isAvailableLessThanTwoLists = true;

    for (let i = 0, j = 0; i < listsValues.length; i++) {
      if (listsValues[i].length) {
        j++;
      }

      if (j > 1) {
        isAvailableLessThanTwoLists = false;
      }
    }

    return [lists, isAvailableLessThanTwoLists];
  }, [content, itemIdsWithSearchResults]);

  const handleToggleMore = (type: AttachmentType | undefined) => {
    setShownType(shownType === type ? undefined : type);
  };

  const renderAddButton = (size: 'sm' | 'lg') => {
    return (
      <Button
        variant='opacity'
        size={size}
        className="button-add"
        onClick={() => {
          openModalTextTemplate(knowledgeId);
        }}
      >
        <div className="plus-icon"><FontIconView icon={AeFontIcons.close} /></div>
      </Button>
    );
  };

  const renderTitle = (key: AttachmentType, title: string, hasAddButton: boolean) => {
    return (
      <Row
        gap='lg'
        directionHorizontal
        horizontal="space-between"
        vertical="center"
      >
        <Typography component="h4" variant="poppins">{t(title)}</Typography>

        <Row directionHorizontal autoColumns vertical="center" horizontal='end' gap='lg'>
          {
            !isAvailableLessThanTwoLists && (
              <Button
                className="more-less-btn"
                variant='link'
                color='blue'
                iconAfter={(
                  <FontIconView
                    icon={AeFontIcons.arrowRight}
                    className={classNames({ 'is-less': shownType === key })}
                  />
                )}
                onClick={() => handleToggleMore(key)}
              >
                {t(shownType === key ? 'less' : 'more')}
              </Button>
            )
          }

          {(isAvailableLessThanTwoLists || shownType) && hasAddButton && renderAddButton('sm')}
        </Row>
      </Row>
    );
  };

  const renderTemplateSlotBtnAction = useCallback((item: Template) => {
    const isAttached = !!attached?.includes(item);

    if (isAttachMode && handleClickToggleAttach) {
      return (
        <ButtonBookmarkAttachToggle
          isAttached={isAttached}
          onClick={() => {
            handleClickToggleAttach(item);
          }}
        />
      );
    }

    return (
      <ButtonBookmark
        onClick={() => {
          const isItemTextLink = item.type !== 'text';

          // Item link or current location url.
          const url = isItemTextLink ? item.text : (
            isDev
              ? 'https://awr-evangelism.web.app' + window.location.pathname + window.location.search
              : window.location.toString()
          );

          const text = isItemTextLink ? item.title : (item.text || item.title);
          openModalShare(
            url,
            interpolateTextTemplate(text, profileData.profile.firstname),
            true
          );
        }}
      >
        <Row directionHorizontal layout='flex' vertical='center'>
          <Typography component='body4' color='white' threeDots>{t('share')}</Typography>&nbsp;
          <FontIconView style={{ fontSize: '0.9rem' }} icon={AeFontIcons.share2} />
        </Row>
      </ButtonBookmark>
    );
  }, [profileData.profile, t, attached, isAttachMode, handleClickToggleAttach]);

  // Reset shown type on folder change.
  useEffect(() => {
    setShownType(undefined);
  }, [knowledgeId]);

  const isSliderView = !isAvailableLessThanTwoLists && !shownType;

  return (
    <Row paddingVertical='extra-lg'>
      {isLoading ? (
        <Loader />
      ) : (
        <Row layout='flex' vertical="space-between" gap='extra-lg'>
          <Scroll>
            <Row gap='sm' paddingHorizontal='extra-lg' style={{ height: '100%' }}>
              {
                // Note: Need to simplify these conditions.
                ((!shownType || shownType === 'text') && !!textList.length) && (
                  <TemplateSlider
                    renderTitle={() => renderTitle('text', 'textTemplates', true)}
                    sliderView={isSliderView}
                    items={textList}
                    templateItemComponent={TextTemplateItem}
                    size={isSliderView ? 'lg' : undefined}
                    renderSlotBtnAction={renderTemplateSlotBtnAction}
                    slotItemsBefore={(isSliderView && renderAddButton('lg'))}
                  />
                )
              }
              {
                ((!shownType || shownType === 'video') && !!videoList.length) && (
                  <TemplateSlider
                    renderTitle={() => renderTitle('video', 'videos', false)}
                    sliderView={isSliderView}
                    items={videoList}
                    templateItemComponent={VideoTemplateItem}
                    size={isSliderView ? 'md' : undefined}
                    renderSlotBtnAction={renderTemplateSlotBtnAction}
                  />
                )
              }
              {
                ((!shownType || shownType === 'image') && !!imageList.length) && (
                  <TemplateSlider
                    renderTitle={() => renderTitle('image', 'images', false)}
                    sliderView={isSliderView}
                    items={imageList}
                    templateItemComponent={ImageTemplateItem}
                    size={isSliderView ? 'sm' : undefined}
                    renderSlotBtnAction={renderTemplateSlotBtnAction}
                  />
                )
              }
              {(imageList.length === 0 && textList.length === 0 && videoList.length === 0) && (
                <NoDataTemplate title={t('noTemplatesYet')} />
              )}
            </Row>
          </Scroll>

          {attached && (
            <Row
              className='templates_actions'
              directionHorizontal
              autoColumns
              horizontal="end"
              layout='flex'
            >
              <Tooltip content={t('detachAll')}>
                <Button
                  disabled={!attached.length}
                  size='sm'
                  iconBefore={AeFontIcons.close}
                  variant='opacity'
                  color="gray"
                  onClick={handleClickClearAttached}
                />
              </Tooltip>
              <Button
                style={{ minWidth: 260 }}
                disabled={!attached.length}
                size='sm'
                onClick={onClickAttachItems}
              >
                {t('attachMedia', { value: attached?.length })}
              </Button>
            </Row>
          )}
        </Row>
      )}
    </Row>
  );
};
