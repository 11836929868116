import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../components/button/Button';
import Row from '../../components/row/Row';
import { useModalContext } from '../../components/modal/ModalProvider';
import { Typography } from '../../components';
import { useModalModifyTextTemplate } from './ModalModifyTextTemplate';
import { Template } from '../../api/resources';
import {useCopy} from '../../hooks/useCopy';
import Scroll from '../../components/scroll/Scroll';

interface CopyTextTemplatePopupProps {
  item: Template;
  isEdit: boolean;
}

const ModalTextTemplate: React.FC<CopyTextTemplatePopupProps> = ({ item, isEdit }) => {
  const { t } = useTranslation();
  const { copy } = useCopy();
  const { openModalTextTemplate } = useModalModifyTextTemplate();

  const textRef = useRef<HTMLSpanElement>(null);

  const [locallyItem, setLocallyItem] = useState(item);

  const openEditPopup = () => {
    openModalTextTemplate(
      locallyItem.parentId || '',
      isEdit ? locallyItem : undefined,
      isEdit,
      (updatedItem: any) => {
        setLocallyItem({ ...item, ...updatedItem });
      },
    );
  };

  const onCopy = async () => {
    if (textRef.current) {
      await copy(textRef.current.innerText);
    }
  };

  return (
    <Row gap='extra-lg'>
      <Typography
        style={{ textAlign: 'center' }}
        component='h3'
        variant='poppins'
      >
        {locallyItem.title}
      </Typography>

      <Scroll fixScrollbarCoversContent staticScrollView autoHeight autoHeightMax={300}>
        <Typography
          ref={textRef}
          component="body1"
          dangerouslySetInnerHTML={{__html: locallyItem.textUrlified}}
        />
      </Scroll>
      <Row gap='lg' directionHorizontal layout="flex" horizontal="center">
        {isEdit && (
          <Button
            type="button"
            onClick={openEditPopup}
            variant="opacity"
            color="gray"
            minWidth="sm"
          >
            {t('edit')}
          </Button>
        )}
        <Button
          type="submit"
          minWidth="sm"
          onClick={onCopy}
        >
          {t('copy')}
        </Button>
      </Row>
    </Row>
  );
};

export const useModalTextTemplate = () => {
  const { openModal } = useModalContext();

  const openModalTextTemplate = (item: Template, isEdit: boolean) => {
    openModal(<ModalTextTemplate isEdit={isEdit} item={item}/>, {
      widthSize: 'sm',
    });
  };

  return {
    openModalTextTemplate,
  };
};
