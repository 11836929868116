import { apiRequest } from './api';
import { ApiParamUserData } from './api.types';

export type ModifyKnowledgeAction = 'add' | 'edit' | 'delete'

export type KnowledgeContentData = {
  action: ModifyKnowledgeAction;
  resourceId: string;
  parentId?: string | null;
  title?: string;
  text?: string;
  language?: string;
}

export const modifyKnowledgeContentRequest = (
  userData: ApiParamUserData, data: KnowledgeContentData
) => {
  return apiRequest('modifyResource', {
    evangelistId: userData.id,
    agentToken: userData.agentToken,
    ...data
  });
};
