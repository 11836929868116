import React from 'react';
import { useTranslation } from 'react-i18next';

import FontIconView from '../../../assets/FontIconView';
import AeFontIcons from '../../../assets/Icons';
import { formatDateToUiDate, toLocalDateString } from '../../../utils/time';
import { Badge, Row, Typography, UserIcon } from '../../index';
import {
  getIsTextWithAttachments,
  getIsTextWithVoice,
  getMessageTextClear,
} from '../../../utils/chat';
import { ColorScheme } from '../../../types';
import { makeLabelFullName } from '../../../utils/common';
import { addQuotes } from '../../../utils/string';

import './ChatItem.scss';

interface ChatItemProps {
  inactive?: boolean;
  active: boolean;
  pinned?: boolean;
  muted?: boolean;
  id: string;
  firstname: string | null;
  lastname?: string | null;
  church?: string | null
  alreadyBaptized?: boolean
  chatStatus?: string;
  text: string;
  date: string;
  unreadCount?: number;
  type: 'leader-interests' | 'seeker' | 'inactive-seeker' | 'broadcast' | 'leader' | 'campaign';
  slotActions?: React.ReactNode;
  slotText?: React.ReactNode;
  evangelistId?: number | string | undefined;
  style?: React.CSSProperties;
  onClick: (id: string) => void;
}

const ChatItem: React.FC<ChatItemProps> = React.memo(({
  inactive,
  active,
  pinned,
  evangelistId,
  firstname,
  lastname,
  church,
  alreadyBaptized,
  text,
  date,
  unreadCount,
  muted,
  id,
  type,
  onClick,
  slotActions,
  slotText,
  style = {}
}) => {
  const { t } = useTranslation();

  const inactiveProfile = type === 'inactive-seeker';
  const isVoiceMessage = getIsTextWithVoice(text);
  const isAttachmentMessage = getIsTextWithAttachments(text);

  let badgeColor: ColorScheme,
    rootColor: ColorScheme;

  if (type === 'leader') {
    rootColor = 'violet';
  } else if (type === 'campaign') {
    rootColor = 'blue';
  } else {
    rootColor = 'green';
  }

  if (muted) {
    badgeColor = 'gray';
  } else if (type === 'leader') {
    badgeColor = 'violet';
  } else if (type === 'leader-interests') {
    badgeColor = 'orange';
  } else {
    badgeColor = 'blue';
  }

  return (
    <Row
      hover
      color={rootColor}
      paddingHorizontal='lg'
      active={active}
      style={style}
      onClick={() => {
        if (!active) {
          onClick(id.toString());
        }
      }}
      dataAttributes={{
        'data-id': id,
      }}
    >
      <Row
        paddingVertical='sm'
        gap='sm'
        directionHorizontal
        style={{
          borderColor: active ? 'transparent' : undefined,
          gridTemplateColumns: 'auto 1fr',
        }}
        border='bottom'
      >
        <div className='chat-item_avatar'>
          <UserIcon
            isBroadcast={type === 'broadcast'}
            variant={type === 'leader' ? 'outline' : undefined}
            item={{ id: String(evangelistId || id), firstname, lastname }}
            inactive={inactiveProfile || inactive}
          />
        </div>

        <div className='chat-item_content'>
          <Row
            gap='extra-sm'
            directionHorizontal
            autoColumns
          >
            <Typography
              variant='poppins'
              component='h4'
              color='text-title'
            >
              <Row
                style={{ width: '100%', height: '100%' }}
                layout='flex'
                tagName='span'
                directionHorizontal
                vertical='center'
                gap='tiny'
              >
                <Typography threeDots>
                  {makeLabelFullName({
                    firstname: firstname || '',
                    lastname: lastname || '',
                  })}
                  {church && ' ' + addQuotes(church)}
                  {alreadyBaptized && ' (BPA) '}
                </Typography>
                <Typography color='text-title-light'>
                  {inactiveProfile && <FontIconView icon={AeFontIcons.eyeCrossed} />}
                  {muted && <FontIconView icon={AeFontIcons.bellFillCrossed} />}
                  {pinned ? (
                    <FontIconView icon={AeFontIcons.pin} style={{ fontSize: '0.9rem' }} />
                  ) : ''}
                </Typography>
              </Row>
            </Typography>
            <Typography
              align='right'
              component='body3'
              color='text-title-light'
              threeDots
            >{formatDateToUiDate(toLocalDateString(date), 'message')}</Typography>
          </Row>

          {
            active && slotActions
              ? (
                <Row
                  directionHorizontal
                  gap='md'
                  className='chat-item_actions'
                >{slotActions}</Row>
              )
              : (
                slotText || (
                  <Row
                    className='chat-item_meta'
                    directionHorizontal
                    gap='lg'
                    autoColumns
                    vertical='end'
                    horizontal='space-between'
                  >
                    <Typography
                      component='body3'
                      color='text-title-light'
                      clamp={2}
                    >
                      {
                        isVoiceMessage
                          ? t('voice_message')
                          : getMessageTextClear(text)
                      }
                    </Typography>
                    <Row
                      horizontal='end'
                      directionHorizontal
                      vertical='center'
                      layout='flex'
                      gap='tiny'
                    >
                      {isAttachmentMessage && <FontIconView icon={AeFontIcons.attachment} />}
                      {isVoiceMessage && <FontIconView icon={AeFontIcons.micro} />}
                      {
                        (unreadCount && unreadCount > 0) ? (
                          <Badge color={badgeColor}>{unreadCount}</Badge>
                        ) : ''
                      }
                    </Row>
                  </Row>
                )
              )
          }
        </div>
      </Row>
    </Row>
  );
});

export default ChatItem;
