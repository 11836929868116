import React, { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';

import { clutch } from '../../utils/common';
import { getCoordinateX } from '../../utils/dom';
import { ColorScheme } from '../../types';
import { LineProgressBar } from '../progress-bar/ProgressBar';

import './Slider.scss';
import { COMPONENT_COLOR_DEFAULT } from '../../utils/constants';

const preventEvent = (e: Event) => {
  e.preventDefault();
};

export type SliderProps = {
  disabled?: boolean
  value: number
  min?: number
  max?: number
  step?: number,
  color?: ColorScheme,
  onChange: (e: any, value: number) => void,
  onChangeCommitted?: (e:any, value: number) => void,
  style?: CSSProperties,
};

const Slider: React.FC<SliderProps> = ({
  value, step = 1,
  min = 0, max = 0, onChangeCommitted,
  disabled, onChange, color
}) => {
  const rootRef = useRef<HTMLDivElement | null>(null);
  const [isDragging, setIsDragging] = useState(false);

  const getSliderBoundingClientRect = useCallback(() => {
    const sliderRefCurrent = rootRef.current;

    if (sliderRefCurrent) {
      return sliderRefCurrent.getBoundingClientRect();
    }

    return undefined;
  }, []);

  // Returns new value, based on coordinates from the given event.
  const getProgressWithEvent = useCallback((e) => {
    const sliderBoundingClientRect = getSliderBoundingClientRect();

    if (sliderBoundingClientRect) {
      const moveCoordinate = getCoordinateX(e);
      if (!moveCoordinate) {
        return 0;
      }

      let sliderRelativeCoordinate = moveCoordinate - sliderBoundingClientRect.left;

      if (sliderRelativeCoordinate <= 0) {
        return min;
      }

      const sliderWidth = sliderBoundingClientRect.width;

      if (sliderRelativeCoordinate >= sliderWidth) {
        return max;
      }

      const progressInPercents = Math.ceil(sliderRelativeCoordinate * 100 /
        sliderWidth);
      const newValue = ((max - min) * progressInPercents / 100) + min;
      const extraPoints = newValue % step;

      return clutch(newValue - extraPoints, min, max);
    }

    return null;
  }, [max, min, step, getSliderBoundingClientRect]);

  const handleThumbMove = useCallback((e: any) => {
    const newValue = getProgressWithEvent(e);

    if (!newValue) {
      return;
    }

    onChange(e, newValue);
  }, [getProgressWithEvent, onChange]);

  const handleMouseReleased = useCallback((e: any) => {
    setIsDragging(false);

    document.removeEventListener('mousemove', handleThumbMove);
    document.removeEventListener('touchmove', handleThumbMove);

    document.removeEventListener('mouseup', handleMouseReleased);
    document.removeEventListener('touchend', handleMouseReleased);

    const newValue = getProgressWithEvent(e);

    if (newValue && onChangeCommitted) {
      onChangeCommitted(e, newValue);
    }
  }, [handleThumbMove, getProgressWithEvent, onChangeCommitted]);

  const handleSliderCapture = useCallback(({ nativeEvent }: any) => {
    setIsDragging(true);

    document.addEventListener('mousemove', handleThumbMove);
    document.addEventListener('touchmove', handleThumbMove, {
      passive: false,
    });

    document.addEventListener('mouseup', handleMouseReleased);
    document.addEventListener('touchend', handleMouseReleased);

    const newValue = getProgressWithEvent(nativeEvent);

    if (newValue) {
      onChange(nativeEvent, newValue);
    }
  }, [handleThumbMove, handleMouseReleased, getProgressWithEvent, onChange]);

  useEffect(() => {
    const sliderRefCurrent = rootRef.current;

    if (sliderRefCurrent) {
      sliderRefCurrent.addEventListener('mousedown', preventEvent, {
        passive: false,
      });
      sliderRefCurrent.addEventListener('touchstart', preventEvent, {
        passive: false,
      });
      sliderRefCurrent.addEventListener('touchmove', preventEvent, {
        passive: false,
      });
    }

    return () => {
      if (sliderRefCurrent) {
        sliderRefCurrent.removeEventListener('mousedown', preventEvent);
        sliderRefCurrent.removeEventListener('touchstart', preventEvent);
        sliderRefCurrent.removeEventListener('touchmove', preventEvent);
      }
    };
  }, [handleSliderCapture]);

  let currentProgressInPercents;

  const valueClutched = clutch(value, min, max);

  if (valueClutched === min) {
    currentProgressInPercents = 0;
  } else if (valueClutched === max) {
    currentProgressInPercents = 100;
  } else {
    currentProgressInPercents = (valueClutched - min) * 100 / (max - min);
  }

  return (
    <LineProgressBar
      ref={rootRef}
      className={classnames('slider', {
        'disabled': disabled,
        'dragging': isDragging,
      })}
      progressElementProps={{
        className: 'slider_progress',
      }}
      color={color}
      progress={currentProgressInPercents}
      onMouseDown={handleSliderCapture}
      onTouchStart={handleSliderCapture}
    />
  );
};

Slider.defaultProps = {
  color: COMPONENT_COLOR_DEFAULT,
};

export default Slider;
