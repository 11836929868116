import format from 'date-fns/format/index';
import { convertToNumber, hasDecimal } from './common';
import isSameDay from 'date-fns/isSameDay';
import parseISO from 'date-fns/parseISO';
import { differenceInDays, isBefore, isWithinInterval, sub } from 'date-fns';
import isToday from 'date-fns/isToday';
import { isTomorrow } from 'date-fns/esm';
import subDays from 'date-fns/subDays';
import set from 'date-fns/set';
import add from 'date-fns/add';

// Returns date in the format for the backend.
export const formatDateForApi = (date: Date | number, withoutTime = false) => {
  if (withoutTime) {
    return format(date, 'yyyy-MM-dd');
  }
  return format(date, 'yyyy-MM-dd HH:mm:ss');
};

export const timeConvert = (value: string | number): string => {
  let time = convertToNumber(value);

  if (hasDecimal(time)) {
    time = (time / 60);
  }

  const fHours = Math.floor(time);
  const minutes = (time - fHours) * 60;
  const rMinutes = Math.round(minutes);
  return fHours + 'h ' + rMinutes + 'm ';
};

type FormatDatePattern = 'message' | 'date-digits' | 'date-words' |
  'duration' | 'reminder' | 'time' | 'day' | 'date-and-time';

const dateFormatTemplate = 'MM/dd/yyyy';

const FORMAT_DATE_TEMPLATE: Record<FormatDatePattern, string> = {
  'message': dateFormatTemplate,
  'date-digits': dateFormatTemplate,
  'date-words': 'MMM dd, y',
  'reminder': 'EEE, MM.dd.yy',
  'date-and-time': 'd MMM, hh:mm aa',
  'duration': 'mm:ss',
  'time': 'hh:mm aa',
  'day': 'd MMM',
};

export const formatDateToUiDate = (date: number | string | Date, pattern: FormatDatePattern) => {
  const template = FORMAT_DATE_TEMPLATE[pattern];
  const formatToTimeIfToday = pattern === 'message' || pattern === 'day';

  try {
    let parsedDate;

    if (typeof date === 'string') {
      parsedDate = parseISO(date);
    } else {
      parsedDate = date;
    }

    const today = new Date();

    if (formatToTimeIfToday && isSameDay(parsedDate, today)) {
      return format(parsedDate, 'HH:mm');
    }

    return format(parsedDate, template);
  } catch (e) {
    return '';
  }
};

export const toLocalDateString = (date: string) => {
  if (!date || date === 'undefined') {
    return '';
  }
  const localDate = parseISO(date.replace(' ', 'T') + '-00:00');
  return localDate.toISOString();
};

export const convertHours24To12 = (hours: number) => {
  if (getIsAm(hours)) {
    return hours === 0 ? 12 : hours;
  }

  return hours === 12 ? 12 : hours - 12;
};

export const getIsAm = (hours: number) => {
  return hours === 0 || hours < 12;
};

export const addLeadingZeroToTime = (time: number) => {
  if (time < 10) {
    return '0' + time;
  }

  return time.toString();
};

export const dateLabelToDate = (label: DateLabel) => {
  const today = set(new Date(), { hours: 12, minutes: 0, seconds: 0, milliseconds: 0 });

  if (label === 'today') {
    return today;
  } else if (label === 'tomorrow') {
    return add(today, { days: 1 });
  } else if (label === '2 days') {
    return add(today, { days: 2 });
  } else if (label === '5 days') {
    return add(today, { days: 5 });
  } else if (label === '10 days') {
    return add(today, { days: 10 });
  }

  return today;
};

export const dateToDateLabel = (date: Date): DateLabel => {
  const today = new Date();

  if (isBefore(date, today)) {
    return 'onCertainDate';
  }

  if (isToday(date)) {
    return 'today';
  } else if (isTomorrow(date)) {
    return 'tomorrow';
  }

  if (isToday(subDays(date, 2))) {
    return '2 days';
  } else if (isToday(subDays(date, 5))) {
    return '5 days';
  } else if (isToday(subDays(date, 10))) {
    return '10 days';
  }

  return 'onCertainDate';
};

export type DateLabel = 'today' | 'tomorrow'
  | '2 days' | '5 days' | '10 days' | 'onCertainDate';

export const checkIsRecently = (date: Date) => {
  const today = new Date();
  const start = sub(new Date(), { hours: 18 });
  return isWithinInterval(date, { start, end: today });
};

export const getDifferenceInDays = (dateStr: string | undefined | null) => {
  if (!dateStr) {
    return 0;
  }
  let daysDifferenceCount = 0;
  const dateValue = parseISO(dateStr);
  if (dateValue) {
    daysDifferenceCount = differenceInDays(new Date(), dateValue);
  }
  return daysDifferenceCount;
};

export type DaysSinceTodayLabel = {
  key: string,
  count?: number,  
};

export const getDaysCountSinceTodayLabel = (dateStr: string | undefined | null) => {
  const count = getDifferenceInDays(dateStr);
  let label: DaysSinceTodayLabel;
  if (count === 0) {
    label = { key: 'today' };
  } else {
    label = {
      key: 'daysAgo',
      count,
    };
  }
  return label;
};
