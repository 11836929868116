import React  from 'react';
import classnames from 'classnames';

import { FontIconView } from '../../index';
import AeFontIcons from '../../../assets/Icons';

import './CriteriaItem.scss';

type DividerProps = React.HTMLProps<HTMLDivElement> & {
  showIcon?: boolean,
  completed: boolean,
}

const CriteriaItem: React.FC<DividerProps> = ({ showIcon, completed, children }) => {
  return (
    <div className={classnames('criteria-item', {
      'completed': completed,
    })}>
      {showIcon && <FontIconView
        icon={completed ? AeFontIcons.check : AeFontIcons.close}
        className='criteria-item__icon'
      />}{children}
    </div>
  );
};

export default CriteriaItem;
