import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { Button, FontIconView } from '../index';
import AeFontIcons from '../../assets/Icons';
import { Tooltip } from '../tooltip/Tooltip';

import styles from './ChatView.module.scss';

interface ChatViewProps {
  className?: string
  isCompact?: boolean
  handleCollapse?: () => void
  handleEdit?: () => void
  slotActions?: ReactNode
}

const ChatView: React.FC<ChatViewProps> = (props) => {
  const { t } = useTranslation();

  return (
    <div className={classnames(styles['root'], props.className)}>
      <div className={styles['actions']}>
        {props.slotActions}

        {props.handleEdit && (
          <Tooltip content={t('edit')} className={styles['btn-edit']}>
            <Button
              variant='transparent'
              color='gray-light'
              onClick={props.handleEdit}
            >
              <FontIconView icon={AeFontIcons.edit} />
            </Button>
          </Tooltip>
        )}

        {
          props.handleCollapse && !props.isCompact && (
            <Tooltip content={t('minimize')} className={styles['btn-expand']}>
              <Button
                variant='transparent'
                color='gray-light'
                onClick={props.handleCollapse}
              >
                <FontIconView icon={AeFontIcons.collapse} />
              </Button>
            </Tooltip>
          )
        }
      </div>

      {props.children}
    </div>
  );
};

export default ChatView;
