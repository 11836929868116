import React  from 'react';
import classnames from 'classnames';

import './AuthContainer.scss';

type AuthContainerWidths = 'sm' | 'lg';

type AuthContainerProps = {
  width?: AuthContainerWidths,
};

const AuthContainer: React.FC<AuthContainerProps> = ({ width = 'sm', children }) => {
  return (
    <div className={classnames('auth-container', width)}>{children}</div>
  );
};

export default AuthContainer;
