import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { Button, TextArea } from '../index';
import { useModalContext } from '../modal/ModalProvider';
import Row from '../row/Row';
import { renderField } from '../custom-field/customField';

import './share-story.scss';

type Inputs = {
  text: string;
};

export const ShareStoryPopup: React.FC<{title: string}> = ({ title }) => {
  const { t } = useTranslation();
  const { closeModal } = useModalContext();

  const ShareStoryValidation = Yup.object().shape({
    text: Yup.string().required(t('errorFieldRequired'))
  });

  const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm<Inputs>({
    resolver: yupResolver(ShareStoryValidation)
  });

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const mailTo = 'stories@awr.org';
    const sharerUrl = `mailto:${mailTo}?subject=${title}&body=${data.text}`;
    window.open(sharerUrl, '_blank');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Row gap='lg'>
        <span className="share-story__title">{t('shareYourStory')}</span>
        <div className="share-story__field">
          {
            renderField(TextArea, {
              ...register('text'),
              errorText: errors.text?.message || '',
              placeholder: t('enterYourStory'),
            })
          }
        </div>
        <Row gap='lg' directionHorizontal layout="flex" horizontal="center">
          <Button
            minWidth='sm'
            color='gray'
            variant='opacity'
            type="button"
            onClick={closeModal}
            disabled={isSubmitting}
          >
            {t('cancel')}
          </Button>
          <Button
            minWidth='sm'
            type="submit"
            disabled={isSubmitting}
          >
            {t('share')}
          </Button>
        </Row>
      </Row>
    </form>
  );
};
