import { apiRequest } from './api';
import {
  ChatItemLeaderCMType,
  ChatItemLeaderCMTypeApi,
  ChatListChatType, ChatListChatTypes,
  GetMessageUpdatesRequestMessage,
  MessageFromEvangelistData,
  MessageItemType, MessageReviewStatuses,
  MessageStatus, SendMessageFromEvangelistMsgData,
  SetMessageViewedRequestStatus,
  SourceDestType, SourceDestTypes
} from './chat.types';
import {
  ChatItemCampaignType,
  ChatItemAwrCampaignTypeApi,
  ChatItemCampaignTypeApi
} from './campaigns.types';
import { APIEvangelistItem } from './evangelists.types';
import {
  getIsChatItemCampaignTypeApi,
  normalizeChatItemCampaign,
  normalizeChatItemLeaderCM,
  normalizeGetMessageUpdatesItem,
  normalizeMessageHistoryItem,
  normalizeSendMessageFromEvangelistMsgData
} from './chat.utils';
import { normalizeChatItemEvangelist } from './evangelists.utils';
import { ApiParamUserData } from './api.types';
import { Role } from '../utils/enums';
import { AWRUserId } from '../utils/constants';

// Get all messages associated with a given seeker and evangelist
export const getMessageHistoryRequest = (
  userData: ApiParamUserData,
  params: {
    role: Role,
    seekerId: string, // seeker ID (0 means all seekers for this evangelist)
    evangelistId: string,
    seekerType: SourceDestType, // Type of seeker messages
  },
  searchParams: {
    offset?: number, // Row offset for retrieval. Starts at 0
    offsetId?: number, // Id of message which will be the center of the returned results
    limit?: number, // Maximum number of rows to return
    inverted?: boolean, // inverts selection direction
  },
) => {
  const isLeaderWatchesDmSeekerChat = params.role !== Role.DM
    && params.seekerType === SourceDestTypes.SEEKER;

  if (isLeaderWatchesDmSeekerChat && !params.evangelistId) {
    // eslint-disable-next-line no-console
    console.log(
      '%cYou, as Leader, forgot to specify {evangelistId} for DM-Seeker chat!',
      'background: red; color: white;'
    );
  }

  let paramsFormatted;

  if (params.role === Role.ADMIN && params.seekerType === SourceDestTypes.AWR) {
    paramsFormatted = {
      id: AWRUserId,
      seekerId: params.seekerId,
      seekerType: SourceDestTypes.EVANGELIST,
      evangelistType: SourceDestTypes.AWR,
      vetterId: userData.id,
    };
  } else {
    paramsFormatted = {
      id: isLeaderWatchesDmSeekerChat ? params.evangelistId : userData.id,
      leaderId: isLeaderWatchesDmSeekerChat ? userData.id : undefined,
      seekerId: params.seekerId,
      seekerType: params.seekerType,
    };
  }
  
  return apiRequest<{
    messages: MessageItemType[],
  }>('getMessageHistory', {
    agentToken: userData.agentToken,
    ...paramsFormatted,
    ...searchParams,
  }).then((response) => response.messages
    ? response.messages.map(normalizeMessageHistoryItem)
    : []);
};

export type GetMessageUpdatesRequestParams = {
  messageIds?: string[], // array of message IDs for message array
  seekerIds?: string[], // array of seeker IDs for messages counts
  dmIds?: string[], // array of evangelist IDs for messages counts (leaders only)
  campaignIds?: string[], // array of campaign IDs for messages counts
  //params bellow are needed for admin request
  id?: string,
  evangelistId?: string,
  vetterId?: string,
}

// Get message details given message and seeker IDs
export const getMessageUpdatesRequest = (
  userData: ApiParamUserData,
  params: GetMessageUpdatesRequestParams,
  evangelistId?: string,
) => {
  const finalParams = { ...params };
  if (userData.role === Role.ADMIN) {
    finalParams.vetterId = userData.id;
    finalParams.id = evangelistId;
  } else {
    finalParams.id = userData.id;
  }
  return apiRequest<{
    counts: {
      lastMessage: null | {
        date: string
        sourceType: SourceDestType
        text: string
      },
      seekerId: string | null,
      unread: string,
      outgoing: string,
      incoming: string,
      ungraded: string | null,
    }[],
    messages: GetMessageUpdatesRequestMessage[],
  }>('getMessageUpdates', {
    agentToken: userData.agentToken,
    ...finalParams,
  }).then((response) => {
    return {
      messages: response.messages.map((message) => {
        return normalizeGetMessageUpdatesItem(message, userData.id);
      }),
      counts: response.counts,
    };
  });
};

// Send text message to seeker from evangelist
export const sendMessageFromEvangelistRequest = (
  userData: ApiParamUserData, recipientId: string, messageData: MessageFromEvangelistData
) => {
  return apiRequest<{
    msgData: SendMessageFromEvangelistMsgData,
    msgStatus: MessageStatus,
    msgId: number,
    rowIndex: number,
  }>('sendMessageFromEvangelist', {
    id: userData.id,
    agentToken: userData.agentToken,
    destId: recipientId,
    categoryId: 4,
    ...messageData
  }).then((response) => {
    return {
      ...response,
      msgData: normalizeSendMessageFromEvangelistMsgData(response.msgData, userData.id),
    };
  });
};

//Send text message to evangelist from admin
export const sendMessageFromAwr = (
  userData: ApiParamUserData, recipientId: string, text: string
) => {
  return apiRequest<{
    msgData: SendMessageFromEvangelistMsgData,
    msgStatus: MessageStatus,
    msgId: number,
    rowIndex: number,
  }>('sendMessageToEvangelistFromAWR', {
    vetterId: userData.id,
    agentToken: userData.agentToken,
    evangelistId: recipientId,
    text,
  }).then((response) => {
    return {
      ...response,
      msgData: normalizeSendMessageFromEvangelistMsgData(response.msgData, userData.id),
    };
  });
};

type MessageViewedRequestType = {
  id?: string,
  vetterId?: string,
  // to read specific messages by ids
  messageId?: string[],
  // to read all chat messages //
  sourceId?: string,
  sourceType?: SourceDestType,
  //to read all messages by admin in AWR chat
  destId?: string,
  destType?: string,
};

// Set a message to the given status
export const setMessageViewedRequest = (
  userData: ApiParamUserData,
  status: SetMessageViewedRequestStatus,
  params: MessageViewedRequestType
) => {
  const finalParams: MessageViewedRequestType = { id: userData.id };
  const { messageId, sourceId, sourceType } = params;
  if (userData.role === Role.ADMIN && params.sourceType === SourceDestTypes.AWR) {
    finalParams.destId = AWRUserId;
    finalParams.destType = SourceDestTypes.AWR;
    finalParams.id = sourceId;
    finalParams.vetterId = userData.id;
  } else if (messageId) {
    finalParams.messageId = messageId;
  } else if (sourceId && sourceType) {
    finalParams.sourceId = sourceId;
    finalParams.sourceType = sourceType;
  }
  return apiRequest('setMessageViewed', {
    agentToken: userData.agentToken,
    status, // 'viewed' is default on the server.
    ...finalParams
  });
};

///set verification status for a message
export const setVetterMessageStatusRequest = (
  userData: ApiParamUserData,
  msgId: string,
  msgStatus: typeof MessageReviewStatuses.DENIED | typeof MessageReviewStatuses.APPROVED,
  msgNote?: string,
  msgScore?: string, // 1-5 where higher number is better
  evangelistId?: string, // only required if msgId is “all”
) =>
  apiRequest('setVetterMessageStatus', {
    id: userData.id,
    agentToken: userData.agentToken,
    msgId,
    msgStatus,
    msgNote,
    msgScore,
    evangelistId,
  });

// Get list of chats for DM or leader
export const getChatListRequest = (userData: ApiParamUserData, chatTypes: ChatListChatType) => {
  return apiRequest<{
    evangelists: APIEvangelistItem[],
    leaders: ChatItemLeaderCMTypeApi[],
    campaigns: (ChatItemCampaignTypeApi | ChatItemAwrCampaignTypeApi)[],
    managers: ChatItemLeaderCMTypeApi[],
      }>('getChatList', {
        id: userData.id,
        agentToken: userData.agentToken,
        chatTypes,
      }).then((response) => {
        return {
          campaigns: response.campaigns,
          leaders: response.leaders,
          evangelists: response.evangelists,
          managers: response.managers,
        };
      });
};

export const getChatListLeadersCMsRequest = (userData: ApiParamUserData) => {
  return getChatListRequest(userData, ChatListChatTypes.LEADER_CM_FOR_DM).then(({
    leaders,
    managers,
  }) => {
    const leadersCMsNormalized: ChatItemLeaderCMType[] = [];

    for (let i = 0; i < leaders?.length; i++) {
      const leaderItem = leaders[i];
      if (leaderItem.firstname) {
        leadersCMsNormalized.push(normalizeChatItemLeaderCM(leaderItem));
      }
    }

    for (let i = 0; i < managers?.length; i++) {
      const managerItem = managers[i];
      const foundManager = leadersCMsNormalized.find((item) => item.id === managerItem.id);
      if (managerItem.firstname && !foundManager) {
        leadersCMsNormalized.push(normalizeChatItemLeaderCM(managerItem));
      }
    }

    return leadersCMsNormalized;
  });
};

export const getChatListEvangelistsRequest = async (userData: ApiParamUserData) => {
  return getChatListRequest(userData, ChatListChatTypes.DMS_FOR_LEADER).then(({evangelists}) => {
    return evangelists.map(normalizeChatItemEvangelist);
  });
};

export const getChatListCampaignsRequest = (userData: ApiParamUserData) => {
  return getChatListRequest(
    userData, ChatListChatTypes.LEADER_CAMPAIGN_OR_GROUP_CHATS
  ).then(({campaigns}) => {
    const campaignsNormalized: ChatItemCampaignType[] = [];

    let campaignItem;
    for (let i = 0; i < campaigns?.length; i++) {
      campaignItem = campaigns[i];
      if (getIsChatItemCampaignTypeApi(campaignItem)) {
        campaignsNormalized.push(normalizeChatItemCampaign(campaignItem));
      }
    }

    return campaignsNormalized;
  });
};
