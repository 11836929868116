import React, {CSSProperties} from 'react';

import AeFontIcons from './Icons';
import {EnumLiteralsOf} from '../types';

export type FontIconViewIcon = EnumLiteralsOf<typeof AeFontIcons>;

export interface IconViewProps {
  className?: string;
  style?: CSSProperties;
  icon: FontIconViewIcon;
}

const FontIconView = ({icon, className, ...restProps}: IconViewProps) => {
  return (
    <i {...restProps} className={(className ? (className + ' ') : '') + 'ae-font-icons'}>
      {String.fromCodePoint(icon)}
    </i>
  );
};

export default FontIconView;
