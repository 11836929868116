import React, { SyntheticEvent, useState } from 'react';
import Calendar, { CalendarProps } from 'react-calendar';
import format from 'date-fns/format/index';
import { useTranslation } from 'react-i18next';

import AeFontIcons from '../../assets/Icons';
import FontIconView from '../../assets/FontIconView';
import { ModalActions, Row } from '../index';

import './ModalCalendar.scss';

type DatePickerProps = {
  initialValue: Date,
  onSubmit: (e: SyntheticEvent<HTMLButtonElement, MouseEvent>, value: Date) => void,
  calendarProps?: CalendarProps,
}

const ModalCalendar: React.FC<DatePickerProps> = ({
  initialValue, onSubmit, calendarProps
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(initialValue);

  return (
    <Row
      gap='extra-lg'
      style={{
        minHeight: '20rem', // To not change height on month change.
      }}
      layout='flex'
      vertical='space-between'
    >
      <Calendar
        calendarType='US'
        minDetail='decade'
        formatShortWeekday={(locale, date) => format(date, 'iiiii')}
        formatMonthYear={(locale, date) => format(date, 'MMMM')}
        formatMonth={(locale, date) => format(date, 'LLLL')}
        prevLabel={<FontIconView icon={AeFontIcons.arrowLeft} />}
        nextLabel={<FontIconView icon={AeFontIcons.arrowRight} />}
        prev2Label={null}
        next2Label={null}
        value={value}
        onChange={setValue}
        {...calendarProps}
      />
      <ModalActions
        btnOkProps={{
          onClick: (e) => {
            onSubmit(e, value);

            return true;
          },
          children: t('done'),
        }}
      />
    </Row>
  );
};

export default ModalCalendar;
