import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import queryString from 'querystring';
import { useTranslation } from 'react-i18next';

import FormProfile from './FormProfile';
import FormPastor from './FormPastor';
import {
  AuthAccountsTypes,
  registerAccountTypeDM,
  URLS,
} from '../../../utils/constants';
import ViewAgreement from './ViewAgreement';
import Row from '../../../components/row/Row';
import { Button, ListItemGo, Typography } from '../../../components';
import { useMainContext } from '../../../MainProvider';
import ViewLanguages from './ViewLanguages';
import ViewLocations from './ViewLocations';
import AuthContainer from '../AuthContainer';
import { useGetCountries, useGetLocations } from '../../../query/locations';
import { Profile } from '../../../api/account.types';
import { useGetUserLanguages } from '../../../query/languages';
import { useGetProfile, useSetProfile, profileDefault } from '../../../query/profile';
import {
  getIsAgreementsFilled,
  getIsProfileExistCurry,
  getIsProfileFilled,
} from '../../../utils/profile';
import { useToastEnhanced } from '../../../enhanced-components/toaster/ToasterEnhanced';

type Views = 'profile' | 'pastor' | 'languages' | 'locations' | 'agreement';

const PageSetupProfile: React.FC<RouteComponentProps> = ({ history, location }) => {
  const profileType = (
    queryString.parse(location.search.slice(1)).type as AuthAccountsTypes || registerAccountTypeDM
  );
  const isCurrentRegisterAccountTypeDM = profileType === registerAccountTypeDM;

  const { t } = useTranslation();
  const { userData, isRoleCM, isRoleLeader } = useMainContext();
  const { profileData } = useGetProfile();
  const { setProfileMutation } = useSetProfile();

  const { userLocations, isUserLocationsLoading } = useGetLocations();

  const { profile } = profileData;

  const getIsProfileExist = getIsProfileExistCurry(profileData);
  const isProfileFilled = getIsProfileExist((profile) => (
    getIsProfileFilled(profile, !isCurrentRegisterAccountTypeDM)
  ));
  const isAgreementsFilled = getIsProfileExist(getIsAgreementsFilled);

  const [view, setView] = useState<Views>(() => (
    (isProfileFilled && !isAgreementsFilled) ? 'agreement' : 'profile'
  ));
  const [profileDataNew, setProfileDataNew] = useState<Profile>(profile);

  const { getCountryByAbbreviation } = useGetCountries();
  const { userLanguages, isUserLanguagesLoading } = useGetUserLanguages();
  const { showToast } = useToastEnhanced();

  // Upd form data if profile was updated.
  useEffect(() => {
    setProfileDataNew(profile);
  }, [profile]);

  let content;

  if (view === 'profile') {
    content = (
      <Row gap='extra-lg'>
        <Typography component='h2' variant='poppins'>{t('yourProfile')}</Typography>

        <FormProfile
          showHomeChurchNameField={isCurrentRegisterAccountTypeDM}
          initialValues={{
            firstName: profileDataNew.firstname || '',
            lastName: profileDataNew.lastname || '',
            country: {
              key: profileDataNew.country || '',
              label: '',
            },
            phoneNumber: profileDataNew.phone || '',
            city: profileDataNew.city || '',
            postalCode: profileDataNew.locationCode || '',
            homeChurchName: profileDataNew.pastorChurch || '',
          }}
          onSubmit={async ({ isValid, values }) => {
            if (isValid) {
              const profileNew = {
                ...profileDataNew,
                firstname: values.firstName,
                lastname: values.lastName,
                country: values.country.key,
                phone: values.phoneNumber,
                city: values.city,
                locationCode: values.postalCode,
              };

              if (isCurrentRegisterAccountTypeDM && values.homeChurchName) {
                profileNew.pastorChurch = values.homeChurchName;
              }

              setProfileDataNew(profileNew);

              await setProfileMutation.mutateAsync({
                id: userData.id,
                profile: profileNew,
              });

              setView('pastor');
            }
          }}
          onBack={() => {
            history.push(URLS.register);
          }}
        />
      </Row>
    );
  }

  if (view === 'pastor' || view === 'languages' || view === 'locations') {
    const showAdditionalInfo = profileType === registerAccountTypeDM;

    const phoneNumberCode = profileDataNew.country
      ? getCountryByAbbreviation(profileDataNew.country)?.code || null
      : null;

    content = (
      <>
        <Row gap='extra-lg' style={{ display: view === 'pastor' ? '' : 'none', }}>
          <Typography component='h2' variant='poppins'>{t('yourPastor')}</Typography>

          <FormPastor
            phoneNumberCode={phoneNumberCode}
            initialValues={{
              pastorName: profileDataNew.pastorName || '',
              pastorEmail: profileDataNew.pastorEmail || '',
              pastorPhoneNumber: profileDataNew.pastorPhone || `+${phoneNumberCode}` || ''
            }}
            onSubmit={async ({ isValid, values }) => {
              const equalEmail = values.pastorEmail === profile?.email;

              if(equalEmail){
                showToast({
                  title: t("notEqualEmail")
                }, {
                  type: 'error'
                });
              } else {
                if (isValid) {
                  const profileNew = {
                    ...profileDataNew,
                    pastorEmail: values.pastorEmail,
                    pastorName: values.pastorName,
                    pastorPhone: values.pastorPhoneNumber,
                  };

                  setProfileDataNew(profileDataNew);

                  await setProfileMutation.mutateAsync({
                    id: userData.id,
                    profile: profileNew,
                  });

                  setView('agreement');
                }
              }
            }}
            onBack={(values) => {
              setProfileDataNew((profileDataOld) => ({
                ...profileDataOld,
                pastorEmail: values.pastorEmail,
                pastorName: values.pastorName,
                pastorPhone: values.pastorPhoneNumber,
              }));

              setView('profile');
            }}
            slotBeforeButtons={
              showAdditionalInfo
                ? (
                  <div className='form-pastor__additional-info'>
                    <Typography component='h2' variant='poppins'>{t('additionalInfo')}</Typography>
                    <ul>
                      <ListItemGo
                        border='bottom'
                        onClick={() => {
                          setView('languages');
                        }}
                      >
                        {t('yourLanguages')}&nbsp;
                        <b>
                          ({
                            isUserLanguagesLoading
                              ? t('loading')
                              : userLanguages?.languages.length
                          })
                        </b>
                      </ListItemGo>

                      <ListItemGo
                        border='bottom'
                        onClick={() => {
                          setView('locations');
                        }}
                      >
                        {t('yourLocations')}&nbsp;
                        <b>
                          ({
                            isUserLocationsLoading
                              ? t('loading')
                              : ((userLocations?.countries.length || 0)
                              + (userLocations?.cities.length || 0)
                              + (userLocations?.postalCodes.length || 0))
                          })
                        </b>
                      </ListItemGo>
                    </ul>
                  </div>
                )
                : ''
            }
          />
        </Row>

        <Row gap='extra-lg' style={{ display: view === 'languages' ? '' : 'none', }}>
          <Typography component='h2' variant='poppins'>{t('yourLanguages')}</Typography>

          <ViewLanguages />

          <Row gap='lg' directionHorizontal>
            <Button
              color='gray'
              variant='opacity'
              onClick={() => { setView('pastor'); }}
            >{t('cancel')}</Button>
            <Button
              onClick={() => { setView('pastor'); }}
            >{t('done')}</Button>
          </Row>
        </Row>

        <Row gap='extra-lg' style={{ display: view === 'locations' ? '' : 'none', }}>
          <Typography component='h2' variant='poppins'>{t('yourLocations')}</Typography>

          <ViewLocations />

          <Row gap='lg' directionHorizontal>
            <Button
              color='gray'
              variant='opacity'
              onClick={() => { setView('pastor'); }}
            >{t('cancel')}</Button>
            <Button
              onClick={() => { setView('pastor'); }}
            >{t('done')}</Button>
          </Row>
        </Row>
      </>
    );
  }

  if (view === 'agreement') {
    content = (
      <ViewAgreement
        onBack={() => {
          if (isRoleCM || isRoleLeader) {
            history.push(URLS.login);
          } else {
            setView('pastor');
          }
        }}
        onAcceptAll={async (data) => {
          await setProfileMutation.mutateAsync({
            id: userData.id,
            profile: {
              ...profileDataNew,
              aupAccept: data.aup,
              dpaAccept: data.dpa,
            },
          });
        }}
      />
    );
  }

  return (
    <AuthContainer width={view === 'agreement' ? 'lg' : 'sm'}>{content}</AuthContainer>
  );
};

export default PageSetupProfile;
