import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Template } from '../../api/resources';
import { TEMPLATE_ATTACH_MAX } from '../../utils/constants';
import { useToastEnhanced } from '../../enhanced-components/toaster/ToasterEnhanced';
import { useModalContext } from '../../components/modal/ModalProvider';
import KnowledgePage from './KnowledgePage';

import './knowledge-page.scss';

type ModalKnowledgeProps = {
  initialAttachments: Template[],
  onClickAttachItems: (attachments: Template[]) => void,
}

const ModalKnowledge: React.FC<ModalKnowledgeProps> = ({
  initialAttachments,
  onClickAttachItems
}) => {
  const { t } = useTranslation();
  const { showToast } = useToastEnhanced();
  const { closeModal } = useModalContext();
  const [attached, setAttached] = useState<Template[]>(initialAttachments);

  const handleClickToggleAttach = useCallback((item: Template) => {
    const isAlreadyAttached = attached.findIndex((i) => i === item) !== -1;

    if (isAlreadyAttached) {
      setAttached((prev) => prev.filter((i) => i !== item));
    } else {
      if (attached.length === TEMPLATE_ATTACH_MAX) {
        showToast({ title: t('attachLimitMessage') }, { type: 'error' });
      } else {
        setAttached((prev) => [...prev, item]);
      }
    }
  }, [t, showToast, attached]);

  const handleClickAttachItems = useCallback(() => {
    onClickAttachItems(attached);
    closeModal();
  }, [closeModal, attached, onClickAttachItems]);

  const clearAttachment = () => setAttached([]);

  return (
    <KnowledgePage
      attached={attached}
      handleClickAttachItems={handleClickAttachItems}
      handleClickToggleAttach={handleClickToggleAttach}
      clearAttachment={clearAttachment}
    />
  );
};

export const useModalKnowledge = () => {
  const { openModal } = useModalContext();

  const openModalKnowledge = (
    initialAttachments: Template[], onClickAttachItems: (items: Template[]) => void
  ) => {
    openModal(
      <ModalKnowledge
        initialAttachments={initialAttachments}
        onClickAttachItems={onClickAttachItems}
      />, {
        scrollbarsClassName: 'modal_chat-attachments',
      });
  };

  return {
    openModalKnowledge,
  };
};
