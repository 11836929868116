import {
  InfiniteData, QueryClient, useInfiniteQuery, useMutation, useQueryClient
} from 'react-query';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ChatItemLeaderCMType,
  MessageFromEvangelistDestType,
  MessageItemType,
  MsgType,
  SetMessageViewedRequestStatus,
  SourceDestType,
  SourceDestTypes
} from '../api/chat.types';
import { createLocalStorageManager } from '../utils/storage';
import { useMainContext } from '../MainProvider';
import { useStorageManager } from '../hooks/useStorageManager';
import {
  getMessageHistoryRequest,
  sendMessageFromAwr,
  sendMessageFromEvangelistRequest,
  setMessageViewedRequest,
  setVetterMessageStatusRequest
} from '../api/chat';
import { uploadAudioFile } from '../api/file-storage';
import { base64ToBlob, blobToBase64, clutch } from '../utils/common';
import { ChatInputSubmitData } from '../components/chat/chat-input/ChatInput.types';
import { useGetByIdWithCache } from '../hooks/useCache';
import {
  PromiseResolveValue,
  SetQueryDataFnTyped,
  UpdateQueryDataFn,
  UpdateQueryDataFnWithStatus,
  UpdateQueryFnStatus
} from '../types';
import {
  makeQueryKeySeekers,
  QUERY_KEY_ALL_SEEKERS,
  updateAllSeekerListItem,
  updateSeekerListItem
} from './seekers';
import { QUERY_KEY_CHAT_LIST_LEADERS, updateChatListLeadersItem } from './leaders';
import { QUERY_KEY_CHAT_LIST_CAMPAIGNS, updateChatListCampaignsItem } from './campaigns';
import { getUserSourceDestType } from '../utils/profile';
import { getIsMessageCanBeRead, getMessageTextClearUrlified, makeDateFormatApi, MessageFactors } from '../utils/chat';
import { findAnyChildIndexInViewport } from '../utils/dom';
import { getMessageListElements } from '../components/chat/message-list/MessageList.utils';
import {
  QUERY_KEY_CHAT_LIST_EVANGELISTS,
  QUERY_KEY_QUICK_EVANGELISTS,
  updateAdminChatListEvangelistItem,
  updateChatListEvangelistItem
} from './evangelists';
import { useToastEnhanced } from '../enhanced-components/toaster/ToasterEnhanced';
import { RequestBaseResponse } from '../api/api.types';
import { AdminSeekerItem, SeekerItemType, SeekersListStatus } from '../api/seekers.types';
import { AWRUserId } from '../utils/constants';
import { ItemFilter } from './common.types';
import { EvangelistItemType, AdminEvangelistItem } from '../api/evangelists.types';
import { ChatItemCampaignType } from '../api/campaigns.types';

type QueryClientMessageHistoryData = InfiniteData<
  PromiseResolveValue<ReturnType<typeof getMessageHistoryRequest>>
> | undefined;

type QueueMessage<QueueMessageAdditionalProperties = {}> = {
  seekerId: string,
  seekerType: SourceDestType,
  message: MessageItemType,
  isAudioUploaded: boolean,
  audioFilename: string,
  retriedCount: number,
  additionalProperties: QueueMessageAdditionalProperties
};

const RETRY_COUNT_MESSAGE_QUEUE = 1;

export const QUERY_KEY_MESSAGE_HISTORY = 'messageHistory';

const getQueryKeyMessageHistory = (recipientId: string) => {
  return [QUERY_KEY_MESSAGE_HISTORY, recipientId];
};

const setQueryDataMessageHistory: SetQueryDataFnTyped<QueryClientMessageHistoryData, {
  recipientId: string;
}> = (queryClient, cb, queryKeyParams) => {
  const queryKey = getQueryKeyMessageHistory(queryKeyParams.recipientId);
  queryClient.setQueryData<any>(queryKey, cb);
};

export const useSendMessageWithMessageQueue = <QueueMessageAdditionalProperties>(config: {
  seekerId: string,
  seekerType: MessageFromEvangelistDestType,
  sendMessageFn: (
    queueMessage: QueueMessage<QueueMessageAdditionalProperties>
  ) => Promise<{
    msgData: MessageItemType,
  }>,
  onSendMessageSuccess: (
    sentMessageData: MessageItemType,
    messagesCountsFactors: {
      unread?: number,
      outgoing?: number,
    },
  ) => void,
  onAddMessageToQueue?: () => void,
}) => {
  const { userData, isRoleAdmin } = useMainContext();

  type QueueMessageFinalType = QueueMessage<QueueMessageAdditionalProperties>;

  const messagesQueueStorageManager = useMemo(() => {
    // TODO: Use Indexed DB. Audio messages are too large as they have 'base64' audio.
    return createLocalStorageManager<QueueMessageFinalType[]>(userData.id + 'chatQueue');
  }, [userData.id]);

  const {
    getValue: getMessagesQueueInstantly,
    value: messagesQueue,
    updateValue: setMessagesQueue,
  } = useStorageManager(messagesQueueStorageManager);

  // Check if any message has status 'queue' and set it to 'errorSend' on hook call.
  useEffect(() => {
    const instantlyMessageQueue = getMessagesQueueInstantly();

    if (instantlyMessageQueue) {
      let isAnyMessageUpdated = false;

      for (let i = 0; i < instantlyMessageQueue.length; i++) {
        if (instantlyMessageQueue[i].message.status === 'queue') {
          instantlyMessageQueue[i].message.status = 'errorSend';
          isAnyMessageUpdated = true;
        }
      }

      if (isAnyMessageUpdated) {
        setMessagesQueue(instantlyMessageQueue);
      }
    }
  }, [getMessagesQueueInstantly, setMessagesQueue]);

  const sendMutation = useMutation(async (queueMessage: QueueMessageFinalType) => {
    const instantlyMessageQueue = getMessagesQueueInstantly();

    // --- Set queue status between retries --- //
    if (instantlyMessageQueue) {
      setMessagesQueue(
        instantlyMessageQueue.map((message) => {
          if (message.message.id === queueMessage.message.id) {
            message.message.status = 'queue';
            queueMessage = message;
          }

          return message;
        })
      );
    }
    // --------------------------------------- //

    // --- Upload audio logic --- //
    if (!queueMessage.isAudioUploaded && queueMessage.message.audio) {
      const audioBlob = await base64ToBlob(queueMessage.message.audio);
      const {filename} = await uploadAudioFile(queueMessage.audioFilename, audioBlob);

      // eslint-disable-next-line require-atomic-updates
      queueMessage.message.audio = filename;
      // eslint-disable-next-line require-atomic-updates
      queueMessage.audioFilename = filename;
      // eslint-disable-next-line require-atomic-updates
      queueMessage.isAudioUploaded = true;

      const instantlyMessageQueue = getMessagesQueueInstantly();

      if (instantlyMessageQueue) {
        setMessagesQueue(
          instantlyMessageQueue.map((message) => {
            if (message.message.id === queueMessage.message.id) {
              return queueMessage;
            }

            return message;
          })
        );
      }
    }
    // --------------------------------------- //

    return config.sendMessageFn(queueMessage);
  }, {
    onSuccess: (response, queueMessage) => {
      // Remove from the message queue and fire the callback that adds message to the history //
      const instantlyMessageQueue = getMessagesQueueInstantly();

      if (instantlyMessageQueue) {
        setMessagesQueue(
          instantlyMessageQueue.filter(
            (message) => message.message.id !== queueMessage.message.id
          )
        );
      }

      config.onSendMessageSuccess(response.msgData, {
        outgoing: 1,
      });
      // --------------------------------------- //
    },
    onError: (error: RequestBaseResponse, queueMessage) => {
      // Set 'errorSend' status if server error or retried count is max retry count.
      const instantlyMessageQueue = getMessagesQueueInstantly();

      if (instantlyMessageQueue) {
        setMessagesQueue(
          instantlyMessageQueue.map((message) => {
            if (message.message.id === queueMessage.message.id) {
              message.retriedCount += 1;

              if (error.status === 'error' || message.retriedCount >= RETRY_COUNT_MESSAGE_QUEUE) {
                message.message.status = 'errorSend';
              }
            }

            return message;
          })
        );
      }
      // --------------------------------------- //
    },
    retry: RETRY_COUNT_MESSAGE_QUEUE,
  });

  const resendMessage = useCallback(async (message: MessageItemType) => {
    const sendMutationFn = sendMutation.mutateAsync;
    const suitableQueueMessage = messagesQueue?.find(
      (queueMessage) => queueMessage.message.id === message.id
    );

    if (suitableQueueMessage) {
      await sendMutationFn(suitableQueueMessage).catch(() => {});
    }

    return undefined;
  }, [messagesQueue, sendMutation.mutateAsync]);

  const sendMessage = useCallback(async (submitData: ChatInputSubmitData & {
    additionalProperties: QueueMessageAdditionalProperties,
  }) => {
    const sendMutationFn = sendMutation.mutateAsync;

    const queueMessageId = messagesQueue?.length
      ? (Number(messagesQueue[messagesQueue.length - 1].message.id) + 1).toString()
      : '0';
    const audioBase64data = submitData.blob && submitData.filename
      ? await blobToBase64(submitData.blob)
      : '';

    const seekerId = config.seekerId;
    const seekerType = config.seekerType;
    const date = makeDateFormatApi();

    const queueMessage: QueueMessageFinalType = {
      seekerId: seekerId,
      seekerType: seekerType,
      message: {
        destId: seekerId,
        destType: seekerType,

        sourceId: isRoleAdmin ? AWRUserId : userData.id,
        sourceType: getUserSourceDestType(userData.role),

        sendDate: date,
        date,

        audio: audioBase64data,
        msgType: audioBase64data ? 'both' : 'text',
        msgVetterId: submitData.msgVetterId || null,
        replyId: submitData.replyId || null,
        requestId: submitData.requestId || null,
        quoteId: submitData.quoteId || null,
        text: submitData.text,
        textClearUrlified: getMessageTextClearUrlified(submitData.text),

        id: queueMessageId,
        sendId: seekerId,

        rowIndex: -1,
        direction: 'to',
        status: 'queue',
        msgStatus: 'verifying',
        msgNotes: null,
        msgScore: null,
        sent: null,
      },
      audioFilename: submitData.filename || '',
      isAudioUploaded: false,
      retriedCount: 0,
      additionalProperties: submitData.additionalProperties,
    };

    const instantlyMessageQueue = getMessagesQueueInstantly();

    setMessagesQueue(
      instantlyMessageQueue?.length
        ? [...instantlyMessageQueue, queueMessage]
        : [queueMessage]
    );

    const onAddMessageToQueue = config.onAddMessageToQueue;

    if (onAddMessageToQueue) {
      onAddMessageToQueue();
    }

    await sendMutationFn(queueMessage).catch(() => {});
  }, [
    isRoleAdmin,
    config.seekerId,
    config.seekerType,
    messagesQueue,
    userData.id,
    userData.role,
    getMessagesQueueInstantly,
    setMessagesQueue,
    sendMutation.mutateAsync,
    config.onAddMessageToQueue,
  ]);

  return {
    sendMutation,
    sendMessage,
    resendMessage,
    messagesQueue: messagesQueue?.filter((message) => {
      return message.seekerId === config.seekerId && message.seekerType === config.seekerType;
    }),
  };
};

export const pushToMessageHistory: UpdateQueryDataFnWithStatus<{
  recipientId: string,
  message: MessageItemType,
}> = (queryClient, options) => {
  const { message } = options;
  let status: UpdateQueryFnStatus = '';

  setQueryDataMessageHistory(queryClient, (oldData) => {
    const oldDataPages = oldData?.pages || [];

    if (message) {
      let newData: QueryClientMessageHistoryData = {
        pages: [[message]],
        pageParams: [],
      };

      let messageAlreadyInHistory = false;

      if (oldDataPages.length) {
        // Prevent push to message history when the new message is already in the list,
        // because notification arrives after the message history is updated on the server.
        for (let i = oldDataPages.length - 1; i >= 0; i--) {
          const chunk = oldDataPages[i];

          if (chunk) {
            for (let j = chunk.length - 1; j >= 0; j--) {
              if (chunk[j].id === message.id) {
                messageAlreadyInHistory = true;
                break;
              }
            }
          }
        }
      }

      if (messageAlreadyInHistory) {
        status = 'fail/already-exists';
      } else {
        status = 'success';

        // EXPERIMENTAL! Dispatches a global event of pushing new message to the history.
        // It is listened in the {BaseChatView.tsx} to check possibly autoscroll to the bottom.
        window.dispatchEvent(new CustomEvent('pushToMessageHistory', {
          detail: message,
        }));

        if (oldData && oldDataPages.length) {
          newData = {
            pages: [...oldDataPages, [message]],
            pageParams: oldData.pageParams,
          };
        }
      }

      return newData;
    }

    status = 'fail/no-data';

    return oldData;
  }, {
    recipientId: options.recipientId,
  });

  return { status };
};

const messageHistoryDefault: QueryClientMessageHistoryData = {
  pages: [],
  pageParams: [],
};

const getMessageId = (item: MessageItemType) => item.id;

export const useClearMessagesByUserId = () => {
  const queryClient = useQueryClient();
  return (userId: string) => queryClient.removeQueries(getQueryKeyMessageHistory(userId));
};

export const useGetMessageHistory = (
  recipientId: string,
  evangelistId: string,
  seekerType: SourceDestType,
  options: {
    searchParams: {
      limit: number,
      inverted?: boolean,
      offsetId?: string,
    },
    chatSize: number,
  },
  onMessageHistoryFetchSuccess?: (messageHistory: MessageItemType[]) => Promise<void>,
) => {
  const { userData, uiRole } = useMainContext();

  const queryKey = getQueryKeyMessageHistory(recipientId);

  const {
    isLoading,
    data = messageHistoryDefault,
    fetchNextPage,
    fetchPreviousPage,
    hasPreviousPage,
    hasNextPage,
    isFetchingNextPage,
    isFetchingPreviousPage,
    isError,
    isFetched,
  } = useInfiniteQuery(queryKey, async ({ pageParam = options.searchParams }) => {
    const messageHistory = await getMessageHistoryRequest(userData, {
      role: uiRole,
      evangelistId,
      seekerId: recipientId,
      seekerType,
    }, pageParam);

    if (onMessageHistoryFetchSuccess) {
      await onMessageHistoryFetchSuccess(messageHistory);
    }

    return messageHistory;
  }, {
    getPreviousPageParam: (message) => {
      const rowIndexFirstMessage = message[0]?.rowIndex;

      if (rowIndexFirstMessage === undefined) {
        return undefined;
      }

      const limit = options.searchParams.limit;
      let rowIndexCalculated = rowIndexFirstMessage - limit;
      let rowIndexNormalized = clutch(rowIndexCalculated, 0, Infinity);

      if (rowIndexNormalized === rowIndexFirstMessage) {
        return undefined;
      }

      return {
        offset: rowIndexNormalized,
        limit: rowIndexFirstMessage - rowIndexNormalized,
      };
    },
    getNextPageParam: (message) => {
      const rowIndexLastMessage = message[message.length - 1]?.rowIndex;

      if (rowIndexLastMessage === undefined) {
        return undefined;
      }

      const chatSize = options.chatSize;

      if (rowIndexLastMessage + 1 >= chatSize) {
        return undefined;
      }

      return {
        offset: rowIndexLastMessage + 1,
        limit: options.searchParams.limit,
      };
    },
    enabled: options.chatSize > 0,
    staleTime: 0,
  });

  const messageHistoryFlat = useMemo(() => {
    return data.pages.flat();
  }, [data.pages]);

  const get = useGetByIdWithCache(QUERY_KEY_MESSAGE_HISTORY, messageHistoryFlat, getMessageId);

  return {
    messageHistoryFlat,
    getMessageById: get,
    isMessageHistoryLoading: isLoading,
    isMessageHistoryFetched: isFetched,
    isMessageHistoryError: isError,
    fetchNextPageMessageHistory: fetchNextPage,
    fetchPreviousPageMessageHistory: fetchPreviousPage,
    hasPreviousMessageHistoryPage: hasPreviousPage,
    hasNextMessageHistoryPage: hasNextPage,
    isFetchingMessageHistoryNextPage: isFetchingNextPage,
    isFetchingMessageHistoryPreviousPage: isFetchingPreviousPage,
  };
};

export const updateMessageHistoryWithIdsOrIndexes: UpdateQueryDataFn<{
  recipientId: string,
  newProperties: Partial<MessageItemType>,
  messagesIds?: string[],
  messageFilterFn?: (item: MessageItemType) => boolean,
  messagesIndexes?: number[],
}> = (queryClient, options) => {
  setQueryDataMessageHistory(queryClient, (oldData) => {
    if (oldData?.pages?.length) {
      const oldDataPagesSpread = [...oldData.pages];

      if (options.messagesIndexes) {
        // Update message in 2d array with the index of message in the flat array.
        options.messagesIndexes?.forEach((index) => {
          let suitableChunkIndex = 0;
          let msgIndexInSuitableChunk = 0;

          for (let i = 0, chunksLength = 0; i < oldDataPagesSpread.length; i++) {
            msgIndexInSuitableChunk = index - chunksLength - 1;
            chunksLength += oldDataPagesSpread[i].length;

            if (chunksLength >= index) {
              suitableChunkIndex = i;
              break;
            }
          }

          oldDataPagesSpread[suitableChunkIndex][msgIndexInSuitableChunk] = {
            ...oldDataPagesSpread[suitableChunkIndex][msgIndexInSuitableChunk],
            ...options.newProperties,
          };
        });
        // ===============
      } else if (options.messagesIds) {
        const messagesIds = options.messagesIds;

        for (let i = 0; i < oldDataPagesSpread.length; i++) {
          for (let j = 0; j < oldDataPagesSpread[i].length; j++) {
            if (messagesIds.includes(oldDataPagesSpread[i][j].id)) {
              oldDataPagesSpread[i] = [...oldDataPagesSpread[i]];

              oldDataPagesSpread[i][j] = {
                ...oldDataPagesSpread[i][j],
                ...options.newProperties,
              };
            }
          }
        }
      } else if (options.messageFilterFn) {
        const messageFilterFn = options.messageFilterFn;

        for (let i = 0; i < oldDataPagesSpread.length; i++) {
          for (let j = 0; j < oldDataPagesSpread[i].length; j++) {
            if (messageFilterFn(oldDataPagesSpread[i][j])) {
              oldDataPagesSpread[i] = [...oldDataPagesSpread[i]];

              oldDataPagesSpread[i][j] = {
                ...oldDataPagesSpread[i][j],
                ...options.newProperties,
              };
            }
          }
        }
      } else {
        // eslint-disable-next-line no-console
        console.log(
          '%cYou forgot to specify {messagesIds} or {messagesIndexes} or {messageFilterFn}!',
          'background: red; color: white;'
        );
      }

      return {
        pages: oldDataPagesSpread,
        pageParams: oldData.pageParams,
      };
    }

    return oldData;
  }, {
    recipientId: options.recipientId,
  });
};

export const useSetMessageStatus = (config: {
  recipientId: string,
  recipientType: MessageFromEvangelistDestType,
  chatUnreadCount: number,
  seekersListStatus?: SeekersListStatus,
}) => {
  const queryClient = useQueryClient();
  const { userData, isUiRoleNotDM, isRoleAdmin } = useMainContext();

  const setMessageStatusMutation = useMutation(
    async (mutationPayload: {
      messagesIndexes?: number[],
      messageIds?: string[],
      status: SetMessageViewedRequestStatus
    }) => {
      return setMessageViewedRequest(
        userData,
        mutationPayload.status,
        mutationPayload.messageIds
          ? {
            messageId: mutationPayload.messageIds,
          }
          : {
            sourceId: config.recipientId,
            sourceType: config.recipientType,
          });
    },
    {
      onSuccess: (response, mutationPayload) => {
        // --- Update message(s) logic --- //
        const isNeedUpdateMessages = !!(
          mutationPayload.messagesIndexes?.length
          || mutationPayload.messageIds?.length
        );

        if (isNeedUpdateMessages) {
          updateMessageHistoryWithIdsOrIndexes(queryClient, {
            recipientId: config.recipientId,
            newProperties: {
              status: mutationPayload.status,
            },
            messagesIndexes: mutationPayload.messagesIndexes,
          });
        }

        // --- Update chat item logic --- //
        const messageCountFactorUnread = mutationPayload.messagesIndexes?.length
          || mutationPayload.messageIds?.length
          || config.chatUnreadCount;
        
        if (messageCountFactorUnread) {
          updateChatItem(queryClient, {
            filter: config.recipientId,
            recipientType: config.recipientType,
            isUiRoleNotDM,
            seekersListStatus: config.seekersListStatus,
            messagesCountsFactors: {
              unread: -(messageCountFactorUnread),
            },
            isRoleAdmin,
          });
        }
      },
    }
  );

  return {
    setMessageStatusMutation,
    isSetMessageViewedRequestLoading: setMessageStatusMutation.isLoading,
  };
};

const isMessageUngraded = (item: MessageItemType) => !item.msgScore;

export const useSetVetterMessageStatus = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { showToast } = useToastEnhanced();
  const { userData, isUiRoleNotDM, isRoleAdmin } = useMainContext();

  const setVetterMessageStatusMutation = useMutation(
    async (mutationPayload: {
      // is used to check if a message is a 'broadcast' message
      // and to decrease counters of ungraded messages for all chat items of this DM
      msgType?: MsgType,
      isUpdate: boolean, // is used to not update the chat item ungraded count on update grading
      recipientId: string, // is used to update the chat item ungraded count

      messageId: string | 'all',
      msgStatus: 'denied' | 'approved',
      msgNotes?: string,
      msgScore?: string,
      evangelistId?: string,
    }) => {
      return setVetterMessageStatusRequest(
        userData,
        mutationPayload.messageId,
        mutationPayload.msgStatus,
        mutationPayload.msgNotes,
        mutationPayload.msgScore,
        mutationPayload.evangelistId,
      );
    },
    {
      onSuccess: (response, mutationPayload) => {
        const isGradedAllDMMessages = mutationPayload.messageId === 'all';

        if (isGradedAllDMMessages) {
          // --- Update messages logic --- //
          updateMessageHistoryWithIdsOrIndexes(queryClient, {
            recipientId: mutationPayload.recipientId,
            messageFilterFn: isMessageUngraded,
            newProperties: {
              msgStatus: mutationPayload.msgStatus,
              msgNotes: mutationPayload.msgNotes,
              msgScore: mutationPayload.msgScore
            },
          });

          if (!mutationPayload.isUpdate) {
            // Re-fetch all chat items,
            // because it's unreal to implement when vetting all messages,
            // because some messages may be not fetched in the dialog
            // and there is no then possibility to update the ungraded count number appropriately
            updateChatItem(queryClient, {
              isForceInvalidate: true,
              filter: '',
              isRoleAdmin,
              isUiRoleNotDM,
              seekersListStatus: 'active',
              recipientType: SourceDestTypes.SEEKER,
            });
          }
        } else {
          // --- Update messages logic --- //
          updateMessageHistoryWithIdsOrIndexes(queryClient, {
            recipientId: mutationPayload.recipientId,
            newProperties: {
              msgStatus: mutationPayload.msgStatus,
              msgNotes: mutationPayload.msgNotes,
              msgScore: mutationPayload.msgScore
            },
            messagesIds: [mutationPayload.messageId],
          });

          if (!mutationPayload.isUpdate) {
            if (mutationPayload.msgType === 'broadcast') {
              updateChatItem(queryClient, {
                filter: (item) => {
                  // if chat item DM is the same as the graded DM owner of a broadcast message
                  if ('evangelistId' in item && item.evangelistId) {
                    return item.evangelistId.toString() === mutationPayload.evangelistId;
                  }
                  // =========================================================================

                  return false;
                },

                isRoleAdmin,
                isUiRoleNotDM,

                seekersListStatus: 'active',
                recipientType: SourceDestTypes.SEEKER,

                messagesCountsFactors: {
                  ungraded: -1,
                },
              });
            } else {
              updateChatItem(queryClient, {
                filter: mutationPayload.recipientId,

                isRoleAdmin,
                isUiRoleNotDM,

                seekersListStatus: 'active',
                recipientType: SourceDestTypes.SEEKER,

                messagesCountsFactors: {
                  ungraded: -1,
                },
              });
            }
          }
        }

        showToast({
          title: t('success'),
        });
      },
    }
  );

  return {
    setVetterMessageStatusMutation,
  };
};

export const useSendMessageFromEvangelist = (config: {
  recipientId: string,
  recipientType: MessageFromEvangelistDestType,
  seekersListStatus?: SeekersListStatus,
  onAddMessageToQueue?: () => void,
}) => {
  const queryClient = useQueryClient();
  const { userData, isUiRoleNotDM, isRoleAdmin } = useMainContext();

  return useSendMessageWithMessageQueue({
    seekerId: config.recipientId,
    seekerType: config.recipientType,
    onAddMessageToQueue: config.onAddMessageToQueue,
    onSendMessageSuccess: (sentMessageData, messagesCountsFactors) => {
      pushToMessageHistory(queryClient, {
        recipientId: config.recipientId,
        message: sentMessageData,
      });

      updateChatItem(queryClient, {
        isUiRoleNotDM,
        filter: config.recipientId,
        recipientType: config.recipientType,
        seekersListStatus: config.seekersListStatus,
        messagesCountsFactors,
        newProperties: {
          text: sentMessageData.text,
          date: sentMessageData.date,
        },
        isRoleAdmin,
      });
    },
    sendMessageFn: (queueMessage) => {
      if (isRoleAdmin) {
        return sendMessageFromAwr(userData, config.recipientId, queueMessage.message.text);
      }
      return sendMessageFromEvangelistRequest(userData, config.recipientId, {
        text: queueMessage.message.text,
        audio: queueMessage.message.audio,
        destType: config.recipientType,
        replyId: queueMessage.message.replyId || undefined,
        requestId: queueMessage.message.requestId || undefined,
        quoteId: queueMessage.message.quoteId || undefined,
        sourceSubType: config.recipientType === SourceDestTypes.EVANGELIST
          ? getUserSourceDestType(userData.role)
          : undefined,
      });
    },
  });
};

export const useReadMessages = (config: {
  recipientId: string,
  recipientType: MessageFromEvangelistDestType,
  unreadCount: number,
  seekersListStatus?: SeekersListStatus,
  messageHistoryFlat: MessageItemType[],
}) => {
  const { messageHistoryFlat } = config;
  const isFetchingRef = useRef(false);

  const { setMessageStatusMutation } = useSetMessageStatus({
    recipientId: config.recipientId,
    recipientType: config.recipientType,
    seekersListStatus: config.seekersListStatus,
    chatUnreadCount: config.unreadCount,
  });

  const {scrollView, messagesElements} = getMessageListElements();

  const { userData, isRoleAdmin } = useMainContext();

  const { showToast } = useToastEnhanced();

  // Finds unread messages ids those are visible in the view and reads them.
  const readMessagesInView = async () => {
    if (
      isFetchingRef.current || config.unreadCount <= 0 || !messageHistoryFlat.length
      || !scrollView || !messagesElements
    ) {
      return;
    }

    const mutationFn = setMessageStatusMutation.mutateAsync;
    const userId = userData.id;
    const viewportTop = scrollView.scrollTop;
    const viewportBottom = viewportTop + scrollView.offsetHeight;

    const someMessageInViewportIndex = findAnyChildIndexInViewport(scrollView, messagesElements);

    const unreadMessagesIndexes: number[] = [];

    const checkMessage = (index: number) => {
      if (getIsMessageCanBeRead(
        messageHistoryFlat[index],
        userId,
        config.recipientType,
        isRoleAdmin
      )) {
        unreadMessagesIndexes.push(index);
      }
    };

    if (someMessageInViewportIndex !== -1) {
      checkMessage(someMessageInViewportIndex);

      let indexToTop = someMessageInViewportIndex - 1;

      // Goes through the messages above first-found message
      // and adds to read list each that is in the viewport.
      while (
        messagesElements[indexToTop]
        && messageHistoryFlat[indexToTop]
        && viewportTop <= (messagesElements[indexToTop] as HTMLElement)?.offsetTop
      ) {
        checkMessage(indexToTop);
        indexToTop--;
      }

      let indexToBottom = someMessageInViewportIndex + 1;

      // Goes through the messages below first-found message
      // and adds to read list each that is in the viewport.
      while (
        messagesElements[indexToBottom]
        && messageHistoryFlat[indexToBottom]
        && viewportBottom >= (messagesElements[indexToBottom] as HTMLElement)?.offsetTop
        + (messagesElements[indexToBottom] as HTMLElement)?.offsetHeight
      ) {
        checkMessage(indexToBottom);
        indexToBottom++;
      }

      if (unreadMessagesIndexes.length) {
        isFetchingRef.current = true;
        await mutationFn({
          messagesIndexes: unreadMessagesIndexes,
          messageIds: unreadMessagesIndexes?.map((index) => messageHistoryFlat[index].id),
          status: 'viewed',
        }).finally(() => {
          isFetchingRef.current = false;
        });
      }
    }
  };

  const readAllMessages = async () => {
    const mutationFn = setMessageStatusMutation.mutateAsync;

    isFetchingRef.current = true;
    await mutationFn({
      status: 'viewed',
    }).catch((error: RequestBaseResponse) => {
      showToast({ title: error.message }, { type: 'error' });
    }).finally(() => {
      isFetchingRef.current = false;
    });
  };

  return {
    readMessagesInView,
    readAllMessages,
  };
};

export const updateChatItem = (
  queryClient: QueryClient, {
    isForceInvalidate,

    filter,

    isUiRoleNotDM,
    isRoleAdmin,

    recipientType,
    seekersListStatus,

    messagesCountsFactors,
    newProperties,
  }: {
    isForceInvalidate?: boolean

    filter: ItemFilter<
      SeekerItemType | AdminEvangelistItem | ChatItemLeaderCMType
      | EvangelistItemType | ChatItemCampaignType | AdminSeekerItem
    >

    isRoleAdmin?: boolean,
    isUiRoleNotDM: boolean,

    recipientType: SourceDestType,
    seekersListStatus?: SeekersListStatus,

    newProperties?: {
      date: string,
      text: string,
    },
    messagesCountsFactors?: Partial<MessageFactors>,
  }) => {
  let status: UpdateQueryFnStatus = '';
  let updateFn, invalidateFn;

  // AWR also as it is a part of seekers list.
  if (!isRoleAdmin
    && (recipientType === SourceDestTypes.SEEKER || recipientType === SourceDestTypes.AWR)) {
    if (seekersListStatus) {
      updateFn = () => updateSeekerListItem(queryClient, {
        seekerListStatus: seekersListStatus,
        isUiRoleLeader: isUiRoleNotDM,
        filter,
        newProperties: newProperties,
        messagesCountsFactors
      });

      invalidateFn = () => {
        queryClient.invalidateQueries(makeQueryKeySeekers(seekersListStatus, isUiRoleNotDM));
      };
    } else {
      // eslint-disable-next-line no-console
      console.log(
        '%cYou forgot to specify {seekerListStatus} for {seekerType = "s"}!',
        'background: red; color: white;'
      );
    }
  } else if (isRoleAdmin &&
    (recipientType === SourceDestTypes.AWR || recipientType === SourceDestTypes.EVANGELIST)) {
    updateFn = () => updateAdminChatListEvangelistItem(queryClient, {
      filter,
      messagesCountsFactors,
      newProperties
    });

    invalidateFn = () => {
      queryClient.invalidateQueries(QUERY_KEY_QUICK_EVANGELISTS);
    };
  } else if (
    recipientType === SourceDestTypes.LEADER
    || recipientType === SourceDestTypes.EVANGELIST && !isUiRoleNotDM
  ) {
    updateFn = () => updateChatListLeadersItem(queryClient, {
      filter,
      messagesCountsFactors,
      newProperties
    });

    invalidateFn = () => {
      queryClient.invalidateQueries(QUERY_KEY_CHAT_LIST_LEADERS);
    };
  } else if (recipientType === SourceDestTypes.EVANGELIST) {
    updateFn = () => updateChatListEvangelistItem(queryClient, {
      filter,
      messagesCountsFactors,
      newProperties
    });

    invalidateFn = () => {
      queryClient.invalidateQueries(QUERY_KEY_CHAT_LIST_EVANGELISTS);
    };
  } else if (recipientType === SourceDestTypes.CAMPAIGN) {
    updateFn = () => updateChatListCampaignsItem(queryClient, {
      filter,
      messagesCountsFactors,
      newProperties
    });

    invalidateFn = () => {
      queryClient.invalidateQueries(QUERY_KEY_CHAT_LIST_CAMPAIGNS);
    };
  } else if (isRoleAdmin && recipientType === SourceDestTypes.SEEKER) {
    updateFn = () => updateAllSeekerListItem(queryClient, {
      filter,
      newProperties: newProperties,
      messagesCountsFactors
    });

    invalidateFn = () => {
      queryClient.invalidateQueries(QUERY_KEY_ALL_SEEKERS);
    };
  }

  if (!isForceInvalidate && updateFn) {
    status = updateFn().status;
  }

  if ((status !== 'success' || isForceInvalidate) && invalidateFn) {
    invalidateFn();
  }

  return { status };
};
