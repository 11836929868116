import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EmailShareButton,
  FacebookMessengerShareButton,
  FacebookShareButton,
  InstapaperShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TelegramShareButton,
  TwitterShareButton,
  ViberShareButton,
  WhatsappShareButton,
} from 'react-share';

import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  InstagramIcon,
  LinkedinIcon,
  PinterestIcon,
  TelegramIcon,
  TwitterIcon,
  ViberIcon,
  WhatsappIcon
} from '../../assets/ShareIcons';
import AeFontIcons from '../../assets/Icons';
import { Button, FontIconView, Input, TextArea } from '../index';
import { useModalContext } from './ModalProvider';
import Row from '../row/Row';
import {useCopy} from '../../hooks/useCopy';
import { renderField } from '../custom-field/customField';

import './ModalShare.scss';

interface SharePopupProps {
  url: string;
  title?: string;
  showPreviewTitle?: boolean;
}

const shareItemsIdsThatCantShareTitle = ['facebook'];

const ModalShare: React.FC<SharePopupProps> = ({
  url,
  title = '',
  showPreviewTitle,
}) => {
  const { t } = useTranslation();
  const { closeModal } = useModalContext();
  const { copy } = useCopy();
  const [titleUserModified, setTitleUserModified] = useState(title);

  const shareIcons = [
    {
      id: 'facebook',
      component: FacebookShareButton,
      icon: FacebookIcon
    },
    {
      id: 'facebook-messenger',
      component: FacebookMessengerShareButton,
      icon: FacebookMessengerIcon
    },
    {
      id: 'twitter',
      component: TwitterShareButton,
      icon: TwitterIcon
    },
    {
      id: 'telegram',
      component: TelegramShareButton,
      icon: TelegramIcon
    },
    {
      id: 'whatsapp',
      component: WhatsappShareButton,
      icon: WhatsappIcon
    },
    {
      id: 'linkedin',
      component: LinkedinShareButton,
      icon: LinkedinIcon
    },
    {
      id: 'pinterest',
      component: PinterestShareButton,
      icon: PinterestIcon
    },
    {
      id: 'viber',
      component: ViberShareButton,
      icon: ViberIcon
    },
    {
      id: 'instagram',
      component: InstapaperShareButton,
      icon: InstagramIcon
    },
    {
      id: 'email',
      component: EmailShareButton,
      icon: EmailIcon
    }
  ];

  const copyHandler = async () => {
    await copy(url, t('copiedLink'));
  };

  return (
    <Row gap='lg'>
      <div className="share__icons">
        {shareIcons.map((item) => {
          const {component: ShareButton, icon: ShareIcon, id} = item;

          const isCanNotShareTitle = shareItemsIdsThatCantShareTitle.includes(id);

          return (
            <ShareButton
              key={item.id}
              url={url}
              quote={isCanNotShareTitle ? titleUserModified : undefined}
              title={isCanNotShareTitle ? '' : titleUserModified}
              separator=":: "
              media={url}
            >
              <ShareIcon />
            </ShareButton>
          );
        })}
      </div>
      {
        showPreviewTitle && (
          renderField(TextArea, {
            textareaHeight: 'sm',
            hasErrorGap: false,
            labelText: t('textPreview'),
            value: titleUserModified,
            onChange: (e, value) => {
              setTitleUserModified(value);
            },
          })
        )
      }
      {
        renderField(Input, {
          labelText: t('copyLink'),
          readOnly: true,
          hasErrorGap: false,
          value: url,
          slotAfter: (
            <Button color='gray' variant='transparent' onClick={copyHandler}>
              &nbsp;<FontIconView icon={AeFontIcons.copy} />
            </Button>
          ),
        })
      }
      <Row gap='lg' directionHorizontal autoColumns horizontal="center">
        <Button color='gray' variant='opacity' style={{ minWidth: '6.7rem' }} onClick={closeModal}>
          {t('cancel')}
        </Button>
      </Row>
    </Row>
  );
};

export const useModalShare = () => {
  const { t } = useTranslation();
  const { openModal } = useModalContext();

  const openModalShare = (url: string, title?: string, showPreviewTitle?: boolean) => {
    openModal(<ModalShare url={url} title={title} showPreviewTitle={showPreviewTitle} />, {
      title: t('share'),
      titleAlign: 'center',
      widthSize: 'sm',
    });
  };

  return {
    openModalShare,
  };
};
