import {
  AdminSeekerItem,
  AdminSeekerItemFromApi,
  SeekerChatItem,
  SeekerChatItemApi,
  SeekerItemType,
  SeekerItemTypeApi,
} from './seekers.types';

const normilizeSeekerChatItem = (item: SeekerChatItemApi, totalNumber?: number): SeekerChatItem => {
  const { alreadyBaptized, church, messageCounts, date, text, sourceType, requestCounts } = item;
  const normalizedItem: SeekerChatItem = {
    alreadyBaptized: alreadyBaptized === '1',
    church,
    date: date || '',
    text: text || '',
    sourceType: sourceType || null,
    unread: Number(messageCounts.unread),
    outgoing: Number(messageCounts.outgoing),
    incoming: Number(messageCounts.incoming),
    ungraded: Number(messageCounts.ungraded),
    open: Number(requestCounts?.open) || 0,
    closed: Number(requestCounts?.closed) || 0,
    waiting: Number(requestCounts?.waiting) || 0,
    study: Number(requestCounts?.study) || 0,
    prayer: Number(requestCounts?.prayer) || 0,
    question: Number(requestCounts?.question) || 0,
  };
  if (totalNumber !== undefined) {
    normalizedItem.totalCount = totalNumber;
  }
  return normalizedItem;
};

export const normalizeSeekerItem = (item: SeekerItemTypeApi): SeekerItemType => {
  return {
    ...normilizeSeekerChatItem(item),
    id: item.id,
    evangelistId: String(item.evangelistId),
    status: item.status,
    chatStatus: item.chatStatus,
    firstname: item.firstname,
    lastname: item.lastname,
    language: item.language,
    country: item.country,
    city: item.city,
    locationCode: item.locationCode,
  };
};

export const normalizeSeekerAdminItem = (
  item: AdminSeekerItemFromApi,
  totalNumber?: number
): AdminSeekerItem => {
  return {
    ...item,
    id: item.seekerId,
    ...normilizeSeekerChatItem(item, totalNumber)
  };
};
