import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Typography } from '../index';

import './BannerWelcome.scss';

const BannerWelcome: React.FC<React.HTMLProps<HTMLDivElement>> = ({ className, children }) => {
  const { t } = useTranslation();

  return (
    <div className={classnames('banner-welcome', className)} style={{
      backgroundImage: 'url("/images/bg-banner.png")',
    }}>
      <div className='greeting'>
        <img src='/images/logo_app.svg' className='logo' alt='Logo' />
        <Typography component='h1' color='white'>
          {t('welcomeTo')} <br /><b>{t('digitalMissionary')}</b>
        </Typography>
        <Typography component='h3' color='white' style={{ fontWeight: 'normal' }} tagName='p'>
          {t('connectWithThose')} {t('lookingForJesus')}
        </Typography>
        {children}
      </div>
      <div className='image-planet'>
        <img src='/images/logo_planet.png' alt="background" />
      </div>
    </div>
  );
};

export default BannerWelcome;
