import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';

import {Button, ChatInput, FontIconView, Loader, Row} from '../index';
import MessageList from './message-list/MessageList';
import {
  useGetMessageHistory,
  useReadMessages,
  useSendMessageFromEvangelist,
} from '../../query/chat';
import {
  getMessageAppearanceIfSpecificMessage,
  getIsMessageBelongsToChat,
  getIsMessageIncoming,
  getIsMessageReminder,
  getMessageTextClear,
  getIsMessageNotification,
} from '../../utils/chat';
import MessageItem, {MessageItemProps} from './message-list/MessageItem';
import {
  MessageFromEvangelistDestType,
  MessageItemType,
  SourceDestType,
  SourceDestTypes,
} from '../../api/chat.types';
import {useMainContext} from '../../MainProvider';
import {Tooltip} from '../tooltip/Tooltip';
import {ColorScheme} from '../../types';
import {getMessageHistoryRequest} from '../../api/chat';
import {useNotificationContext} from '../../PushNotificationsProvider';
import AeFontIcons from '../../assets/Icons';
import {useCopy} from '../../hooks/useCopy';
import {getRoleTranslationString, makeLabelFullName} from '../../utils/common';
import {getIsDocumentHidden} from '../../utils/user-agent';
import ChatView from './ChatView';
import { useModalMessageReview } from './ModalMessageReview';
import { Role } from '../../utils/enums';
import { useReplyQuoteMessages } from '../../hooks/useReplyQuoteMessages';
import { SeekersListStatus } from '../../api/seekers.types';
import { useGetRequestInfo } from './useGetRequestInfo';
import { sortByDate } from '../../utils/sorts';
import { useToastEnhanced } from '../../enhanced-components/toaster/ToasterEnhanced';
import { GradeStarsViewWithAPI } from './GradeStarsViewWithAPI';

import styles from './BaseChatView.module.scss';

const OFFSET_CAN_AUTOSCROLL_TO_BOTTOM = 50;
const OFFSET_LOAD_NEW_CHUNK = 16;

const TIMEOUT_DOCUMENT_VISIBLE_FOCUS = 5_000;
const TIMEOUT_DOCUMENT_VISIBLE_NO_FOCUS = 10_000;

const TIMEOUT_DOCUMENT_HIDDEN_DEFAULT = 20_000;
const TIMEOUT_DOCUMENT_HIDDEN_FACTOR = 1_000;
const TIMEOUT_DOCUMENT_HIDDEN_MAX = 90_000;

const TIMEOUT_SCROLL_TO_UNGRADED = 500;

export type BaseChatViewAdditionalData = {
  isIncoming: boolean
  messageAppearance: MessageItemProps['messageAppearance']
  iconClassName: string
};

type BaseChatViewRenderMessageActionsFn = (
  item: MessageItemType, additionalData: BaseChatViewAdditionalData
) => ReactNode;

interface BaseChatViewProps {
  isCompact?: boolean,

  showLoader?: boolean,
  canReply: boolean;
  canSendMessages: boolean;
  canReviewOutgoing?: boolean;
  canSendAttachments: boolean;

  messageColor: 'blue' | 'violet' | 'default';
  chatColor: ColorScheme;
  messageActionsColor: ColorScheme;

  recipientId: string;
  recipientType: MessageFromEvangelistDestType;
  evangelistId: string;
  seekersListStatus?: SeekersListStatus;
  recipientFullName?: string;

  scrollMessageId?: string;
  chatMessagesCountTotal: number;
  chatMessagesCountUnread: number;
  chatMessagesCountUngraded?: number;

  getMessageAuthorFn?: (memberId: string, memberType: SourceDestType) => {
    firstname: string | null,
    lastname: string | null,
    role?: Role,
  } | undefined;

  openFloatChatFn?: () => void;
  onClickChatViewButtonEdit?: () => void;

  renderMessageActions?: BaseChatViewRenderMessageActionsFn;
  renderSlotBeforeMessages?: (messageHistoryFlat: MessageItemType[]) => ReactNode;

  slotChatViewActions?: ReactNode
}

const makeReplyQuoteMessagesIds = (messageHistory: MessageItemType[]) => {
  const messagesIds = [];

  let messageItem;
  for (let i = 0; i < messageHistory.length; i++) {
    messageItem = messageHistory[i];

    const replyQuoteId = messageItem.quoteId || messageItem.replyId;
    if (replyQuoteId) {
      messagesIds.push(replyQuoteId);
    }
  }

  return messagesIds;
};

const BaseChatView: React.FC<BaseChatViewProps> = (props) => {
  const {
    getMessageAuthorFn,
    messageColor, canReply,
    recipientId, recipientType,
    scrollMessageId, evangelistId,
    messageActionsColor, canReviewOutgoing,
    canSendMessages, chatMessagesCountUngraded
  } = props;

  const checkForUpdatesTimerIdRef = useRef(-1);
  const prevClientHeightRef = useRef<number | undefined>();
  const prevRecipientIdRef = useRef<string | undefined>();

  const [messagesListScrollbars, setMessagesListScrollbars] = useState<any | null>(null);

  const { t } = useTranslation();
  const { userData, uiRole, isUiRoleNotDM, isRoleAdmin } = useMainContext();
  const userId = userData.id;
  const { isAppReceivesNotifications } = useNotificationContext();
  const openModalMessageReview = useModalMessageReview();
  const { showToast } = useToastEnhanced();
  const { copy } = useCopy();

  const isVetterWatchesChat = (isRoleAdmin || isUiRoleNotDM)
    && recipientType === SourceDestTypes.SEEKER;

  const {
    fetchIfNeededReplyQuoteMessagesByIds,
    getReplyQuoteMessageById,
  } = useReplyQuoteMessages();

  const fetchReplyQuoteMessages = async (messageHistory: MessageItemType[]) => {
    await fetchIfNeededReplyQuoteMessagesByIds(
      makeReplyQuoteMessagesIds(messageHistory),
      recipientId,
    );
  };

  const {
    messageHistoryFlat,
    getMessageById,

    fetchNextPageMessageHistory,
    fetchPreviousPageMessageHistory,

    isFetchingMessageHistoryPreviousPage,
    isFetchingMessageHistoryNextPage,
    isMessageHistoryLoading,
    isMessageHistoryError,
    isMessageHistoryFetched,

    hasPreviousMessageHistoryPage,
    hasNextMessageHistoryPage,
  } = useGetMessageHistory(recipientId, evangelistId, recipientType, {
    searchParams: {
      limit: 30,
      inverted: scrollMessageId ? undefined : true,
      offsetId: scrollMessageId || undefined,
    },
    chatSize: props.chatMessagesCountTotal,
  }, fetchReplyQuoteMessages);

  const getRequestInfo = useGetRequestInfo(messageHistoryFlat);

  const handleAddMessageToQueue = useCallback(() => {
    // {setTimeout} logic, but works fine,
    // Is used to scroll message view to the bottom
    // after a new queue message is added (IOW after the user has submitted the chat form).
    setTimeout(() => {
      if (messagesListScrollbars?.scrollToBottom) {
        messagesListScrollbars.scrollToBottom();
      }
    });
  }, [messagesListScrollbars]);

  const {
    sendMessage,
    resendMessage,
    messagesQueue,
  } = useSendMessageFromEvangelist({
    recipientId: recipientId,
    recipientType: recipientType,
    seekersListStatus: props.seekersListStatus,
    onAddMessageToQueue: handleAddMessageToQueue,
  });
  const { readMessagesInView, readAllMessages } = useReadMessages({
    recipientId,
    recipientType: recipientType,
    seekersListStatus: props.seekersListStatus,
    unreadCount: props.chatMessagesCountUnread,
    messageHistoryFlat,
  });

  const isLoading = (isMessageHistoryError ? false : isMessageHistoryLoading) || props.showLoader;

  const scrollToMessageById = useCallback((id: string) => {
    const containerElement = messagesListScrollbars?.container;
    if (containerElement) {
      const replyMessageItem = containerElement.querySelector('[data-id="' + id + '"]');
      if (replyMessageItem) {
        messagesListScrollbars.scrollTop(replyMessageItem.offsetTop);
      }
    }
  }, [messagesListScrollbars]);

  //This UE is aimed for chat, watched be vetter. It looks for earliest available
  //ungraded messages and scrolls up to it on chat opening.
  useEffect(() => {
    //If ungraded count was not passed or it equal to 0, UE is skipped
    if (chatMessagesCountUngraded === undefined || chatMessagesCountUngraded === 0) {
      return;
    }
    const prevRecipientId = prevRecipientIdRef.current;
    if (
      !messagesListScrollbars?.container ||
      messageHistoryFlat.length === 0 ||
      prevRecipientId === recipientId
    ) {
      return;
    }
    if (!isMessageHistoryLoading && isVetterWatchesChat) {
      const unscoredMessages = messageHistoryFlat.filter(
        (item) => !item.msgScore && item.direction === 'to'
      );
      if (unscoredMessages.length > 0) {
        sortByDate(unscoredMessages, false, (item) => item.date);
        prevRecipientIdRef.current = recipientId;
        setTimeout(() => scrollToMessageById(unscoredMessages[0].id), TIMEOUT_SCROLL_TO_UNGRADED);
      } else {
        fetchPreviousPageMessageHistory();
      }
    }
  }, [
    isMessageHistoryLoading,
    isVetterWatchesChat,
    messageHistoryFlat,
    fetchPreviousPageMessageHistory,
    scrollToMessageById,
    recipientId,
    messagesListScrollbars,
    chatMessagesCountUngraded
  ]);

  const scrollToBottomIfNowAtTheBottom = useCallback(() => {
    if (messagesListScrollbars?.container) {
      const {clientHeight, scrollTop, scrollHeight,} = messagesListScrollbars.container.children[0];

      if ((scrollHeight - clientHeight) - scrollTop < OFFSET_CAN_AUTOSCROLL_TO_BOTTOM) {
        setTimeout(() => {
          if (messagesListScrollbars?.scrollToBottom) {
            messagesListScrollbars.scrollToBottom();
          }
        });
      }
    }
  }, [messagesListScrollbars]);

  const [replyMessage, setReplyMessage] = useState<MessageItemType | undefined>(undefined);

  const handleClickReply = useCallback((message: MessageItemType) => {
    setReplyMessage(message);
  }, []);

  const handleCloseReplyMessage = useCallback(() => {
    setReplyMessage(undefined);
  }, []);

  const handleClickRepliedMessage = useCallback(({ replyId }: MessageItemType) => {
    if (replyId) {
      scrollToMessageById(replyId);
    }
  }, [scrollToMessageById]);

  const handleSubmitChatInput = useCallback((submitData) => {
    sendMessage({
      ...submitData,
      additionalProperties: {},
    });

    // Immediately resolve to clear input right after the submit.
    return Promise.resolve();
  }, [sendMessage]);

  // Only if app can't receive push-notifications or
  // current chat doesn't belong to the user (so he doesn't receive pushes related to this chat)!
  // Checks for potentially missing new messages chunk.
  useEffect(() => {
    let useEffectUnMounted = false;

    const checkForUpdates = async () => {
      await getMessageHistoryRequest({
        agentToken: userData.agentToken,
        id: userData.id,
      }, {
        evangelistId,
        seekerId: recipientId,
        seekerType: recipientType,
        role: uiRole,
      }, {
        offset: messageHistoryFlat[messageHistoryFlat.length - 1]?.rowIndex || 0,
      }).then(async (response) => {
        if (response.length > 1 && getIsMessageBelongsToChat(response[0], recipientId)) {
          const newMessagesSlice = response.slice(1);

          await fetchReplyQuoteMessages(newMessagesSlice);

          for (let i = 0; i < newMessagesSlice.length; i++) {
            // It is listened in the {useNoPushNotifications.ts}
            // to update message history and chat item.
            window.dispatchEvent(new CustomEvent('fetchedNewMessageWithAutoRefresh', {
              detail: newMessagesSlice[i],
            }));
          }
        }
      }).catch((e) => {
        showToast({
          title: t(e.message)
        }, {
          type: 'error'
        });
      });
    };

    // Check for updates with dynamic {timeout}.
    let timeoutDocumentHidden = TIMEOUT_DOCUMENT_HIDDEN_DEFAULT;
    const setTimeoutCheckForUpdates = () => {
      let timeout = TIMEOUT_DOCUMENT_VISIBLE_NO_FOCUS;

      if (getIsDocumentHidden()) {
        timeout = timeoutDocumentHidden;

        if (timeoutDocumentHidden < TIMEOUT_DOCUMENT_HIDDEN_MAX) {
          timeoutDocumentHidden += TIMEOUT_DOCUMENT_HIDDEN_FACTOR;
        }
      } else if (document.hasFocus()) {
        timeout = TIMEOUT_DOCUMENT_VISIBLE_FOCUS;
      }

      checkForUpdatesTimerIdRef.current = window.setTimeout(async () => {
        await checkForUpdates();

        setTimeoutCheckForUpdates();
      }, timeout);
    };
    // ---------------------------------------

    const isChatNotBelongsToUser = isUiRoleNotDM
      && recipientType === SourceDestTypes.SEEKER && evangelistId;

    if (
      (isChatNotBelongsToUser || !isAppReceivesNotifications)
      && isMessageHistoryFetched
    ) {
      checkForUpdates().then(() => {
        if (!useEffectUnMounted) {
          setTimeoutCheckForUpdates();
        }
      });
    }

    return () => {
      useEffectUnMounted = true;
      clearTimeout(checkForUpdatesTimerIdRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAppReceivesNotifications, recipientId, recipientType, isMessageHistoryFetched,
    evangelistId, isUiRoleNotDM, userData.id, userData.agentToken, messageHistoryFlat, uiRole
  ]);

  // Initial scroll for the dialog.
  // Either to the bottom or to the specific msg from the {location}.
  useEffect(() => {
    if (!isLoading) {
      if (scrollMessageId) {
        scrollToMessageById(scrollMessageId);
      } else if (messagesListScrollbars?.scrollToBottom) {
        messagesListScrollbars.scrollToBottom();
      }
    }
  }, [
    messagesListScrollbars,
    scrollToMessageById,
    scrollMessageId,
    isLoading,
    recipientId,
  ]);

  // Read all messages on chat open.
  useEffect(() => {
    if (!canSendMessages || isLoading || props.chatMessagesCountUnread <= 0) {
      return;
    }

    readAllMessages();
    // Dependency is missed deliberately. Probably is bad logic.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, canSendMessages]);

  // Checks for potential autoscroll when adding to the message history.
  useEffect(() => {
    const handlePushToMessageHistory = (e: CustomEvent<MessageItemType>) => {
      const message = e.detail;

      if (getIsMessageBelongsToChat(message, recipientId)) {
        scrollToBottomIfNowAtTheBottom();
      }
    };

    window.addEventListener('pushToMessageHistory', handlePushToMessageHistory);

    return () => {
      window.removeEventListener('pushToMessageHistory', handlePushToMessageHistory);
    };
  }, [scrollToBottomIfNowAtTheBottom, recipientId]);

  const loadMessagesChunkIfScrollEdge = useCallback(() => {
    if (messagesListScrollbars?.container) {
      const {
        clientHeight,
        scrollTop,
        scrollHeight,
      } = messagesListScrollbars.container.children[0];

      if (
        hasPreviousMessageHistoryPage
        && scrollTop < OFFSET_LOAD_NEW_CHUNK
        && !isFetchingMessageHistoryPreviousPage
      ) {
        fetchPreviousPageMessageHistory();
      } else if (
        hasNextMessageHistoryPage
        && scrollTop > scrollHeight - clientHeight - OFFSET_LOAD_NEW_CHUNK
        && !isFetchingMessageHistoryNextPage
      ) {
        fetchNextPageMessageHistory();
      }
    }
  }, [
    messagesListScrollbars,
    isFetchingMessageHistoryPreviousPage,
    isFetchingMessageHistoryNextPage,
    hasPreviousMessageHistoryPage,
    hasNextMessageHistoryPage,
    fetchPreviousPageMessageHistory,
    fetchNextPageMessageHistory,
  ]);

  useEffect(() => {
    loadMessagesChunkIfScrollEdge();
  }, [loadMessagesChunkIfScrollEdge]);

  const renderMessage = useCallback((
    message: MessageItemType, renderMessageActions: BaseChatViewRenderMessageActionsFn,
  ) => {
    const {id, requestId, textClearUrlified, replyId, quoteId} = message;

    const isReminder = getIsMessageReminder(message);
    const isNotification = getIsMessageNotification(message);
    const isIncoming = getIsMessageIncoming(message, userId, recipientType, isRoleAdmin);

    const messageAuthor = getMessageAuthorFn
      ? getMessageAuthorFn(message.sourceId, message.sourceType)
      : undefined;

    const messageRequestInfo = (requestId && isIncoming && getRequestInfo)
      ? getRequestInfo(requestId)
      : undefined;

    const replyMessage = replyId
      ? (getMessageById(replyId) || getReplyQuoteMessageById(replyId))
      : undefined;
    const quoteMessage = quoteId
      ? (getMessageById(quoteId) || getReplyQuoteMessageById(quoteId))
      : undefined;
    const replyOrQuoteMessage = (replyMessage || quoteMessage);

    const replyOrQuoteMessageRequestInfo = (replyOrQuoteMessage?.requestId && getRequestInfo)
      ? getRequestInfo(replyOrQuoteMessage?.requestId)
      : undefined;
    const replyOrQuoteMessageAuthor = (getMessageAuthorFn && replyOrQuoteMessage)
      ? getMessageAuthorFn(replyOrQuoteMessage.sourceId, replyOrQuoteMessage.sourceType)
      : undefined;

    const messageType = getMessageAppearanceIfSpecificMessage(
      isIncoming, isReminder, isNotification, messageRequestInfo?.isClosed,
    ) || messageColor;

    const isReviewMessage = !isReminder && !isIncoming && canReviewOutgoing;

    const baseMessageActionsContent = (
      <Row
        directionHorizontal
        autoColumns
        layout="flex"
        className={styles['actions']}
        vertical="start">
        {textClearUrlified && (
          <Tooltip content={t('copy')}>
            <Button
              variant="transparent"
              size="extra-sm"
              color={messageActionsColor}
              onClick={() => {
                copy(getMessageTextClear(message.text));
              }}>
              <FontIconView icon={AeFontIcons.copy} />
            </Button>
          </Tooltip>
        )}
        {isReviewMessage && (
          <Tooltip content={t('reviewMessage')}>
            <Button
              variant="transparent"
              size="extra-sm"
              color="blue"
              onClick={() => {
                openModalMessageReview(message, recipientId, evangelistId);
              }}>
              <FontIconView icon={AeFontIcons.star} className={styles['action-icon']} />
            </Button>
          </Tooltip>
        )}
        {renderMessageActions(message, {
          isIncoming,
          messageAppearance: messageType,
          iconClassName: styles['action-icon'],
        })}
      </Row>
    );

    let messageActionsContent = baseMessageActionsContent;
    if (isReviewMessage) {
      messageActionsContent = (
        <Row className={styles['actions']}>
          {baseMessageActionsContent}
          <GradeStarsViewWithAPI
            evangelistId={evangelistId}
            msgType={message.msgType}
            score={Number(message.msgScore)}
            recipientId={message.destId}
            messageId={message.id}
          />
        </Row>
      );
    }

    return (
      <Row
        dataAttributes={{
          'data-id': id
        }}
        key={id}
        className={classNames(
          styles['message'],
          isIncoming && !isReminder ? styles['in'] : styles['out']
        )}
        directionHorizontal
        gap="sm">
        <MessageItem
          id={id}
          incoming={isIncoming}
          canReviewOutgoing={canReviewOutgoing}
          messageAppearance={messageType}
          name={messageAuthor && makeLabelFullName(messageAuthor)}
          roleLabel={messageAuthor?.role && t(getRoleTranslationString(messageAuthor.role))}
          text={message.text}
          textClearUrlified={textClearUrlified}
          audio={message.audio}
          date={message.date}
          sendStatus={message.status}
          messageScore={message.msgScore}
          messageReviewStatus={message.msgStatus}
          requestCategoryName={
            messageRequestInfo?.categoryName ? t(messageRequestInfo.categoryName) : ''
          }
          isReplyMessageQuote={!!quoteMessage}
          replyName={replyOrQuoteMessageAuthor && makeLabelFullName(replyOrQuoteMessageAuthor)}
          replyRoleLabel={
            replyOrQuoteMessageAuthor?.role &&
            t(getRoleTranslationString(replyOrQuoteMessageAuthor.role))
          }
          replyText={replyOrQuoteMessage?.text}
          replyTextClearUrlified={replyOrQuoteMessage?.textClearUrlified}
          replyAudio={replyOrQuoteMessage?.audio || ''}
          replyRequestCategoryName={
            replyOrQuoteMessageRequestInfo?.categoryName
              ? t(replyOrQuoteMessageRequestInfo.categoryName)
              : ''
          }
          isReplyRequestClosed={
            replyOrQuoteMessageRequestInfo ? replyOrQuoteMessageRequestInfo.isClosed : false
          }
          onClickRepliedMessage={() => {
            handleClickRepliedMessage(message);
          }}
          onClickReview={() => {
            openModalMessageReview(message, recipientId, evangelistId);
          }}
        />
        {messageActionsContent}
      </Row>
    );
  }, [
    t,
    evangelistId,
    userId,
    recipientId,
    recipientType,
    isRoleAdmin,
    messageColor,
    getReplyQuoteMessageById,
    getMessageById,
    getMessageAuthorFn,
    getRequestInfo,
    openModalMessageReview,
    handleClickRepliedMessage,
    copy,
    messageActionsColor,
    canReviewOutgoing,
  ]);

  const messagesHistoryElements = useMemo(() => {
    const renderMessageActions = props.renderMessageActions;
    const messageActionsColor = props.messageActionsColor;

    return messageHistoryFlat.map((message) => {
      return renderMessage(message, (item, additionalData) => {
        const isReminder = additionalData.messageAppearance === 'reminder';

        return (
          <>
            {
              (canSendMessages && canReply && additionalData.isIncoming && !isReminder) && (
                <Tooltip content={t('reply')}>
                  <Button
                    variant='transparent'
                    size='extra-sm'
                    color={messageActionsColor}
                    onClick={() => {
                      handleClickReply(message);
                    }}
                  >
                    <FontIconView className={styles['action-icon']} icon={AeFontIcons.reply} />
                  </Button>
                </Tooltip>
              )
            }
            {renderMessageActions && renderMessageActions(message, additionalData)}
          </>
        );
      });
    });
  }, [
    t,
    props.renderMessageActions,
    props.messageActionsColor,
    canSendMessages,
    canReply,
    renderMessage,
    messageHistoryFlat,
    handleClickReply,
  ]);

  const isShowMicIcon = useMemo(() => {
    if (recipientType !== SourceDestTypes.SEEKER) {
      return true;
    }
    if (messageHistoryFlat.length > 0) {
      return messageHistoryFlat[0].audioSupported;
    }
    return false;
  }, [recipientType, messageHistoryFlat]);

  //This UE is needed to prevent endless chat load when making scroll up to previous chunk
  useEffect(() => {
    if (messagesListScrollbars?.container && prevClientHeightRef?.current) {
      const { scrollHeight } = messagesListScrollbars?.getValues() || {};
      const { scrollTop } = messagesListScrollbars.container.children[0];
      if (scrollTop < OFFSET_LOAD_NEW_CHUNK) {
        messagesListScrollbars?.scrollTop(scrollHeight - prevClientHeightRef?.current);
      }
    }
  }, [messagesHistoryElements.length, messagesListScrollbars]);

  const queueMessagesElements = useMemo(() => {
    if (messagesQueue){
      return messagesQueue.map((queueMessage) => {
        return renderMessage(queueMessage.message, (item, additionalData) => {
          if (item.status === 'errorSend' && !additionalData.isIncoming) {
            return (
              <Tooltip content={t('repeat')}>
                <Button
                  variant='transparent'
                  size='extra-sm'
                  color='blue'
                  onClick={() => {
                    resendMessage(item);
                  }}
                >
                  <FontIconView className={styles['action-icon']} icon={AeFontIcons.reply} />
                </Button>
              </Tooltip>
            );
          }

          return null;
        });
      });
    }

    return null;
  }, [
    t,
    renderMessage,
    resendMessage,
    messagesQueue,
  ]);

  return (
    <ChatView
      className={classNames(styles['root'], {
        [styles['compact']]: props.isCompact,
      })}
      slotActions={props.slotChatViewActions}
      isCompact={props.isCompact}
      handleCollapse={props.openFloatChatFn}
      handleEdit={props.onClickChatViewButtonEdit}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <MessageList
            ref={(instance) => {
              setMessagesListScrollbars(instance);
            }}
            unreadCount={props.chatMessagesCountUnread}
            isVetterWatchesChat={isVetterWatchesChat}
            onScrollStop={() => {
              if (canSendMessages) {
                readMessagesInView();
              }

              loadMessagesChunkIfScrollEdge();
            }}
            onScrollFrame={(clientHeight) => {
              prevClientHeightRef.current = clientHeight;
            }}
          >
            {
              props.renderSlotBeforeMessages
                ? props.renderSlotBeforeMessages(messageHistoryFlat)
                : null
            }
            {(hasPreviousMessageHistoryPage || isFetchingMessageHistoryPreviousPage) && <Loader />}
            {messagesHistoryElements}
            {(hasNextMessageHistoryPage || isFetchingMessageHistoryNextPage) && <Loader />}
            {queueMessagesElements}
          </MessageList>
          {
            canSendMessages && (
              <ChatInput
                recipientFullName={props.recipientFullName}
                isAwrHelpChat={isRoleAdmin && recipientType === SourceDestTypes.AWR}
                isCompact={props.isCompact}
                isShowMicIcon={isShowMicIcon}
                color={props.chatColor}
                replyMessage={replyMessage}
                chatId={recipientId}
                canSendAttachments={props.canSendAttachments}
                onSubmit={handleSubmitChatInput}
                onCloseReplyMessage={handleCloseReplyMessage}
              />
            )
          }
        </>
      )}
    </ChatView>
  );
};

export default BaseChatView;
