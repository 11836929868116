import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import i18n from './utils/i18n';
import MainProvider from './MainProvider';
import MainApp from './MainApp';
import ModalProvider from './components/modal/ModalProvider';
import SidePanelProvider from './components/side-panel/SidePanelProvider';
import {
  toastEnhanced,
  ToasterContainerEnhanced
} from './enhanced-components/toaster/ToasterEnhanced';
import { isDev } from './utils/constants';
import PushNotificationsProvider from './PushNotificationsProvider';
import FloatWindowProvider from './components/float-window/FloatWindow';
import { ApiRequestError } from './api/api.utils';

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      if (error && error instanceof ApiRequestError) {
        if (error.status === 'unauthorized') {
          if (!window.apiRequestsToRefetch) {
            window.apiRequestsToRefetch = [query];
          } else {
            window.apiRequestsToRefetch.push(query);
          }
          window.dispatchEvent(new CustomEvent('apiError401', {
            detail: error,
          }));
        } else {
          toastEnhanced({
            title: error.message || i18n.t('serverError'),
          }, {
            type: 'error',
            toastId: 'apiError'
          });
        }
      }
    },
  }),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: Infinity, // fetch query once in runtime
      retry: 0,
    },
  }
});

// @ts-ignore
window.__DEBUG_queryClient = queryClient;

class App extends Component{
  // Reload app if loading chunk error.
  componentDidCatch(error: Error) {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    }
  }

  render() {
    return (
      <QueryClientProvider client={queryClient}>
        {isDev && <ReactQueryDevtools initialIsOpen={false} />}
        <I18nextProvider i18n={i18n}>
          <BrowserRouter>
            <MainProvider>
              <PushNotificationsProvider>
                <ModalProvider>
                  <FloatWindowProvider>
                    <SidePanelProvider>
                      <MainApp />
                    </SidePanelProvider>
                  </FloatWindowProvider>
                </ModalProvider>
              </PushNotificationsProvider>
            </MainProvider>
            <ToasterContainerEnhanced />
          </BrowserRouter>
        </I18nextProvider>
      </QueryClientProvider>
    );
  }
}

export default App;
