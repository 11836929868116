import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { browserName, browserVersion, isMobile } from 'react-device-detect';

import MainLayout from './pages/MainLayout';
import LoginLayout from './pages/LoginLayout';
import { useMainContext } from './MainProvider';
import LoadingLayout from './pages/LoadingLayout';
import {
  registerAccountTypeCM,
  registerAccountTypeDM,
  registerStepSetup,
  registerStepVerification,
  URLS, AppVersion
} from './utils/constants';
import { useToastEnhanced } from './enhanced-components/toaster/ToasterEnhanced';
import { devDataStorage } from './components/change-server/ChangeServerPopup.utils';
import { getIsDocumentHidden } from './utils/user-agent';
import VersionDiffersLayout from './pages/VersionDiffersLayout';
import {
  getIsAgreementsFilled,
  getIsEmailVerified,
  getIsProfileExistCurry,
  getIsProfileFilled,
} from './utils/profile';
import { createLocalStorageManager } from './utils/storage';
import { appInitializeRequest } from './api/account';
import usePushNotifications from './hooks/usePushNotifications';
import useNoPushNotifications from './hooks/useNoPushNotifications';
import { useGetProfile } from './query/profile';
import { OrientationPopup } from './components/orientation-popup/OrientationPopup';
import {
  withOrientationChange,
  WithOrientationChangeState,
} from './components/withOrientationChange';
import { useModalContext } from './components/modal/ModalProvider';
import i18n from './utils/i18n';
import { loadTranslations } from './api/languages';

const sentMetricsLS = createLocalStorageManager<string[]>('metrics');
const orientationStorage = createLocalStorageManager<string[]>('orientation');

const MainApp = ({ isPortrait }: WithOrientationChangeState) => {
  usePushNotifications();
  useNoPushNotifications();

  const { t } = useTranslation();
  const metricKeyRef = useRef('');
  const history = useHistory();
  const { showToast } = useToastEnhanced();
  const { openModal, closeModalWithTitle } = useModalContext();

  const {
    isInitDataLoaded,
    isAuth,
    isAppVersionDiffers,
    userData,
    isUiRoleDM,
    isRoleCM,
    isOnlyDM,
    isRoleAdmin,
  } = useMainContext();
  const { profileData, isProfileLoading } = useGetProfile();

  const profileExistCurry = getIsProfileExistCurry(profileData);
  const isEmailVerified = profileExistCurry(getIsEmailVerified);
  const isProfileFilled = profileExistCurry((profile) => (
    getIsProfileFilled(profile, !isUiRoleDM)
  ));
  const isFillingProfileRequired = isOnlyDM;
  const isAgreementsFilled = profileExistCurry(getIsAgreementsFilled);

  const isUserAuthCompleted = isRoleAdmin || (
    isAuth && isEmailVerified && (isProfileFilled || !isFillingProfileRequired) && isAgreementsFilled
  );

  useEffect(() => {
    const devData = devDataStorage.load();

    if (devData) {
      window.apiUrlNew = devData.apiUrlNew;
    }
  }, []);

  // Send app version, user data to server for some metrics.
  useEffect(() => {
    if (isAuth) {
      const sentMetrics = sentMetricsLS.load();

      const metricKey = AppVersion + userData.id + isUiRoleDM;

      if (
        sentMetrics?.includes(metricKey)
        && metricKeyRef.current === metricKey // is metric key already fetching
      ) {
        return;
      }

      metricKeyRef.current = metricKey;

      const initLang = async () => {

        const userLangChoice = localStorage.getItem('userLang') || 'eng';

        const langCode = userLangChoice.length > 2 ? userLangChoice.slice(0, -1) : userLangChoice;
        
        loadTranslations(i18n, userLangChoice)
          .then(((result:any) => {

            localStorage.setItem('userLang', userLangChoice);
          }))
          .catch((error:any) => console.log('err load lang', error));

        await i18n.changeLanguage(langCode);
      };

      initLang()
        .catch(console.error);

      appInitializeRequest(userData, !isUiRoleDM, {
        countries: false,
        appVersion: AppVersion,
        appSource: 'web',
        browserName,
        browserVersion,
      }).then(() => {
        const sentMetrics = sentMetricsLS.load(); // load anew as data might be changed

        sentMetricsLS.save(sentMetrics ? [...sentMetrics, metricKey] : [metricKey]);

        if (
          metricKeyRef.current === metricKey // is ref still has metric key of this request
        ) {
          metricKeyRef.current = '';
        }
      });
    }
  }, [isAuth, userData, isUiRoleDM]);

  // Redirect to 'Verify email', 'Setup profile' if those aren't completed yet.
  useEffect(() => {
    let redirectUrl;

    if (isAppVersionDiffers || !isInitDataLoaded || isProfileLoading) {
      return;
    }

    if (isAuth) {
      if (isRoleAdmin) {
        redirectUrl = undefined;
      } else if (!isEmailVerified) {
        redirectUrl =
          URLS.register
          + '/' + registerStepVerification
          + '?type=' + (isRoleCM ? registerAccountTypeCM : registerAccountTypeDM);
      } else if ((!isProfileFilled && isFillingProfileRequired) || !isAgreementsFilled) {
        redirectUrl =
          URLS.register
          + '/' + registerStepSetup
          + '?type=' + (isRoleCM ? registerAccountTypeCM : registerAccountTypeDM);
      }
    } else {
      redirectUrl = URLS.login;
    }

    if (redirectUrl) {
      history.push(redirectUrl);
    }
  }, [
    isRoleAdmin,
    isProfileLoading,
    history,
    isAuth,
    isInitDataLoaded,
    isAppVersionDiffers,
    isRoleCM,
    isEmailVerified,
    isProfileFilled,
    isFillingProfileRequired,
    isAgreementsFilled,
  ]);

  useEffect(() => {
    const orientationKeys = orientationStorage.load() || [];
    const passedId = userData.id + userData.role;

    if (isMobile && isPortrait) {
      if (!orientationKeys.includes(passedId)) {
        openModal((
          <OrientationPopup onClose={(isDoNotShowAgain) => {
            if (isDoNotShowAgain) {
              orientationStorage.save([...orientationKeys, passedId]);
            }
          }} />
        ), {
          widthSize: 'sm',
          title: t('rotateYourDevice'),
          hideOnBackdropClick: false,
        });
      }
    } else {
      closeModalWithTitle && closeModalWithTitle(t('rotateYourDevice'));
    }
  }, [t, isPortrait, userData]);

  // Show toast about 'noConnection' when browser goes offline.
  useEffect(() => {
    const handleOffline = () => {
      if (!getIsDocumentHidden()) {
        showToast({
          title: t('noConnection'),
        }, {
          type: 'error',
          pauseOnFocusLoss: false,
        });
      }
    };

    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('offline', handleOffline);
    };
  }, [t, showToast]);

  if (!isInitDataLoaded) {
    return <LoadingLayout />;
  }

  if (isAppVersionDiffers) {
    return <VersionDiffersLayout />;
  }

  if (isUserAuthCompleted) {
    return <MainLayout />;
  }

  return <LoginLayout />;
};

export default withOrientationChange(MainApp);
