import React from 'react';

import { Image, Typography } from '../../components';
import FontIconView from '../../assets/FontIconView';
import AeFontIcons from '../../assets/Icons';
import { TemplateItemProps } from './Templates.types';

const VideoTemplateItem = ({
  className = '', style, item, isShowTitle, slotBtnAction
}: TemplateItemProps) => {
  return (
    <div key={item.id} className={'template-item video-template ' + className} style={style}>
      <div className='template-item__inner'>
        <a href={item.text} target='_blank' rel='noreferrer'>
          {
            item.thumbnail && (
              <Image src={item.thumbnail} title={item.title} />
            )
          }
          <div className='template-item__play'>
            <FontIconView icon={AeFontIcons.play} />
          </div>
        </a>
        <div className='template-item__slot-absolute'>{slotBtnAction}</div>
      </div>
      {isShowTitle && (
        <Typography component='h5' variant='poppins'>{item.title}</Typography>
      )}
    </div>
  );
};

export default VideoTemplateItem;
