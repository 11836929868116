import {apiRequest} from './api';
import {
  AdminSeekerItemFromApi,
  SeekerItemType,
  SeekerItemTypeApi,
  SeekerProfileType,
  SeekersListStatus,
} from './seekers.types';
import { normalizeSeekerAdminItem, normalizeSeekerItem } from './seekers.utils';
import { ApiParamUserData } from './api.types';
import { fixMePlease } from '../utils/common';
import { Role } from '../utils/enums';
import { isRoleAdmin, isRoleDM } from '../utils/profile';
import { ChannelPlatform } from './channels.types';

export const getSeekerProfileRequest = (
  userData: ApiParamUserData,
  seekerId: string,
  uiRole: Role,
) => {
  const params: {
    id: string,
    evangelistId?: string,
    leaderId?: string,
    vetterId?: string,
  } = {
    id: seekerId,
    evangelistId: userData.id,
  };

  const isRoleAdminValue = isRoleAdmin(uiRole);
  const isRoleDMValue = isRoleDM(uiRole);

  if (isRoleAdminValue) {
    params.vetterId = userData.id;
  }

  if (!isRoleDMValue && !isRoleAdminValue) {
    params.evangelistId = undefined;
    params.leaderId = userData.id;
  }

  return apiRequest<{
    profile: SeekerProfileType,
  }>('getSeekerProfile', {
    agentToken: userData.agentToken,
    ...params,
  });
};

export const getSeekerChannelRequest = (userData: ApiParamUserData, id: string) => {
  return apiRequest('getSeekerChannel', {
    seekerId: id,
    evangelistId: userData.id,
    agentToken: userData.agentToken
  }).then((response) => response.channels);
};

export type SetSeekerProfileRequestProfile = Partial<
  Pick<
    SeekerProfileType,
    'firstname' | 'lastname' | 'status' | 'language' |
    'city' | 'country' | 'locationCode' | 'chatStatus' | 'notes'
    >
>;

export const setSeekerProfileRequest = (
  userData: ApiParamUserData, seekerId: string, seeker: SetSeekerProfileRequestProfile
) => {
  const params: {
    id: string,
    evangelistId: string,
    vetterId?: string,
  } = { id: seekerId, evangelistId: userData.id };

  if (userData.role === Role.ADMIN) {
    params.vetterId = userData.id;
  }

  return apiRequest('setSeekerProfile', {
    agentToken: userData.agentToken,
    profile: seeker,
    ...params,
  });
};

export const setSeekerChannelRequest = (
  userData: ApiParamUserData, data: { seekerId: string, channelId: string }
) => {
  return apiRequest('setSeekerChannel', {
    evangelistId: userData.id,
    agentToken: userData.agentToken,
    ...data
  });
};

export type ConnectSeekerDataType = {
  seekerId: string;
  action: string;
  value?: string | null;
};

export const connectSeekerRequest = (userData: ApiParamUserData, data: ConnectSeekerDataType) => {
  return apiRequest('connectSeeker', {
    agentToken: userData.agentToken,
    ...data
  });
};

type FetchSeekerParams = {
  // Desired status of seekers. Defaults to ‘active’.
  // Can also be ‘inactive’ to return all inactive seekers.
  status?: string,
  id?: string, // array of evangelist IDs
  // Required if id is an array. If no “Id” parameter then get all seekers
  // for all DMs for all of the leader’s campaigns.
  leaderId?: string,
  agentToken: string,
}

// Get {SeekerItem} of all seekers currently associated with this evangelist.
export const getSeekersRequest = async (
  userData: ApiParamUserData,
  status: SeekersListStatus,
  isUiRoleNotDM: boolean,
  isRoleContainsDm: boolean
) => {
  const params: FetchSeekerParams = {
    agentToken: userData.agentToken
  };

  if (isUiRoleNotDM) {
    params.leaderId = userData.id;
  } else {
    params.id = userData.id;
  }

  return apiRequest<{
    seekers: SeekerItemTypeApi[]; // array of seeker info assigned to this evangelist
    evangelists: string[];
  }>('getSeekers', {
    ...params,
    status
  }).then((response) => {
    // Prevent seekers duplicates.
    const seekerIds = new Set();
    const responseSeekers = response.seekers;

    const seekers: SeekerItemType[] = [];

    let seeker, seekerId;
    for (let i = 0; i < responseSeekers.length; i++) {
      seeker = responseSeekers[i];
      seekerId = seeker.id;
      if (isRoleContainsDm && isUiRoleNotDM && seeker.evangelistId?.toString() === userData.id) {
        continue;
      }

      if (seekerIds.has(seekerId)) {
        // eslint-disable-next-line no-console
        fixMePlease(
          'seekers list',
          `%cAsk about seekers duplicates for Leader. Is still reproduced: dup ${seekerId}`,
          'background: red; color: white;'
        );
        continue;
      }

      seekers.push(normalizeSeekerItem(seeker));
      seekerIds.add(seekerId);
    }

    return {
      seekers,
      evangelists: response.evangelists
    };
  });
};

export type AddSeekerRequestParamSeeker = {
  channelId: string
  channelAddress: string
  firstname: string
  language: string // 3-digit code
  lastname?: string
  email?: string
  country?: string
  city?: string
  zipcode?: string
  phone?: string
}

// Add new seeker to system
export const addSeekerRequest = (
  userData: ApiParamUserData, seekers: AddSeekerRequestParamSeeker[], broadcastId?: string
) => {
  return apiRequest<{
    seekerIds: {
      channelAddress: string,
      seekerId: number,
      status: 'success' | 'error'
    }[]
  }>('addSeeker', {
    agentToken: userData.agentToken,
    id: userData.id,
    seekers,
    broadcastId,
  });
};

export type SeekerSortType = '' | 'newestFirst' | 'oldestFirst';

export type SeekerListParams = {
  limit?: number;
  offset?: number;

  // Only one of these params should be used at once.
  search?: string;
  msgSearch?: string;
  // =======================

  vetterId?: string; // TODO: Implement
  seekerId?: string; // TODO: Implement
  campaignIds?: string[];
  evangelistIds?: string[];
  channelPlatforms?: ChannelPlatform[];
  sortBy?: SeekerSortType;
  newestOnly?: boolean;
};

export const getSeekerListRequest = (userData: ApiParamUserData, params: SeekerListParams) => {
  const paramsFinal: Partial<ApiParamUserData & SeekerListParams> = {
    agentToken: userData.agentToken,
    vetterId: userData.id,
    ...params
  };

  if (!paramsFinal.search) {
    delete paramsFinal.search;
  }

  if (!paramsFinal.msgSearch) {
    delete paramsFinal.msgSearch;
  }

  if (!paramsFinal.evangelistIds) {
    delete paramsFinal.evangelistIds;
  }

  if (!paramsFinal.campaignIds) {
    delete paramsFinal.campaignIds;
  }

  return apiRequest<{
    totalCount: number,
    seekers: AdminSeekerItemFromApi[],
  }>('getSeekerList', paramsFinal).then((response) => {
    return response.seekers.map((item, index) =>
      normalizeSeekerAdminItem(item, index === 0 ? response.totalCount : undefined)
    );
  });
};

export type TransferSeekerRequest = {
  seekerId: string;
  destEvangelistId: string;
}

type RoleFlags = {
  isUiRoleCM: boolean
  isUiRoleLeader: boolean
  isRoleAdmin: boolean
}

export const transferSeekerRequest = (
  userData: ApiParamUserData,
  roleFlags: RoleFlags,
  data: TransferSeekerRequest,
) => {
  const userIdKey = roleFlags.isRoleAdmin ? 'vetterId' : 'leaderId';

  return apiRequest('transferSeeker', {
    agentToken: userData.agentToken,
    [userIdKey]: userData.id,
    ...data,
  });
};

export const deleteSeekerRequest = (id: string, userData: ApiParamUserData) => {
  return apiRequest('deleteSeeker', {
    id,
    vetterId: userData.id,
    agentToken: userData.agentToken,
  });
};
