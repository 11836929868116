import React from 'react';
import { useTranslation } from 'react-i18next';

import { Course } from './types';
import { useSidePanelContext } from '../side-panel/SidePanelProvider';
import { CourseInfo } from './CourseInfo';
import { timeConvert } from '../../utils/time';
import FontIconView from '../../assets/FontIconView';
import AeFontIcons from '../../assets/Icons';

export const CourseItem = ({ item }: { item: Course }) => {
  const { t } = useTranslation();
  const { openPanel, closePanel } = useSidePanelContext();
  const { icon, title, lessons, duration } = item;

  const openCourseInfo = () => {
    openPanel({ component: <CourseInfo closePanel={closePanel} item={item} /> });
  };

  return (
    <div className="courses-item" onClick={openCourseInfo}>
      <div className="courses-item__icon">
        <img src={icon} alt={title} />
      </div>
      <span className="courses-item__title">{title}</span>
      <div className="courses-item__text">
        <FontIconView icon={AeFontIcons.stack} />
        <span>{lessons} {t('lessons')}</span>
      </div>
      <div className="courses-item__text">
        <FontIconView icon={AeFontIcons.time} />
        <span>{timeConvert(duration)}</span>
      </div>
    </div>
  );
};
