import { EnumLiteralsOf } from '../types';

export const studentStatus = {
  SUBSCRIBED: 'subscribed',
  UNSUBSCRIBED: 'unsubscribed',
  FINISHED: 'finished',
  CHANGED: 'changed',
  COMPLETED: 'completed'
} as const;

export type StudentStatusType = EnumLiteralsOf<typeof studentStatus>;

export type StudentItemApi = {
  id: string;
  firstname: string | null;
  lastname: string | null;
  createDate: string;
  lastContact: string | null;
  lastStudy: {
    seriesId: string;
    status: StudentStatusType;
    language: string;
    aveListenPercent: number;
    lessonsSent: number;
    lastLesson?: {
      lessonDate: string;
      lessonIndex: string;
      lessonTitle: string;
      lessonTopic: string;
      lessonUrl: string;
      curLessonTime: string;
      totalTime: string;
      aveListenPercent: string;
      lastProgressDate: string | null;
    };
  };
};

export type StudentItem = {
  id: string;
  firstname: string | null;
  lastname: string | null;
  lastContact: string | null;
  lastProgressDate: string | null;
  createDate: string;
  aveStudentListenPercent: number;
  aveLessonListenPercent: number;
  lessonIndex: number;
  lessonTitle: string;
  lessonTopic: string;
  seriesId: string;
  status: StudentStatusType;
  lessonsSent: number;
  language: string;
  lessonDate: string;
  url: string;
  firstIndex?: number;
  totalStudyLessons?: number;
};

export const STUDY_LIMIT = {
  lastChat: 'lastChat',
  lastProgress: 'lastProgress',
  progress: 'progress'
} as const;

export type StudyLimitType = EnumLiteralsOf<typeof STUDY_LIMIT>;

export type StudyLimitItemApi = {
  colors: string[];
  limits: number[];
};

export type StudyLimitApi = {
  [STUDY_LIMIT.lastChat]: StudyLimitItemApi;
  [STUDY_LIMIT.progress]: StudyLimitItemApi;
  [STUDY_LIMIT.lastProgress]: StudyLimitItemApi;
};

export type StudyLimitItem = {
  color: string;
  daysLimit: number;
};

export type StudyLimit = Record<StudyLimitType, StudyLimitItem[]>;

export type LessonItemApi = {
  index: string;
  topic: string;
};

export type StudyItemApi = {
  study: {
    seriesId: string;
    title: string;
    language: string;
    firstIndex: string;
    totalLessons: string;
  };
  lessons: LessonItemApi[];
};

export type LessonItem = {
  id: string;
  index: number;
  topic: string;
};

export type StudyItem = {
  seriesId: string;
  title: string;
  language: string;
  firstIndex: number;
  totalLessons: number;
  lessons: LessonItem[];
};

export type HistoryItemLessonApi = {
  lessonDate: string,
  lessonIndex: string,
  lessonTitle: string,
  lessonTopic: string,
  lessonUrl: string,
  curLessonTime: string,
  totalTime: string,
  aveListenPercent: string,
  lastProgressDate: string | null
};

export type HistoryItemApi = {
  seriesId: string,
  status: StudentStatusType,
  statusDate: string,
  language: string,
  aveListenPercent: string,
  lessonsSent: number,
  lessons: HistoryItemLessonApi[],
};

export type HistoryItemLesson = {
  id: string,
  lessonDate: string,
  lessonIndex: number,
  lessonTitle: string,
  lessonTopic: string,
  lessonUrl: string,
  curLessonTime: number,
  totalTime: number,
  aveListenPercent: number,
  lastProgressDate: string | null
};

export type HistoryItem = {
  id: string,
  seriesId: string,
  status: StudentStatusType,
  statusDate: string,
  language: string,
  aveListenPercent: number,
  lessonsSent: number,
  lessons: HistoryItemLesson[],
};

export type StudentPerformanceType = {
  student?: StudentItem,
  studentHistory: HistoryItem[],
  totalSeriesCount: number,
  completeSeriesCount: number,
  allLessonsCount: number,
  lessonsAvePercent: number,
  currentSeriesTotalTime: number,
  lessonsAveTime: number,
  allLessonsTotalTime: number,
  totalTimePercent: number
};

export type CommandItemCommon = {
  command: string,
  key: string,
  status: 'active' | 'inactive',
  description: string,
  confirmMsg: string,
  successMsg: string,
};

export type CommandParamItemApi = {
  key: string | number,
  text: string | number,
  language?: string | null;
};

export type CommandItemApi = CommandItemCommon & {
  params: CommandParamItemApi[],
};

export type CommandParamItem = {
  key: string,
  text: string,
  language?: string | null,
  lang?: string | null,
  shortText?: string | null;
};

export type CommandItem = CommandItemCommon & {
  params: CommandParamItem[],
};
