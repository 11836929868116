import { SelectOption } from '../select/Select.types';
import { emailRegex } from '../../utils/string';

export const FIELD_MAX_LENGTH_NAME = 40;
export const FIELD_MAX_LENGTH_PASTOR_NAME = 80; 

export type Validator<FieldValueType> = (value: FieldValueType) => {
  label: [string] | [string, { [field: string]: string | number }], valid: boolean
};

export type ValidatorCreator<Config, FieldValueType> = (config: Config) =>
  Validator<FieldValueType>;

export const isRequired: Validator<string | number | SelectOption> = (value) => ({
  label: ['errorFieldRequired'],
  valid:
    typeof value === 'string' || typeof value === 'number'
      ? !!value
      : !!value.key
});

export const isSingleWord: Validator<string> = (value) => ({
  label: ['errorOnlySingleWord'],
  valid: !value.includes(' '),
});

export const isEmail: Validator<string> =
  (value) => ({
    label: ['msgInvalidEmail'],
    valid: emailRegex.test(value)
  });

export const checkPasswordLength: Validator<string> =
  (value) => ({
    label: ['msgLengthMatch'],
    valid: value.length >= 8
  });

export const createMaxLengthValidator: ValidatorCreator<number, string> =
  (maxLength) => (value) => {
    return {
      label: ['nameLengthValidate', {
        value: maxLength,
      }],
      valid:value.length <= maxLength,
    };
  };

export const checkPasswordNumberExistence: Validator<string> =
  (value) => ({
    label: ['msgNumberMatch'],
    valid: /^(?=.*[0-9])/.test(value)
  });

export const checkPasswordLettersCases: Validator<string> =
  (value) => ({
    label: ['msgCaseMatch'],
    valid: /^(?=.*[a-z])(?=.*[A-Z])/.test(value)
  });

export const isPostalCode: Validator<string> =
  (value) => ({
    label: ['errorInvalidPostal'],
    valid: /^([0-9]{5}|[a-zA-Z][a-zA-Z ]{0,49})$/.test(value)
  });
