import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import {Button, ButtonClose, Typography} from '../index';
import Row, {RowProps} from '../row/Row';
import Scroll from '../scroll/Scroll';
import { ButtonProps } from '../button/Button';
import {useSidePanelContext} from './SidePanelProvider';

interface DefaultPanelProps {
  buttonCancelProps?: ButtonProps,
  buttonApplyProps?: ButtonProps,
  children: React.ReactNode;
  slotButtons?: React.ReactNode;
  slotAfterTitle?: React.ReactNode;
  titleProps?: Partial<RowProps>;
  className?: string;
  title: string;
  variant?: 'two-buttons' | 'one-button',
  onClickBack?: () => void;
  onClickSave?: () => void;
  styles?: { [key: string]: string };
}

export const DefaultPanel = forwardRef<HTMLDivElement, DefaultPanelProps>(({
  buttonCancelProps,
  className,
  styles,
  buttonApplyProps,
  variant,
  titleProps,
  title,
  onClickBack,
  onClickSave,
  children,
  slotAfterTitle,
  slotButtons,
}, ref) => {
  const { t } = useTranslation();
  const { closePanel } = useSidePanelContext();

  return (
    <div className={'side-panel__inner-wrap ' + className} ref={ref}>
      <Row
        gap='sm'
        horizontal='space-between'
        vertical='center'
        border='bottom'
        {...titleProps}
        className="side-panel__title"
      >
        <Typography component='h3' variant='poppins' threeDots>{t(title)}</Typography>
        {slotAfterTitle}
      </Row>
      <Scroll
        className="side-panel__content"
        style={styles}
        flexColumn={true}
      >
        {children}
      </Scroll>
      {
        (!slotButtons && variant === 'two-buttons') && (
          <Row directionHorizontal gap='lg'>
            <Button
              color='gray'
              variant='opacity'
              onClick={onClickBack || closePanel}
              //eslint-disable-next-line react/no-children-prop
              children={t('cancel')}
              {...buttonCancelProps}
            />
            <Button
              onClick={onClickSave}
              //eslint-disable-next-line react/no-children-prop
              children={t('save')}
              {...buttonApplyProps}
            />
          </Row>
        )
      }
      {
        (!slotButtons && variant === 'one-button') && (
          <Row
            directionHorizontal
            autoColumns
            gap='lg'
            horizontal='end'
          >
            <ButtonClose onClick={onClickBack || closePanel} {...buttonCancelProps} />
          </Row>
        )
      }
      {slotButtons}
    </div>
  );
});

DefaultPanel.defaultProps = {
  variant: 'two-buttons',
};
