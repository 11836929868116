const AeFontIcons = {
  'add': 61697,
  'arrowCircleRight': 61698,
  'arrowDown': 61699,
  'arrowLeft': 61700,
  'arrowLongDown': 61701,
  'arrowLongRight': 61702,
  'arrowLongUp': 61703,
  'arrowRight': 61704,
  'attachment': 61705,
  'awrHelpRound': 61706,
  'awrHelp': 61707,
  'bellCrossed': 61708,
  'bellFillCrossed': 61709,
  'bellFill': 61710,
  'bell': 61711,
  'book': 61712,
  'broadcastFilled': 61713,
  'broadcast': 61714,
  'calendar': 61715,
  'chatFilled': 61716,
  'chatOutline': 61717,
  'check': 61718,
  'checkmarkRound': 61719,
  'church': 61720,
  'clockCheck': 61721,
  'clockFilled': 61722,
  'clock': 61723,
  'closeRound': 61724,
  'close': 61725,
  'collapse': 61726,
  'connectLeader': 61727,
  'connect': 61728,
  'copy': 61729,
  'cross': 61730,
  'crowd': 61731,
  'dangerous': 61732,
  'dashboard': 61733,
  'delete': 61734,
  'edit': 61735,
  'ellipse': 61736,
  'emailSent': 61737,
  'email': 61738,
  'error': 61739,
  'expand': 61740,
  'eyeCrossed': 61741,
  'eye': 61742,
  'facebook': 61743,
  'faq': 61744,
  'filters': 61745,
  'info': 61746,
  'leader': 61747,
  'letter': 61748,
  'location': 61749,
  'lock': 61750,
  'logout': 61751,
  'mail': 61752,
  'menu': 61753,
  'micro': 61754,
  'minus': 61755,
  'mission': 61756,
  'move': 61757,
  'newChats': 61758,
  'openReq': 61759,
  'outlineFilter': 61760,
  'pin': 61761,
  'planet': 61762,
  'play': 61763,
  'playerStop': 61764,
  'plus': 61765,
  'point': 61766,
  'profileFill': 61767,
  'profile': 61768,
  'progress': 61769,
  'questionRound': 61770,
  'question': 61771,
  'quote': 61772,
  'read': 61773,
  'refresh': 61774,
  'reminder': 61775,
  'reply': 61776,
  'requestLimits': 61777,
  'request': 61778,
  'search': 61779,
  'seekers': 61780,
  'selectedMark': 61781,
  'sent': 61782,
  'series': 61783,
  'settings': 61784,
  'share2': 61785,
  'share': 61786,
  'stack': 61787,
  'starFilled': 61788,
  'star': 61789,
  'studentFilled': 61790,
  'studentTotal': 61791,
  'student': 61792,
  'teams': 61793,
  'telegram': 61794,
  'threeDots': 61795,
  'time': 61796,
  'trash': 61797,
  'twitter': 61798,
  'upload': 61799,
  'waitReq': 61800,
  'whatsapp': 61801,
  'youtube': 61802,
} as const;

export default AeFontIcons;
