import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import classnames from 'classnames';

import { Backdrop, Button, Typography } from '../index';
import AeFontIcons from '../../assets/Icons';
import { ModalStackValue } from './ModalProvider';
import Scroll from '../scroll/Scroll';
import { Tooltip } from '../tooltip/Tooltip';
import {useListenKey} from '../../hooks/useListenKey';
import PortalRootAfter from '../PortalRootAfter';
import ModalActions from './ModalActions';

import './Modal.scss';

export const Modals: React.FC<ModalStackValue> = ({stack, closeModal}) => {
  const { t } = useTranslation();
  const nodeRef = React.useRef<HTMLDivElement>(null);

  useListenKey({
    enabled: !!stack.length,
    key: 'Escape',
    onKeyDown: () => {
      closeModal();
    },
  });

  return (
    <TransitionGroup component={null}>
      <PortalRootAfter>
        {stack.map((modal, index) => {
          const { component, props } = modal;
          const {
            loading,
            widthSize,
            title,
            titleAlign,
            zIndex,
            hideOnBackdropClick = true,
            okButtonProps,
            okButton,
            cancelButton,
            cancelButtonProps,
            scrollbarsClassName,
          } = props;

          const style: CSSProperties = { height: 'unset' };

          if (widthSize === 'sm') {
            style.width = 406;
          } else if (widthSize === 'md') {
            style.width = 524;
          } else if (widthSize === 'lg') {
            style.width = 600;
          } else {
            style.width = 660;
          }

          return (
            <CSSTransition
              key={index}
              nodeRef={nodeRef}
              in={modal === stack[stack.length - 1]}
              classNames="modal"
              timeout={250}
            >
              <div className={classnames('modal', {
                'loading': loading,
              })} ref={nodeRef} style={{ zIndex }}>
                <Scroll
                  staticScrollView
                  className={classnames('modal__scrollbars', scrollbarsClassName)}
                  style={style}
                >
                  <div
                    className='modal__inner'
                    style={{
                      gridTemplateRows: (
                        (props.title ? 'auto ' : '')
                        + '1fr '
                        + ((okButton || cancelButton) ? 'auto' : '')
                      )
                    }}
                  >
                    <Tooltip content={t('close')} className='modal__close-btn'>
                      <Button
                        disabled={loading}
                        variant='transparent'
                        color='gray-light'
                        onClick={closeModal}
                        iconBefore={AeFontIcons.close}
                      />
                    </Tooltip>
                    {title && (
                      <Typography
                        style={{ textAlign: titleAlign }}
                        component='h3'
                        variant='poppins'
                      >
                        {title}
                      </Typography>
                    )}
                    {component && <div className='modal__content'>{component}</div>}
                    {
                      (okButton || cancelButton) && (
                        <ModalActions
                          btnOk={okButton}
                          btnCancel={cancelButton}
                          btnCancelProps={{
                            ...cancelButtonProps,
                            onClick: (e) => {
                              if (cancelButtonProps?.onClick) {
                                if (cancelButtonProps.onClick(e)) {
                                  closeModal();
                                }
                              } else {
                                closeModal();
                              }
                            },
                          }}
                          btnOkProps={{
                            ...okButtonProps,
                            onClick: (e) => {
                              if (okButtonProps?.onClick) {
                                if (okButtonProps.onClick(e)) {
                                  closeModal();
                                }
                              } else {
                                closeModal();
                              }
                            },
                          }}
                        />
                      )
                    }
                  </div>
                </Scroll>
                <Backdrop
                  className='modal_backdrop'
                  style={{
                    pointerEvents: (hideOnBackdropClick && !loading) ? 'all' : 'none',
                  }}
                  onClick={closeModal}
                />
              </div>
            </CSSTransition>
          );
        })}
      </PortalRootAfter>
    </TransitionGroup>
  );
};
