import { baseRequest } from './api';
import { BaseUrl } from '../utils/constants';
import { ApiParamUserData } from './api.types';

const FORMAT_AUDIO_CONVERTED = 'mp3';
const SERVER_STORAGE_URL = 'https://awrinfo.org/orion_storage';

export type UploadBannerData = {
  banner: File;
  managerId: string;
  campaignId: string;
}

export const uploadAudioFile = (filename: string, blob: Blob) => {
  const formData = new FormData();
  formData.append('fileToUpload', blob, filename);

  return baseRequest((window.apiUrlNew || BaseUrl) + '/upload_audio.php', true, {
    body: formData,
  }).then((result) => {
    return {
      filename: filename.split('.')[0] + '.' + FORMAT_AUDIO_CONVERTED,
      ...result,
    };
  });
};



export const uploadCampaignBannerRequest = (userData: ApiParamUserData, data: UploadBannerData) => {
  const formData = new FormData();
  formData.append('agentToken', userData.agentToken);
  formData.append('banner', data.banner);
  formData.append('managerId', data.managerId);
  formData.append('campaignId', data.campaignId);

  return baseRequest((window.apiUrlNew || BaseUrl) + '/upload_banner.php', true, {
    body: formData,
  }).then((result) => ({
    filename: `${SERVER_STORAGE_URL}/${data.banner.name}`,
    ...result,
  }));
};
