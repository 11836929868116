import { useMainContext } from '../MainProvider';
import { useQuery } from 'react-query';
import { getSettingsRequest, Settings } from '../api/settings';

export const useGetSettings = () => {
  const { userData } = useMainContext();

  const { data, isLoading } = useQuery<Settings, Error>('settings', () => (
    getSettingsRequest(userData)
  ));

  return {
    isSettingsLoading: isLoading,
    settings: data
  };
};
