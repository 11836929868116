import React, {forwardRef} from 'react';

import SelectContainer, {SelectContainerProps} from './SelectContainer';
import {SelectInput} from '../index';
import {SelectInputProps} from './SelectInput';

type SelectVariantInputProps = Omit<SelectContainerProps, 'renderInput'> & {
  inputProps?: Partial<SelectInputProps>,
  placeholder?: string;
};

const Select = forwardRef<HTMLInputElement, SelectVariantInputProps>((
  props, ref
) => {
  return (
    <SelectContainer {...props} renderInput={(inputProps) => {
      return (
        <SelectInput
          name={inputProps.name}
          value={inputProps.value}
          onBlur={inputProps.onBlur}
          onFocus={inputProps.onFocus}
          isOpen={inputProps.isOpen}
          disabled={inputProps.disabled}
          size={inputProps.size}
          invalid={inputProps.invalid}
          placeholder={props.placeholder}
          {...props.inputProps}
          wrapperProps={{
            onClick: inputProps.openSelect,
            ...props.inputProps?.wrapperProps,
          }}
          ref={ref}
        />
      );
    }}/>
  );
});

export default Select;
