import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import { Checkbox, FontIconView, InputSearch, Loader, Typography } from '../index';
import {MultiSelectListProps} from './MultiSelect.types';
import Scroll from '../scroll/Scroll';

import './MultiSelectList.scss';

const MultiSelectList: React.FC<MultiSelectListProps> = ({
  withFilter, isLoading, value, options, onChange, inputProps, size
}) => {
  const { t } = useTranslation();

  const [filter, setFilter] = useState('');

  const listFiltered = (
    filter
      ? options.filter(
        ({ label }) => label.toLowerCase().includes(filter.toLowerCase())
      )
      : options
  );

  return (
    <div className='multi-select-list'>
      {
        withFilter && (
          <InputSearch
            wrapperProps={{
              className: 'multi-select-list_input-wrapper',
            }}
            value={filter}
            onChange={(e, value) => {
              setFilter(value);
            }}
            size={size}
            {...inputProps}
          />
        )
      }

      <Scroll
        className='multi-select-list_scrollbars'
        autoHeight
        autoHeightMax={260}
        staticScrollView
        flexColumn
      >
        <ul>
          {
            isLoading
              ? <Loader />
              : listFiltered.length ? listFiltered.map((item) => {
                const isChecked = value.some((i) => i.key === item.key);

                return (
                  <Checkbox
                    key={item.key}
                    value={isChecked}
                    wrapperClassName='multi-select-list_checkbox'
                    onChange={(e) => {
                      if (isChecked) {
                        onChange(e, value.filter((i) => i.key !== item.key));
                      } else {
                        onChange(e, [...value, item]);
                      }
                    }}
                    slotAfter={(<Typography component='body2'>
                      {item.icon && (
                        <FontIconView
                          icon={item.icon}
                          style={{ lineHeight: 1.5, }}
                          {...item.iconProps}
                        />
                      )}
                      &nbsp;{item.label}
                    </Typography>)}
                  />
                );
              }) : <Typography align='center' component='body3'>{t('noResultsFound')}</Typography>
          }
        </ul>
      </Scroll>
    </div>
  );
};

MultiSelectList.defaultProps = {
  withFilter: true,
};

export default MultiSelectList;
