import { useMainContext } from '../MainProvider';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  executeStudentCommandRequest,
  getStudentCommandsRequest,
  getStudentHistoryRequest,
  getStudentsRequest,
  getStudiesRequest,
  getStudyLimitsRequest
} from '../api/students';
import {
  CommandParamItem,
  StudentItem,
  StudentPerformanceType,
  studentStatus,
  StudyItem,
  STUDY_LIMIT,
  StudyLimit
} from '../api/students.types';
import { useGetByIdWithCache } from '../hooks/useCache';
import { useToastEnhanced } from '../enhanced-components/toaster/ToasterEnhanced';
import { useTranslation } from 'react-i18next';
import { useNotificationContext } from '../PushNotificationsProvider';
import { NO_NOTIFICATIONS_RE_FETCH_INTERVAL } from '../utils/constants';

export const QUERY_KEY_STUDENTS = 'students';
export const QUERY_KEY_STUDY_LIMITS = 'studyLimits';
export const QUERY_KEY_STUDIES = 'studies';
export const QUERY_KEY_STUDENT_HISTORY = 'studentHistory';
export const QUERY_KEY_STUDENT_COMMAND = 'studentCommand';

const studentsInitialData: [] = [];
const studyLimitsInitialData: StudyLimit = {
  [STUDY_LIMIT.lastChat]: [],
  [STUDY_LIMIT.progress]: [],
  [STUDY_LIMIT.lastProgress]: []
};
const studiesInitialData: [] = [];

const getStudentId = (item: StudentItem) => item.id;

export const useGetStudentsList = (config?: { enabled?: boolean }) => {
  const { userData } = useMainContext();
  const { isAppReceivesNotifications } = useNotificationContext();

  const { isLoading, data = studentsInitialData, refetch, isError } = useQuery(
    QUERY_KEY_STUDENTS,
    () => getStudentsRequest(userData),
    {
      refetchInterval: isAppReceivesNotifications ? Infinity : NO_NOTIFICATIONS_RE_FETCH_INTERVAL,
      ...config
    }
  );
  const get = useGetByIdWithCache(QUERY_KEY_STUDENTS, data, getStudentId);

  return {
    isStudentsLoading: isLoading,
    isStudentsError: isError,
    refetchStudents: refetch,
    students: data,
    getStudentById: get
  };
};

const getStudyId = (item: StudyItem) => item.seriesId;

export const useGetStudies = (config?: { enabled?: boolean }) => {
  const { userData } = useMainContext();
  const { isLoading, data = studiesInitialData, refetch, isError } = useQuery(
    QUERY_KEY_STUDIES,
    () => getStudiesRequest(userData),
    config
  );
  const get = useGetByIdWithCache(QUERY_KEY_STUDIES, data, getStudyId);

  return {
    isStudiesLoading: isLoading,
    isStudiesError: isError,
    refetchStudies: refetch,
    studies: data,
    getStudyById: get
  };
};

export const useGetStudyLimits = (config?: { enabled?: boolean }) => {
  const { userData } = useMainContext();
  const {
    isLoading,
    data = studyLimitsInitialData,
    refetch,
    isError
  } = useQuery(QUERY_KEY_STUDY_LIMITS, () => getStudyLimitsRequest(userData), config);

  return {
    isLimitsLoading: isLoading,
    isLimitsError: isError,
    refetchLimits: refetch,
    studyLimits: data
  };
};

export const getQueryKeyStudentHistory = (studentId: string) => {
  return [QUERY_KEY_STUDENT_HISTORY, studentId];
};

export const useGetStudentHistory = (studentId: string) => {
  const { userData } = useMainContext();
  const { isAppReceivesNotifications } = useNotificationContext();
  
  const { isLoading, data = [], refetch, isError } = useQuery(
    getQueryKeyStudentHistory(studentId),
    () => getStudentHistoryRequest(userData, studentId),
    { 
      refetchInterval: isAppReceivesNotifications ? Infinity : NO_NOTIFICATIONS_RE_FETCH_INTERVAL,
      enabled: !!studentId
    }
  );

  return {
    isHistoryLoading: isLoading,
    isHistoryError: isError,
    refetchHistory: refetch,
    studentHistory: data
  };
};

export const useGetStudentPerformance = (studentId: string): StudentPerformanceType => {
  const { getStudentById } = useGetStudentsList();
  const { studentHistory } = useGetStudentHistory(studentId);

  const student = getStudentById(studentId);

  let allLessonsCount = 0;
  let completeSeriesCount = 0;
  let totalAvePercent = 0;
  let currentSeriesTotalTime = 0;
  let allLessonsCurrentTime = 0;
  let allLessonsTotalTime = 0;

  if (student) {
    studentHistory.forEach((item, index) => {
      if (item.status === studentStatus.COMPLETED) {
        completeSeriesCount += 1;
      }
      if (index === 0) {
        item.lessons.forEach((lessonItem) => {
          currentSeriesTotalTime += lessonItem.curLessonTime;
        });
      }
      item.lessons.forEach((lessonItem) => {
        if (index === 0) {
          currentSeriesTotalTime += lessonItem.curLessonTime;
        }
        allLessonsCurrentTime += lessonItem.curLessonTime;
        allLessonsTotalTime += lessonItem.totalTime;
      });
      totalAvePercent += item.aveListenPercent;
      allLessonsCount += item.lessons.length;
    });
  }

  return {
    student,
    studentHistory,
    totalSeriesCount: studentHistory.length,
    completeSeriesCount,
    allLessonsCount,
    lessonsAvePercent:
      totalAvePercent === 0 ? 0 : Math.round(totalAvePercent / studentHistory.length),
    currentSeriesTotalTime,
    lessonsAveTime: Math.round(allLessonsCurrentTime / allLessonsCount),
    allLessonsTotalTime,
    totalTimePercent: Math.round((allLessonsCurrentTime / allLessonsTotalTime) * 100)
  };
};

export const getQueryKeyStudentCommand = (studentId: string) => {
  return [QUERY_KEY_STUDENT_COMMAND, studentId];
};

export const useGetStudentCommands = (studentId: string) => {
  const { userData } = useMainContext();
  const { isAppReceivesNotifications } = useNotificationContext();

  const { isLoading, data = [], refetch, isError } = useQuery(
    getQueryKeyStudentCommand(studentId),
    () => getStudentCommandsRequest(userData, studentId),
    { 
      refetchInterval: isAppReceivesNotifications ? Infinity : NO_NOTIFICATIONS_RE_FETCH_INTERVAL,
      enabled: !!studentId, 
      staleTime: 0,
    }
  );

  return {
    isCommandsLoading: isLoading,
    isCommandsError: isError,
    refetchCommands: refetch,
    studentCommands: data
  };
};

export const useExecuteStudentCommand = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { showToast } = useToastEnhanced();
  const { userData } = useMainContext();

  const executeStudentCommand = useMutation(
    (payload: {
      studentId: string;
      command: string;
      successMsg: string;
      param?: CommandParamItem;
    }) => {
      return executeStudentCommandRequest(userData, {
        seekerId: payload.studentId,
        command: payload.command,
        param: payload.param || []
      });
    },
    {
      onSuccess: (_, payload) => {
        const { studentId } = payload;
        queryClient.invalidateQueries(QUERY_KEY_STUDENTS);
        queryClient.invalidateQueries(getQueryKeyStudentHistory(studentId));
        queryClient.invalidateQueries(getQueryKeyStudentCommand(studentId));
        showToast({ title: t(payload.successMsg) });
      }
    }
  );

  return {
    executeStudentCommand
  };
};
