import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox } from '../index';
import { useModalContext } from '../modal/ModalProvider';

import './orientation.scss';

interface OrientationPopupProps {
  onClose: (isDoNotShowAgain: boolean) => void;
}

export const OrientationPopup: React.FC<OrientationPopupProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const { closeModal } = useModalContext();

  const [isCheck, setIsCheck] = useState(false);

  const closeHandler = () => {
    onClose(isCheck);
    closeModal();
  };

  return (
    <div>
      {t('rotateForBetterExp')}
      <div>
        <Checkbox
          value={isCheck}
          wrapperClassName='orient__label'
          onChange={() => setIsCheck(!isCheck)}
          slotAfter={<>&nbsp;{t('dontShowAgain')}</>}
        />
      </div>
      <div className="orient__actions">
        <Button minWidth='sm' onClick={closeHandler}>{t('ok')}</Button>
      </div>
    </div>
  );
};
