import React from 'react';

import SelectList, { SelectListProps, SelectListRenderItemFn } from './SelectList';
import { ListItem, Typography } from '../index';
import SelectCheckmark from './SelectCheckmark';

const renderItem: SelectListRenderItemFn = (option, isActive, handleChange) => {
  const key = option.key;

  return (
    <ListItem
      hover
      autoColumns
      directionHorizontal
      gap='md'
      horizontal='space-between'
      vertical='center'
      paddingVertical='md'
      paddingHorizontal='extra-lg'
      color='blue-opacity'
      dataAttributes={{
        'data-id': key,
      }}
      key={key}
      onClick={handleChange}
    >
      <Typography
        component='h5'
        color={isActive ? 'blue' : 'text-body'}
      >{option.label}</Typography>
      {isActive ? <SelectCheckmark /> : null}
    </ListItem>
  );
};

interface SelectListBoldProps extends Omit<SelectListProps, 'renderItem'> {}

const SelectListBold: React.FC<SelectListBoldProps> = (props) => {
  return (
    <SelectList {...props} renderItem={renderItem} />
  );
};

export default SelectListBold;
