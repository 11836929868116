import { Language } from '../api/languages.types';
import { SelectOption } from '../components/select/Select.types';
import { createLocalStorageManager } from '../utils/storage';
import { useGetAvailableTreeLanguages } from './useGetAvailableTreeLanguages';
import { useUiLanguage } from './useLanguage';

const defaultEnglishLanguage: Language = {
  language: 'eng',
  languageCode: 'en-us',
  name: 'English'
};

const faqLanguageStorage =
  createLocalStorageManager<Language>('faqLanguage');

const attachmentLanguageStorage =
  createLocalStorageManager<Language>('attachmentLanguage');

export const useGetCurrentTreeLanguage = (isFaq?: boolean) => {
  const { language: uiLanguage } = useUiLanguage();
  const availableTreeLanguages = useGetAvailableTreeLanguages(isFaq);

  let treeLanguage: Language | null | undefined;
  if (isFaq) {
    treeLanguage = faqLanguageStorage.load();
  } else {
    treeLanguage = attachmentLanguageStorage.load();
  }

  if (!treeLanguage) {
    treeLanguage = availableTreeLanguages.find(item => item.language === uiLanguage);
  }

  const saveTreeLanguage = (changedLanguageOption: SelectOption) => {
    if (changedLanguageOption.key === treeLanguage?.language) {
      return;
    }

    const newChangedLanguage = availableTreeLanguages.find(
      (item) => item.language === changedLanguageOption.key
    );
    if (!newChangedLanguage) {
      return;
    }

    if (isFaq) {
      faqLanguageStorage.save(newChangedLanguage);
    } else {
      attachmentLanguageStorage.save(newChangedLanguage);
    }
  };

  return {
    language: (treeLanguage || defaultEnglishLanguage) as Language,
    saveTreeLanguage,
    availableTreeLanguages
  };
};
