import {EnumLiteralsOf} from '../types';
import { TooltipStyle } from '../components/tooltip/Tooltip';

export const dispatchChangeEvent = (target: HTMLElement) => {
  const event = document.createEvent('HTMLEvents');
  event.initEvent('change', true, true);

  target.dispatchEvent(event);
};

/**
 * @description Returns x coordinate of the received event
 * or the last x coordinate of the touches of the received event.
 */
export const getCoordinateX = (e: TouchEvent & MouseEvent) => {
  const { x, touches, changedTouches } = e;

  if (x !== undefined) {
    return x;
  }

  if (touches) {
    const lastTouch = touches[touches.length - 1];

    if (lastTouch) {
      return lastTouch.clientX;
    }
  }

  if (changedTouches) {
    const lastTouch = changedTouches[changedTouches.length - 1];

    if (lastTouch) {
      return lastTouch.clientX;
    }
  }

  return undefined;
};

export const EventKey = {
  enter: 'Enter',
  backspace: 'Backspace',
  comma: ',',
  escape: 'Escape',
} as const;

export type EventKeys = EnumLiteralsOf<typeof EventKey>;

export const highlightSearch = (text: string, search: string = '') => {
  if (search) {
    const regex = new RegExp('(' + search + ')', 'gi');
    return text.replace(regex, '<span class="highlighted">$1</span>');
  }

  return text;
};

export const getClientPositions = (event: MouseEvent | TouchEvent) => {
  let top = 0, left = 0;

  if ('clientY' in event) {
    top = event.clientY;
    left = event.clientX;
  } else {
    if (event.changedTouches?.[0]) {
      const firstTouch = event.changedTouches[0];
      left = firstTouch.pageX;
      top = firstTouch.pageY;
    }
  }

  return {
    top,
    left,
  };
};

const checkIfElementIsInViewport = (viewport: HTMLElement, element: HTMLElement) => {
  return ( // If is in the viewport.
    viewport.scrollTop <= element.offsetTop
    && (viewport.scrollTop + viewport.offsetHeight) >= (element.offsetTop + element.offsetHeight)
  ) || ( // If is is bigger than the viewport and crosses top and bottom corners.
    viewport.scrollTop > element.offsetTop
    && (viewport.scrollTop + viewport.offsetHeight) < (element.offsetTop + element.offsetHeight)
  );
};

// Returns an index of the any message item that is in the viewport.
// Binary search is used for better performance until virtualized is not implemented.
export const findAnyChildIndexInViewport = (viewport: HTMLElement, children: HTMLCollection) => {
  let first = 0;
  let last = children.length - 1;
  let position = -1;
  let found = false;
  let middle;

  while (!found && first <= last) {
    middle = Math.floor((first + last) / 2);

    if (checkIfElementIsInViewport(viewport, children[middle] as HTMLElement)) {
      found = true;
      position = middle;
    } else if ((children[middle] as HTMLElement).offsetTop > viewport.scrollTop) {
      last = middle - 1;
    } else {
      first = middle + 1;
    }
  }

  return position;
};

export const calculateTextDimension = (
  text: string,
  classes: string[],
  styles: TooltipStyle[] = [],
) => {
  const dimensions = { width: 0, height: 0 };
  const div = document.createElement('div');
  div.setAttribute('class', classes.join(' '));

  if (styles.length) {
    for (let i: number = 0; i < styles.length; i++) {
      const style = styles[i];
      // @ts-ignore
      div.style[style.name] = style.value;
    }
  }
  div.innerHTML = text;

  document.body.appendChild(div);

  dimensions.width = div.clientWidth;
  dimensions.height = div.clientHeight;
  div.parentNode?.removeChild(div);

  return dimensions;
};
