import React from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import ScrollBar from 'react-custom-scrollbars-2';
import { Link } from 'react-router-dom';

import { fetchEvangelistStats } from '../../api/account';
import { useMainContext } from '../../MainProvider';
import { Button, LabelProfileStatus, Loader, Paper, Typography } from '../../components';
import { makeLabelFullName } from '../../utils/common';

import { useSidePanelContext } from '../../components/side-panel/SidePanelProvider';
import { MyCourses } from '../../components/courses/MyCourses';
import { CourseItem } from '../../components/courses/CourseItem';
import Scroll from '../../components/scroll/Scroll';
import { MyAchievements } from '../../components/achievements/MyAchievements';
import { AchievementItem } from '../../components/achievements/AchievementItem';
import { useUserRequests } from '../../hooks/useUserRequests';
import { useModalContext } from '../../components/modal/ModalProvider';
import { timeConvert } from '../../utils/time';
import { ShareStoryPopup } from '../../components/share-story-popup/ShareStoryPopup';
import Row from '../../components/row/Row';
import { isDev, URLS } from '../../utils/constants';
import { usePanelMyRequestLimits } from '../../components/side-panel/MyRequestLimits';
import { useGetProfile } from '../../query/profile';
import { useGetAchievements } from '../../query/achievements';
import { useGetCourses } from '../../query/courses';
import AeFontIcons from '../../assets/Icons';
import FontIconView from '../../assets/FontIconView';

import './profile.scss';
import { useGetSettings } from '../../query/settings';

export const QUERY_KEY_EVANGELIST_STATS = 'evangelistStats';

const ProfilePage = () => {
  const { t } = useTranslation();
  const { userData, currentRoleStatus } = useMainContext();
  const { profileData } = useGetProfile(userData.id);
  const { openModal, closeModal } = useModalContext();
  const { openPanel } = useSidePanelContext();
  const { unassigned, seekersAmount, total } = useUserRequests();
  const { openPanelMyRequestLimits } = usePanelMyRequestLimits();

  const { data: evangelistStats = {} } = useQuery<any, Error>(QUERY_KEY_EVANGELIST_STATS, () =>
    fetchEvangelistStats(userData)
  );
  
  const { settings } = useGetSettings();

  const { achievements, isAchievementsLoading } = useGetAchievements();

  const { courses, isCoursesLoading } = useGetCourses();

  const {
    profile: { avgScore, score, ...profile }
  } = profileData;

  const openSidePanel = (type: string) => {
    if (type === 'achievements') {
      openPanel({ component: <MyAchievements achievements={achievements} /> });
    } else {
      openPanel({ component: <MyCourses courses={courses} /> });
    }
  };

  const openShareModal = () => {
    openModal(<ShareStoryPopup title={t('generalStory')} />, {
      widthSize: 'sm',
      title: t('shareStory'),
      titleAlign: 'center'
    });
  };

  const renderMoreBtn = (type: string) => (
    <Button
      variant='link'
      color='blue'
      iconAfter={AeFontIcons.arrowRight}
      onClick={() => openSidePanel(type)}
    >
      {t('more')}
    </Button>
  );

  const chatScore = Number(evangelistStats?.aveMessageScore)?.toFixed(2);

  return (
    <ScrollBar autoHide>
      <div className="profile__grid">
        <Paper className="profile__item">
          <div className="profile-section">
            <Row directionHorizontal horizontal='space-between' autoColumns vertical='center'>
              <Typography
                component='h2'
                threeDots
                variant='poppins'
              >{t('hi_user', { value: makeLabelFullName(profile) })}!</Typography>
              <LabelProfileStatus status={currentRoleStatus} />
            </Row>
            <Row directionHorizontal autoColumns horizontal='space-between'>
              <Typography component='body1' threeDots>
                {t('totalUnassignedRequests')}&nbsp;
                <sup onClick={() => {
                  openModal((
                    <Row className="confirm-text" gap='lg'>
                      <p>{t('unassignedRequestsMsg')}</p>
                      <Row gap='lg' directionHorizontal horizontal="center" autoColumns>
                        <Button minWidth='sm' onClick={closeModal}>
                          {t('ok')}
                        </Button>
                      </Row>
                    </Row>
                  ), {
                    title: t('whatAreUnassignedRequests'),
                    widthSize: 'lg',
                    titleAlign: 'center',
                  });
                }}>
                  <FontIconView icon={AeFontIcons.info} />
                </sup>
              </Typography>
              <span className="profile-text-bold">{unassigned}</span>
            </Row>

            <div className="profile-section__text">
              {
                isDev ? <Link  to={URLS.checkProfiles}>{t('mySeekers')}</Link> : t('mySeekers')
              }
            </div>
            <Row directionHorizontal autoColumns horizontal='space-between' vertical='center'>
              <div className="profile-section__interests">
                <span className="profile-section__interests-count">{seekersAmount}</span>
                <span className="profile-section__interests-total">/{total}</span>
              </div>
              <Button variant="outline" color="blue" onClick={openPanelMyRequestLimits}>
                {t('changeLimits')}
              </Button>
            </Row>
          </div>
          <div className="question-block">
            <FontIconView icon={AeFontIcons.question} className="question-item" />
            <FontIconView icon={AeFontIcons.question} className="question-item md" />
            <FontIconView icon={AeFontIcons.question} className="question-item md" />
            <FontIconView icon={AeFontIcons.question} className="question-item" />
          </div>
        </Paper>
        <Paper className="profile__item">
          <div className="profile-performance">
            <div className="profile-title">{t('yourPerformance')}</div>
            <div className="profile-performance__props">
              <Row className="profile-performance__row" vertical='center'>
                <Typography component='body1' threeDots>
                  {t('responseTime')}
                </Typography>
                <FontIconView icon={AeFontIcons.clockFilled} />
                <span className="profile-text-bold">
                  {timeConvert(evangelistStats?.requests?.aveResponseTime)}
                </span>
              </Row>
              <Row className="profile-performance__row" vertical='center'>
                <Typography component='body1' threeDots>
                  {t('chatScore')}
                </Typography>
                <FontIconView icon={AeFontIcons.email} style={{ fontSize: '0.6rem' }} />
                <span className="profile-text-bold">{chatScore}</span>
              </Row>
              <Row className="profile-performance__row" vertical='center'>
                <Typography component='body1' threeDots>
                  {t('dmScore')}
                </Typography>
                <FontIconView icon={AeFontIcons.starFilled} />
                <span className="profile-text-bold">{score || '0'}</span>
              </Row>
            </div>
          </div>
        </Paper>
        <Paper
          className="profile__item"
          style={{ background: 'no-repeat center / cover url("/images/share-story.png")' }}>
          <div className="profile-share">
            <div className="profile-share__title">{t('shareStory')}</div>
            <Button className="profile-share__btn" onClick={openShareModal}>
              {t('share')}
            </Button>
          </div>
        </Paper>
        <Paper className="profile__item">
          <Row gap='huge'>
            <Row
              directionHorizontal
              autoColumns
              horizontal='space-between'
              vertical='center'
            >
              <span className="profile-title">{t('yourTraining')}</span>
            </Row>
            <Button
              style={{
                verticalAlign: 'top',
              }}
              size='sm'
              variant='outline'
              color='blue'
              className='youtubeLink'
              onClick={() => {
                window.open(
                  settings?.trainingVideos || 'https://www.youtube.com/@awrdigitalmissionaryapp',
                  '_blank' // <- This is what makes it open in a new window.
                );
              }}
              >{t('videoTrainingLesson')}</Button>
          </Row>
        </Paper>
        {/*
        {isCoursesLoading ? (
          <div className="profile__item">
            <Loader />
          </div>
        ) : (
          !isCoursesLoading &&
          courses.length !== 0 && (
            <Paper className="profile__item">
              <Row gap='huge'>
                <Row
                  directionHorizontal
                  autoColumns
                  horizontal='space-between'
                  vertical='center'
                >
                  <span className="profile-title">{t('yourCourses')}</span>
                  {renderMoreBtn('courses')}
                </Row>
                <Scroll horizontal style={{ height: 190, width: '100%' }}>
                  {courses.map((item) => (
                    <CourseItem key={item.trainingId} item={item} />
                  ))}
                </Scroll>
              </Row>
            </Paper>
          )
        )}
                  */ } 
        {isAchievementsLoading ? (
          <div className="profile__item">
            <Loader />
          </div>
        ) : (
          !isAchievementsLoading &&
          achievements.length !== 0 && (
            <Paper className="profile__item">
              <Row gap='huge'>
                <Row
                  directionHorizontal
                  autoColumns
                  horizontal='space-between'
                  vertical='center'
                >
                  <span className="profile-title">{t('yourAchievements')}</span>
                  {renderMoreBtn('achievements')}
                </Row>
                <Scroll horizontal style={{ height: 150, width: '100%' }}>
                  {achievements.map((item) => (
                    <AchievementItem key={item.keyName} item={item} />
                  ))}
                </Scroll>
              </Row>
            </Paper>
          )
        )}
      </div>
    </ScrollBar>
  );
};

export default ProfilePage;
