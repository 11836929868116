import React from 'react';

import { Row, Typography } from '../index';

type NoResultsTemplateProps = {
  title?: string,
  message?: string,
  style?: React.CSSProperties
};

const NoDataTemplate: React.FC<NoResultsTemplateProps> = (props) => {
  return (
    <Row
      gap='sm'
      horizontal='center'
      vertical='start'
      paddingHorizontal='lg'
      paddingVertical='biggest'
      style={props.style}
    >
      <Typography color='text-title-light' align='center' component='h2' variant='poppins'>
        {props.title}
      </Typography>
      <Typography color='text-title-light' align='center' component='body1'>
        {props.message}
      </Typography>
      {props.children}
    </Row>
  );
};

export default NoDataTemplate;
