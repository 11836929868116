import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, FormField, Input } from '../../../components';
import Form, { FormSubmitHandler } from '../../../components/form/Form';
import {
  FieldEmail,
  FieldFirstName,
} from '../../../components/form/Fields';
import Row from '../../../components/row/Row';
import { createMaxLengthValidator, isRequired } from '../../../components/form/Form.utils';

import './FormPastor.scss';

type FormPastorFormData = {
  pastorName: string,
  pastorEmail: string,
  pastorPhoneNumber: string,
}

type FormPastorProps = {
  slotBeforeButtons: any,
  phoneNumberCode: number | null,
  initialValues: FormPastorFormData,
  onSubmit: FormSubmitHandler<FormPastorFormData>,
  onBack: (values: FormPastorFormData) => void,
  style?: CSSProperties,
}

const FormPastor: React.FC<FormPastorProps> = ({
  initialValues, onBack, onSubmit, slotBeforeButtons, phoneNumberCode, style
}) => {
  const { t } = useTranslation();

  return (
    <Form
      style={style}
      className='form-pastor'
      fields={initialValues}
      onSubmit={onSubmit}
    >
      {
        ({ isSubmitting, values }) => (
          <>
            <FieldFirstName
              name="pastorName"
              labelText={t('pastorFullName')}
              validators={[isRequired, createMaxLengthValidator(80)]}
            />

            <Row gap='lg' directionHorizontal>
              <FieldEmail
                name="pastorEmail"
                labelText={t('pastorEmail')}
              />
              <FormField
                name="pastorPhoneNumber"
                labelText={t('pastorPhoneNumber')}
                component={Input}
                
                componentProps={{
                  placeholder: t('enterPhone'),
                  type: 'text',
                  autoComplete: 'off',
                }}
              />
            </Row>

            {slotBeforeButtons}

            <Row gap='lg' directionHorizontal>
              <Button type='button' color='gray' variant='opacity' onClick={() => {
                onBack(values);
              }}>{t('back')}</Button>
              <Button disabled={isSubmitting} type='submit'>{t('continue')}</Button>
            </Row>
          </>
        )
      }
    </Form>
  );
};

export default FormPastor;
