import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

import { useMainContext } from '../MainProvider';
import {
  fetchRequestCategories,
  getRequestsRequest,
  returnRequestRequest,
  setRequestStatusRequest
} from '../api/request';
import { useGetByIdWithCache } from '../hooks/useCache';
import { useToastEnhanced } from '../enhanced-components/toaster/ToasterEnhanced';
import { RequestCategoryItemType, RequestDenyReason, RequestItemType } from '../api/request.types';
import { useGetProfile } from './profile';
import { removeSeekerListItemsByIds } from './seekers';

export const QUERY_KEY_REQUESTS = 'requests';
export const QUERY_KEY_REQUEST_CATEGORIES = 'requestCategories';

export const useSetRequest = () => {
  const { t } = useTranslation();
  const { showToast } = useToastEnhanced();
  const queryClient = useQueryClient();
  const { userData, isUiRoleNotDM } = useMainContext();

  const closeRequestMutation = useMutation((id: string) => {
    return setRequestStatusRequest(userData, id, 'closed');
  }, {
    onSuccess: () => {
      // Note: 'invalidate' feasibly can be replaced with {setQueryData|.
      queryClient.invalidateQueries(QUERY_KEY_REQUESTS);

      showToast({
        title: t('success'),
      });
    }
  });

  const denyRequestMutation = useMutation((params: {
    id: string,
    reason: RequestDenyReason,
    seekerId: string,
  }) => {
    return returnRequestRequest(userData, params.id, params.reason);
  }, {
    onSuccess: (response, mutationPayload) => {
      // Note: 'invalidate' feasibly can be replaced with {setQueryData}.
      queryClient.invalidateQueries(QUERY_KEY_REQUESTS);
      
      removeSeekerListItemsByIds(queryClient, {
        ids: [mutationPayload.seekerId],
        isUiRoleLeader: isUiRoleNotDM,
        seekerListStatus: 'active',
      });

      showToast({
        title: t('success'),
      });
    }
  });

  return {
    closeRequestMutation,
    denyRequestMutation,
  };
};

const requestsInitialData: [] = [];

const getRequestId = (item: RequestItemType) => item.request.id;

// Note: for now only 'active' is supported.
export const useGetRequests = (
  params: { status: 'active', requestId?: string[], id?: string[] },
  options?: { enabled: boolean },
) => {
  const { userData } = useMainContext();

  const queryKey =  [QUERY_KEY_REQUESTS, ...Object.values(params)];

  const {
    data = requestsInitialData,
    isLoading,
    isError,
  } = useQuery(queryKey, () => getRequestsRequest(userData, params), options);

  const get = useGetByIdWithCache(queryKey, data, getRequestId);

  return {
    isRequestsLoading: isLoading,
    isRequestsError: isError,
    getRequestById: isLoading ? () => undefined : get,
    requests: data,
  };
};

// Note: for now only 'active' is supported.
export const useGetAllStatusesRequests = () => {
  return useGetRequests({ status: 'active' });
  // const {
  //   requests: activeRequests,
  //   isRequestsLoading: isActiveRequestsLoading,
  //   getRequestById: getActiveRequestById,
  // } = useGetRequests('active');
  // const {
  //   requests: inactiveRequests,
  //   isRequestsLoading: isInactiveRequestsLoading,
  //   getRequestById: getInactiveRequestById,
  // } = useGetRequests('inactive');
  //
  // return {
  //   isRequestsLoading: isActiveRequestsLoading || isInactiveRequestsLoading,
  //   getRequestById: (isActiveRequestsLoading || isInactiveRequestsLoading)
  //     ? () => undefined
  //     : (id: string) => getActiveRequestById(id) || getInactiveRequestById(id),
  //   requests: [...activeRequests, ...inactiveRequests],
  // };
};

const requestsCategoriesInitialData: [] = [];
const getRequestCategoryId = (item: RequestCategoryItemType) => item.id;

export const useGetRequestsCategories = (config?: {
  staleTime?: number,
  enabled?: boolean,
}) => {
  const { userData } = useMainContext();
  const { profileData } = useGetProfile(userData.id);

  const {
    isLoading,
    data = requestsCategoriesInitialData,
    isError,
  } = useQuery(QUERY_KEY_REQUEST_CATEGORIES, () => {
    return fetchRequestCategories(userData, profileData.languages);
  }, { enabled: !!profileData, ...config, });
  const get = useGetByIdWithCache(QUERY_KEY_REQUEST_CATEGORIES, data, getRequestCategoryId);

  return {
    isRequestsCategoriesLoading: isLoading,
    isRequestsCategoriesError: isError,
    getRequestCategoryById: get,
    requestCategories: data,
  };
};
