import React from 'react';
import classnames from 'classnames';

import AeFontIcons from '../../assets/Icons';
import FontIconView from '../../assets/FontIconView';

import './SelectArrow.scss';

type SelectArrowProps = {
  className?: string,
  isRotated: boolean,
};

const SelectArrow: React.FC<SelectArrowProps> = (props) => {
  return (
    <FontIconView
      className={classnames('select-arrow', props.className, {
        'is-rotated': props.isRotated,
      })}
      icon={AeFontIcons.arrowDown}
    />
  );
};

export default SelectArrow;
