import React  from 'react';
import classnames from 'classnames';

import { ColorScheme } from '../../types';

import './Divider.scss';

const Divider: React.FC<React.HTMLProps<HTMLDivElement & { color?: ColorScheme }>> = ({
  className,
  children,
  color,
  ...restProps
}) => {
  return (
    <div className={classnames('divider', color, className)} {...restProps}>
      {children && <span className="divider__text">{children}</span>}
    </div>
  );
};

export default Divider;
