import { apiRequest } from '../api';
import { ApiParamUserData } from '../api.types';
import { VetterItemApi } from './vetters.types';
import { normalizeVetters } from './vetters.utils';

export const getVettersListRequest = (userData: ApiParamUserData) =>
  apiRequest<{ vetters: VetterItemApi[] }>('getVetterList', {
    vetterId: userData.id,
    agentToken: userData.agentToken
  }).then((r) => normalizeVetters(r.vetters));

export const setEvangelistVetterRequest = (
  userData: ApiParamUserData,
  evangelistId: string,
  newVetterId: string
) =>
  apiRequest('setEvangelistVetter', {
    vetterId: userData.id,
    evangelistId,
    newVetterId,
    agentToken: userData.agentToken
  });
