import React, { CSSProperties, forwardRef, SyntheticEvent, useState } from 'react';
import classnames from 'classnames';

import './InputWrapper.scss';

type InputWrapperSize = 'sm' | 'lg';
type InputWrapperBorderRadius = 'md' | 'extra-lg';

const InputWrapperBorderRadiusToClassNameMap: Record<InputWrapperBorderRadius, string> = {
  md: 'br-md',
  'extra-lg': 'br-extra-lg',
};

export type InputWrapperProps = {
  className?: string,
  style?: CSSProperties,

  invalid?: boolean,
  disabled?: boolean,
  size?: InputWrapperSize,
  borderRadius?: InputWrapperBorderRadius,

  onClick?: (e: SyntheticEvent<HTMLDivElement, MouseEvent>) => void,
  onFocus?: (e: SyntheticEvent<HTMLDivElement, FocusEvent>) => void,
  onBlur?: (e: SyntheticEvent<HTMLDivElement, FocusEvent>) => void,
  children?: React.ReactNode,
};

const InputWrapper = forwardRef<HTMLDivElement, InputWrapperProps>((props, ref) => {
  const [focused, setFocused] = useState(false);

  return (
    <div
      ref={ref}
      className={classnames(
        'input-wrapper',
        props.className,
        props.borderRadius ? InputWrapperBorderRadiusToClassNameMap[props.borderRadius] : '',
        props.size, {
          'invalid':props.invalid,
          'focus': focused,
          'disabled': props.disabled,
        }
      )}
      onFocus={(e) => {
        setFocused(true);

        if (props.onFocus) {
          props.onFocus(e);
        }
      }}
      onBlur={(e) => {
        setFocused(false);

        if (props.onBlur) {
          props.onBlur(e);
        }
      }}
      onClick={(e) => {
        if (!props.disabled && props.onClick) {
          props.onClick(e);
        }
      }}
      style={props.style}
    >{props.children}</div>
  );
});

InputWrapper.defaultProps = {
  size: 'lg',
  borderRadius: 'md'
};

export default InputWrapper;
