import React, {useCallback, useRef} from 'react';

import { Button, Input, Popup, SelectListDefault } from '../index';
import {usePopup} from '../popup/Popup';
import InputClean from '../input/InputClean';
import {clutch} from '../../utils/common';
import {addLeadingZeroToTime} from '../../utils/time';
import {TimePickerProps} from './TimePicker.types';
import SelectArrow from '../select/SelectArrow';

import './TimePicker.scss';

export const TIME_PICKER_SEPARATOR = ':';

const timePickerSelectOptions = new Array(12).fill(0).map((_, i) => {
  const label = ((i + 1) < 10 ? '0' + (i + 1) : (i + 1)) + (TIME_PICKER_SEPARATOR + '00');

  return {
    key: label,
    label,
  };
});

const TimePicker: React.FC<TimePickerProps> = (props) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const { isOpen, closePopup, openPopup, anchorEl } = usePopup();

  const hidePopup = useCallback(() => {
    closePopup();
  }, [closePopup]);

  const togglePopup = useCallback(() => {
    if (isOpen) {
      hidePopup();
    } else {
      openPopup({
        currentTarget: rootRef.current
      });
    }
  }, [isOpen, hidePopup, openPopup]);

  return (
    <div ref={rootRef}>
      <Input
        className='time-picker_input-hours'
        size={props.size}
        value={props.value.hours}
        onChange={(e, value) => {
          const valueParsed = value.replace(/[\D]/g, '').slice(0, 2);

          props.onChange(e, {
            hours: valueParsed ? clutch(Number(valueParsed), 1, 12).toString() : '',
            minutes: props.value.minutes,
            am: props.value.am,
          });
        }}
        onBlur={(e) => { // e.g. update value from '3' to '03' on blur.
          props.onChange(e, {
            hours: addLeadingZeroToTime(Math.max(Number(props.value.hours), 1)),
            minutes: props.value.minutes,
            am: props.value.am,
          });
        }}
        slotAfter={(inputProps) => {
          return (
            <>
              <InputClean
                tabIndex={-1}
                readOnly
                className='time-picker_separator'
                value={TIME_PICKER_SEPARATOR}
              />
              <InputClean
                {...inputProps}
                className='time-picker_input-minutes'
                value={props.value.minutes}
                onChange={(e, value) => {
                  const valueParsed = value.replace(/[\D]/g, '').slice(0, 2);

                  props.onChange(e, {
                    hours: props.value.hours,
                    minutes: valueParsed ? clutch(Number(valueParsed), 0, 59).toString() : '',
                    am: props.value.am,
                  });
                }}
                onBlur={(e) => { // e.g. update value from '9' to '09' on blur.
                  props.onChange(e, {
                    hours: props.value.hours,
                    minutes: addLeadingZeroToTime(Number(props.value.minutes)),
                    am: props.value.am,
                  });
                }}
              />
              <Button
                type='button' // Prevent form submit.
                size='extra-sm'
                variant='opacity'
                color='gray'
                onClick={(e) => {
                  props.onChange(e, {
                    hours: props.value.hours,
                    minutes: props.value.minutes,
                    am: !props.value.am,
                  });
                }}
              >{props.value.am ? 'AM' : 'PM'}</Button>
              <Button
                type='button' // Prevent form submit.
                variant='link'
                color='text-title-light'
                onClick={togglePopup}
                iconBefore={<SelectArrow className='select-input_arrow' isRotated={isOpen} />}
              />
            </>
          );
        }}
      />
      <Popup
        isShown={isOpen}
        anchorEl={anchorEl}
        preferOpenToBottom
        innerProps={{
          style: {
            width: rootRef.current?.offsetWidth
          },
        }}
        minHeight={155} // To show at least 3 options.
        onHide={hidePopup}
      >
        <SelectListDefault
          scrollbarsProps={{
            staticScrollView: true,
            autoHeight: true,
            autoHeightMax: 228,
          }}
          value={props.value.hours + TIME_PICKER_SEPARATOR + props.value.minutes}
          onChange={(e, option) => {
            const [hours, minutes] = option.key.split(TIME_PICKER_SEPARATOR);
            props.onChange(e, {
              hours,
              minutes,
              am: props.value.am
            });

            hidePopup();

            // Fix popup is reopened: {Field} uses "label" element, that triggers
            // redundant {onClick} on its children and popup reopens.
            e.preventDefault();
          }}
          options={timePickerSelectOptions}
        />
      </Popup>
    </div>
  );
};

export default TimePicker;
