import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Scrollbars from 'react-custom-scrollbars-2';

import AeFontIcons from '../../../assets/Icons';
import { Button, ListItemRemove, Typography } from '../../../components';
import { useModalContext } from '../../../components/modal/ModalProvider';
import { arrayMove } from '../../../utils/array';
import ModalUserLanguages from './ModalUserLanguages';
import Select from '../../../components/select/Select';
import Field from '../../../components/form/field/Field';
import {
  useGetAvailableLanguages,
  useGetUserLanguages,
  useModifyUserLanguages
} from '../../../query/languages';

const ViewLanguages = () => {
  const { t } = useTranslation();
  const { openModal, closeModal } = useModalContext();

  const {
    availableLanguages,
    isAvailableLanguagesLoading,
    getAvailableRequestLanguageByLanguage,
  } = useGetAvailableLanguages();
  const { userLanguages, isUserLanguagesLoading } = useGetUserLanguages({
    staleTime: 0,
  });
  const { modifyUserLanguagesMutation } = useModifyUserLanguages();

  const renderUserLanguage = (language: string) => {
    const isPrimaryLanguage = userLanguages?.primaryLanguage
      ? language === userLanguages?.primaryLanguage
      : false;

    return (
      <ListItemRemove
        key={language}
        disabled={isPrimaryLanguage}
        onClickRemove={() => {
          modifyUserLanguagesMutation.mutate({
            language: language,
            action: 'delete',
          });
        }}
      >
        {getAvailableRequestLanguageByLanguage(language)?.name}&nbsp;
        {
          isPrimaryLanguage && (
            <Typography tagName='span' component='body1' color='blue'>
               ({t('applicationLanguage')})
            </Typography>
          )
        }
      </ListItemRemove>
    );
  };

  const handleClickAddLanguage = () => {
    openModal((
      <ModalUserLanguages
        languagesAdded={userLanguages?.languages || []}
        onAddLanguages={async ({ values }) => {
          modifyUserLanguagesMutation.mutate({
            language: values.languages.map((i) => i.key),
            action: 'add',
          });
          closeModal();
        }}
        onCancel={closeModal}
      />
    ), {
      title: t('addLanguage'),
      widthSize: 'md'
    });
  };

  const languagesOptions = useMemo(() => {
    return availableLanguages?.map((item) => ({
      key: item.language,
      label: item.name,
    })) || [];
  }, [availableLanguages]);

  const languageList = useMemo(() => {
    return userLanguages?.primaryLanguage
      ? arrayMove( // Does 'primaryLanguage' first in the list.
        userLanguages.languages,
        userLanguages?.languages.findIndex((lang) => {
          return lang === userLanguages?.primaryLanguage;
        }),
        0,
      )
      : userLanguages?.languages || [];
  }, [userLanguages.languages, userLanguages?.primaryLanguage]);

  return (
    <>
      <Typography component='h4' variant='poppins'>{t('applicationLanguage')}</Typography>

      <Field size="sm" labelText={t('language')}>
        <Select
          disabled={isUserLanguagesLoading}
          placeholder={t('enterCity')}
          value={userLanguages?.primaryLanguage || ''}
          onChange={(e, option) => {
            modifyUserLanguagesMutation.mutate({
              language: option.key,
              action: 'primary',
            });
          }}
          options={languagesOptions}
        />
      </Field>

      <Typography component='h4' variant='poppins'>{t('requestLanguages')}</Typography>
      <Scrollbars
        autoHeight
        autoHeightMax={260}
        renderView={(props) => <ul {...props} />}
      >
        {languageList.map(renderUserLanguage)}
      </Scrollbars>

      <Button
        disabled={isAvailableLanguagesLoading}
        variant='outline'
        color='blue'
        onClick={handleClickAddLanguage}
        iconBefore={AeFontIcons.plus}
      >
        {t('addLanguage')}
      </Button>
    </>
  );
};

export default ViewLanguages;
