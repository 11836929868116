import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { useMainContext } from '../../MainProvider';
import {
  isServiceWorkerSupported,
  Routes,
  SERVER_URL,
  URLS
} from '../../utils/constants';
import { Button, DividerVertical, UserIcon } from '../index';
import { dashToCamel, makeLabelFullName } from '../../utils/common';
import {
  useUserPrimaryLanguage
} from '../../hooks/useLanguage';
import { Tooltip } from '../tooltip/Tooltip';
import Row from '../row/Row';
import {useNotificationContext} from '../../PushNotificationsProvider';
import useModalNotifications from './ModalNotifications';
import SelectVariantButton from '../select/SelectVariantButton';
import AeFontIcons from '../../assets/Icons';
import FontIconView from '../../assets/FontIconView';
import {useModalContext} from '../modal/ModalProvider';
import {createLocalStorageManager} from '../../utils/storage';
import Typography from '../typography/Typography';
import { SelectOption } from '../select/Select.types';
import { useGetAvailableLanguages, useModifyUserLanguages } from '../../query/languages';
import { useGetProfile } from '../../query/profile';

import './Header.scss';
import { fetchTranslationRequest, loadTranslations } from '../../api/languages';

const enableNotificationsPassedUsers = createLocalStorageManager<string[]>('enableNotifyMsg');

const Header = () => {
  const history = useHistory();
  const { toggleMenu, userData, isUiRoleNotDM, uiRole, isRoleAdmin } = useMainContext();
  const { profileData } = useGetProfile();
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { openModal } = useModalContext();
  const { modifyUserLanguagesMutation } = useModifyUserLanguages();
  const { userPrimaryLanguageObj } = useUserPrimaryLanguage();
  const {availableLanguages: languages} = useGetAvailableLanguages();
  const languageShort = userPrimaryLanguageObj?.language;
  const {openModalNotifications} = useModalNotifications();
  const [notificationModalOpened, setNotificationModalOpened] = useState(false); 
  const {
    notificationsList,
    setNotificationsList,
    isAppReceivesNotifications,
    isNotificationsInit,
    isPushPermissionGranted,
  } = useNotificationContext();

  const renderInfo = () => {
    if (isRoleAdmin) {
      return (
        <div className='header__profile'>
          <div className='header__name'>
            {t('hi')},&nbsp;<strong>admin</strong>
          </div>
        </div>
      );
    }

    return (
      <Tooltip content={t('myInformation')}>
        <Link className='header__profile with-hover' to={URLS.myInformation}>
          <div className='header__name'>
            {t('hi')},&nbsp;<strong>{makeLabelFullName(profileData.profile)}</strong>
          </div>
          <div className='header__icon'>
            <UserIcon item={{ ...profileData.profile, id: userData.id }} />
          </div>
        </Link>
      </Tooltip>
    );
  };

  const renderHeaderTitle = () => {
    const currentRoute = Routes.find((item) => {
      return item.role?.includes(uiRole) && (item.url === pathname || pathname.includes(item.url));
    });

    if (currentRoute) {
      if (currentRoute.fullPath) {
        const urlArr = pathname.split('/').filter(Boolean);

        return (
          <Row directionHorizontal layout="flex" vertical="center" gap="sm">
            {urlArr.map((url, index) => {
              const lastElement = urlArr.length - 1 === index;

              if (lastElement) {
                return (
                  <Typography threeDots variant="poppins" component="h3" key={url}>
                    {t(dashToCamel(url))}
                  </Typography>
                );
              }

              return (
                <React.Fragment key={url}>
                  <Typography threeDots variant="poppins" component="h3">
                    {t(dashToCamel(url))}
                  </Typography>
                  <Typography color="text-title-light" component="body4">
                    <FontIconView icon={AeFontIcons.arrowRight} style={{ display: 'flex' }} />
                  </Typography>
                </React.Fragment>
              );
            })}
          </Row>
        );
      }

      return (
        <Row directionHorizontal vertical="center" gap="sm">
          <Typography variant="poppins" threeDots component="h3">
            {t(currentRoute.label)}
          </Typography>
        </Row>
      );
    }

    return <Typography variant="poppins" component="h3">AWR 360°</Typography>;
  };

  const onChangeLang = async (e: any, option: SelectOption) => {
    if (option.key === languageShort) {
      return;
    }
    

    modifyUserLanguagesMutation.mutate({
      action: 'primary',
      language: option.key,
    });
    await loadTranslations(i18n, option.key);
    localStorage.setItem('userLang', option.key);
  };

  const languagesOptions = languages.map((language) => {
    return {
      key: language.language,
      label: language.name,
    };
  });

  const notificationListNotViewedCount = useMemo(() => {
    return notificationsList.filter((item) => !item.viewed).length;
  }, [notificationsList]);

  // This is here as this component already have almost all properties to show the message.
  useEffect(() => {
    if (
      isNotificationsInit
      && (!isPushPermissionGranted || !isServiceWorkerSupported)
    ) {
      const passedUsers = enableNotificationsPassedUsers.load();
      const passedUsersUserId = userData.id + userData.role;

      if (!passedUsers || !passedUsers.includes(passedUsersUserId)) {
        if(!notificationModalOpened){
          openModal(t(!isServiceWorkerSupported
            ? 'notificationsNoSupport'
            : 'notificationsDisabled'), {
            title: t('notifications'),
            widthSize: 'md',
            okButton: true,
            hideOnBackdropClick: false,
            onClose: () => {
              enableNotificationsPassedUsers.save(
                passedUsers ? [...passedUsers, passedUsersUserId] : [passedUsersUserId]
              );
            },
          });
          setNotificationModalOpened(true);
        }
      }
    }
  }, [
    t,
    userData.id,
    userData.role,
    isNotificationsInit,
    isPushPermissionGranted,
    
  ]);

  return (
    <Row
      className='header'
      directionHorizontal
      vertical='center'
      bgColor='white'
      horizontal='space-between'
      paddingHorizontal='lg'
    >
      {window.apiUrlNew === SERVER_URL.prod && (
        <div className='server-status-box'>prod</div>
      )}
      <Row directionHorizontal autoColumns vertical='center' gap='extra-lg' horizontal='start'>
        <div className='header__menu-icon'>
          <Tooltip content={t('menu')}>
            <Button
              variant='transparent'
              iconBefore={AeFontIcons.menu}
              onClick={toggleMenu}
            />
          </Tooltip>
        </div>
        {history.location.pathname !== URLS.main && (
          <Tooltip content={t('goBack')}>
            <Button
              size='sm'
              typographyVariant='poppins'
              variant='transparent'
              color='blue'
              className='header__back'
              iconBefore={(
                <FontIconView icon={AeFontIcons.arrowLeft} style={{ verticalAlign: 'middle' }} />
              )}
              onClick={() => history.goBack()}
            >
              {t('back')}
            </Button>
          </Tooltip>
        )}
        {renderHeaderTitle()}
      </Row>
      <Row directionHorizontal autoColumns vertical='center' gap='extra-lg' horizontal='end'>
        {!isUiRoleNotDM && (
          <Tooltip content={t('knowledgeBase')}>
            <Link className='header__knowledge' to={URLS.knowledge}>
              <Button
                variant='transparent'
                color='gray'
              >
                <FontIconView icon={AeFontIcons.book} style={{ fontSize: '1rem' }} />
              </Button>
            </Link>
          </Tooltip>
        )}
        <Tooltip content={t('notifications')} className='header__notification'>
          <Button
            variant='transparent'
            color='gray'
            onClick={() => {
              openModalNotifications();

              if (notificationListNotViewedCount) {
                setNotificationsList(notificationsList.map((item) => {
                  return {
                    ...item,
                    viewed: true,
                  };
                }));
              }
            }}
          >
            <FontIconView
              icon={isAppReceivesNotifications ? AeFontIcons.bell : AeFontIcons.bellCrossed}
              style={{ fontSize: '1.2rem' }}
            />
            {notificationListNotViewedCount
              ? <span className='header__notification-indicator' />
              : ''}
          </Button>
        </Tooltip>

        <Tooltip content={t('myLanguages')}>
          <SelectVariantButton
            listProps={{
              withFilter: false,
            }}
            popupMinWidth='sm'
            value={languageShort || ''}
            onChange={onChangeLang}
            options={languagesOptions}
          />
        </Tooltip>
        <DividerVertical />
        {renderInfo()}
      </Row>
    </Row>
  );
};

export default Header;
