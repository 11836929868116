import { apiRequest } from './api';
import { urlify } from '../utils/string';
import { ApiParamUserData } from './api.types';

export type AttachmentType = 'audio' | 'video' | 'image' | 'text';

export type TemplateFromApi = {
  id: string;
  evangelistId?: string;
  language: string;
  media: string;
  mediaSize?: string;
  parentId?: string;
  resource: string;
  supplement?: any;
  thumbnail?: string;
  title: string;
}

export type Template = {
  id: string;
  evangelistId?: string;
  language: string;
  type: AttachmentType;
  mediaSize?: string;
  resource?: string;
  parentId?: string;
  text: string;
  textUrlified: string;
  supplement?: any;
  thumbnail?: string;
  title: string;
}

export type TreeItem = {
  title: string;
  id: string;
  children?: TreeItem[];
  searchResults?: number;
  searchWords?: string;
  articles?: number;
}

export type Resource = {
  id: string;
  title: string;
  articles?: number;
  searchResults?: number;
  searchWords?: string;
  children?: Resource[] | [];
}

export const normalizeResourceList = (list: TemplateFromApi[] = []): Template[] => {
  return list
    .filter((item) => !!item)
    .map((item) => {
      const text = (item.resource || '')
        .replace(/<p>/g, '')
        .replace(/<\/p>/g, '\n')
        .replace(/&nbsp;/g, ' ')
        .replace(/<\/?[^>]+(>|$)/g, '');

      return {
        ...item,
        id: item.id,
        text,
        textUrlified: urlify(text),
        type: item.media as AttachmentType,
      };
    });
};

// Get pre-written resource for given evangelist and seeker.
// Supports fields that get exchanged with evangelist and/or seeker info.
export const fetchResourcesContent = (userData: ApiParamUserData, language: string, params: {
  parentIds?: string[], // array of parent ID of resources to be retrieved
  resourceIds?: string[], // array of resource IDs to be retrieved
}) => {
  return apiRequest('getResource', {
    evangelistId: userData.id,
    agentToken: userData.agentToken,
    language,
    ...params
  }).then((response) => normalizeResourceList(response.content));
};

export type ResourcesListType = 'kb' | 'faq';

// Get index of resource data for searching purpose
export const fetchResourcesList = (userData: ApiParamUserData, params: {
  language: string, // language code for desired resources
  type: ResourcesListType, // Can be ‘kb’ or ‘faq’. Defaults to ‘kb’.
  search?: string // text string with space-separated search words
}) => {
  return apiRequest<{
    map: Resource[]
  }>('getResourceMap', {
    evangelistId: userData.id,
    agentToken: userData.agentToken,
    ...params
  }).then((response) => response.map);
};
