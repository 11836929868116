import {ReminderItemType, ReminderItemTypeApi} from './reminder.types';
import {toLocalDateString} from '../utils/time';

export const normalizeReminders = (reminders: ReminderItemTypeApi[]): ReminderItemType[] => {
  return reminders.map((reminder) => {
    return {
      ...reminder,
      schedule: toLocalDateString(reminder.schedule),
    };
  });
};
