import { TreeLanguageItem, TreeLanguageItemApi } from './treeLanguages.types';

export const normalizeTreeLanguageItem = (item: TreeLanguageItemApi): TreeLanguageItem => {
  return {
    id: item.language,
    isAttachment: Number(item.kb) === 1,
    isFaq: Number(item.faq) === 1,
    isLeaderFaq: Number(item.leaderfaq) === 1,
  };
};
