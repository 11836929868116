import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FormField,
  Form,
  Select,
  Tabs,
  Typography,
  CheckList, ModalActions
} from '../../../components';
import { Country } from '../../../api/account';
import Row from '../../../components/row/Row';
import { FormChildrenProps, FormSubmitHandler } from '../../../components/form/Form';
import InputTags from '../../../components/input/InputTags';
import {MultiSelectValue} from '../../../components/multi-select/MultiSelect.types';
import { useGetCities } from '../../../query/locations';
import { SelectOption } from '../../../components/select/Select.types';

type ModalUserLocationsFormData = {
  country: SelectOption,
  cities: MultiSelectValue,
  postalCodes: string[]
};

type ModalUserLocationsProps = {
  citiesAdded: string[],
  isMyLocations?: boolean,
  countries: Country[],
  isCountriesLoading?: boolean,
  onCancel: () => void,
  onSubmit: FormSubmitHandler<ModalUserLocationsFormData>,
}

const ModalUserLocations: React.FC<ModalUserLocationsProps> = ({
  countries,
  isCountriesLoading,
  citiesAdded,
  isMyLocations = false,
  onSubmit,
  onCancel
}) => {
  const { t } = useTranslation();
  const [country, setCountry] = useState('');

  const {
    cities = [],
    isCitiesLoading,
  } = useGetCities(country);

  const countriesOptions = countries.map(({ country, abbreviation }) => {
    return {
      key: abbreviation,
      label: country
    };
  });

  const citiesItems = cities.filter((city) => !citiesAdded.includes(city)).map((item) => {
    return {
      key: item,
      label: item
    };
  });

  const fields: ModalUserLocationsFormData = {
    country: {
      key: '',
      label: ''
    },
    cities: [],
    postalCodes: [],
  };

  return (
    <Form
      style={{ height: '28.8rem' }}
      fields={fields}
      onSubmit={onSubmit}
    >
      {
        ({ values }: FormChildrenProps<ModalUserLocationsFormData>) => {
          return (
            <Row gap='extra-lg' style={{
              height: '100%',
              alignContent: 'space-between',
            }}>
              <Tabs head={[t('countryCity'), t('zipCode')]}>
                <Row gap='extra-lg'>
                  <FormField
                    size='sm'
                    showMarkRequired
                    name='country'
                    component={Select}
                    labelText={t('country')}
                    componentProps={{
                      disabled: isCountriesLoading,
                      placeholder: t('enterCountry'),
                      options: countriesOptions,
                      onChange: (e: any, { label }: any) => {
                        setCountry(label);
                      },
                    }}
                  />

                  {
                    country
                      ? (
                        <FormField
                          size='sm'
                          name='cities'
                          component={CheckList}
                          componentProps={{
                            options: citiesItems,
                            isLoading: isCitiesLoading,
                          }}
                        />
                      )
                      : (
                        <Typography
                          component='body1'
                          color='text-title-light'
                          align='center'
                        >
                          {t('chooseCountryLocation')}
                        </Typography>
                      )
                  }
                </Row>

                <FormField
                  size='sm'
                  labelText={t('postalCode')}
                  name='postalCodes'
                  component={InputTags}
                  componentProps={{
                    placeholder: t('zipPostalCommaSeparated'),
                  }}
                />
              </Tabs>

              <ModalActions
                btnCancelProps={{
                  onClick: onCancel,
                }}
                btnOkProps={{
                  disabled: (!values.cities.length && !isMyLocations) && !values.postalCodes.length,
                  children: t('add'),
                }}
              />
            </Row>
          );
        }
      }
    </Form>
  );
};

export default ModalUserLocations;
