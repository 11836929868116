import React  from 'react';
import classNames from 'classnames';

import { Typography } from '../../index';

import './FieldLabel.scss';

export type FieldLabelSize = 'sm' | 'lg';

export type FieldLabelProps = {
  showMarkRequired?: boolean;
  size?: FieldLabelSize;
  disabled?: boolean;
}

const FieldLabel: React.FC<FieldLabelProps> = (props) => {
  return (
    <Typography
      color={props.disabled ? 'gray-light' : 'text-title-light'}
      component={props.size === 'sm' ? 'h5' : 'h4'}
      className={classNames('field-label', props.size)}
    >{props.children} {props.showMarkRequired ? '*' : ''}</Typography>
  );
};

export default FieldLabel;
