import React, { createContext, useContext, useState } from 'react';

import { SidePanel } from './SidePanel';

import './side-panel.scss';

interface SidePanelContextProps {
  openPanel: (content: PanelStackItem) => void;
  closePanel: () => void;
  closeAllPanels: () => void;
  isOpenSidePanel: boolean;
}

type PanelOptions = {

};

export type PanelStackItem = {
  component: React.ReactNode;
  options?: PanelOptions;
};

const SidePanelContext = createContext<SidePanelContextProps>({} as SidePanelContextProps);

// TODO: Implement {useSidePanelStack}. The example of implementation is {useFloatWindowStack}.
const SidePanelProvider: React.FC = (props) => {
  const [panelStack, setPanelStack] = useState<PanelStackItem[]>([]);

  return (
    <SidePanelContext.Provider value={{
      openPanel: ({ component, options = {} }: PanelStackItem) => {
        setPanelStack((oldStack) => [...oldStack, { component, ...options }]);
      },
      closePanel: () => {
        setPanelStack((oldStack) => {
          oldStack.pop();

          return [...oldStack];
        });
      },
      closeAllPanels: () => {
        setPanelStack([]);
      },
      isOpenSidePanel: !!panelStack.length,
    }}>
      {props.children}
      <SidePanel content={panelStack} />
    </SidePanelContext.Provider>
  );
};

export const useSidePanelContext = () => useContext<SidePanelContextProps>(SidePanelContext);

export default SidePanelProvider;
