import React, { useState, useEffect, CSSProperties, SyntheticEvent } from 'react';
import classNames from 'classnames';

import { Typography } from '../index';
import { canHover } from '../../utils/user-agent';
import { calculateTextDimension } from '../../utils/dom';

import './tooltip.scss';

type DirectionType = 'top' | 'bottom';

interface TooltipProps {
  children: React.ReactNode;
  className?: string,
  content: string;
  delay?: number;
}

export type TooltipStyle = { name: keyof React.CSSProperties, value: string };

// Unstable code - fontSize value hardcoded for now, but could be changed for tablet or mobile
const tooltipTextStyles: TooltipStyle[] = [
  {name: 'fontSize', value: '0.7rem' },
  {name: 'fontFamily', value: 'Roboto, sans-serif' },
  {name: 'padding', value: '0 0.66rem' },
];

export const Tooltip: React.FC<TooltipProps> = ({
  children,
  content,
  delay = 500,
  className,
}) => {
  const timeoutRef = React.useRef<any>();
  const [active, setActive] = useState<boolean>(false);
  const [style, setStyle] = useState<CSSProperties>({});
  const [position, setPosition] = useState<DirectionType>('top');

  const showTip = (e: SyntheticEvent<HTMLDivElement, MouseEvent>) => {
    const { width: tooltipWidth } = calculateTextDimension(
      content,
      ['tooltip__tip'],
      tooltipTextStyles,
    );

    // take children rect because parent rect could be wider
    const currentTargetRect = e.currentTarget.children[0].getBoundingClientRect();

    timeoutRef.current = setTimeout(() => {
      setActive(true);

      const onBottom = window.innerHeight - currentTargetRect.bottom > currentTargetRect.top;
      const onRight = window.innerWidth - currentTargetRect.right < tooltipWidth;

      const style: CSSProperties = {
        top: onBottom ? currentTargetRect.bottom : currentTargetRect.top,
      };

      if (onRight) {
        const right = window.innerWidth - (currentTargetRect.left + currentTargetRect.right) / 2;
        const halfTooltipWidth = tooltipWidth / 2;
        let rightDiff = 0;

        if (halfTooltipWidth > right) {
          rightDiff = halfTooltipWidth - right;
        }

        style.right = right - tooltipWidth + rightDiff;
      } else {
        style.left = currentTargetRect.left + (currentTargetRect.width / 2);
      }

      setPosition(onBottom ? 'bottom' : 'top');
      setStyle(style);
    }, delay);
  };

  const hideTip = () => {
    setActive(false);
    clearInterval(timeoutRef.current);
  };

  useEffect(() => {
    return () => clearInterval(timeoutRef.current);
  }, []);

  return (
    <div
      className={className}
      onMouseEnter={canHover ? showTip : undefined}
      onMouseLeave={canHover ? hideTip : undefined}
    >
      {children}
      {active && (
        <Typography
          component='h6'
          color='text-title-light'
          className={classNames('tooltip__tip', position)}
          style={style}
        >{content}</Typography>
      )}
    </div>
  );
};
