import { RequestErrorStatus } from './api.types';
import { isDev } from '../utils/constants';

export const token = '4UzUFgaJ;f87{X+TjSSD=3dvS[YpZG';

export class ApiRequestError extends Error {
  status;
  cmdResponse;

  constructor(message: string, status: RequestErrorStatus, cmdResponse?: string) {
    super(message);
    this.status = status;
    this.cmdResponse = cmdResponse;
  }
}

export const consoleLogRequestResult = (
  name: string, time: string, params: any, result: any, isError?: boolean
) => {
  /* eslint-disable no-console */
  if (isDev) {
    console.groupCollapsed((isError ? '🔴' : '🟢') +  'Request ' + name);
    console.log('====Time====');
    console.log(time + 'ms');
    console.log('====Params====');
    try {
      // @ts-ignore
      console.log(JSON.parse(params));
    } catch (e) {
      console.log(params);
    }
    console.log('====' + (isError ? 'Error' : 'Result') + '====');
    console.log(result);
    console.groupEnd();
  }
};
