import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Badge, FontIconView, NoDataTemplate, Row, Typography } from '../index';
import { Resource, TreeItem } from '../../api/resources';
import { Color } from '../../types';
import Scroll from '../scroll/Scroll';
import { useUrlQuery } from '../../hooks/useUrlQuery';
import AeFontIcons from '../../assets/Icons';
import { findNode } from '../../utils/array';
import { gather } from '../../utils/common';

import '../../pages/knowledge/knowledge-page.scss';
import { useModalTextTemplate } from '../../pages/knowledge/ModalTextTemplate';
import { useModalModifyTextTemplate } from '../../pages/knowledge/ModalModifyTextTemplate';

interface TreeViewProps {
  tree: TreeItem[];
  parentId?: string;
  search?: string;
  onItemClick: (id: string) => void;
  activeId?: string | null;
  isFaq?: boolean;
}

interface KnowledgeItem extends Resource {
  hasChildren: boolean;
  level: number;
}

export const levels: Color[] = ['blue', 'green', 'orange', 'pink'];

export const checkChildren = (item: TreeItem) => {
  if (item.children) {
    const result = item.children.find((child) => !!child.children);
    if (result) {
      return true;
    }
  }
  return false;
};

const makeTreeList = (
  tree: TreeItem[],
  expanded: string[],
  level = 0,
  search: string,
) => {
  const outList = [];

  for (let i = 0; i < tree.length; i++) {
    const item = tree[i];

    // skip the item if it doesn't have a search results
    if (search && !item.searchResults) {
      continue;
    }

    let nestArray = [] as KnowledgeItem[];
    if (item.children) {
      nestArray = makeTreeList(item.children, expanded, level + 1, search);
    } else {
      outList.push({ ...item, level, hasChildren: false });
    }

    if (item.children) {
      const hasChildren = checkChildren(item);
      outList.push({ ...item, level, hasChildren });
    }

    if (expanded.indexOf(item.id) !== -1 && nestArray.length) {
      outList.push(...nestArray);
    }
  }

  return outList;
};

const makeFaqList = (
  tree: TreeItem[],
  expanded: string[],
  level = 0,
  search: string
) => {
  const outList = [];
  for (let i = 0; i < tree.length; i++) {
    const item = tree[i];

    // skip the item if it doesn't have a search results
    if (search &&
      // This is right condition!
      // Because it's expected for now, that server returns {searchWords: "" (empty string)}
      // It's server issue, but for now this crunch is expected!
      !('searchWords' in item)
      // ==============================
    ) {
      continue;
    }

    let nestArray = [] as KnowledgeItem[];

    if (item.children) {
      nestArray = makeFaqList(item.children, expanded, level + 1, search);
    } else {
      outList.push({
        ...item,
        level,
        hasChildren: !!item.children,
        articles: item.articles && item.articles === 1 ? 0 : 1,
      });
    }

    if (expanded.indexOf(item.id) !== -1 && nestArray) {
      outList.push(...nestArray);
    }
  }
  return outList;
};

export const TreeView: React.FC<TreeViewProps> = ({
  tree,
  onItemClick,
  activeId = '',
}) => {
  const { t } = useTranslation();
  const query = useUrlQuery();
  const [expanded, setExpanded] = useState<string[]>([]);
  const search = query.get('search');

  const treeList = makeTreeList(tree, expanded, 0, search || '');

  const handleExpand = (id: string) => {
    const itemIndex = expanded.indexOf(id);
    if (itemIndex !== -1) {
      expanded.splice(itemIndex, 1);
    } else {
      expanded.push(id);
    }
    setExpanded([...expanded]);
  };

  // Keep expanded items actual regarding {activeId}
  useEffect(() => {
    if (tree && activeId) {
      const trace: Resource[] = [];
      findNode(tree, activeId, trace);
      const currentTab = trace[trace.length - 1];

      if (currentTab) {
        setExpanded(Array.from(new Set([...expanded, ...gather(trace, 'id')])));
      }
    }
  }, [tree, activeId]);
  
  const { openModalTextTemplate } = useModalModifyTextTemplate();

  return (
    <Scroll>
      {!treeList.length ? (
        <NoDataTemplate title={t('noResultsFound')} message={t('noContentMatchCriteria')} />
      ) : (
        <Row vertical='start' gap='md' paddingVertical='extra-lg'>
          {treeList.map((node) => {
            const { id, hasChildren, level, articles } = node;


            const startMargin = 16 * level;
            const isExpanded = expanded.indexOf(id) !== -1;
            const isSelected = id === activeId;
            const color = levels[level];

            return (
              <Row
                key={id}
                directionHorizontal
                vertical='center'
                paddingHorizontal='extra-lg'
                gap='lg'
                className={classNames('tree-node', color, {
                  'is-selected': isSelected
                })}
                style={{
                  marginLeft: startMargin, // Note: is weak logic because of hardcode.
                  gridTemplateColumns: 'auto 1fr'
                }}
              >
                <div
                  style={{ visibility: hasChildren ? 'visible' : 'hidden' }}
                  onClick={() => handleExpand(id)}
                >
                  <div className={isExpanded ? 'minus-icon' : 'plus-icon'}>
                    {isExpanded
                      ? <FontIconView icon={AeFontIcons.minus} />
                      : <FontIconView icon={AeFontIcons.close} />}
                  </div>
                </div>

                <Row
                  directionHorizontal
                  gap="lg"
                  autoColumns
                  horizontal="space-between"
                  vertical="center"
                  className="card tree__node-inner"
                  paddingHorizontal='lg'
                  paddingVertical='md'
                  onClick={() => {
                    // this fix https://awr360.atlassian.net/browse/DMT-516
                    // logic could be changed to change parent item
                    if ( articles === 0) {
                      openModalTextTemplate(id); }
                    else if (!hasChildren || articles === 1) {
                      onItemClick(id);
                    } else {
                      handleExpand(id);
                    }
                  }}
                >
                  <div>
                    <Typography tagName='span' component='body3' className="tree-node__title">
                      {node.title}
                    </Typography>&nbsp;&nbsp;
                    {!!search && node.searchResults && node.searchResults ? (
                      <Badge>{node.searchResults}</Badge>
                    ) : null}
                  </div>

                  {articles === 1 && (
                    <FontIconView icon={AeFontIcons.arrowRight} className="tree-node__arrow" />
                  )}
                </Row>
              </Row>
            );
          })}
        </Row>
      )}
    </Scroll>
  );
};
