import React from 'react';
import { useTranslation } from 'react-i18next';

import {Button, FontIconView, Input} from '../index';

import { InputProps } from './Input';
import AeFontIcons from '../../assets/Icons';

import './InputSearch.scss';

const InputSearch: React.FC<InputProps> = (props) => {
  const { t } = useTranslation();
  
  const searchIcon = <FontIconView icon={AeFontIcons.search} className="input-search__icon" />;

  let slotAfter;

  if (props.value) {
    slotAfter = (
      <>
        <Button
          size={props.size}
          onClick={(e) => {
            if (props.onChange) {
              props.onChange(e as any, '');
            }
          }}
          color='text-title-light'
          variant='transparent'
          iconBefore={<FontIconView icon={AeFontIcons.close} />}
        />
        {searchIcon}
      </>
    );
  } else {
    slotAfter = searchIcon;
  }

  return (
    <Input
      placeholder={t('search')}
      type="text"
      slotAfter={slotAfter}
      {...props}
      wrapperProps={{
        ...props.wrapperProps,
        style: {
          minWidth: 128,
          ...props.wrapperProps?.style,
        }
      }}
    />
  );
};

export default InputSearch;
