import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import SelectList, { SelectListProps, SelectListRenderItemFn } from './SelectList';
import { ListItem, Row, Typography } from '../index';
import SelectCheckmark from './SelectCheckmark';
import { useGetChannels } from '../../query/channels';
import { ChannelPlatformToIconMap, getIsChannelVerified } from '../../utils/channels';

interface SelectListChannelsProps extends Omit<SelectListProps, 'renderItem'> {
}

const SelectListChannels: React.FC<SelectListChannelsProps> = (props) => {
  const { t } = useTranslation();

  const { getChannelListItemById } = useGetChannels();

  const renderItem: SelectListRenderItemFn = useCallback((option, isActive, handleChange) => {
    const key = option.key;

    const channel = getChannelListItemById(option.key);

    if (!channel || !option.label) {
      return null;
    }

    const Icon = ChannelPlatformToIconMap[channel.platform];

    return (
      <ListItem
        key={key}
        hover
        autoColumns
        directionHorizontal
        gap='md'
        horizontal='space-between'
        vertical='center'
        paddingVertical='md'
        paddingHorizontal='extra-sm'
        color='blue-opacity'
        border='bottom'
        dataAttributes={{
          'data-id': key,
        }}
        onClick={handleChange}
      >
        <Row directionHorizontal vertical='center' gap='extra-lg' autoColumns layout='flex'>
          <Icon size='32' />
          <Row>
            <Typography
              component='body3'
              color={isActive ? 'blue' : 'text-body'}
            >{channel.friendlyName}</Typography>
            <Typography component='body4' color={isActive ? 'blue' : 'text-title-light'}>
              {option.label}
              {
                getIsChannelVerified(channel)
                  ? ''
                  : <>&nbsp;-&nbsp;{t('unverified')}</>
              }
            </Typography>
          </Row>
        </Row>
        {isActive ? <div><SelectCheckmark />&nbsp;&nbsp;&nbsp;&nbsp;</div> : null}
      </ListItem>
    );
  }, [t, getChannelListItemById]);

  return (
    <SelectList {...props} renderItem={renderItem} />
  );
};

export default SelectListChannels;
