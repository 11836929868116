import React, { CSSProperties, ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import { Popup } from '../index';
import { usePopup } from '../popup/Popup';
import { SelectListProps } from './SelectList';
import { InputSize } from '../input/InputClean';
import SelectListDefault from './SelectListDefault';
import {
  makeStatelessFormValueUpdateEvent,
  parseSelectValueToPrimitive,
  parseSelectValueToSelectOption
} from './Select.utils';
import { StatelessFormValueUpdateEvent, SelectOption, SelectValue } from './Select.types';

export type SelectContainerPopupMinWidth = 'sm' | 'lg';

export type SelectContainerProps = {
  popupMinWidth?: SelectContainerPopupMinWidth,

  className?: string,
  style?: CSSProperties,
  disabled?: boolean,
  isLoading?: boolean,
  canUnselect?: boolean,
  size?: InputSize,
  invalid?: boolean,

  name?: string,
  defaultValue?: SelectValue,
  value?: SelectValue,
  options: SelectOption[],
  onChange: (e: StatelessFormValueUpdateEvent, value: SelectOption) => void,
  onBlur?: (e: any) => void,
  onFocus?: (e: any) => void,

  listProps?: Partial<SelectListProps>,
  renderInput: (inputProps: {
    name?: string,
    invalid?: boolean,
    value: string,
    onBlur?: (e: any) => void,
    onFocus?: (e: any) => void,
    isOpen: boolean,
    openSelect: (e: any) => void,
    disabled?: boolean,
    size?: 'sm' | 'lg',
  }) => ReactNode,
};
const SelectPopupMinWidthToValueMap: Record<SelectContainerPopupMinWidth, string> = {
  sm: '10rem',
  lg: '26rem'
};

const SelectContainer: React.FC<SelectContainerProps> = ({
  name, isLoading, disabled = isLoading, canUnselect,
  size, invalid, popupMinWidth,

  options, value: valueExternal, defaultValue, onChange, onBlur, onFocus,

  listProps,
  renderInput,
}) => {
  const rootRef = useRef<HTMLDivElement>(null);

  const [filter, setFilter] = useState('');
  const [valueLocal, setValueLocal] = useState<SelectValue>(defaultValue || '');

  const { isOpen, closePopup, openPopup, anchorEl } = usePopup();

  const valueKey = parseSelectValueToPrimitive(valueExternal ?? valueLocal);
  const valueLabel = (options.find((option) => option.key === valueKey)?.label || '');

  const withSearch = options.length > 10;

  const handleHide = useCallback(() => {
    closePopup();

    setFilter('');
  }, [closePopup]);

  const handleOpen = useCallback(() => {
    openPopup({
      currentTarget: rootRef.current,
    });
  }, [openPopup]);

  const handleChange = useCallback((newValue: SelectOption) => {
    setValueLocal(newValue);

    onChange(makeStatelessFormValueUpdateEvent(name, newValue.key), newValue);
  }, [name, onChange]);

  // Reset value if {options} don't have {valueKey}.
  useEffect(() => {
    if (isLoading || disabled || !valueKey) {
      return;
    }

    if (options.findIndex((option) => option.key === valueKey) === -1) {
      handleChange(parseSelectValueToSelectOption(''));
    }
  }, [isLoading, disabled, options, valueKey, handleChange]);

  // Dispatch {onChange} on change {defaultValue}.
  useEffect(() => {
    if (defaultValue) {
      handleChange(parseSelectValueToSelectOption(defaultValue));
    }
  }, [handleChange, defaultValue]);

  return (
    <div ref={rootRef}>
      {renderInput({
        value: valueLabel,
        isOpen,
        openSelect: handleOpen,
        disabled: disabled || !options.length,

        invalid,
        size,
        onFocus,
        onBlur,
        name,
      })}

      <Popup
        preferOpenToBottom
        innerProps={{
          style: {
            width: rootRef.current?.offsetWidth,
            minWidth: popupMinWidth ? SelectPopupMinWidthToValueMap[popupMinWidth] : undefined,
          },
        }}
        minHeight={withSearch ? 225 : 155} // To show at least 3 options.
        anchorEl={anchorEl}
        isShown={isOpen}
        onHide={handleHide}
      >
        <SelectListDefault
          scrollbarsProps={{
            staticScrollView: true,
            autoHeight: true,
            autoHeightMax: 228,
          }}
          withFilter={listProps?.withFilter || withSearch}
          filter={filter}
          onChangeFilter={(e) => {
            setFilter(e.currentTarget.value);
          }}
          options={options}
          value={valueKey}
          onChange={(e, value) => {
            handleChange(value);
            handleHide();

            // Fix popup is reopened: {Field} uses "label" element, that triggers
            // redundant {onClick} on its children and popup reopens.
            e.preventDefault();
          }}
          searchInputProps={{
            size,
            ...listProps?.searchInputProps,
          }}
          canUnselect={canUnselect}
          {...listProps}
        />
      </Popup>
    </div>
  );
};

export default SelectContainer;
