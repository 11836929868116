import { useLocation } from 'react-router-dom';
import {useMemo} from 'react';

export const useUrlQuery = () => {
  const location = useLocation();

  const urlSearchParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location]);

  return urlSearchParams;
};
