import React, {
  createContext,
  MouseEventHandler,
  TouchEventHandler,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';

import PortalRootAfter from '../PortalRootAfter';
import { getClientPositions } from '../../utils/dom';
import {
  FloatWindowContextProps,
  FloatWindowPosition,
  FloatWindowProps,
  FloatWindowStackItem,
  FloatWindowStackItemPosition,
} from './FloatWindow.types';
import { clutch } from '../../utils/common';
import { useToastEnhanced } from '../../enhanced-components/toaster/ToasterEnhanced';
import { useMainContext } from '../../MainProvider';

import './FloatWindow.scss';

export const FloatWindowDraggable: React.FC = (props) => {
  return <div data-draggable=''>{props.children}</div>;
};

const FloatWindow: React.FC<FloatWindowProps> = (props) => {
  const {
    makeFloatWindowOnTop,
    updateFloatWindowPosition,
  } = useFloatWindowContext();

  const [
    positionStart, setPositionStart
  ] = useState<FloatWindowPosition | undefined>(undefined);
  const [position, setPosition] = useState(props.position);
  const [rootElement, setRootElement] = useState<HTMLDivElement | null>(null);

  const { id } = props;

  const handleTouchStart: TouchEventHandler<HTMLDivElement> = useCallback(() => {
    makeFloatWindowOnTop(id);
  }, [id, makeFloatWindowOnTop]);

  const handleMouseDown: MouseEventHandler<HTMLDivElement> = useCallback(() => {
    makeFloatWindowOnTop(id);
  }, [id, makeFloatWindowOnTop]);

  const handleTouchEnd = useCallback(() => {
    setPositionStart(undefined);

    updateFloatWindowPosition(id, position);
  }, [id, updateFloatWindowPosition, position]);

  const handleMouseUp = useCallback(() => {
    setPositionStart(undefined);

    updateFloatWindowPosition(id, position);
  }, [id, updateFloatWindowPosition, position]);

  const handleRootRef = useCallback((el) => {
    if (el) {
      setRootElement(el);
    }
  }, []);

  useEffect(() => {
    let rootWidth: number;
    let rootHeight: number;
    let windowWidth: number;
    let windowHeight: number;
    let offsetX: number;
    let offsetY: number;

    const handleNewPosition = (position: FloatWindowPosition) => {
      setPosition({
        top: clutch(position?.top + offsetY, 0, windowHeight - rootHeight),
        left: clutch(position?.left + offsetX, 0, windowWidth - rootWidth),
      });
    };
    const handleMouseMove = (e: MouseEvent) => {
      handleNewPosition(getClientPositions(e));
    };
    const handleTouchMove = (e: TouchEvent) => {
      handleNewPosition(getClientPositions(e));
    };

    if (positionStart && rootElement) {
      rootWidth = rootElement.offsetWidth;
      rootHeight = rootElement.offsetHeight;
      windowWidth = window.innerWidth;
      windowHeight = window.innerHeight;
      const rootBoundingClientRect = rootElement.getBoundingClientRect();
      offsetX = rootBoundingClientRect.left - positionStart.left;
      offsetY = rootBoundingClientRect.top - positionStart.top;

      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('touchmove', handleTouchMove);
    }

    return () => {
      if (positionStart) {
        window.removeEventListener('mousemove', handleMouseMove);
        window.removeEventListener('touchmove', handleTouchMove);
      }
    };
  }, [rootElement, positionStart]);

  useEffect(() => {
    let draggableElements: NodeListOf<HTMLDivElement> | undefined;

    const handleTouchStart = (e: TouchEvent) => {
      makeFloatWindowOnTop(id);
      setPositionStart(getClientPositions(e));
    };
    const handleMouseDown = (e: MouseEvent) => {
      makeFloatWindowOnTop(id);
      setPositionStart(getClientPositions(e));
    };

    if (rootElement) {
      draggableElements = rootElement
        .querySelectorAll('[data-draggable]') as NodeListOf<HTMLDivElement> | undefined;

      draggableElements?.forEach((el) => {
        el.addEventListener('touchstart', handleTouchStart);
        el.addEventListener('mousedown', handleMouseDown);
      });
    }

    return () => {
      draggableElements?.forEach((el) => {
        if (el) {
          el.removeEventListener('touchstart', handleTouchStart);
          el.removeEventListener('mousedown', handleMouseDown);
        }
      });
    };
  }, [id, makeFloatWindowOnTop, rootElement]);

  return (
    <div
      ref={handleRootRef}
      className={'float-window' + (position ? ' dragging' : '')}
      style={{
        ...position,
        // @ts-ignore
        '--data-z-index': props.zIndex,
      }}
      onMouseUp={handleMouseUp}
      onTouchEnd={handleTouchEnd}
      onTouchCancel={handleTouchEnd}
      onMouseDown={handleMouseDown}
      onTouchStart={handleTouchStart}
    >
      {props.children}
    </div>
  );
};

const FloatWindowContext = createContext<FloatWindowContextProps>({
  openFloatWindow: () => {},
  closeFloatWindow: () => {},
  makeFloatWindowOnTop: () => {},
  updateFloatWindowPosition: () => {},
});

let floatWindowStackShared: FloatWindowStackItem[] = [];
const MAX_FLOAT_WINDOW_STACK_LENGTH = 5;

const FloatWindowProvider: React.FC = (props) => {
  const { t } = useTranslation();
  const [stack, setStack] = useState<FloatWindowStackItem[]>(floatWindowStackShared);
  const { showToast } = useToastEnhanced();
  const { userData } = useMainContext();

  const value: FloatWindowContextProps = useMemo(() => {
    return {
      openFloatWindow: (newStackItem) => {
        setStack((oldStack) => {
          const newStack = [];

          for (let i = 0; i < oldStack.length; i++) {
            if (oldStack.length === MAX_FLOAT_WINDOW_STACK_LENGTH) {
              showToast({
                title: t('floatChatsLimit', { length: MAX_FLOAT_WINDOW_STACK_LENGTH }),
              }, { type: 'error' });

              return oldStack;
            }
            if (oldStack[i].key === newStackItem.key) {
              // eslint-disable-next-line no-console
              console.log(
                '%cError during opening float window! Passed key is not unique!',
                'background: red; color: white;',
                newStackItem
              );
              return oldStack;
            }

            newStack.push(oldStack[i]);
          }

          const lastItem = oldStack[oldStack.length - 1];
          let position: FloatWindowStackItemPosition = {};

          if (lastItem) {
            position.right = (lastItem.position.right || 16) + 16;
          }

          newStack.push({
            ...newStackItem,
            position: { ...position },
            zIndex: oldStack.length + 1,
          });

          return newStack;
        });
      },
      closeFloatWindow: (key) => {
        setStack((oldStack) => {
          if (key) {
            return oldStack.filter((stackItem) => stackItem.key !== key);
          }

          return oldStack.slice(0, -1);
        });
      },
      makeFloatWindowOnTop: (key) => {
        setStack((oldStack) => {
          return oldStack.map((item) => {
            return {
              ...item,
              zIndex: (item.key === key)
                ? oldStack.length
                : Math.min(0, item.zIndex - 1),
            };
          });
        });
      },
      updateFloatWindowPosition: (key, position) => {
        setStack((oldStack) => {
          return oldStack.map((stack) => {
            if (stack.key === key) {
              return {
                ...stack,
                position,
              };
            }

            return stack;
          });
        });
      },
    };
  }, []);

  useEffect(() => {
    window.dispatchEvent(new CustomEvent('floatingWindowStackUpdate', {
      detail: stack,
    }));
    floatWindowStackShared = stack;
  }, [stack]);

  useEffect(() => {
    if (!userData.agentToken) {
      setStack([]);
    }
  }, [userData]);

  return (
    <FloatWindowContext.Provider value={value}>
      {props.children}
      <PortalRootAfter>
        {
          stack.map((item) => {
            return <FloatWindow
              key={item.key}
              id={item.key}
              position={item.position}
              zIndex={item.zIndex}
            >{item.component}</FloatWindow>;
          })
        }
      </PortalRootAfter>
    </FloatWindowContext.Provider>
  );
};

export const useFloatWindowStack = () => {
  const [stack, setStack] = useState<FloatWindowStackItem[]>(floatWindowStackShared);

  const getIsFloatWindowOpenByKey = useCallback((key: string) => {
    return stack.some((stackItem) => stackItem.key === key);
  }, [stack]);

  useEffect(() => {
    const handleFloatingWindowStackUpdate = (e: CustomEvent<FloatWindowStackItem[]>) => {
      setStack(e.detail);
    };

    window.addEventListener('floatingWindowStackUpdate', handleFloatingWindowStackUpdate);

    return () => {
      window.removeEventListener('floatingWindowStackUpdate', handleFloatingWindowStackUpdate);
    };
  }, []);

  return {
    getIsFloatWindowOpenByKey,
  };
};

export const useFloatWindowContext = () => useContext(FloatWindowContext);

export default FloatWindowProvider;
