import React, { useRef } from 'react';

import { Popup, SelectInput } from '../index';
import MultiSelectList from './MultiSelectList';
import { usePopup } from '../popup/Popup';
import { InputProps } from '../input/Input';
import {MultiSelectListProps} from './MultiSelect.types';

export type MultiSelectProps = MultiSelectListProps & {
  inputProps: Partial<InputProps>,
  placeholder?: string;
};

const MultiSelect: React.FC<MultiSelectProps> = ({
  value, onChange, options, inputProps = {}, ...restProps
}) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const { isOpen, closePopup, openPopup, anchorEl } = usePopup();

  const withSearch = options.length > 10;

  return (
    <div ref={rootRef}>
      <SelectInput
        size={restProps.size}
        placeholder={restProps.placeholder}
        {...inputProps}
        isOpen={isOpen}
        disabled={inputProps.disabled || !options.length}
        value={value.map(({ key }) => {
          return options.find((i) => i.key === key)?.label;
        }).join(', ')}
        wrapperProps={{
          onClick: (e) => {
            openPopup({
              currentTarget: e.currentTarget
            });
          },
        }}
      />
      <Popup
        isShown={isOpen}
        anchorEl={anchorEl}
        preferOpenToBottom={true}
        minHeight={withSearch ? 225 : 155} // To show at least 3 options.
        innerProps={{
          style: {
            width: rootRef.current?.offsetWidth
          },
        }}
        onHide={closePopup}
      >
        <MultiSelectList
          withFilter={withSearch}
          value={value}
          onChange={onChange}
          options={options}
          {...restProps}
        />
      </Popup>
    </div>
  );
};

export default MultiSelect;
