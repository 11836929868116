import React from 'react';
import { useTranslation } from 'react-i18next';

import {ButtonClose} from '../index';
import { Achievement } from './types';
import { ProgressBar } from '../progress-bar/ProgressBar';
import Row from '../row/Row';
import { useSidePanelContext } from '../side-panel/SidePanelProvider';

interface AchievementInfoProps {
  item: Achievement;
}

export const AchievementInfo: React.FC<AchievementInfoProps> = ({ item }) => {
  const { t } = useTranslation();
  const { closePanel } = useSidePanelContext();

  return (
    <div className="side-panel__inner-wrap">
      <div className="side-panel__title">
        <span className="side-panel__title-text">{t('achievementInfo')}</span>
      </div>
      <div className="side-panel__content">
        <div className="achievement-info">
          <div className="achievement-item">
            <div className="achievement-item__icon">
              <img src={item.icon} alt={item.title} />
            </div>
            <div className="achievement-item__content">
              <span className="achievement-item__title">{item.title}</span>
              <span className="achievement-item__subtitle">
                {t('finishThe')} {t('toGetAchievement')}
              </span>
              <ProgressBar percentage={item.progress} type='line' color="green" />
            </div>
          </div>
          <div className="achievement-info__description">
            {item.description}
          </div>
        </div>
      </div>
      <Row gap='lg' directionHorizontal layout="flex" horizontal="end">
        <ButtonClose onClick={closePanel} />
      </Row>
    </div>
  );
};
