import React from 'react';
import { Achievement } from './types';
import { useSidePanelContext } from '../side-panel/SidePanelProvider';
import { AchievementInfo } from './AchievementsInfo';
import { ProgressBar } from '../progress-bar/ProgressBar';

interface AchievementItemProps {
  item: Achievement;
}

export const AchievementItem: React.FC<AchievementItemProps> = ({ item }) => {
  const { openPanel } = useSidePanelContext();

  const openCourseInfo = () => {
    openPanel({ component: <AchievementInfo item={item} /> });
  };

  return (
    <div className="achievement-item" onClick={openCourseInfo}>
      <ProgressBar percentage={item.progress} type="circle" color="green" showIsCompleted>
        <div className="achievement-item__icon">
          <img src={item.icon} alt={item.keyName} />
        </div>
      </ProgressBar>
      <span className="achievement-item__text">{item.title}</span>
    </div>
  );
};
